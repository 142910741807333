import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../common/logo";
import CreateWorkspace from "./Steps/CreateWorkspace";
import InviteMembers from "./Steps/InviteMembers";
import Stepper from "./Steps/Stepper";
import Usage from "./Steps/Usage";

type Step = 1 | 2 | 3;

export const enum USAGE_TYPE {
  "WORK" = "work",
  "EDUCATION" = "education",
  "PERSONAL" = "personal",
}

export type CreateOrganizationRes = {
  _id: string;
  __typename: string;
  orgType: string;
  name: string;
  accessCode: string;
};

const imageUrls = [
  "https://storage.googleapis.com/minted-nfts/minted/minted-config/images/minted-onboarding-01.png",
  "https://storage.googleapis.com/minted-nfts/minted/minted-config/images/minted-onboarding-02.png",
  "https://storage.googleapis.com/minted-nfts/minted/minted-config/images/minted-onboarding-03.png",
];

function JoinOrganization() {
  let { step: stepParams } = useParams();
  const navigate = useNavigate();

  // For Step 1: Usage
  const [usage, setUsage] = useState<USAGE_TYPE | undefined>();
  const [organization, setOrganization] = useState<CreateOrganizationRes>();

  // step param check
  if (!stepParams || ![1, 2, 3].includes(+stepParams)) navigate("/");

  const step = stepParams ? (+stepParams as Step) : 1; // falsy should not happen

  // Stying
  const sectionMinW = 400;

  return (
    <Box>
      <Flex minH={"800px"} flexWrap={"wrap"}>
        <Box flex={1} minW={sectionMinW} p={10}>
          <Box onClick={() => navigate("/")} cursor={"pointer"}>
            <Logo />
          </Box>
          <Box mt={8} w={"50%"}>
            <Stepper totalSteps={3} currentStep={step as Step} />
          </Box>
          <Box pl={3}>
            {
              {
                1: <Usage usage={usage} setUsage={setUsage} nextStep={() => navigate("/joinMinted/2")} />,
                2: (
                  <CreateWorkspace
                    nextStep={() => navigate("/joinMinted/3")}
                    orgType={(usage as string) || ""}
                    organization={organization}
                    setOrganization={setOrganization}
                  />
                ),
                3: <InviteMembers organization={organization} />,
              }[step]
            }
          </Box>
        </Box>
        <Box
          flex={1}
          minW={sectionMinW}
          p={"8%"}
          bg={"secondary.900"}
          display="flex"
          justifyContent={"center"}
          alignContent={"center"}
        >
          <Image rounded="md" fit="contain" src={imageUrls[step - 1]} />
        </Box>
      </Flex>
    </Box>
  );
}

export default JoinOrganization;
