import {
  Box,
  Flex,
  HStack,
  Text,
  Card,
  CardBody,
  Stack,
  Heading,
  Icon,
  FormControl,
  Input,
  Textarea,
  VStack,
  IconButton,
  InputGroup,
  InputRightElement,
  Tooltip,
  Switch,
  Button,
  Image,
  useClipboard,
} from "@chakra-ui/react";
import MasterBox from "../Layout/MasterBox";
import { createContractData } from "../../util/Constants";
import { useEffect, useRef, useState } from "react";
import { SystemProps, useColorModeValue } from "@chakra-ui/system";
import Label from "../common/Form/Label";
import { CopyIcon } from "@chakra-ui/icons";
import { FiUpload } from "react-icons/fi";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";

const layoutStyles = {
  labelWidth: ["full", "full", "150px"],
  containerWidth: ["xs", "sm", "lg", "md", "2xl"],
  containerDirection: ["column", "column", "column", "row", "row"],
};

const CreateContractForm = (props: any) => {
  const { setRenderedComponentValue, formData } = props;
  const { hasCopied, setValue: setCopyValue, onCopy } = useClipboard("");
  const NFTImageInputRef = useRef<HTMLInputElement>(null);

  formData["contractType"] = "GotmintedSoulebound";
  formData["blockchain"] = "Polygon";

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: formData,
  });

  const nftImage = watch("media");

  useEffect(() => {
    reset(formData);
  }, [formData]);

  const onSubmit = handleSubmit((data) => {
    console.log(data);
  });

  return (
    <Box mt={10}>
      <MasterBox>
        <Box py={5}>
          <ArrowBackIcon onClick={() => setRenderedComponentValue(false)} cursor={"pointer"} />
        </Box>
        <Box maxW={1000}>
          <form onSubmit={onSubmit}>
            <Flex justify={"space-between"} flexDirection={["column", "column", "column", "row"]}>
              <Box>
                <Flex
                  py={4}
                  w={layoutStyles.containerWidth}
                  direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                >
                  <Box w={layoutStyles.labelWidth}>
                    <Label>Title</Label>
                  </Box>
                  <Box w="full">
                    <FormControl isInvalid={errors.title ? true : false}>
                      <Input
                        type="text"
                        disabled
                        {...register("title", {
                          required: "This is required",
                        })}
                      />
                    </FormControl>
                  </Box>
                </Flex>

                <Flex
                  py={4}
                  w={layoutStyles.containerWidth}
                  direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                >
                  <Box w={layoutStyles.labelWidth}>
                    <Label>Description</Label>
                  </Box>
                  <Box w="full">
                    <FormControl isInvalid={errors.description ? true : false}>
                      <Textarea variant={"outline"} disabled {...register("description")} />
                    </FormControl>
                  </Box>
                </Flex>
              </Box>

              <VStack align={"start"}>
                <Box>
                  <Label>Banner</Label>
                  <Text fontSize={13}>Profile URL (1400/200px)</Text>
                </Box>

                <Flex direction={layoutStyles.containerDirection as SystemProps["flexDirection"]} width={300}>
                  <Box w="full">
                    <input
                      type="file"
                      {...register("media", {
                        required: "Image is required",
                      })}
                      onChange={(e) => {
                        setValue("media", e.target.files?.[0]);
                      }}
                      accept={"image/*"}
                      name={"media"}
                      ref={NFTImageInputRef}
                      style={{ display: "none" }}
                    />
                    <Flex
                      width={300}
                      height={150}
                      bg={useColorModeValue("black.100", "black.200")}
                      justifyContent={"center"}
                      alignItems={"center"}
                      cursor={"pointer"}
                      rounded={10}
                      boxShadow={"md"}
                    >
                      {nftImage ? (
                        <Image
                          src={URL.createObjectURL(nftImage)}
                          onClick={() => NFTImageInputRef?.current?.click()}
                          alt="Image Preview"
                          objectFit={"contain"}
                          width={300}
                          height={150}
                        />
                      ) : (
                        <Flex direction={"column"} px={5} textAlign={"center"}>
                          <IconButton
                            icon={<FiUpload />}
                            variant={"ghost"}
                            aria-label="profile-image"
                            size={"lg"}
                            onClick={() => NFTImageInputRef?.current?.click()}
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Box>
                </Flex>
              </VStack>
            </Flex>
            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                <Label>Contract Type</Label>
              </Box>
              <Box w="full" display="flex" alignItems="center">
                <InputGroup>
                  <Input type="text" variant={"filled"} disabled {...register("contractType")} />
                  <InputRightElement
                    cursor={"pointer"}
                    onClick={() => {
                      setCopyValue(formData.contractType);
                      onCopy();
                    }}
                  >
                    <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                      <Icon as={CopyIcon} />
                    </Tooltip>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Flex>

            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                <Label>Block Chain</Label>
              </Box>
              <Box w="full" display="flex" alignItems="center">
                <InputGroup>
                  <Input type="text" variant={"filled"} disabled {...register("blockchain")} />
                </InputGroup>
              </Box>
            </Flex>

            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                <Label>Credential</Label>
              </Box>

              <HStack w="full">
                <Switch size={"lg"} {...register("isCredential", {})} />
                <Text>Determines if Users can "Add to Linkedin" on Profile Page</Text>
              </HStack>
            </Flex>
            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                <Label>Visibility</Label>
              </Box>
              <HStack w="full">
                <Switch size={"lg"} {...register("isVisible", {})} />
                <Text>Determines if NFT displays in the explore page</Text>
              </HStack>
            </Flex>
            <HStack py={10} spacing={5}>
              <Button rounded={"lg"} size={"lg"}>
                Cancel
              </Button>
              <Button rounded={"lg"} type="submit" size={"lg"}>
                Deploy
              </Button>
            </HStack>
          </form>
        </Box>
      </MasterBox>
    </Box>
  );
};

const UniqueContract = (props: any) => {
  const { setRenderedComponentValue, setFormData } = props;

  return (
    <MasterBox>
      <Flex mt={[5, 10]} mb={[2, 5]} flexDirection="column">
        <Text textStyle={"h1"} fontWeight={"normal"}>
          {"Create Collection"}
        </Text>
      </Flex>
      <Flex flexWrap={"wrap"}>
        {createContractData.map((data) => (
          <Card
            maxW="sm"
            minH={200}
            m={5}
            onClick={() => {
              setRenderedComponentValue(true), setFormData(data);
            }}
            cursor="pointer"
            key={data.id}
          >
            <CardBody>
              <Stack mt="6" spacing="3">
                <HStack>
                  <Icon as={data.icon} w={8} h={8} />
                  <Heading size="md">{data.title}</Heading>
                </HStack>
                <Text>{data.description}</Text>
              </Stack>
            </CardBody>
          </Card>
        ))}
      </Flex>
    </MasterBox>
  );
};

export const CreateContract = () => {
  const [renderedcomponentValue, setRenderedComponentValue] = useState(false);
  const [formData, setFormData] = useState(null);

  if (renderedcomponentValue) {
    return <CreateContractForm setRenderedComponentValue={setRenderedComponentValue} formData={formData} />;
  }
  return <UniqueContract setRenderedComponentValue={setRenderedComponentValue} setFormData={setFormData} />;
};
