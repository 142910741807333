import { useState } from "react";
import axios from "axios";
import { appConfig } from "../util/Constants";
import { getUniqueListBy, makeQueryStringForArray } from "../util/helper";

function useFetchNFT() {
  const [nfts, setNfts] = useState<[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchNFTs = async (contractAddresses: string[], withMetadata: boolean) => {
    setLoading(true);
    try {
      const baseURL = `${appConfig.ALCHEMY_POLYGON_URL}/getNFTsForCollection`;
      const getAll = async () => {
        let nfts: any[] = [];
        for (let contractAddress of contractAddresses) {
          let nextToken = undefined;
          let callNum = 0;
          while (nextToken || callNum === 0) {
            const config = {
              method: "get",
              url: nextToken
                ? `${baseURL}?contractAddress=${contractAddress}&withMetadata=${withMetadata}&startToken=${nextToken}`
                : `${baseURL}?contractAddress=${contractAddress}&withMetadata=${withMetadata}`,
              headers: {},
            };
            const res: any = await axios(config);
            if (res.data?.nfts && Array.isArray(res.data?.nfts)) nfts.push(...res.data.nfts);
            nextToken = res.data?.nextToken;
            callNum++;
          }
        }
        return nfts;
      };
      const allNFTs = await getAll();
      setNfts(getUniqueListBy(allNFTs || [], "contract.address", "id.tokenId") as []);
      setLoading(false);
      setError(null);
    } catch (error: any) {
      setLoading(false);
      setError(error);
    }
  };

  return { nfts, loading, error, fetchNFTs };
}

export default useFetchNFT;
