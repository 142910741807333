import { appConfig } from "./Constants";
// Handle To open the link in new tab
export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

// Owner Address
export const getstandardizedOwnerAddress = (address: string) => {
  return address.length ? `${address.slice(0, 6)}....${address.slice(-4)}` : "";
};

export const getStandardizedNFTName = (name: string) => {
  const maxChar = 52;
  return name && name.length > maxChar ? `${name.slice(0, maxChar).trim()}...` : name;
};

// get paper flow link
export const getPaperFlowLink = (contractAddress: string, id: number) => {
  const paperLinks = getParameterCaseInsensitive(contractAddress);
  if (paperLinks && paperLinks[id]) {
    return paperLinks[id];
  } else {
    return "";
  }
};

function getParameterCaseInsensitive(key: string) {
  const asLowercase = key.toLowerCase();
  const object = appConfig.PAPER_FLOW_URLs;
  return object[Object.keys(object).find((k) => k.toLowerCase() === asLowercase) || ""];
}

export const makeQueryStringForArray = (data: string[], key: string) => {
  let queryString = "";
  data.forEach((element, index) => {
    queryString = queryString + `${key}[]=${element}`;
    if (index !== data.length - 1) {
      queryString = queryString + "&";
    }
  });
  return queryString;
};

export const defaultAvatarURL = () => {
  return `/img/avatars/${Math.floor(Math.random() * 5) + 1}.svg`;
};

// Clean Error Messages
export const getCleanErrorMessage = (message: string) => {
  if (message.includes("You are not allowed to mint this token")) {
    return "Sorry! This token requires your wallet to belong to an allowlist. If you believe you should be allowed to mint this token, please contact your admin or press the 'chat' button in the lower right of your screen";
  } else if (message.includes("Invariant failed")) {
    return "Oops! Your transaction could not be completed. Please log out (top-right corner with your wallet address and log back in. If this does not work, please contact your admin, or press the 'chat' button in the lower right of your screen";
  } else if (message.includes("User denied account access")) {
    return "Oops! Your magic session has expired. Please log out (top-right corner with your wallet address and log back in. If this does not work, please contact your admin, or press the 'chat' button in the lower right of your screen";
  } else {
    return message;
  }
};

export const getPropertyByString = (object: any, propString: string) => {
  let value = object;
  const props = propString.split(".");
  for (let index = 0; index < props.length; index += 1) {
    if (props[index] === undefined) break;
    value = value[props[index]];
  }
  return value;
};

export const getUniqueListBy = (arr: any[], key: string, key2?: string) => {
  const uniqueIds: any[] = [];
  const unique = arr.filter((element) => {
    const item = getPropertyByString(element, key);
    let item2 = "";
    if (key2) {
      item2 = getPropertyByString(element, key2);
    }
    const newItem = item.toString().toLowerCase() + item2.toString().toLowerCase();
    const isDuplicate = uniqueIds.includes(newItem);
    uniqueIds.push(newItem);
    if (!isDuplicate) {
      return true;
    }
    return false;
  });
  return unique;
};

export const hexToDecimal = (hex: string) => parseInt(hex, 16);

export const dataURLtoFile = (dataURL: string, fileName: string) => {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1] || "";
  const bstr = window.atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n) {
    n = n - 1;
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};
