import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

function SuccessModal(props: {
  isOpen: boolean;
  onClose: any;
  ctaAction: any;
  title: string;
  description: string;
  subDescription: string;
  ctaTitle: string;
}) {
  const { isOpen, onClose, ctaAction, title, description, subDescription, ctaTitle } = props;
  const textColor = useColorModeValue("black.300", "white");
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"sm"} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent borderRadius={"2xl"} py={5}>
        <ModalCloseButton />
        <ModalBody textAlign={"center"} pt={10}>
          <Flex justifyContent={"center"}>
            <Stack
              bg="secondary.900"
              height={"80px"}
              width={"80px"}
              alignItems={"center"}
              justifyContent={"center"}
              borderRadius={40}
            >
              <Icon color={"primary.800"} w={7} h={7} as={CheckIcon} />
            </Stack>
          </Flex>
          <Text textStyle={"h2"} px={6} pt={5} pb={2}>
            {title}
          </Text>
          <Text textStyle={"paragraph"} color={textColor}>
            {description}
          </Text>
          <Text textStyle={"paragraph"} color={textColor}>
            {subDescription}
          </Text>
          <Button onClick={onClose} mt={4}>
            {ctaTitle}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SuccessModal;
