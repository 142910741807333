import { SmallCloseIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Image,
  Input,
  Select,
  Spinner,
  Stack,
  StackDirection,
  Switch,
  SystemProps,
  Text,
  Textarea,
  useColorModeValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import { useState, useRef, useEffect, useContext } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { FiUpload } from "react-icons/fi";
import {
  IMetaDataJSON,
  IFILE_UPLOAD_TYPE,
  IFormValues,
  IMintNFTData,
} from "../../../Interfaces/minterApp/CreateNFTForm";
import { appConfig } from "../../../util/Constants";
import { dataURLtoFile, getstandardizedOwnerAddress, hexToDecimal } from "../../../util/helper";
import Label from "../../common/Form/Label";
import GenerateArtModal from "../Modal/GenerateArtModal";
import MintingOnBlockChainModal from "../Modal/MintingOnBlockChainModal";
import { useNavigate } from "react-router-dom";
import useAdminFunctions from "../../../hooks/Dashboard/AdminFunctions";
import { ConnectWalletContext, ConnectWalletContextType } from "../../../context/connectWalletContext";

const layoutStyles = {
  labelWidth: ["full", "full", "150px"],
  containerWidth: ["xs", "sm", "lg", "md", "2xl"],
  containerDirection: ["column", "column", "column", "row", "row"],
};

const imageSize = ["250px", "250px", "250px", "350px", "350px"];

function CreateNFTForm(props: { collections: any; organizationName: string }) {
  const { collections, organizationName } = props;
  const [metadataJson, setMetadataJson] = useState<IMetaDataJSON | null>(null);
  const [mintNFTData, setMintNFTData] = useState<IMintNFTData | null>(null);
  const [maxSupply, setMaxSupply] = useState<number>(1);
  const [isTransferable, setIsTransferable] = useState<boolean>(false);
  const [isAllowListRequired, setIsAllowListRequired] = useState<boolean>(true);
  const [isRevealed, setIsRevealed] = useState<boolean>(false);
  const { address: walletAddress } = useContext(ConnectWalletContext) as ConnectWalletContextType;

  const NFTImageInputRef = useRef<HTMLInputElement>(null);
  const {
    isOpen: isOpenMintingModal,
    onOpen: onOpenMintingModal,
    onClose: onCloseMintingModal,
  } = useDisclosure({
    defaultIsOpen: true,
  });
  const navigate = useNavigate();

  const {
    isOpen: isOpenGenerateArtModal,
    onOpen: onOpenGenerateArtModal,
    onClose: onCloseGenerateArtModal,
  } = useDisclosure();

  // For uploading image file to GCP
  const [{ data: uploadImageData, loading: uploadImageLoading, error: uploadImageError }, uploadImage] = useAxios(
    {
      baseURL: appConfig.REST_API_URL,
      url: "/upload",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "cache-control": "no-cache",
      },
      method: "POST",
    },
    {
      manual: true,
    }
  );

  // For uploading JSON file to GCP
  const [{ data: uploadJsonData, loading: uploadJsonLoading, error: uploadJsonError }, uploadJson] = useAxios(
    {
      baseURL: appConfig.REST_API_URL,
      url: "/upload",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "cache-control": "no-cache",
      },
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm<IFormValues>({
    defaultValues: {
      properties: [
        {
          trait: "",
          traitValue: "",
        },
      ],
      contract: collections && collections.length > 0 ? collections[0].contractAddress : "",
    },
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
    rules: {
      minLength: 0,
      maxLength: 10,
    },
  });
  const nftImage = watch("media");
  const contractAddress = watch("contract");
  const tokenId = watch("tokenId");

  // Fetching next token Id from collection
  const { contract, getNextTokenId, mintNFTV3, mintNFT, burnNFT, airdropSingleToSingle } = useAdminFunctions({
    contractAddress,
  });

  useEffect(() => {
    if (contract) {
      fetchGetNextTokenId();
    }
  }, [contract]);

  const fetchGetNextTokenId = async () => {
    const result = await getNextTokenId();
    setValue("tokenId", `${hexToDecimal(result)}`);
  };

  const resetForm = () => {
    reset();
    fetchGetNextTokenId();
  };

  useEffect(() => {
    if (metadataJson && uploadImageData) {
      const str = JSON.stringify({
        name: metadataJson.name,
        description: metadataJson.description,
        attributes: metadataJson.attributes,
        image: uploadImageData?.url || "",
      });
      const encoded = window.btoa(str);
      const fileName = `${metadataJson.tokenId}.json`;
      const file = dataURLtoFile(`data:application/JSON;base64,${encoded}`, fileName);
      let formData = new FormData();
      formData.append("file", file);
      formData.append("organizationName", organizationName);
      formData.append("fileType", IFILE_UPLOAD_TYPE.NFT_METADATA);
      formData.append("collectionName", metadataJson.selectedContractName);
      formData.append("fileName", fileName);

      // Uploading JSON meta data
      uploadJson({
        data: formData,
      });

      setMetadataJson(null);
    }
  }, [uploadImageData, metadataJson]);

  useEffect(() => {
    if (uploadJsonData) {
      setMintNFTData({
        tokenId,
        metaDataURI: uploadJsonData.url,
      });
      onOpenMintingModal();
    }
  }, [uploadJsonData]);

  const onSubmit = handleSubmit((data) => {
    const selectedContractName = collections.filter((item: any) => item.contractAddress === data.contract)[0].title;
    // 1.Creating Data for file upload
    let formData = new FormData();
    formData.append("file", data.media || "");
    formData.append("organizationName", organizationName);
    formData.append("fileType", IFILE_UPLOAD_TYPE.NFT_IMAGE);
    formData.append("collectionName", selectedContractName);
    formData.append("fileName", `${data.tokenId}.${data?.media?.name?.split(".")[1] || ""}`);

    // Uploading Image
    uploadImage({
      data: formData,
    });

    setMetadataJson({
      name: data.title,
      description: data.description,
      tokenId: data.tokenId,
      attributes: data.properties.map((item) => ({ trait_type: item.trait, value: item.traitValue })),
      selectedContractName,
    });
  });

  const creatNextTokenOnBlockChain = async () => {
    try {
      const result = await mintNFTV3(
        mintNFTData?.metaDataURI || "",
        maxSupply,
        isTransferable,
        isAllowListRequired,
        !isRevealed
      );
      return result;
    } catch (error) {
      return error;
    }
  };

  const mintOnBlockChain = async () => {
    try {
      const result = await airdropSingleToSingle(walletAddress || "", tokenId);
      return result;
    } catch (error) {
      return error;
    }
  };

  const burnNFTOnBlockChain = async () => {
    try {
      const result = await burnNFT(walletAddress || "", tokenId, 1);
      return result;
    } catch (error) {
      return error;
    }
  };

  return (
    <Box pb={10}>
      {(uploadImageError || uploadJsonError) && (
        <Alert status="error">
          <AlertIcon />
          {uploadImageError?.response?.data?.message || "Something went wrong please try again"}
          {uploadJsonError?.response?.data?.message || "Something went wrong please try again"}
        </Alert>
      )}

      {(uploadImageLoading || uploadJsonLoading) && (
        <Alert status="info">
          <Spinner />
          <Text style={{ marginLeft: 10 }}>{`Uploading ${
            uploadImageLoading ? "nft image" : "JSON"
          } file please wait for few seconds`}</Text>
        </Alert>
      )}

      <form onSubmit={onSubmit}>
        <Flex
          py={4}
          w={layoutStyles.containerWidth}
          direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
        >
          <Box w={layoutStyles.labelWidth}>
            <Label>Contract</Label>
          </Box>
          <Box w="full">
            <FormControl isInvalid={errors.contract ? true : false}>
              <Select
                placeholder="Select the Contract"
                {...register("contract", {
                  required: "This is required",
                })}
              >
                {collections.map((item: any, index: number) => (
                  <option value={item?.contractAddress || ""} key={`${item.title}${index}`}>
                    {`${item?.title || ""} - ${getstandardizedOwnerAddress(item?.contractAddress || "")}`}
                  </option>
                ))}
              </Select>
              <FormErrorMessage>{errors.contract && errors.contract.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </Flex>

        <Flex
          py={4}
          w={layoutStyles.containerWidth}
          direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
        >
          <Box w={layoutStyles.labelWidth}>
            <Label>Title</Label>
          </Box>
          <Box w="full">
            <FormControl isInvalid={errors.title ? true : false}>
              <Input
                type="text"
                placeholder="Enter a title for your NFT"
                {...register("title", {
                  required: "This is required",
                  minLength: { value: 4, message: "Minimum length should be 4" },
                })}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </Flex>

        <Flex
          py={4}
          w={layoutStyles.containerWidth}
          direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
        >
          <Box w={layoutStyles.labelWidth}>
            <Label>Description</Label>
          </Box>
          <Box w="full">
            <FormControl isInvalid={errors.description ? true : false}>
              <Textarea
                variant={"outline"}
                placeholder="Description for your NFT"
                {...register("description", {
                  required: "This is required",
                  minLength: { value: 4, message: "Minimum length should be 4" },
                })}
              />
              <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
            </FormControl>
          </Box>
        </Flex>

        {/* Media */}
        <Flex>
          <Flex py={4} w={"full"} direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}>
            <Box w={["full, full", "130px"]}>
              <Label>Media</Label>
            </Box>
            <Flex direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}>
              <Box w="full">
                <input
                  type="file"
                  {...register("media", {
                    required: "Image is required",
                  })}
                  onChange={(e) => {
                    setValue("media", e.target.files?.[0]);
                  }}
                  accept={"image/*"}
                  name={"media"}
                  ref={NFTImageInputRef}
                  style={{ display: "none" }}
                />
                <Flex
                  height={imageSize}
                  width={imageSize}
                  bg={useColorModeValue("black.100", "black.200")}
                  justifyContent={"center"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  rounded={10}
                  boxShadow={"md"}
                >
                  {nftImage ? (
                    <Image
                      src={URL.createObjectURL(nftImage)}
                      onClick={() => NFTImageInputRef?.current?.click()}
                      alt="Image Preview"
                      objectFit={"contain"}
                      height={imageSize}
                      width={imageSize}
                    />
                  ) : (
                    <Flex direction={"column"} px={5} textAlign={"center"}>
                      <Text textStyle={"h3"} mb={2}>
                        Upload Your Media
                      </Text>
                      <Text textStyle={"paragraphMedium"}>PNG, JPG, GIF, or JPEG files are allowed</Text>

                      <IconButton
                        icon={<FiUpload />}
                        variant={"ghost"}
                        aria-label="profile-image"
                        size={"lg"}
                        onClick={() => NFTImageInputRef?.current?.click()}
                      />
                      <Text textStyle={"paragraphMedium"} color={"red"}>
                        {errors.media && errors.media?.message}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Box>

              <Flex
                align={["flex-start", "flex-start", "flex-start", "center"]}
                direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
              >
                <Box>
                  <Text textStyle={"h3"} mb={2} px={16} py={5}>
                    Or
                  </Text>
                </Box>
                <Box>
                  <Flex
                    height={imageSize}
                    width={imageSize}
                    bg={useColorModeValue("black.100", "black.200")}
                    justifyContent={"center"}
                    alignItems={"center"}
                    cursor={"pointer"}
                    rounded={10}
                    boxShadow={"md"}
                  >
                    <Flex direction={"column"} px={5} textAlign={"center"}>
                      <Text textStyle={"h3"} mb={2}>
                        Create AI Generated Art
                      </Text>
                      <Button size={"lg"} onClick={onOpenGenerateArtModal}>
                        Create with DALL-E 2
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        {/* Properties */}
        <Flex direction={"column"}>
          <Flex pt={4} w={"full"} direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}>
            <Box w={["full, full", "132px"]}>
              <Label>Properties</Label>
            </Box>

            <Flex direction={"column"} w={"full"}>
              {fields.map((item, index: number) => {
                return (
                  <Stack
                    spacing={5}
                    w={"67%"}
                    key={item.id}
                    mt={index === 0 ? 0 : 5}
                    direction={layoutStyles.containerDirection as StackDirection}
                  >
                    <FormControl isInvalid={errors?.properties?.[index]?.trait ? true : false}>
                      <Input
                        type="text"
                        placeholder="Trait(e.g Type) "
                        {...register(`properties.${index}.trait` as const)}
                      />
                      <FormErrorMessage>
                        {errors?.properties?.[index]?.trait?.message && errors?.properties?.[index]?.trait?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors?.properties?.[index]?.traitValue ? true : false}>
                      <Input
                        type="text"
                        placeholder="Value(e.g Avatar) "
                        {...register(`properties.${index}.traitValue` as const)}
                      />
                      <FormErrorMessage>
                        {errors?.properties?.[index]?.traitValue?.message &&
                          errors?.properties?.[index]?.traitValue?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <IconButton
                      aria-label="delete property for nft"
                      icon={<SmallCloseIcon />}
                      variant="unstyled"
                      onClick={() => remove(index)}
                    />
                  </Stack>
                );
              })}
            </Flex>
          </Flex>

          <Flex mt={5}>
            <Box minW={["full, full", "122px"]} display={["none", "none", "none", "block"]}></Box>
            <Box>
              <Button
                onClick={() => {
                  append({ trait: "", traitValue: "" });
                }}
                variant={"filled"}
              >
                Add More
              </Button>
            </Box>
          </Flex>
        </Flex>

        {/* Token ID */}
        <Flex
          align={["flex-start", "flex-start", "flex-start", "flex-start", "center"]}
          justifyContent={"space-between"}
          direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
        >
          <Flex
            py={4}
            w={layoutStyles.containerWidth}
            direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
          >
            <Box w={layoutStyles.labelWidth}>
              <Label>Token ID</Label>
            </Box>
            <Box w="full">
              <FormControl isInvalid={errors.tokenId ? true : false}>
                <Input
                  type="text"
                  placeholder="Enter Token # to mint"
                  {...register("tokenId", {
                    required: "This is required",
                  })}
                  disabled
                />
                <FormErrorMessage>{errors.tokenId && errors.tokenId.message}</FormErrorMessage>
              </FormControl>
            </Box>
          </Flex>
          <HStack py={10} spacing={5}>
            <Button rounded={"lg"} onClick={() => navigate(`/dashboard`)} size={"lg"} disabled={uploadImageLoading}>
              Cancel
            </Button>
            <Button rounded={"lg"} type="submit" size={"lg"} disabled={uploadImageLoading}>
              Create
            </Button>
          </HStack>
        </Flex>
      </form>

      <Accordion allowToggle>
        <AccordionItem>
          <AccordionButton
            borderTop={"1px"}
            borderTopStyle={"solid"}
            borderTopColor={"gray.400"}
            borderBottom={"1px"}
            borderBottomStyle={"solid"}
            borderBottomColor={"gray.400"}
          >
            <Box flex="1" textAlign="left">
              <Text textStyle={"h3"}>Advanced Configuration</Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <HStack mt={[5, 5, 10]} spacing={[5, 5, 5, 10]}>
              <Text textStyle={"h3"}>Max Supply</Text>
              <HStack align={"center"} spacing={10}>
                <Input
                  placeholder="Max supply"
                  size="md"
                  value={maxSupply}
                  onChange={(event) => {
                    setMaxSupply(+event.target.value);
                  }}
                />
                <Button variant="link" onClick={() => setMaxSupply(0)}>
                  Set to unlimited
                </Button>
                <Text>OR</Text>
                <Button variant="link" onClick={() => setMaxSupply(1)}>
                  One of One NFT
                </Button>
              </HStack>
            </HStack>
            <HStack mt={[5, 5, 10]} spacing={[5, 5, 5, 10]}>
              <Switch
                size="lg"
                isChecked={isRevealed}
                onChange={() => {
                  setIsRevealed(!isRevealed);
                }}
              />
              <VStack align={"flex-start"} spacing={0}>
                <Text textStyle={"h3"}>Hide NFT</Text>
                <Text textStyle={"paragraphMedium"}>
                  if toggled on, NFT will display pre-reveal media. If no media is uploaded. NFT will not display
                </Text>
              </VStack>
            </HStack>
            <HStack mt={[5, 5, 10]} spacing={[5, 5, 5, 10]}>
              <Switch
                size="lg"
                isChecked={!isTransferable}
                onChange={() => {
                  setIsTransferable(!isTransferable);
                }}
              />
              <VStack align={"flex-start"} spacing={0}>
                <Text textStyle={"h3"}>Non-Transferable</Text>
                <Text textStyle={"paragraphMedium"}>
                  if toggled on, NFT cannot be transfered to another wallet or sold on marketplaces.
                </Text>
              </VStack>
            </HStack>
            <HStack mt={[5, 5, 10]} spacing={[5, 5, 5, 10]}>
              <Switch
                size="lg"
                isChecked={isAllowListRequired}
                onChange={() => {
                  setIsAllowListRequired(!isAllowListRequired);
                }}
              />
              <VStack align={"flex-start"} spacing={0}>
                <Text textStyle={"h3"}>Allow List</Text>
                <Text textStyle={"paragraphMedium"}>
                  if toggled on, users cannot mint unless they are on the allow list, Configure allow list in the NFT
                  dashboard
                </Text>
              </VStack>
            </HStack>
            {/* <Flex justifyContent={"flex-end"} mt={[5, 5, 10]}>
              <Button size={"lg"}>Finish</Button>
            </Flex> */}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {mintNFTData && (
        <MintingOnBlockChainModal
          isOpen={isOpenMintingModal}
          onClose={onCloseMintingModal}
          contractAddress={contractAddress}
          metaDataURI={mintNFTData?.metaDataURI || ""}
          tokenId={mintNFTData?.tokenId || ""}
          creatNextTokenOnBlockChain={creatNextTokenOnBlockChain}
          burnNFTOnBlockChain={burnNFTOnBlockChain}
          mintOnBlockChain={mintOnBlockChain}
          resetForm={resetForm}
        />
      )}

      <GenerateArtModal isOpen={isOpenGenerateArtModal} onClose={onCloseGenerateArtModal} />
    </Box>
  );
}

export default CreateNFTForm;
