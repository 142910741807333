import { css } from "styled-components";

export const Text = css`
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
  font-weight: 700;
  font-style: normal;
`;

export const ParagraphStyle = css`
  font-family: var(--font-family-inter-regular);
  font-size: var(--font-size-l);
  letter-spacing: 0;
  font-weight: 400;
  font-style: normal;
`;

export const Label = css`
  font-family: var(--font-family-inter-semibold);
  font-size: var(--font-size-s);
  letter-spacing: 0;
  font-weight: 600;
  font-style: normal;
`;

export const ButtonText = css`
  font-family: var(--font-family-inter-bold);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiBoldJaguar20px = css`
  color: var(--x060713);
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiMediumGunPowder15px = css`
  color: var(--x3f4358);
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const SatoshiBoldJaguar14px = css`
  color: var(--x060713);
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiBoldPersianBlue14px = css`
  color: var(--x252fc1);
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiMediumWhite16px = css`
  color: var(--ffffff);
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-l);
  font-weight: 500;
  font-style: normal;
`;

export const SatoshiBoldWaterloo16px = css`
  color: var(--x79809e);
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiBoldWhite14px = css`
  color: var(--ffffff);
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiBoldJaguar16px = css`
  color: var(--x060713);
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiMediumWhite14px = css`
  color: var(--ffffff);
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-s);
  font-weight: 500;
  font-style: normal;
`;

export const Border2pxF2f3f6 = css`
  border: 2px solid var(--f2f3f6);
`;

export const SatoshiBoldPersianBlue16px = css`
  color: var(--x252fc1);
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiBlackJaguar48px = css`
  color: var(--x060713);
  font-family: var(--font-family-satoshi-black);
  font-size: var(--font-size-xxl);
  font-weight: 900;
  font-style: normal;
`;

export const SatoshiBoldWhite14px2 = css`
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-xs);
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiBoldWaterloo14px = css`
  color: var(--x79809e);
  font-family: var(--font-family-satoshi-bold);
  font-size: var(--font-size-xs);
  font-weight: 700;
  font-style: normal;
`;

export const SatoshiBlackWhite24px = css`
  color: var(--ffffff);
  font-family: var(--font-family-satoshi-black);
  font-size: var(--font-size-xl);
  font-weight: 900;
  font-style: normal;
`;

export const SatoshiBlackBlack20px = css`
  color: var(--black);
  font-family: var(--font-family-satoshi-black);
  font-size: var(--font-size-l);
  font-weight: 900;
  font-style: normal;
`;

export const SatoshiMediumJaguar16px = css`
  color: var(--x060713);
  font-family: var(--font-family-satoshi-medium);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;
