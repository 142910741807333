import { useContext, useEffect, useRef, useState, useMemo } from "react";
import { DASHBOARD_MODAL_DATA, DEV_ENV, IPFS_IMAGE_BASE_URL } from "../../util/Constants";
import { Box, Button, Flex, HStack, SimpleGrid, Stack, Text, useDisclosure } from "@chakra-ui/react";

import { useQuery } from "@apollo/client";
import MasterBox from "../Layout/MasterBox";

import Loader from "../common/Loader";

import NFTsTable, { NFTTableRowType } from "../common/Table/NFTsTable";
import ContractsTable from "../common/Table/ContractsTable";
import SuccessModal from "../common/Modal/SuccessModal";
import DashboardNFTActionModal from "../common/Modal/DashboardNFTActionModal";

import { ConnectWalletContext, ConnectWalletContextType } from "../../context/connectWalletContext";
import { FETCH_ORGANIZATION_CONTRACT_ADDRESS } from "../../queries/organization";
import useFetchNFT from "../../hooks/FetchNTF";

import UsersTable, { USER_ROLE_TYPE } from "../common/Table/UsersTable";
import DashboardUserActionModal from "../common/Modal/DashboardUserActionModal";
import SendEmailToUserModal from "../common/Modal/SendEmailToUserModal";
import { StatsCard } from "../common/Card/StatsCard";
import { BarChart } from "../common/Charts/BarChart";
import DashboardNFTAnalyticsTable from "../common/Table/DashboardNFTAnalyticsTable";
import { useParams } from "react-router-dom";

import SettingSection from "./Sections/SettingSection";
import useFetchOwnerNFTCollection from "../../hooks/FetchOwnerNFTCollection";
import UserOwnedNFTsModal from "../common/Modal/UserOwnedNFTsModal";

export enum ActionType {
  ALLOW_LIST = "allow_list",
  AIRDROP = "airdrop",
  ELIFIBILITY = "eligibility",
  RECLAIM_TOKEN = "reclaim_token",
}

export enum ContractActionType {
  STATUS = "status",
  REVEALED = "revealed",
}

export enum ContractAction {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum UserActionType {
  AIRDROP = "user_airdrop",
  SENT_MESSAGE = "user_sent_message",
  SET_PROFILE_PICTURE = "user_profile_picture",
}

export enum DashboardTabType {
  CONTRACTS = "contracts",
  NFTS = "nfts",
  USERS = "users",
  ANALYTICS = "analytics",
  SETTINGS = "settings",
}

type Tab = {
  key: DashboardTabType;
  label: string;
  action: DashboardTabType;
};

const tabs: Tab[] = [
  { key: DashboardTabType.NFTS, label: "NFTs", action: DashboardTabType.NFTS },
  { key: DashboardTabType.USERS, label: "Users", action: DashboardTabType.USERS },
  { key: DashboardTabType.CONTRACTS, label: "Contracts", action: DashboardTabType.CONTRACTS },
  { key: DashboardTabType.SETTINGS, label: "Settings", action: DashboardTabType.SETTINGS },
];

if (DEV_ENV) {
  tabs.push({ key: DashboardTabType.ANALYTICS, label: "Analytics", action: DashboardTabType.ANALYTICS });
}

function Dashboard() {
  // Tab assigned from router
  const { tab: tabParam } = useParams();
  let preasignedTab = undefined;
  if (tabParam && tabs.find((t) => t.key === tabParam)) preasignedTab = tabParam as DashboardTabType;

  const [activeTab, setActiveTab] = useState<DashboardTabType>(preasignedTab || DashboardTabType.NFTS);
  const [actionType, setActionType] = useState<ActionType | null>(null);
  const [userActionType, setUserActionType] = useState<UserActionType | null>(null);
  const [tokenId, setTokenId] = useState("");
  const [amount, setAmount] = useState<number>(1);

  const [contracts, setContracts] = useState<any[]>([]);

  const [orgCollectionsNames, setOrgCollectionsNames] = useState<{ [key: string]: string } | null>(null);
  const [nftContractAddress, setNFTContractAddress] = useState("");
  const [selectedUserToAirDropNFTs, setSelectedUserToAirDropNFTs] = useState<any>(null);
  const fetchingNFTsRef: any = useRef(null);
  const fetchingNFTOwnersRef: any = useRef(null);

  // Modal Hook Assignment
  const {
    isOpen: isOpenNFTActionModal,
    onOpen: onOpenNFTActionModal,
    onClose: onCloseNFTActionModal,
  } = useDisclosure();

  const {
    isOpen: isOpenUserActionModal,
    onOpen: onOpenUserActionModal,
    onClose: onCloseUserActionModal,
  } = useDisclosure();
  const {
    isOpen: isOpenSendMessageToUserModal,
    onOpen: onOpenSendMessageToUserModal,
    onClose: onCloseSendMessageToUserModal,
  } = useDisclosure();

  const {
    isOpen: isOpenUserOwnedNFTsModal,
    onOpen: onOpenUserOwnedNFTsModal,
    onClose: onCloseUserOwnerNFTsModal,
  } = useDisclosure();

  const {
    nftOwnerMap,
    ownerEmailMap,
    loading: nftOwnerMapLoading,
    fetchOwnerNFTCollection,
  } = useFetchOwnerNFTCollection();
  const { isOpen: isOpenSuccessModal, onOpen: onOpenSuccessModal, onClose: onCloseSuccessModal } = useDisclosure();
  const { user, address: walletAddress } = useContext(ConnectWalletContext) as ConnectWalletContextType;

  // Fetching Organization NFTs
  const { nfts: nftData, loading: nftLoading, error: nftError, fetchNFTs } = useFetchNFT();

  // Fetching Organization Contract Address
  const {
    data: orgUsersData,
    loading: orgUsersDataLoading,
    error: orgUsersDataError,
    refetch: refetchOrgUsers,
  } = useQuery(FETCH_ORGANIZATION_CONTRACT_ADDRESS, {
    variables: { ids: [user?.organization?._id || ""] },
  });

  // Get, set contracts and collections in state
  useEffect(() => {
    if (orgUsersData && walletAddress) {
      let orgContarctAddressesArray: string[] = [];
      let orgCollectionNames: any = {};
      orgUsersData?.organizations.forEach((element: any) => {
        element?.collections.forEach((subElement: any) => {
          if (!subElement.isDefaultCollection || !subElement.isVisible) {
          } else {
            orgContarctAddressesArray.push(subElement.contractAddress);
            orgCollectionNames[`${subElement.contractAddress.toLowerCase()}`] = subElement.title;
          }
        });
      });
      setContracts(orgContarctAddressesArray);
      setOrgCollectionsNames(orgCollectionNames);
      if (!(fetchingNFTsRef.current as boolean) && orgContarctAddressesArray.length) {
        fetchNFTs(orgContarctAddressesArray, true);
        fetchingNFTsRef.current = "NFTLoaded";
      }
    }
  }, [orgUsersData]);

  //
  const nftTableData = useMemo(() => {
    if (nftData) {
      const tempData: NFTTableRowType[] = [];
      nftData.forEach((element: any) => {
        if (element?.metadata?.name && element?.id?.tokenId) {
          const image = element?.metadata?.image ? element.metadata.image.replace("ipfs://", IPFS_IMAGE_BASE_URL) : "";
          tempData.push({
            image: image,
            name: element?.metadata?.name || "",
            tokenId: element?.id.tokenId || "",
            description: element?.metadata?.description || "",
            nftContractAddress: element?.contract?.address || "",
            attributes: element?.metadata?.attributes,
          });
        }
      });

      if (!(fetchingNFTOwnersRef.current as boolean) && tempData.length) {
        fetchOwnerNFTCollection(tempData.map((d) => d.nftContractAddress));
        fetchingNFTOwnersRef.current = "NFTLoaded";
      }
      return tempData;
    } else {
      return [];
    }
  }, [nftData]);

  const orgUsers = useMemo(() => {
    return orgUsersData?.organizations?.length > 0 && orgUsersData.organizations[0].users
      ? orgUsersData.organizations[0].users
      : [];
  }, [orgUsersData]);

  const organization = useMemo(() => {
    return orgUsersData?.organizations?.length > 0 ? orgUsersData.organizations[0] : null;
  }, [orgUsersData]);

  const onSetNFTActionType = (actionType: ActionType, tokenId?: string, nftContAddress?: string) => {
    setActionType(actionType);
    if ([ActionType.ALLOW_LIST, ActionType.AIRDROP, ActionType.RECLAIM_TOKEN].includes(actionType)) {
      setTokenId(tokenId || "");
      setNFTContractAddress(nftContAddress || "");
    }
  };

  const onSetUserActionType = (actionType: UserActionType, user: any) => {
    setUserActionType(actionType);
    setSelectedUserToAirDropNFTs(user);
    if (UserActionType.SET_PROFILE_PICTURE === actionType) {
      onOpenUserOwnedNFTsModal();
    }
  };

  return (
    <>
      <MasterBox minH={"60vh"}>
        <Box mt={[5, 10]} mb={[2, 5]}>
          <Text textStyle={"h1"} fontWeight={"normal"}>
            Dashboard
          </Text>
          <Text textStyle={"paragraph"} color={"black.200"}>
            Here you can see all the information related to NFTs.
          </Text>
        </Box>
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={[3, 0]} py={5}>
          <HStack flexDirection={["column", "row"]}>
            {tabs
              .filter((t) => DEV_ENV || t.label !== DashboardTabType.ANALYTICS)
              .map((tab) => {
                return (
                  <Button
                    key={tab.label}
                    variant={activeTab === tab.action ? "filled" : "ghost"}
                    fontSize={"16"}
                    onClick={() => {
                      setActiveTab(tab.action);
                      setUserActionType(null);
                    }}
                  >
                    {tab.label}
                  </Button>
                );
              })}
          </HStack>
        </SimpleGrid>
        {activeTab === DashboardTabType.CONTRACTS && (
          <Box>
            <ContractsTable organizationId={user?.organization?._id || ""} />
          </Box>
        )}
        {activeTab === DashboardTabType.NFTS && (
          <Box>
            {nftLoading && <Loader />}
            {nftError && (
              <Text textStyle={"paragraphMedium"} color={"red.400"}>
                Something went wrong {JSON.stringify(nftError)}
              </Text>
            )}
            {!nftLoading && !nftError && (
              <NFTsTable
                data={nftTableData}
                onOpen={onOpenNFTActionModal}
                setActionType={onSetNFTActionType}
                orgCollectionsNames={orgCollectionsNames}
                nftOwnerMapLoading={nftOwnerMapLoading}
                nftOwnerMap={nftOwnerMap}
                ownerEmailMap={ownerEmailMap}
              />
            )}
            {nftContractAddress && (
              <DashboardNFTActionModal
                isOpen={isOpenNFTActionModal}
                onClose={onCloseNFTActionModal}
                actionType={actionType}
                onOpenSuccessModal={onOpenSuccessModal}
                contractAddress={nftContractAddress}
                organizationId={user?.organization?._id || ""}
                tokenId={tokenId}
                amount={amount}
              />
            )}
          </Box>
        )}
        {activeTab === DashboardTabType.USERS && (
          <Box>
            {orgUsersDataLoading && nftLoading && <Loader />}
            {orgUsersDataError && (
              <Text textStyle={"paragraphMedium"} color={"red.400"}>
                Something went wrong {JSON.stringify(orgUsersDataError)}
              </Text>
            )}
            {!orgUsersDataLoading && !orgUsersDataError && !nftLoading && (
              <UsersTable
                data={
                  orgUsers && orgUsers.length > 0
                    ? orgUsers.filter((item: any) => !(item.role === USER_ROLE_TYPE.OPERATOR))
                    : []
                }
                onOpen={onOpenUserActionModal}
                setActionType={onSetUserActionType}
                onOpenMessageModal={onOpenSendMessageToUserModal}
                accessCode={organization?.accessCode}
                organizationId={organization?._id}
              />
            )}
            {orgCollectionsNames && nftTableData && selectedUserToAirDropNFTs && (
              <DashboardUserActionModal
                isOpen={isOpenUserActionModal}
                onClose={onCloseUserActionModal}
                actionType={userActionType}
                onOpenSuccessModal={onOpenSuccessModal}
                nfts={nftTableData}
                orgCollectionsNames={orgCollectionsNames}
                walletAddress={selectedUserToAirDropNFTs.walletAddress}
              />
            )}
            {UserActionType.SENT_MESSAGE === userActionType && selectedUserToAirDropNFTs && (
              <SendEmailToUserModal
                isOpen={isOpenSendMessageToUserModal}
                onClose={onCloseSendMessageToUserModal}
                onOpenSuccessModal={onOpenSuccessModal}
                email={selectedUserToAirDropNFTs.email}
              />
            )}
            {UserActionType.SET_PROFILE_PICTURE === userActionType && selectedUserToAirDropNFTs && (
              <UserOwnedNFTsModal
                isOpen={isOpenUserOwnedNFTsModal}
                onClose={onCloseUserOwnerNFTsModal}
                onOpenSuccessModal={onOpenSuccessModal}
                organizationId={user?.organization?._id || ""}
                walletAddress={selectedUserToAirDropNFTs.walletAddress}
                actionType={userActionType}
                userId={selectedUserToAirDropNFTs._id}
                refetchOrgUsers={refetchOrgUsers}
                user={selectedUserToAirDropNFTs}
              />
            )}
          </Box>
        )}

        {activeTab === DashboardTabType.ANALYTICS && (
          <>
            <Box>
              <SimpleGrid columns={[1, null, 3]} spacing={[2, 2, 5, 5, 8, 9]} mb={5}>
                <StatsCard title="Total Users at Smoothstack" number="85" />
                <StatsCard title="Total NFTs" number="15" />
                <StatsCard title="Total Collections (Smart Contracts)" number="15" />
              </SimpleGrid>
            </Box>
            <SimpleGrid columns={[1, null, 2]} overflowX={"auto"} overflowY={"hidden"} pt={5} mb={10}>
              <BarChart title={"# of owners by NFT"} />
            </SimpleGrid>
            <DashboardNFTAnalyticsTable />
          </>
        )}
        {activeTab === DashboardTabType.SETTINGS && (
          <Box>
            {orgUsersDataLoading && <Loader />}
            {orgUsersDataError && (
              <Text textStyle={"paragraphMedium"} color={"red.400"}>
                Something went wrong {JSON.stringify(orgUsersDataError)}
              </Text>
            )}
            {!orgUsersDataLoading && !orgUsersDataError && organization ? (
              <SettingSection data={orgUsersData.organizations[0]} />
            ) : (
              <p>{JSON.stringify(orgUsersData)}</p>
            )}
          </Box>
        )}
      </MasterBox>
      <Box py={12}></Box>
      <SuccessModal
        isOpen={isOpenSuccessModal}
        onClose={onCloseSuccessModal}
        ctaAction={() => {
          onCloseSuccessModal();
        }}
        title={DASHBOARD_MODAL_DATA[`${actionType || userActionType || "airdrop"}`].modalSuccessHeader}
        description={DASHBOARD_MODAL_DATA[`${actionType || userActionType || "airdrop"}`].modalSuccessDesc}
        ctaTitle={DASHBOARD_MODAL_DATA[`${actionType || userActionType || "airdrop"}`].modalSuccessBtnTitle}
        subDescription={""}
      />
    </>
  );
}

export default Dashboard;
