import { useContext, useEffect, useRef, useState } from "react";
import { Button, Box, HStack, SimpleGrid, Text } from "@chakra-ui/react";

import MasterBox from "../Layout/MasterBox";
import Loader from "../common/Loader";

import OrganizationsTable from "../common/Table/OrganizationsTable";

import { useQuery } from "@apollo/client";
import { FETCH_ORGANIZATIONS_ACCESS_HUB } from "../../queries/organization";
import { ConnectWalletContext, ConnectWalletContextType } from "../../context/connectWalletContext";

export enum AccessHubTabType {
  ORGS = "orgs",
}

export default function AccessHub() {
  const [activeTab, setActiveTab] = useState<AccessHubTabType>(AccessHubTabType.ORGS);

  const [orgsLoading, setOrgsLoading] = useState(true);

  const { user } = useContext(ConnectWalletContext) as ConnectWalletContextType;

  const [orgData, setOrgData] = useState([]);

  const {
    data: organizationData,
    loading: orgDataLoading,
    error: orgUsersDataError,
  } = useQuery(FETCH_ORGANIZATIONS_ACCESS_HUB, {
    variables: { ids: [user?.organization?._id || ""] },
  });

  useEffect(() => {
    if (organizationData) {
      let tmpData = organizationData.organizations;
      setOrgData(tmpData);
    }
    setOrgsLoading(false);
  }, [organizationData, orgsLoading]);

  return (
    <>
      <MasterBox minH={"60vh"}>
        {/*Title Box*/}
        <Box mt={[5, 10]} mb={[2, 5]}>
          <Text textStyle={"h1"} fontWeight={"normal"}>
            Access Hub
          </Text>
        </Box>

        {/*org button*/}
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={[3, 0]} py={5}>
          <HStack>
            <Button variant={"filled"} fontSize={"16"}>
              Organizations
            </Button>
          </HStack>
        </SimpleGrid>

        {activeTab === AccessHubTabType.ORGS && (
          <Box>
            <>
              {orgsLoading && <Loader />}
              {orgUsersDataError && (
                <Text textStyle={"paragraphMedium"} color={"red.400"}>
                  Something went wrong {JSON.stringify(orgUsersDataError)}
                </Text>
              )}

              {!orgDataLoading && !orgUsersDataError && !!organizationData && (
                <>
                  <OrganizationsTable pData={orgData} />
                </>
              )}
            </>
          </Box>
        )}
      </MasterBox>
      <Box py={12}></Box>
    </>
  );
}
