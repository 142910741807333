import axios, { AxiosResponse } from "axios";
import { appConfig } from "./Constants";

const backendBaseUrl = appConfig.BACKEND_API_URL;

export type SendEmailInviteProps = {
  emails: string[];
  code: string; //org access code
};
export const sendEmailInvitets = async (requestBody: SendEmailInviteProps) => {
  try {
    const response: AxiosResponse = await axios.post(`${backendBaseUrl}sendInvites`, requestBody);
    return response.data;
  } catch (err) {
    console.error("Error sending email invites", err);
    throw new Error("Error sending email invites");
  }
};

export type SendAirdropsEmailProp = {
  emails: string[];
  subject: string;
  message: string;
};
export const sendAirdropsEmail = async (requestBody: SendAirdropsEmailProp) => {
  try {
    const response: AxiosResponse = await axios.post(`${backendBaseUrl}sendAirdrops`, requestBody);
    return response.data;
  } catch (err) {
    console.error("Error sending airdrops email", err);
    throw new Error("Error sending airdrops email");
  }
};
