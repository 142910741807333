import axios from "axios";
import { useState } from "react";
import { appConfig } from "../util/Constants";
import { BigNumber } from "@ethersproject/bignumber";
const zerosRegex = "0x0000000000000000000000000000000000000000";

function useFetchNFTOwners() {
  const [owners, setOwners] = useState<string[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchOwners = (contractAddress: string, id: BigNumber | string) => {
    setLoading(true);
    const baseURL = `${appConfig.ALCHEMY_POLYGON_URL}/getOwnersForToken`;
    const config = {
      method: "get",
      url: `${baseURL}?contractAddress=${contractAddress}&tokenId=${id}`,
      headers: {},
    };
    axios(config)
      .then((response) => {
        const filterOwners = response.data.owners.filter((item: any) => item !== zerosRegex);
        setOwners(filterOwners);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  return {
    owners,
    loading,
    error,
    fetchOwners,
  };
}

export default useFetchNFTOwners;
