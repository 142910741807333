import {
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";
import { AiOutlineExclamationCircle, AiOutlineCheckCircle } from "react-icons/ai";
import ProgressStepper from "../ProgressStepper";
import { CSVLink } from "react-csv";
import { NFTTableRowType } from "../../common/Table/NFTsTable";
import { hexToDecimal } from "../../../util/helper";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface ExportingNFTCsvModalProps {
  isOpen: boolean;
  onClose: any;
  data: NFTTableRowType[];
  supplyMap: Map<string, any> | undefined;
  orgCollectionsNames: any;
}

const stepperTitle: string[] = ["Gathering NFT Data", "Creating Export", "Finalizing"];

const ExportingNFTCsvModal = ({ isOpen, onClose, data, supplyMap, orgCollectionsNames }: ExportingNFTCsvModalProps) => {
  const [exportData, setExportData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const [selectedCollection, setSelectedCollection] = useState<string>();
  const csvLinkRef = useRef<any>(null);
  const minWaitTime = 2 * 1000;
  const onClickMintNFT = async () => {};

  function delay() {
    return new Promise((resolve) => setTimeout(resolve, minWaitTime));
  }
  useEffect(() => {
    if (isOpen && data && data.length > 0 && selectedCollection && supplyMap) {
      if (step === 0) delay().then(() => setStep(1));
      if (step === 1) {
        const constructedData = data.reduce((arr, opt) => {
          const collectionName = orgCollectionsNames[`${opt.nftContractAddress.toLowerCase()}`] || "-";
          if (collectionName !== selectedCollection) return arr;
          const nftSupply = supplyMap.get(`${opt.nftContractAddress}-${opt.tokenId}`);
          const ownerList = (nftSupply?.ownerList || [] as any[]).filter((owner:any) => owner && (owner.email || owner.address));
          const defaultText = "Not Claimed";
          const commonFields = {
            Collection_Name: collectionName,
            NFT_Name: opt.name,
            Token_ID: hexToDecimal(opt.tokenId.split("x")[1]),
            Description: opt.description,
            Owners: nftSupply?.owners || 0,
            Supply: nftSupply?.supply || 0,
          };
          // Add nft attributes
          let attributeFields: { [k: string]: any } = {};
          if (opt.attributes) {
            const sortedAttributes = opt.attributes.sort((a, b) => (a.trait_type > b.trait_type ? 1 : -1));
            sortedAttributes.forEach((attribute) => {
              if (attribute.value && attribute.value !== "") attributeFields[attribute.trait_type] = attribute.value;
            });
          }
          // Construct NFT data with Owners
          if (ownerList.length > 0) {
            ownerList.forEach((owner:any) => {
              if (owner?.email || owner?.address) {
                arr.push({
                  Email: owner?.email || defaultText,
                  Wallet: owner?.address || defaultText,
                  ...commonFields,
                  ...attributeFields,
                });
              }
            });
          } else {
            arr.push({
              Email: defaultText,
              Wallet: defaultText,
              ...commonFields,
              ...attributeFields,
            });
          }
          return arr;
        }, new Array());
        setExportData(constructedData);
        delay().then(() => setStep(2));
      }
      if (step === 2) {
        delay().then(() => setLoading(false));
      }
    }
  }, [isOpen, step, selectedCollection, supplyMap, data]);

  const handleClose = () => {
    onClose();
    setLoading(true);
    setStep(0);
    setSelectedCollection(undefined);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          handleClose();
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />

        <ModalContent>
          <Box py={8}></Box>
          <ModalCloseButton />
          {data.length === 0 ? (
            <ModalBody mt={-2} textAlign={"center"}>
              <Text textStyle={"h2"} px={6} pb={2}>
                You currently don't have any NFT
              </Text>
              <Button
                onClick={() => {
                  handleClose();
                }}
                mt={5}
              >
                Close
              </Button>
            </ModalBody>
          ) : (
            <ModalBody mt={-2} textAlign={"center"}>
              <Flex align={"center"} justifyContent={"center"}>
                <Stack bg={error ? "red.100" : "secondary.900"} padding={5} borderRadius={"100px"} mb={6}>
                  <Icon
                    as={loading ? AiOutlineExclamationCircle : AiOutlineCheckCircle}
                    w={"45px"}
                    h={"45px"}
                    color={error ? "red.400" : "primary.800"}
                  />
                </Stack>
              </Flex>
              <Text textStyle={"h2"} px={6} pb={2}>
                {loading
                  ? "Preparing NFT Export Data"
                  : `NFT report for ${selectedCollection} collection is ready to download!`}
              </Text>
              {!selectedCollection ? (
                <Box mt={8}>
                  <Menu>
                    <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                      {selectedCollection ? selectedCollection : "Please select a collection first"}
                    </MenuButton>
                    <MenuList>
                      {[
                        ...new Set(
                          data
                            .map((item) => orgCollectionsNames[`${item.nftContractAddress.toLowerCase()}`] || undefined)
                            .filter((collection) => collection)
                        ),
                      ].map((collection) => (
                        <MenuItem onClick={() => setSelectedCollection(collection)}>{collection}</MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                </Box>
              ) : (
                <>
                  {loading && (
                    <>
                      <ProgressStepper
                        loading={loading}
                        errorMessage={error}
                        title={stepperTitle[step] || ""}
                        activeIndex={step}
                      />
                    </>
                  )}
                  <Button
                    onClick={() => {
                      csvLinkRef.current?.link.click();
                      handleClose();
                    }}
                    mt={5}
                    isLoading={loading}
                  >
                    {`Download NFT Report: ${selectedCollection}`}
                  </Button>
                  <CSVLink data={exportData} filename="NFTs.csv" target="_blank" ref={csvLinkRef} />
                </>
              )}
              <Box py={4}></Box>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default ExportingNFTCsvModal;
