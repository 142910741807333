import React, { useState, useEffect, useContext } from "react";
import { Box, Text, Button, Input, Spacer, useToast, useDisclosure } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { CREATE_ORGANIZATION_MUTATION } from "../../../queries/organization";
import { CreateOrganizationRes } from "../JoinOrganization";
import useConnectWalletStrategy from "../../common/ConnectWallet/ConnectWalletStrategy";
import { LoginTypeEnum } from "../../common/ConnectWallet/ConnectWalletModal";
import LogRocket from "logrocket";
import SuccessModal from "../../common/Modal/SuccessModal";
import { useLoginState } from "../../Layout/Header";
import { useBetween } from "use-between";
import { UPDATE_USER_ROLE_MUTATION } from "../../../queries/profile";
import { USER_ROLE_TYPE } from "../../common/Table/UsersTable";
import { ConnectWalletContext, ConnectWalletContextType } from "../../../context/connectWalletContext";
import { cloneDeep } from "@apollo/client/utilities";
import { ErrorMessage } from "../../../util/Constants";

function CreateWorkspace(props: {
  nextStep: any;
  orgType: string;
  organization: CreateOrganizationRes | undefined;
  setOrganization: any;
}) {
  const { nextStep, orgType, organization, setOrganization } = props;
  const [orgName, setOrgName] = useState<string>("");
  const [domain, setDomain] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const { setIsLogin, loading: isLoginLoading, setLoading, isLogin } = useBetween(useLoginState);
  const { isOpen: isOpenSuccessLogin, onOpen: onOpenSuccessLogin, onClose: onCloseSuccessLogin } = useDisclosure();
  const { connectWallet } = useConnectWalletStrategy();
  const toast = useToast();

  // gql
  const [createOrganization, { data: createOrgData, loading: isCreateOrgLoading, error: createOrgError }] =
    useMutation(CREATE_ORGANIZATION_MUTATION);
  const [updateUser, { data: updateUserData, loading: isUpdatingUserLoading, error: updateUserError }] =
    useMutation(UPDATE_USER_ROLE_MUTATION);
  const { updateUser: updateUserContext, user: userContext } = useContext(
    ConnectWalletContext
  ) as ConnectWalletContextType;

  const handleToastError = (error: string) => {
    toast({
      title: error,
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top",
    });
  };

  const magicLogin = async (orgData: any) => {
    try {
      setLoading(true);
      const accessCode = orgData.createOrganization?.accessCode || "";
      const organizationId = orgData.createOrganization?._id || "";
      const userData = await connectWallet(LoginTypeEnum.MAGIC_LINK, email, accessCode);
      const userId = userData.data.data?.loginUserMagic?.user._id;
      if (userId && organizationId) {
        // update user to Admin role
        await updateUser({
          variables: {
            id: userId,
            role: USER_ROLE_TYPE.ADMIN,
            organizationId: organizationId,
          },
        });
      } else {
        throw Error("Failed to retrieve user id after magic login");
      }
      onOpenSuccessLogin();
      setIsLogin(true);
      setLoading(false);
      LogRocket.identify(email);
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      const msg = error.message?.toLowerCase() ?? "";
      const errorMsg = msg.includes("duplicate key") ? ErrorMessage.duplicateEmail : ErrorMessage.genericLoginErrorMsg;
      handleToastError(errorMsg);
    }
  };

  useEffect(() => {
    // Update local context and storage
    if (userContext && userContext.role !== USER_ROLE_TYPE.ADMIN) {
      let updatedUserData = cloneDeep(userContext);
      updatedUserData.role = USER_ROLE_TYPE.ADMIN;
      localStorage.setItem("user", JSON.stringify(updatedUserData));
      updateUserContext(updatedUserData);
    }
  }, [userContext]);

  const handleCreateWorkSpace = () => {
    createOrganization({
      variables: {
        name: orgName,
        domain: domain,
        orgType: orgType,
      },
    })
      .then((res) => {
        magicLogin(res.data);
      })
      .catch((e) => {
        console.error("Faild to create organization", e);
        const msg = e.message?.toLowerCase() || "";
        const errorMsg =
          msg.includes("duplicate") && msg.includes("domain_1")
            ? ErrorMessage.duplicateDomain
            : ErrorMessage.genericJoinMintedErrorMsg;
        handleToastError(errorMsg);
      });
  };

  useEffect(() => {
    if (createOrgData) {
      setOrganization(createOrgData.createOrganization);
    }
  }, [createOrgData]);

  const isLoading = isCreateOrgLoading || isLoginLoading || isUpdatingUserLoading;

  return (
    <>
      <Box>
        <Text textStyle={"h1"}>Create Workspace</Text>
        <Text m={2} color="gray.500" fontWeight={700}>
          Your workspace, is your destination to create and distribute digital collectables
        </Text>
        <Spacer h={8} />
        <Box w="70%">
          <Text mt="8px" mb="8px" textStyle={"h3"}>
            Organization Name
          </Text>
          <Input
            type="text"
            placeholder="Enter your organization name"
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
          />
          <Spacer h={4} />
          <Text mt="8px" mb="8px" textStyle={"h3"}>
            Website
          </Text>
          <Input type="text" placeholder="www.myorg.com" value={domain} onChange={(e) => setDomain(e.target.value)} />
          <Spacer h={4} />
          <Text mt="8px" mb="8px" textStyle={"h3"}>
            Email Address
          </Text>
          <Input type="email" placeholder="@email.com" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Box>
        <Spacer h={10} />
        <Button
          onClick={handleCreateWorkSpace}
          disabled={!orgName || !email || !domain || isLoading || isLogin}
          isLoading={isLoading}
        >
          Continue
        </Button>
      </Box>

      <SuccessModal
        isOpen={isOpenSuccessLogin}
        onClose={() => {
          onCloseSuccessLogin();
          nextStep();
        }}
        ctaAction={() => {
          onCloseSuccessLogin();
          nextStep();
        }}
        title={"Verified!"}
        description={"You have successfully verified your account"}
        subDescription={""}
        ctaTitle={"Continue"}
      />
    </>
  );
}

export default CreateWorkspace;
