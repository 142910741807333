import { useState } from "react";
import axios from "axios";
import { appConfig } from "../util/Constants";

function useFetchAIResponse() {
  const [fetchedAiResponse, setFetchedAiResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchAIResponse = async (query: string) => {
    setLoading(true);
    try {
      const baseURL = `${appConfig.AI_URL}/getNFTs`;
      const config = {
        method: "get",
        url: `${baseURL}?query=${query}`,
        headers: {},
      };
      const res: any = await axios(config);
      if (res.data?.result) setFetchedAiResponse(res.data?.result);
      setLoading(false);
      setError(null);
    } catch (error: any) {
      setLoading(false);
      setError(error);
    }
  };

  return { fetchedAiResponse, loading, error, fetchAIResponse };
}

export default useFetchAIResponse;
