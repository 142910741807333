import MasterBox from "./../Layout/MasterBox";
import HeroSection from "./Section/HeroSection";

export default function Home() {
  return (
    <>
      <MasterBox minH={700}>
        <HeroSection />
      </MasterBox>
    </>
  );
}
