import { Box, Image, Flex, Text, useColorModeValue, Icon } from "@chakra-ui/react";
import { MdVerified } from "react-icons/md";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { getstandardizedOwnerAddress } from "../../util/helper";
import { FALLBACK_URL_IMAGE } from "../../util/Constants";

export default function CollectionCard(props: any) {
  const {
    collection: { contractAddress, description, bannerUrl, title, isDefaultCollection, isVisible, owner },
  } = props;

  // const cardRef: any = useRef(null);
  // const [owners, setOwners ] = useState([] as string[]);

  // const fetchOwnersForCollection = () => {
  //   const baseURL = `${process.env.REACT_APP_ALCHEMY_API_MUMBAI}/getOwnersForCollection`;
  //   const config = {
  //     method: 'get',
  //     url: `${baseURL}?contractAddress=${contractAddress}`,
  //     headers: { }
  //   };
  //   axios(config)
  //   .then(response => {
  //     setOwners(response.data?.ownerAddresses);
  //   })
  //   .catch(error => console.log(error));
  // }

  // useEffect( () => {
  //   if(!(cardRef.current as boolean)){
  //     fetchOwnersForCollection();
  //     cardRef.current = "ownersLoaded";
  //   }
  // }, [])

  const backgroundBox = useColorModeValue("white", "gray.800");
  const borderColorBox = useColorModeValue("gray.200", "gray.500");
  const boxShadow = useColorModeValue("xl", "2xl");

  if (!isDefaultCollection || !isVisible) {
    return <></>;
  }

  return (
    <RouterLink to={`/collection/${contractAddress}`}>
      <Box
        bg={backgroundBox}
        _hover={{
          boxShadow: boxShadow,
        }}
        rounded={"lg"}
        border={"1px"}
        borderColor={borderColorBox}
        borderStyle={"solid"}
        overflow={"hidden"}
      >
        <Image
          h={[150, 230]}
          w={"full"}
          src={bannerUrl}
          objectFit={"cover"}
          alt="collection cover"
          fallbackSrc={FALLBACK_URL_IMAGE}
        />

        <Box px={5} pt={1} pb={4}>
          <Text textStyle={"h3"} pt={2} noOfLines={1} minHeight={10}>
            {title}
          </Text>
          <Text noOfLines={3} textStyle={"paragraphMedium"} color={"gray.500"} pt={1} minHeight={20}>
            {description}
          </Text>
          <Flex direction={"column"} justifyContent={"center"} pt={3}>
            <Text textStyle={"label"} color={"black.200"} p={0} m={0}>
              Created by
            </Text>
            <Flex align={"center"} minHeight={5}>
              {owner && owner.name && (
                <>
                  <Text textStyle={"label"} fontSize={14} p={0} mr={1}>
                    {owner.name}
                  </Text>
                  <Icon as={MdVerified} color="blue.500" />
                </>
              )}
            </Flex>
          </Flex>
          {/* <Text
                textStyle={"paragraphMedium"}
                color={useColorModeValue("black", "white")}
              >
                {`${contractAddress.slice(0, 4)}....${contractAddress.slice(-4)}`}
              </Text>
            <Text
              textStyle={"paragraphMedium"}
              color={useColorModeValue("black", "white")}
            >
              By{" "}
              <Link color="primary.900" href="#">
                {owners.length ? `${owners[0].slice(0,4)}....${owners[0].slice(-4)}` : '' }
              </Link>
            </Text> */}
        </Box>
      </Box>
    </RouterLink>
  );
}
