import { Box, Button, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Header from "../Layout/Header";
import MasterBox from "../Layout/MasterBox";
import { BuzzFeedQuiz } from "react-buzzfeed-quiz";
import "react-buzzfeed-quiz/lib/styles.css";
import { appConfig } from "../../util/Constants";
import { getPaperFlowLink, openInNewTab } from "../../util/helper";
import { useNavigate } from "react-router-dom";

// Finding a most frequent number in array
function mostFrequent(arr: number[], n: number) {
  // Sort the array
  arr.sort();

  // find the max frequency using linear
  // traversal
  let max_count = 1,
    res = arr[0];
  let curr_count = 1;

  for (let i = 1; i < n; i++) {
    if (arr[i] == arr[i - 1]) curr_count++;
    else curr_count = 1;

    if (curr_count > max_count) {
      max_count = curr_count;
      res = arr[i - 1];
    }
  }

  return res;
}

function Quiz() {
  const [resultIndex, setResultIndex] = useState<number | null>(null);
  const [answersArray, setAnswersArray] = useState<number[]>([]);
  const navigate = useNavigate();
  return (
    <>
      <MasterBox>
        <Flex justifyContent={"center"} pt={5}>
          <Text textStyle={"h1"} fontWeight={"normal"} width={"4xl"} textAlign={"center"}>
            Ever wondered what NFT are you? Neither do we. Find out anyway.
          </Text>
        </Flex>
        <BuzzFeedQuiz
          title={""}
          description={""}
          byline={true}
          autoScroll={true}
          onRestart={() => {
            setResultIndex(null);
          }}
          onResult={() => {
            const result = mostFrequent(answersArray, answersArray.length);
            setResultIndex(result);
          }}
          onAnswerSelection={(questionIndex, answerIndex, resultID) => {
            let tempAnswers = answersArray;
            tempAnswers[questionIndex || 0] = resultID || 0;
            setAnswersArray(tempAnswers);
            console.log({
              questionIndex,
              answerIndex,
              resultID,
            });
          }}
          facebookShareButton={false}
          facebookShareLink={"www.yourlink.com"}
          twitterShareButton={false}
          twitterShareLink={"www.yourlink.com"}
          copyShareButton={false}
          copyShareLink={"This text was copied using the copyShareLink prop."}
          questions={[
            {
              question: "First app you open when you wake up?",
              questionRelativeToImage: "overlap",
              answerArrangement: "tile",
              backgroundColor: "black",
              answers: [
                {
                  answer: "Github",
                  resultID: 1,
                  backgroundImageSrc: "/img/Quiz/Q1/Github.png",
                },
                {
                  answer: "Twitter",
                  resultID: 2,
                  backgroundImageSrc: "/img/Quiz/Q1/Twitter.png",
                },
                {
                  answer: "Discord",
                  resultID: 3,
                  backgroundImageSrc: "/img/Quiz/Q1/Discord.png",
                },
                {
                  answer: "Instagram",
                  resultID: 4,
                  backgroundImageSrc: "/img/Quiz/Q1/Instagram.png",
                },
                {
                  answer: "Facebook",
                  resultID: 5,
                  backgroundImageSrc: "/img/Quiz/Q1/Facebook.png",
                },
              ],
            },
            {
              question: "Weekend plans",
              questionRelativeToImage: "overlap",
              answerArrangement: "tile",
              backgroundColor: "black",
              answers: [
                {
                  answer: "Debugging code",
                  resultID: 1,
                  backgroundImageSrc: "/img/Quiz/Q2/Debugging_Code.jpg",
                },
                {
                  answer: "Dinner w/ friends",
                  resultID: 2,
                  backgroundImageSrc: "/img/Quiz/Q2/Dinner_Friends.jpg",
                },
                {
                  answer: "Wagie @ McDonalds",
                  resultID: 3,
                  backgroundImageSrc: "/img/Quiz/Q2/Wagie_McDonalds.png",
                },
                {
                  answer: "Binge watching Netflix",
                  resultID: 4,
                  backgroundImageSrc: "/img/Quiz/Q2/Netflix.jpg",
                },
                {
                  answer: "Mowing the lawn",
                  resultID: 5,
                  backgroundImageSrc: "/img/Quiz/Q2/Mowing.jpg",
                },
              ],
            },
            {
              question: "Pick a celebrity",
              questionRelativeToImage: "overlap",
              answerArrangement: "tile",
              backgroundColor: "black",
              answers: [
                {
                  answer: "Vitalik Buterin",
                  resultID: 1,
                  backgroundImageSrc: "/img/Quiz/Q3/Vitalik_Buterin.jpg",
                },
                {
                  answer: "Paris Hilton",
                  resultID: 2,
                  backgroundImageSrc: "/img/Quiz/Q3/Paris_Hilton.png",
                },
                {
                  answer: "Pranksy",
                  resultID: 3,
                  backgroundImageSrc: "/img/Quiz/Q3/Pranksy.png",
                },
                {
                  answer: "Logan Paul",
                  resultID: 4,
                  backgroundImageSrc: "/img/Quiz/Q3/Logan_Paul.png",
                },
                {
                  answer: "Mark Cuban",
                  resultID: 5,
                  backgroundImageSrc: "/img/Quiz/Q3/Mark_Cuban.png",
                },
              ],
            },
            {
              question: "What's your pet peeve?",
              questionRelativeToImage: "overlap",
              answerArrangement: "tile",
              backgroundColor: "black",
              answers: [
                {
                  answer: "No tea",
                  resultID: 1,
                  backgroundImageSrc: "/img/Quiz/Q4/No_Tea.png",
                },
                {
                  answer: "Unsolicited DMs",
                  resultID: 2,
                  backgroundImageSrc: "/img/Quiz/Q4/Unsolicited_DMs.png",
                },
                {
                  answer: "Floor undercutters",
                  resultID: 3,
                  backgroundImageSrc: "/img/Quiz/Q4/Floor_Undercutters.png",
                },
                {
                  answer: "Delivery driver forgot condiments",
                  resultID: 4,
                  backgroundImageSrc: "/img/Quiz/Q4/Delivery_Driver_Forgets_Condiments.png",
                },
                {
                  answer: "Damn kids and their music!",
                  resultID: 5,
                  backgroundImageSrc: "/img/Quiz/Q4/Damn_Kids.png",
                },
              ],
            },
            {
              question: "How would your friends describe you?",
              questionRelativeToImage: "overlap",
              answerArrangement: "tile",
              backgroundColor: "black",
              answers: [
                {
                  answer: "Smart & Focused",
                  resultID: 1,
                  backgroundImageSrc: "/img/Quiz/Q5/Smart_Focused.png",
                },
                {
                  answer: "Fun & Hip",
                  resultID: 2,
                  backgroundImageSrc: "/img/Quiz/Q5/Fun_Hip.png",
                },
                {
                  answer: "Risk-taker",
                  resultID: 3,
                  backgroundImageSrc: "/img/Quiz/Q5/Risk_Taker.png",
                },
                {
                  answer: "Easygoing",
                  resultID: 4,
                  backgroundImageSrc: "/img/Quiz/Q5/Easygoing.png",
                },
                {
                  answer: "Uptight & Frugal",
                  resultID: 5,
                  backgroundImageSrc: "/img/Quiz/Q5/Uptight.png",
                },
              ],
            },
            {
              question: "Where's your ideal work space?",
              questionRelativeToImage: "overlap",
              answerArrangement: "tile",
              backgroundColor: "black",
              answers: [
                {
                  answer: "Anywhere with wifi",
                  resultID: 1,
                  backgroundImageSrc: "/img/Quiz/Q6/Anwhere_Wifi.png",
                },
                {
                  answer: "Bed",
                  resultID: 2,
                  backgroundImageSrc: "/img/Quiz/Q6/Bed.png",
                },
                {
                  answer: "A dark room",
                  resultID: 3,
                  backgroundImageSrc: "/img/Quiz/Q6/Dark_Room.png",
                },
                {
                  answer: "School Library",
                  resultID: 4,
                  backgroundImageSrc: "/img/Quiz/Q6/School_Library.png",
                },
                {
                  answer: "Office",
                  resultID: 5,
                  backgroundImageSrc: "/img/Quiz/Q6/Office.png",
                },
              ],
            },
            {
              question: "Where do you typically buy your NFTs?",
              questionRelativeToImage: "overlap",
              answerArrangement: "tile",
              backgroundColor: "black",
              answers: [
                {
                  answer: "Mint from contract",
                  resultID: 1,
                  backgroundImageSrc: "/img/Quiz/Q7/Mint_From_Contract.png",
                },
                {
                  answer: "Magic Eden",
                  resultID: 2,
                  backgroundImageSrc: "/img/Quiz/Q7/Magic_Eden.png",
                },
                {
                  answer: "Opensea",
                  resultID: 3,
                  backgroundImageSrc: "/img/Quiz/Q7/Opensea.png",
                },
                {
                  answer: "Still figuring that out",
                  resultID: 4,
                  backgroundImageSrc: "/img/Quiz/Q7/Still_Figuring_Out.png",
                },
                {
                  answer: "Google",
                  resultID: 5,
                  backgroundImageSrc: "/img/Quiz/Q7/Google.png",
                },
              ],
            },
            {
              question: "Which conference do you attend?",
              questionRelativeToImage: "overlap",
              answerArrangement: "tile",
              backgroundColor: "black",
              answers: [
                {
                  answer: "Eth Denver",
                  resultID: 1,
                  backgroundImageSrc: "/img/Quiz/Q8/Eth_Denver.png",
                },
                {
                  answer: "Nft NYC",
                  resultID: 2,
                  backgroundImageSrc: "/img/Quiz/Q8/NFT_NYC.png",
                },
                {
                  answer: "ApeFest",
                  resultID: 3,
                  backgroundImageSrc: "/img/Quiz/Q8/ApeFest.png",
                },
                {
                  answer: "ComiCon",
                  resultID: 4,
                  backgroundImageSrc: "/img/Quiz/Q8/ComiCon.png",
                },
                {
                  answer: "Tony Robbins 'Date w/ Destiny'",
                  resultID: 5,
                  backgroundImageSrc: "/img/Quiz/Q8/Date_with_Destiny.png",
                },
              ],
            },
          ]}
          results={[
            {
              title: "Builder",
              description:
                "You are the crux of the castle, the builder and sustainer of the NFT ecosystem. While everyone else is worrying about floor prices you are busy building the next-gen pfp utility project. Crypto Chicks love you, Degens worship you. I know, its exhausting being so great.",
              resultImageSrc: "https://gateway.ipfscdn.io/ipfs/QmaKiFyeovmmFBepeNfhFwzK311EoByt9ewAVNLqxZpy98/0.png",
              imageAttribution: "it's not a bear market, it's a build market.",
              resultID: 1,
            },
            {
              title: "The Crypto Chick",
              description:
                "You are the “Crypto Chick” of your friends; a Goddess among normies. As a walking compendium of cutting-edge, crypto-related information, you bless twitter with your witty insights and somehow show up at every NFT conference on earth. Degens love you, Builders worship you. You’re worth your weight in Bored Apes.",
              imageAttribution: " In it for the 'tech'.",
              resultImageSrc: "https://gateway.ipfscdn.io/ipfs/QmaKiFyeovmmFBepeNfhFwzK311EoByt9ewAVNLqxZpy98/1.png",
              resultID: 2,
            },
            {
              title: "The Degen",
              description:
                "You are the unsung hero of web3. Minting, flipping, hazing, asking “Can the devs do something???”… you and your brethren are the lifeblood of the NFT ecosystem. You live on the edge of a knife, at once a bag dumper AND bag holder. Your parents are worried about you but they don’t understand you’re an “entrepreneur”. Boomers loathe you, Noobs want to be you. Self-deprecation is a dying art, but you keep it well and alive.",
              imageAttribution: "I don't research, I ape.",
              resultImageSrc: "https://gateway.ipfscdn.io/ipfs/QmaKiFyeovmmFBepeNfhFwzK311EoByt9ewAVNLqxZpy98/2.png",
              resultID: 3,
            },
            {
              title: "The Noob",
              description:
                "You are a bright-eyed wanderer into an exciting and unforgiveable landscape. One of your degen friends bragged about making a gajillion dollars selling jpegs so you watched a couple of Gary Vee YouTube vids and decided to jump in. You have no idea what you’re doing, or even saying, but you emphatically overuse the term “WAGMI” every chance you get. Boomers warn you, Builders welcome you. What does “NFT” stand for again?",
              imageAttribution: "can i buy the NFT with apple pay?",
              resultImageSrc: "https://gateway.ipfscdn.io/ipfs/QmaKiFyeovmmFBepeNfhFwzK311EoByt9ewAVNLqxZpy98/3.png",
              resultID: 4,
            },
            {
              title: "The Boomer",
              description:
                "You are a realist, a pragmatist, and the only reasonable human left on the planet. While not posting second-hand furniture ads to your local neighborhood’s facebook group you’re arguing online with teenagers about the inherent “value” of pixels on a screen. Oddly enough you never seem to win. Noobs think you’re old, Degens know you’re older. At least you don’t live in your mom’s basement.",
              imageAttribution: "why would I buy a JPEG when I can right-click save?",
              resultImageSrc: "https://gateway.ipfscdn.io/ipfs/QmaKiFyeovmmFBepeNfhFwzK311EoByt9ewAVNLqxZpy98/4.png",
              resultID: 5,
            },
          ]}
        />
        {resultIndex && (
          <Flex justifyContent={"center"} mt={[-10, -32]} py={4}>
            <Button
              rounded={"md"}
              onClick={() => {
                const quizContractAddress = appConfig.QUIZ_CONTRACT_ADDRESS;
                navigate(`/detail/${quizContractAddress}/${resultIndex - 1}`);
                // const link = getPaperFlowLink(quizContractAddress, resultIndex || 0);
                // if (link) {
                //   openInNewTab(link);
                // }
              }}
            >
              Claim Your NFT
            </Button>
          </Flex>
        )}
        <Box py={5}></Box>
      </MasterBox>
    </>
  );
}

export default Quiz;
