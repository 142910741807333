import styled from "styled-components";
import { Box, Button, Text, useDisclosure, Image, useColorModeValue } from "@chakra-ui/react";
import { Fragment } from "react";
import { useBetween } from "use-between";
import { useLoginState } from "../../Layout/Header";
import ConnectWalletModal from "./ConnectWalletModal";

const Container = styled.div`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DialogDiv = styled.div`
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid;
  border-radius: 15px;
  padding: 20px 20px;
  margin: 5px;
`;

const walletImg = {
  value: "Connect your wallect",
  path: "/img/wallet/wallet.png",
};

function ConnectYourWallet() {
  const { isOpen: isOpenConnectWallet, onOpen: onOpenConnectWallet, onClose: onCloseConnectWallet } = useDisclosure();
  const { setIsLogin, loading, setLoading } = useBetween(useLoginState);
  const borderColor = useColorModeValue("rgba(0, 0, 0, 0.1)", "gray");
  return (
    <Fragment>
      <Container>
        <DialogDiv style={{ borderColor: borderColor }}>
          <Box>
            <Image boxSize={[16]} objectFit="contain" src={walletImg.path} alt={walletImg.value} />
          </Box>
          <Text textStyle={"h3"} px={6} pt={5}>
            Connect your wallet
          </Text>
          <Text fontSize="lg" pt={3}>
            You need to connect your wallet to view your profile and NFTs
          </Text>
          <Button size={"sm"} fontWeight={"normal"} px={6} mt={5} onClick={() => onOpenConnectWallet()}>
            Connect wallet
          </Button>
        </DialogDiv>
      </Container>

      <ConnectWalletModal
        isOpen={isOpenConnectWallet}
        onClose={onCloseConnectWallet}
        setIsLogin={setIsLogin}
        setLoading={setLoading}
        loading={loading}
      />
    </Fragment>
  );
}

export default ConnectYourWallet;
