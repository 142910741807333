import { Text, VStack } from "@chakra-ui/react";

function AttributeCard(props: { item: any }) {
  const {
    item: { trait_type, value },
  } = props;

  return (
    <VStack
      border={2}
      bg={"black.100"}
      borderRadius={10}
      borderStyle={"solid"}
      borderColor={"black.100"}
      py={3}
      px={4}
      justifyContent={"start"}
      alignItems={"flex-start"}
      spacing={0}
    >
      <Text textStyle={"labelMedium"} fontWeight={"700"} color={"black.200"}>
        {trait_type}
      </Text>
      <Text textStyle={"paragraphMedium"} fontWeight={"700"} color={"black"} overflowWrap={"anywhere"}>
        {value}
      </Text>
    </VStack>
  );
}

export default AttributeCard;
