import { Flex, Box } from "@chakra-ui/react";
import React from "react";

function Stepper(props: { totalSteps: number; currentStep: number }) {
  const { totalSteps, currentStep } = props;
  const stepperWidth = 100 / totalSteps;
  return (
    <Box m={4}>
      <Flex>
        {Array.from({ length: totalSteps }).map((it, index) => {
          const color = index + 1 <= currentStep ? "blue" : "gray";
          return (
            <hr
              key={`stepper-${index}`}
              style={{
                width: `${stepperWidth}%`,
                height: "8px",
                backgroundColor: color,
                borderRadius: "4px",
                margin: "4px",
              }}
            />
          );
        })}
      </Flex>
    </Box>
  );
}
// height:2px;border-width:0;color:gray;background-color:gray"
export default Stepper;
