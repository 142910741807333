import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { BigNumber } from "ethers";
import { useEffect, useRef, useState } from "react";
import { AiOutlineSetting } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
import { BiBox } from "react-icons/bi";
import { BsCardChecklist } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import useAdminFunctions from "../../../hooks/Dashboard/AdminFunctions";
import { DEV_ENV, FALLBACK_URL_IMAGE } from "../../../util/Constants";
import { ActionType } from "../../Dashboard";
import EligibilityRulesModal from "../Modal/EligibilityRulesModal";
import NotFound from "../NotFound";
import { hexToDecimal, getStandardizedNFTName } from "../../../util/helper";
import { cloneDeep } from "@apollo/client/utilities";
import ExportingNFTCsvModal from "../../minter/Modal/ExportingNFTCsvModal";
import { Link } from "react-router-dom";

export interface NFTTableRowType {
  image: string;
  name: string;
  tokenId: string;
  description: string;
  nftContractAddress: string;
  attributes?: NFTAttribute[];
}

interface NFTAttribute {
  value: string;
  trait_type: string;
}
interface NFTsTableProps {
  onOpen: any;
  setActionType: any;
  data: NFTTableRowType[];
  orgCollectionsNames: any;
  nftOwnerMapLoading: boolean;
  nftOwnerMap: undefined | Map<string, Map<string, string[]>>;
  ownerEmailMap: undefined | Map<string, string>;
}

const Columns = [
  {
    label: "#Token ID",
    colSpan: 1,
  },
  {
    label: "Name",
    colSpan: 2,
  },
  {
    label: "Owners",
    colSpan: 2,
  },
  {
    label: "Collection Name",
    colSpan: 1,
  },
  {
    label: "",
    colSpan: 1,
  },
];

function NFTsTable(props: NFTsTableProps) {
  const { onOpen, setActionType, data, orgCollectionsNames, nftOwnerMapLoading, nftOwnerMap, ownerEmailMap } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOptions, setFilterOptions] = useState<NFTTableRowType[]>(() => data);
  const [supplyMap, setSupplyMap] = useState<Map<string, any>>();
  const [isOpenExportModal, setIsOpenExportModal] = useState<boolean>(false);
  const rowBorderColor = useColorModeValue("gray.200", "gray.500");
  const rowValueColor = useColorModeValue("black.300", "white");
  const {
    isOpen: isOpenEligibilityRules,
    onOpen: onOpenEligibilityRules,
    onClose: onCloseEligibilityRules,
  } = useDisclosure();
  const navigate = useNavigate();

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm.length > 0) {
        const lowerCaseST = searchTerm.toLowerCase();

        const filterData = data.filter(
          (item: any) =>
            item.name.toLowerCase().includes(lowerCaseST) ||
            orgCollectionsNames[`${item.nftContractAddress.toLowerCase()}`].toLowerCase().includes(lowerCaseST)
        );
        setFilterOptions(filterData);
      } else if (data.length > 0) {
        setFilterOptions(data);
      }
    }, 1000);
    return () => clearTimeout(getData);
  }, [searchTerm]);

  const getOwners = (item: NFTTableRowType) => {
    if (nftOwnerMap?.has(item.nftContractAddress)) {
      const nftTokenMap = nftOwnerMap.get(item.nftContractAddress);
      if (nftTokenMap?.has(item.tokenId)) {
        const ownerAddressList = nftTokenMap.get(item.tokenId) || [];
        return ownerAddressList.map((ow) => {
          const ownerAddress = ow.toUpperCase();
          const ownerEmail = ownerEmailMap?.get(ownerAddress);
          return ownerEmail ? { email: ownerEmail, address: ownerAddress } : undefined;
        });
      }
    }
    return [];
  };

  return (
    <>
      <Flex justifyContent={"space-between"} alignItems={"center"} mb={5}>
        <InputGroup size="md" maxW={["100%", "100%", "40%", "30%"]}>
          <Input
            value={searchTerm}
            placeholder="Search NFTs by name or collection"
            size="md"
            onChange={(event) => setSearchTerm(event.target.value)}
          />
          {searchTerm.length > 0 && (
            <InputRightElement width="4.5rem">
              <IconButton
                aria-label="clear user search"
                h="1.75rem"
                size="sm"
                icon={<CloseIcon />}
                variant={"unstyled"}
                onClick={() => setSearchTerm("")}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <Box>
          <Flex>
            {DEV_ENV && <Button onClick={() => navigate("/createNFT")}>+ Create NFT</Button>}
            <Tooltip label="Download CSV File">
              <IconButton
                variant="ghost"
                fontSize="20px"
                icon={<HiDownload />}
                aria-label={""}
                onClick={() => {
                  setIsOpenExportModal(true);
                }}
                ml={4}
                mr={4}
              />
            </Tooltip>
          </Flex>
        </Box>
      </Flex>
      <Box overflow={"auto"}>
        <Grid
          templateRows={"repeat(1, 1fr)"}
          templateColumns={"repeat(10, 1fr)"}
          bg={useColorModeValue("black.100", "black.400")}
          py={4}
          rounded={"lg"}
          minW={"1050px"}
          overflow={"hidden"}
          alignItems={"center"}
          textAlign={{ base: "center", md: "left" }}
        >
          <GridItem></GridItem>
          {Columns.map((item, index) => (
            <GridItem key={item.label + index} colSpan={item.colSpan}>
              <Text textStyle={"paragraph"} color={rowValueColor}>
                {item.label}
              </Text>
            </GridItem>
          ))}
          <GridItem></GridItem>
        </Grid>
        {filterOptions.length > 0 &&
          filterOptions.map((item, index) => {
            const owners = getOwners(item);
            return (
              <Grid
                templateRows={"repeat(1, 1fr)"}
                templateColumns={"repeat(10, 1fr)"}
                rounded={"lg"}
                border={"1px"}
                borderColor={rowBorderColor}
                borderStyle={"solid"}
                py={4}
                mt={3}
                overflow={"hidden"}
                alignItems={"center"}
                textAlign={{ base: "center", md: "left" }}
                key={"user_table" + item.tokenId + index}
                minW={"1050px"}
              >
                <GridItem>
                  <Flex justifyContent={"center"}>
                    <Link to={`/detail/${item.nftContractAddress}/${hexToDecimal(item.tokenId.split("x")[1])}`}>
                      <Image
                        rounded="xl"
                        width="90px"
                        height="90px"
                        fit="cover"
                        src={item.image}
                        fallbackSrc={FALLBACK_URL_IMAGE}
                        alt={item.name}
                        _hover={{
                          width: "100px",
                          height: "100px",
                        }}
                        cursor={"pointer"}
                      />
                    </Link>
                  </Flex>
                </GridItem>
                <GridItem>
                  <Text textStyle={"paragraph"} color={rowValueColor}>
                    {hexToDecimal(item.tokenId.split("x")[1])}
                  </Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <Text textStyle={"paragraph"} color={rowValueColor} paddingRight={4}>
                    {getStandardizedNFTName(item.name)}
                  </Text>
                </GridItem>
                <GridItem colSpan={2} display={"flex"}>
                  <MaxSupply
                    index={index}
                    owners={owners.filter((owner) => !!owner)}
                    nftOwnerMapLoading={nftOwnerMapLoading}
                    contractAddress={item.nftContractAddress}
                    tokenId={item.tokenId}
                    supplyMap={supplyMap}
                    setSupplyMap={setSupplyMap}
                  />
                </GridItem>
                <GridItem colSpan={1}>
                  <Text textStyle={"paragraph"} color={rowValueColor}>
                    {orgCollectionsNames[`${item.nftContractAddress.toLowerCase()}`] || "-"}
                  </Text>
                </GridItem>
                {/* <GridItem colSpan={2}>
            <Text textStyle={"paragraph"} color={rowValueColor} noOfLines={1}>
              {item.description}
            </Text>
          </GridItem> */}

                <GridItem>
                  <Text textStyle={"paragraph"} color={rowValueColor}></Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <HStack justifyContent={{ base: "center", md: "flex-start" }}>
                    <Button
                      size={"sm"}
                      onClick={() => {
                        onOpen();
                        setActionType(ActionType.AIRDROP, item.tokenId, item.nftContractAddress);
                      }}
                    >
                      Airdrop Token
                    </Button>
                    {/* <IconButton
                      variant="ghost"
                      fontSize="20px"
                      icon={<AiOutlineEdit />}
                      aria-label={""}
                      onClick={() => {
                        // onOpen();
                        // setActionType(ActionType.ELIFIBILITY, item.tokenId, item.nftContractAddress);
                      }}
                    /> */}
                    <Tooltip label="Create Allow List">
                      <IconButton
                        variant="ghost"
                        fontSize="20px"
                        icon={<BsCardChecklist />}
                        aria-label={""}
                        onClick={() => {
                          setActionType(ActionType.ALLOW_LIST, item.tokenId, item.nftContractAddress);
                          onOpen();
                        }}
                      />
                    </Tooltip>

                    {DEV_ENV && (
                      <Tooltip label="Reclaim NFT">
                        <IconButton
                          variant="ghost"
                          fontSize="20px"
                          icon={<BiBox />}
                          aria-label={""}
                          onClick={() => {
                            setActionType(ActionType.RECLAIM_TOKEN, item.tokenId, item.nftContractAddress);
                            onOpen();
                          }}
                        />
                      </Tooltip>
                    )}

                    <Tooltip label="Edit NFT">
                      <IconButton
                        variant="ghost"
                        fontSize="20px"
                        aria-label={""}
                        icon={<AiOutlineSetting />}
                        onClick={() =>
                          navigate(`/editNFT/${item.nftContractAddress}/${hexToDecimal(item.tokenId.split("x")[1])}`)
                        }
                      />
                    </Tooltip>
                  </HStack>
                </GridItem>
              </Grid>
            );
          })}
        {!(data?.length > 0) && <NotFound message={"No NFTs created"} />}
        <EligibilityRulesModal isOpen={isOpenEligibilityRules} onClose={onCloseEligibilityRules} />
      </Box>
      <ExportingNFTCsvModal
        isOpen={isOpenExportModal}
        onClose={() => setIsOpenExportModal(false)}
        data={filterOptions}
        supplyMap={supplyMap}
        orgCollectionsNames={orgCollectionsNames}
      />
    </>
  );
}
interface MaxSupplyProps {
  index: number;
  owners: ({ email: string; address: string } | undefined)[];
  nftOwnerMapLoading: boolean;
  contractAddress: string;
  tokenId: string;
  supplyMap: Map<string, any> | undefined;
  setSupplyMap: any;
}

function MaxSupply(props: MaxSupplyProps) {
  const fetchSupplyRef: any = useRef(null);
  const { index, owners, nftOwnerMapLoading, contractAddress, tokenId, supplyMap, setSupplyMap } = props;
  const {
    contract,
    maxSupply,
    getTokenMaxSupply,
    isLoading: adminFunctionsLoading,
  } = useAdminFunctions({
    contractAddress,
  });

  // Styling
  const rowValueColor = useColorModeValue("black.300", "white");
  const rowBorderColor = useColorModeValue("gray.200", "gray.500");

  // Load maxSupply
  useEffect(() => {
    const getSupply = async () => {
      await getTokenMaxSupply(tokenId);
    };
    if ((!(fetchSupplyRef.current as boolean) || !maxSupply) && !adminFunctionsLoading && contract) {
      getSupply();
      fetchSupplyRef.current = "SupplyLoaded";
    }
  }, [contract]);

  const displayMaxSupply = (supply: BigNumber | undefined) => {
    if (supply) {
      const hexNum = Number(supply._hex);
      const maxSupply = hexNum == 0 ? "unlimited" : hexNum;
      // add supply and owners to the map
      const updatedSupplyMap = supplyMap ? cloneDeep(supplyMap) : new Map();
      const filteredOwner = owners?.filter((o) => !!o) || [];
      updatedSupplyMap.set(`${contractAddress}-${tokenId}`, { owners: filteredOwner.length, supply: maxSupply, ownerList: filteredOwner });
      setSupplyMap(updatedSupplyMap);
      return maxSupply;
    } else {
      // In case if the initial call failed
      getTokenMaxSupply(tokenId);
    }
  };

  const key = `max-supply-${index}`;

  return nftOwnerMapLoading || adminFunctionsLoading || !contract || !owners ? (
    <Text textStyle={"paragraph"} color={rowValueColor} key={key}>
      Loading...
    </Text>
  ) : owners.length === 0 || owners.filter((o) => !!o).length === 0 ? (
    <Text textStyle={"paragraph"} color={rowValueColor} width={"fit-content"} key={key}>
      {`0 of ${displayMaxSupply(maxSupply)}`}
    </Text>
  ) : (
    <Popover placement="right" trigger="hover" key={key}>
      <PopoverTrigger>
        <Text textStyle={"paragraph"} color={rowValueColor} width={"fit-content"}>
          {`${owners.filter((o) => !!o).length} of ${displayMaxSupply(maxSupply)}`}
        </Text>
      </PopoverTrigger>
      <PopoverContent borderColor={rowBorderColor} width={"fit-content"}>
        <PopoverArrow />
        <PopoverBody>
          {owners
            .filter((owner) => !!owner)
            .map((owner) => (
              <Text textStyle={"label"} key={owner?.email}>
                {owner?.email}
              </Text>
            ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export default NFTsTable;
