import { Container } from "@chakra-ui/react";

function MasterBox(props: any) {
  const { children } = props;
  return (
    <Container maxW={["full", "1440px"]} {...props}>
      {children}
    </Container>
  );
}

export default MasterBox;
