import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Spacer,
  Textarea,
  useClipboard,
  Button,
  InputGroup,
  Input,
  InputRightElement,
  Tooltip,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import SuccessModal from "../../common/Modal/SuccessModal";
import { useNavigate } from "react-router-dom";
import { CreateOrganizationRes } from "../JoinOrganization";
import { useMutation } from "@apollo/client";
import { CREATE_INVITES_MUTATION } from "../../../queries/invite";
import * as API from "../../../util/api";
import { ErrorMessage } from "../../../util/Constants";
import { JOIN_CODE_URL_PREFIX } from "../../../util/Constants";

function InviteMembers(props: { organization: CreateOrganizationRes | undefined }) {
  const { organization } = props;
  const accessCode = organization?.accessCode || "";
  const joinCode = JOIN_CODE_URL_PREFIX + accessCode;
  const [emails, setEmails] = useState<string>("");
  const [isOpenInviteSuccessModal, setOpenInviteSuccessModal] = useState<boolean>(false);
  const [createInvites, { loading: isAddUserLoading, error: addUserError }] = useMutation(CREATE_INVITES_MUTATION);

  const { hasCopied, onCopy } = useClipboard(joinCode);
  const toast = useToast();
  const navigate = useNavigate();
  const handleInvite = () => {
    // Add user to DB
    const emailList = emails.split(",");
    createInvites({
      variables: {
        organizationId: organization?._id || "",
        emailAddressList: emailList,
      },
    })
      .then(() => {
        setEmails("");
        setOpenInviteSuccessModal(true);
        API.sendEmailInvitets({
          emails: emailList,
          code: accessCode,
        } as API.SendEmailInviteProps);
      })
      .catch((e) => {
        console.error("error", e);
      });
  };

  useEffect(() => {
    if (!isAddUserLoading && addUserError) {
      const errorMsg = addUserError.message?.toLowerCase().includes("duplicate key")
        ? ErrorMessage.duplicateInvite
        : ErrorMessage.genericInviteErrorMsg;
      toast({
        title: errorMsg,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [addUserError]);

  const goToDashboard = () => navigate("/dashboard/settings");

  return (
    <>
      <Box>
        <Text textStyle={"h1"}>Invite Members</Text>
        <Text m={2} color="gray.500" fontWeight={700}>
          Invite users to your digital collectable workspace by adding ther email addresses below or sending them the
          joint link.
        </Text>
        <Spacer h={8} />
        <Text mt="8px" mb="8px" textStyle={"h3"}>
          Email Address
        </Text>
        <Textarea
          variant="outline"
          value={emails}
          onChange={(e) => setEmails(e.target.value)}
          placeholder="Email seperated by commas"
        />
        <Spacer h={4} />
        {accessCode && (
          <Box>
            <Text mt="8px" mb="8px" textStyle={"h3"}>
              Join Code
            </Text>
            <InputGroup>
              <Input type="text" value={joinCode} variant={"filled"} disabled />
              <InputRightElement onClick={() => onCopy()} cursor={"pointer"}>
                <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                  <Icon as={CopyIcon} />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </Box>
        )}

        <Spacer h={10} />
        <Button onClick={handleInvite} loadingText={"Loading"}>
          Invite and Continue
        </Button>
        <Button ml={4} onClick={() => goToDashboard()} variant={"unstyled"} loadingText={"Loading"} pl={2} pr={2}>
          Skip
        </Button>
      </Box>
      <SuccessModal
        isOpen={isOpenInviteSuccessModal}
        onClose={() => {
          setOpenInviteSuccessModal(false);
          goToDashboard();
        }}
        ctaAction={() => {
          setOpenInviteSuccessModal(false);
          goToDashboard();
        }}
        title={"Invite Sent!"}
        description={"You have successfully invited users to your digital collectable workspace!"}
        subDescription={""}
        ctaTitle={"Go to dashboard"}
      />
    </>
  );
}

export default InviteMembers;
