import { Grid, useColorModeValue, Flex, Box, Spacer, Text, List, ListItem, Button } from "@chakra-ui/react";
import { supportEmail } from "../../../util/Constants";
import { USER_ROLE_TYPE } from "../../common/Table/UsersTable";

type PlanContent = {
  title: string;
  description: string;
  lists: {
    listTitle: string;
    listContents: string[];
  }[];
};

const planContents = {
  STARTER: {
    title: "Starter",
    description: "Create your Digital Collectible NFTverse",
    lists: [
      { listTitle: "Limits", listContents: ["1 Collection", "Unlimited NFTs", "100 Users, 1 Admin"] },
      {
        listTitle: "Features",
        listContents: [
          "Secure User Wallet Provisioning",
          "Domain or Join code gated",
          "Custom Smart Contracts",
          "Gasless Minting & Distribution",
          "16 hour customer support time",
        ],
      },
    ],
  },
  BUSINESS: {
    title: "Business",
    description: "Advanced Features, No Limits",
    lists: [
      { listTitle: "Limits", listContents: ["5 Collection", "Unlimited NFTs", "250 Users, 5 Admin"] },
      {
        listTitle: "Everything in Starter, plus",
        listContents: [
          "Custom Branding",
          "Analytics",
          "QR Code Generator",
          "DALL-E AI Art Creator",
          "8 hour customer support time",
        ],
      },
    ],
  },
  ENTERPRISE: {
    title: "Enterprise",
    description: "Advanced Features, No Limits",
    lists: [
      { listTitle: "Limits", listContents: ["Unlimited Collection", "Unlimited NFTs", "Unlimited Users"] },
      {
        listTitle: "Everything in Business, plus",
        listContents: [
          "Custom Domain",
          "System Integrations",
          "Opensea Support Customization",
          "Opensea Customization Support",
          "4 hour customer support time",
        ],
      },
    ],
  },
};

function PlanNBillingSettingSubsection(props: any) {
  const { data: orgData } = props;
  const orgId = orgData._id || "";
  const userCount = orgData.users.filter((u: any) => ![USER_ROLE_TYPE.OPERATOR].includes(u.role)).length || 0;
  const adminCount = orgData.users.filter((u: any) => [USER_ROLE_TYPE.ADMIN].includes(u.role)).length || 0;

  const sendEmailToSupport = () => {
    window.open(`mailto:${supportEmail}?subject=Upgrade my plan!`, "_blank");
  };

  // Stying
  const rowBorderColor = useColorModeValue("gray.200", "gray.500");
  const statusBoxColor = useColorModeValue(
    { background: "#eaeaf9", text: "#252fc1" },
    { background: "white", text: "black" }
  );
  return (
    <Box>
      <Grid rounded={"lg"} border={"2px"} borderColor={rowBorderColor} borderStyle={"solid"} m={3}>
        <Box p={4}>
          <Flex>
            <Box flex="1">
              <Text textStyle={"h3"}>minted Starter</Text>
              <Text textStyle={"h1"}>Free</Text>
            </Box>
            <Box flex="1">
              <Box display="flex" justifyContent={"right"}>
                <Text
                  p={2}
                  fontWeight={"600"}
                  textStyle={"h4"}
                  width={"fit-content"}
                  rounded={"lg"}
                  bg={statusBoxColor.background}
                  textColor={statusBoxColor.text}
                >
                  Active
                </Text>
              </Box>
              <Text p={2} textAlign={"right"}>
                Organization ID:{orgId}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box borderTop={"1px"} borderColor={rowBorderColor} borderStyle={"solid"}>
          <Flex direction={"row"} flexWrap={"wrap"}>
            <Box flex="1" p={4}>
              <Text textStyle={"h2"}>{adminCount}</Text>
              Admins
            </Box>
            <Box
              borderLeft={"1px"}
              borderColor={rowBorderColor}
              borderStyle={"solid"}
              p={4}
              flex="1"
              justifyContent={"right"}
            >
              <Text textStyle={"h2"}>{userCount}</Text>
              Users
            </Box>
          </Flex>
        </Box>
      </Grid>

      {/* Lower Section for Plans */}
      <Text m={3} pt={4} textStyle={"h2"}>
        All Plans
      </Text>
      <Flex flexWrap={"wrap"}>
        <Box flex="1" minW={"350px"} m={3}>
          <PlanSections isCurrentPlan={true} content={planContents["STARTER"]} />
        </Box>
        <Box flex="1" minW={"350px"} m={3}>
          <PlanSections
            isCurrentPlan={false}
            buttonText={"Upgrade"}
            content={planContents["BUSINESS"]}
            buttonFunc={() => sendEmailToSupport()}
          />
        </Box>
        <Box flex="1" minW={"350px"} m={3}>
          <PlanSections
            isCurrentPlan={false}
            buttonText={"Contact Sales"}
            content={planContents["ENTERPRISE"]}
            buttonFunc={() => sendEmailToSupport()}
          />
        </Box>
      </Flex>
    </Box>
  );
}

type PlanSectionsProp = {
  content: PlanContent;
  isCurrentPlan: boolean;
  buttonText?: string;
  isButtonDisabled?: boolean;
  buttonFunc?: () => void;
};

function PlanSections(props: PlanSectionsProp) {
  const { content, isCurrentPlan, buttonText, isButtonDisabled, buttonFunc } = props;

  // Stying
  const subItemColor = useColorModeValue("gray.400", "gray.300");
  const rowBorderColor = useColorModeValue("gray.200", "gray.500");
  const fontWeight = 600;
  const PlanBoxPadding = "6";

  return (
    <Grid rounded={"lg"} border={"2px"} borderColor={rowBorderColor} borderStyle={"solid"} p={PlanBoxPadding}>
      <Text textStyle={"h3"}>{content.title}</Text>
      <Text color={subItemColor} fontWeight={fontWeight}>
        {content.description}
      </Text>
      <List mt={6} spacing={6}>
        {content.lists.map((list) => {
          return (
            <>
              <ListItem fontWeight={fontWeight}> {list.listTitle}</ListItem>
              {list.listContents.map((lc) => {
                return (
                  <ListItem color={subItemColor} fontWeight={fontWeight}>
                    {lc}
                  </ListItem>
                );
              })}
            </>
          );
        })}
      </List>
      <Spacer mt={10} />
      {isCurrentPlan ? (
        <Box display="flex" justifyContent={"center"}>
          <Text m={1} textStyle={"h3"}>
            Your Plan
          </Text>
        </Box>
      ) : (
        <Button
          disabled={!!isButtonDisabled}
          onClick={() => {
            if (buttonFunc) buttonFunc();
          }}
        >
          {buttonText}
        </Button>
      )}
    </Grid>
  );
}

export default PlanNBillingSettingSubsection;
