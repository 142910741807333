import React from "react";
import { Box, Button, Flex, Image, Input, Spacer, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useBetween } from "use-between";
import { LoginTypeEnum } from "../common/ConnectWallet/ConnectWalletModal";
import useConnectWalletStrategy from "../common/ConnectWallet/ConnectWalletStrategy";
import Logo from "../common/logo";
import SuccessModal from "../common/Modal/SuccessModal";
import { useLoginState } from "../Layout/Header";
import LogRocket from "logrocket";

const imageUrls = "https://storage.googleapis.com/minted-nfts/minted/minted-config/images/minted-onboarding-01.png";

export default function JoinMinted() {
  const [email, setEmail] = React.useState<string>();
  const [accessCode, setAccessCode] = React.useState<string>();
  const { setIsLogin, loading: isLoginLoading, setLoading } = useBetween(useLoginState);
  const { isOpen: isOpenSuccessLogin, onOpen: onOpenSuccessLogin, onClose: onCloseSuccessLogin } = useDisclosure();
  const { connectWallet } = useConnectWalletStrategy();
  const navigate = useNavigate();
  const toast = useToast();

  // Retrieve query parameters from url
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamEmail = queryParams.get("email");
    const queryParamAccessCode = queryParams.get("code");
    setEmail(queryParamEmail || "");
    setAccessCode(queryParamAccessCode || "");
  }, []);

  const magicLogin = async (orgData: any) => {
    try {
      if (email) {
        setLoading(true);
        await connectWallet(LoginTypeEnum.MAGIC_LINK, email, accessCode);
        onOpenSuccessLogin();
        setIsLogin(true);
        setLoading(false);
        LogRocket.identify(email);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: "Something went wrong while connect with magic. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  // Stying
  const sectionMinW = 400;

  return (
    <>
      <Box>
        <Flex minH={"700px"} flexWrap={"wrap"}>
          <Box flex={1} minW={sectionMinW} p={10}>
            <Box onClick={() => navigate("/")} cursor={"pointer"}>
              <Logo />
            </Box>
            <Spacer h={12} />
            <Box pl={3}>
              <Text textStyle={"h1"}>Create Wallet</Text>
              <Text m={2} color="gray.500" fontWeight={700}>
                Enter your email below to create your wallet and join your organization's digital collectible universe.
              </Text>
              <Spacer h={8} />
              <Box w="70%">
                <Text mt="8px" mb="8px" textStyle={"h3"}>
                  Email Address
                </Text>
                <Input
                  type="text"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value || "")}
                />
                <Spacer h={4} />
                <Text mt="8px" mb="8px" textStyle={"h3"}>
                  Join Code
                </Text>
                <Input
                  type="text"
                  placeholder="Enter your organization join code"
                  value={accessCode}
                  onChange={(e) => setAccessCode(e.target.value || "")}
                />
              </Box>
              <Spacer h={10} />
              <Button onClick={magicLogin} disabled={!email || isLoginLoading} isLoading={isLoginLoading}>
                Join minted
              </Button>
            </Box>
          </Box>
          <Box
            flex={1}
            minW={sectionMinW}
            p={"8%"}
            bg={"secondary.900"}
            display="flex"
            justifyContent={"center"}
            alignContent={"center"}
          >
            <Image rounded="md" fit="contain" src={imageUrls} />
          </Box>
        </Flex>
      </Box>

      <SuccessModal
        isOpen={isOpenSuccessLogin}
        onClose={() => {
          onCloseSuccessLogin();
          navigate("/collection");
        }}
        ctaAction={() => {
          onCloseSuccessLogin();
          navigate("/collection");
        }}
        title={"Verified!"}
        description={"You have successfully verified your account"}
        subDescription={""}
        ctaTitle={"Continue"}
      />
    </>
  );
}
