import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue } from "@chakra-ui/react";
import { BarChart as RechartsBarChart, Bar, XAxis, YAxis, ResponsiveContainer, Tooltip } from "recharts";
import { ChevronDownIcon } from "@chakra-ui/icons";

const data = [
  {
    name: "JAN",
    nft: 10,
  },
  {
    name: "FEB",
    nft: 15,
  },
  {
    name: "MAR",
    nft: 12,
  },
  {
    name: "APR",
    nft: 25,
  },
  {
    name: "MAY",
    nft: 20,
  },
  {
    name: "JUN",
    nft: 20,
  },
  {
    name: "JUL",
    nft: 24,
  },
  {
    name: "AUG",
    nft: 10,
  },
  {
    name: "SEP",
    nft: 19,
  },
  {
    name: "OCT",
    nft: 18,
  },
  {
    name: "NOV",
    nft: 17,
  },
  {
    name: "DEC",
    nft: 37,
  },
];

const monthData = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

interface BarChartProps {
  title: string;
}

export const BarChart = (props: BarChartProps) => {
  const { title } = props;
  const backgroundBox = useColorModeValue("white", "gray.800");
  const borderColorBox = useColorModeValue("gray.200", "gray.500");

  return (
    <Box
      bg={backgroundBox}
      py={5}
      px={10}
      rounded={"lg"}
      border={"1px"}
      borderColor={borderColorBox}
      borderStyle={"solid"}
      minW={500}
    >
      <Flex
        justify={"space-between"}
        width={"full"}
        align={"center"}
        borderBottom={1}
        mb={4}
        pb={2}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.500")}
      >
        <Box>
          <Text color="black.200" textStyle={"paragraphMedium"}>
            {title}
          </Text>
        </Box>
        <Box>
          <Menu isLazy>
            <MenuButton color="black.200" fontWeight={400}>
              Month {<ChevronDownIcon />}
            </MenuButton>
            <MenuList h="130px" overflowX="auto">
              {monthData &&
                monthData.map((month, index) => (
                  <MenuItem color="black.200" fontWeight="500" key={index}>
                    {month}
                  </MenuItem>
                ))}
            </MenuList>
          </Menu>
        </Box>
      </Flex>
      <ResponsiveContainer width="100%" aspect={4.0 / 1.7}>
        <RechartsBarChart
          // width={"1000"}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: -20,
            bottom: 5,
          }}
          barSize={15}
        >
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 10, right: 10 }}
            tickLine={false}
            axisLine={false}
            tick={{ fontSize: 12 }}
          />
          <Tooltip contentStyle={{ color: "black" }} />
          <YAxis unit="Min" tickLine={false} axisLine={false} tick={{ fontSize: 10 }} />
          <Bar dataKey="nft" fill={"#252FC1"} background={{ fill: "#F2F7FF", radius: 30 }} radius={30} />
        </RechartsBarChart>
      </ResponsiveContainer>
    </Box>
  );
};
