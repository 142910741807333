import { HStack, Icon, Text, useBreakpointValue, useColorModeValue } from "@chakra-ui/react";

function Logo(props: any) {
  const { textColor, logoColor } = props;
  const defaultTextColor = useColorModeValue("gray.800", "white");
  return (
    <HStack spacing={1}>
      <Icon viewBox="0 0 26 32" width={25} height={27} pt={1}>
        <path d="M0 19.1396L12.7173 12.1146L25.4347 19.1396L12.7173 26.1647L0 19.1396Z" fill={logoColor || "#252FC1"} />
        <path d="M0 15.1462L12.7173 8.12123L25.4347 15.1462L12.7173 22.1713L0 15.1462Z" fill="white" />
        <path d="M0 13.1462L12.7173 6.12123L25.4347 13.1462L12.7173 20.1713L0 13.1462Z" fill={logoColor || "#252FC1"} />
      </Icon>
      <Text
        textAlign={useBreakpointValue({ base: "center", md: "left" })}
        color={textColor || defaultTextColor}
        textStyle={"h2"}
      >
        minted
      </Text>
    </HStack>
  );
}

export default Logo;
