import { CheckIcon } from "@chakra-ui/icons";
import { Box, Divider, Stack, Text } from "@chakra-ui/react";
import { Fragment } from "react";

interface ProgressStepperProps {
  loading: boolean;
  errorMessage: string;
  title: string;
  activeIndex: number;
}

const data = [0, 1, 2];

const StepperButton = (props: { title: string; isActive?: boolean; isFinish?: boolean }) => {
  const { title, isActive, isFinish } = props;

  if (isFinish) {
    return (
      <Box borderWidth={1} borderStyle={"solid"} borderColor={"primary.900"} borderRadius={"25px"} padding={"9px 14px"}>
        <CheckIcon w={"19px"} h={"28px"} color={"primary.900"} />
      </Box>
    );
  }

  return (
    <Text
      textStyle={"paragraphMedium"}
      borderWidth={1}
      borderStyle={"solid"}
      borderColor={"primary.900"}
      borderRadius={"25px"}
      padding={"12px 20px"}
      fontWeight={"bold"}
      color={isActive ? "white" : "primary.900"}
      bg={isActive ? "primary.900" : "white"}
    >
      {title}
    </Text>
  );
};
const ProgressStepper = ({ loading, errorMessage, title, activeIndex }: ProgressStepperProps) => {
  return (
    <>
      <Stack direction={"row"} align={"center"} spacing={0} mt={5}>
        {data.map((item: number, index: number) => (
          <Fragment key={index}>
            <StepperButton title={`${index + 1}`} isFinish={index < activeIndex} isActive={activeIndex === index} />
            {index !== data.length - 1 && <Divider orientation="horizontal" color={"primary.900"} />}
          </Fragment>
        ))}
      </Stack>
      <Box mt={5}>
        {loading && <Text textStyle={"paragraphMedium"}>{`${title}: This may take a few minutes`}</Text>}
        {errorMessage && <Text textStyle={"paragraphMedium"}>{errorMessage}</Text>}
      </Box>
    </>
  );
};

export default ProgressStepper;
