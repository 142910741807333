import { CopyIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useColorModeValue,
  Textarea,
  Spacer,
  useClipboard,
  InputGroup,
  Input,
  InputRightElement,
  Tooltip,
  Icon,
} from "@chakra-ui/react";
import { JOIN_CODE_URL_PREFIX } from "../../../util/Constants";

export default function AddUsersModal(props: {
  isOpen: boolean;
  onClose: () => void;
  loading: boolean;
  value: string;
  setValue: (v: any) => void;
  ctaAction: () => void;
  accessCode: string;
}) {
  const { isOpen, onClose, loading, value, setValue, ctaAction, accessCode } = props;
  const joinCode = JOIN_CODE_URL_PREFIX + accessCode;
  const { hasCopied, onCopy } = useClipboard(joinCode);
  const handleChange = (event: any) => setValue(event.target.value);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"3xl"} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent borderRadius={"2xl"} py={5}>
        <Text textStyle={"h1"} px={6} pt={5}>
          Invite Members
        </Text>
        <ModalCloseButton />
        <ModalBody>
          <Text textStyle={"paragraph"} color={useColorModeValue("black.300", "white")}>
            Invite users to your digital collectable workspace by adding their email addresses below or sending them the
            join link.
          </Text>
          <Spacer h={8} />
          <Text mt="8px" mb="8px" textStyle={"h3"}>
            Email Address
          </Text>
          <Textarea variant="outline" value={value} onChange={handleChange} placeholder="Email seperated by commas" />
          <Spacer h={4} />
          {accessCode && (
            <>
              <Text mt="8px" mb="8px" textStyle={"h3"}>
                Join Code
              </Text>
              <InputGroup>
                <Input type="text" value={joinCode} variant={"filled"} disabled />
                <InputRightElement onClick={() => onCopy()} cursor={"pointer"}>
                  <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                    <Icon as={CopyIcon} />
                  </Tooltip>
                </InputRightElement>
              </InputGroup>
            </>
          )}
          <Flex justify={"center"} mt={5}>
            <Button onClick={ctaAction} isLoading={loading} loadingText={"Loading"}>
              Invite New Members
            </Button>
          </Flex>
        </ModalBody>
        <ModalFooter flexDirection={"column"}></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
