import { Flex, HStack, Text, Tooltip, useClipboard } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { SiEthereum } from "react-icons/si";
import { getstandardizedOwnerAddress } from "../../util/helper";

interface ValutAddressProps {
  walletAddress: string;
}

function ValutAddress(props: ValutAddressProps) {
  const { walletAddress } = props;
  const { hasCopied, onCopy } = useClipboard(walletAddress);
  return (
    <Tooltip label={hasCopied ? "Copied" : "Copy"}>
      <Flex direction={"row"} justifyContent={"space-between"} alignItems={"center"} w={"full"} onClick={onCopy}>
        <HStack>
          <SiEthereum />
          <Text>{getstandardizedOwnerAddress(walletAddress)}</Text>
        </HStack>
        <HStack>
          <CopyIcon />
        </HStack>
      </Flex>
    </Tooltip>
  );
}

export default ValutAddress;
