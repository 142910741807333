import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { getPaperLink } from "../../services/Paper";
import { TbConfetti } from "react-icons/tb";

let checkOutLink =
  "https://paper.xyz/checkout/9c83d5aa-e3d8-46cf-b4d8-4a0fdad22b29/one-time-link/fcc60243-d59c-4f6f-965e-2ca2ab48200a";

interface PaperModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function PaperModal(props: PaperModalProps) {
  const { isOpen, onClose } = props;
  const { hasCopied, onCopy } = useClipboard(checkOutLink);
  useEffect(() => {
    if (isOpen) {
      // fetchPaperLink();
    }
  }, [isOpen]);

  const fetchPaperLink = async () => {
    try {
      const response = await getPaperLink();
      console.log(response);
    } catch (error) {
      console.log("Error While Fetching the Paper Link", error);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>NFT Claim Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={5}>
            <Text pb={3}>
              You can use this link to claim the NFT. You can only use this one
              time.
            </Text>
            <Link href={checkOutLink} isExternal color={"primary.900"}>
              {checkOutLink}
              <ExternalLinkIcon mx="2px" />
            </Link>
          </ModalBody>
          <ModalFooter>
            <Button
              rightIcon={hasCopied ? <TbConfetti /> : <CopyIcon />}
              w={"full"}
              onClick={onCopy}
              variant={"ghost"}
            >
              {hasCopied ? "Copied Successfully" : "Copy Link"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default PaperModal;
