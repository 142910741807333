import {
  Box,
  Flex,
  Heading,
  HStack,
  Image,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Switch,
  VStack,
  Button,
  Icon,
  useDisclosure,
  useColorModeValue,
  IconButton,
} from "@chakra-ui/react";

import useAdminFunctions from "../../hooks/Dashboard/AdminFunctions";
import MasterBox from "../Layout/MasterBox";
import { getstandardizedOwnerAddress } from "../../util/helper";
import { MdDescription } from "react-icons/md";
import { BiDetail } from "react-icons/bi";
import { AiTwotonePropertySafety } from "react-icons/ai";
import { useParams } from "react-router-dom";
import useFetchNFTMetaData from "../../hooks/FetchNTFMetaData";
import { useEffect, useRef, useState } from "react";
import Loader from "../common/Loader";
import { v4 as uuidv4 } from "uuid";
import { CloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import EditNFTActionModal, { EditNFTActionModalType } from "./Modal/EditNFTActionModal";
import { DEV_ENV } from "../../util/Constants";
const imageSize = ["250px", "250px", "250px", "350px", "350px"];

export const EditNFT = () => {
  const navigate = useNavigate();
  const hideImageInputRef = useRef<HTMLInputElement>(null);
  const replaceImageInputRef = useRef<HTMLInputElement>(null);
  const [actionType, setActionType] = useState<EditNFTActionModalType>();
  const [hideNFTImage, setHideNFTImage] = useState<File>();
  const [replaceNFTImage, setReplaceNFTImage] = useState<File>();
  let { id, contractAddress } = useParams();
  const { metaData: nftMetadata, loading: metaDataLoading, fetchMetaData } = useFetchNFTMetaData();

  const {
    contract,
    isTransferable,
    isAllowListRequired,
    maxSupplyEditNFT,
    getIsTransferable,
    getIsAllowListRequired,
    getTokenMaxSupplyEditNFT,
    setTransferable,
    toggleAllowList,
    setTokenMaxSupplyEditNFT,
  } = useAdminFunctions({
    contractAddress: contractAddress || "",
  });

  useEffect(() => {
    if (contract) {
      const tokenId = id || "";
      getIsAllowListRequired(tokenId);
      getIsTransferable(tokenId);
      getTokenMaxSupplyEditNFT(tokenId);
    }
  }, [contract]);

  useEffect(() => {
    fetchMetaData && fetchMetaData(contractAddress || "", id || "");
  }, [id, contractAddress]);

  const {
    isOpen: isOpenNFTConfirmationModal,
    onOpen: onOpenNFTConfirmationModal,
    onClose: onCloseNFTConfirmationModal,
  } = useDisclosure();

  return (
    <MasterBox minH={"65vh"}>
      {metaDataLoading ? (
        <Loader />
      ) : (
        <Box py={10}>
          <>
            <Flex mt={[5, 10]} mb={[2, 5]} justify="space-between">
              <Box>
                <Text textStyle={"h1"} fontWeight={"normal"}>
                  Edit NFT Details
                </Text>

                <Text>
                  Once minted, only some attributes of an NFT are editable. Save changes below to update the properties
                  of the NFT
                </Text>
              </Box>
              <Icon as={CloseIcon} onClick={() => navigate(-1)} cursor="pointer" />
            </Flex>

            <HStack spacing={[3, 20]} pt={5} flexDirection={["column", "row"]} align="stretch">
              <Box>
                <Image src={nftMetadata?.image || ""} w={300} />
                <Accordion allowToggle mt={3} maxW={300}>
                  <AccordionItem>
                    <h2>
                      <AccordionButton borderBottom={"1px"} borderBottomStyle={"solid"} borderBottomColor={"gray.400"}>
                        <Icon as={MdDescription} mr={1} />
                        <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                          Description
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>{nftMetadata?.description || "-"} </AccordionPanel>
                  </AccordionItem>

                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        borderTopStyle={"solid"}
                        borderTopColor={"gray.400"}
                        borderBottom={"1px"}
                        borderBottomStyle={"solid"}
                        borderBottomColor={"gray.400"}
                      >
                        <Icon as={AiTwotonePropertySafety} mr={1} />

                        <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                          Properties
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      {nftMetadata?.attributes?.map((data: any) => (
                        <Flex justify={"space-between"} key={uuidv4()}>
                          <Text>{data.trait_type}</Text>
                          <Text>{data.value}</Text>
                        </Flex>
                      ))}
                    </AccordionPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <h2>
                      <AccordionButton
                        borderTopStyle={"solid"}
                        borderTopColor={"gray.400"}
                        borderBottom={"1px"}
                        borderBottomStyle={"solid"}
                        borderBottomColor={"gray.400"}
                      >
                        <Icon as={BiDetail} mr={1} />

                        <Box as="span" flex="1" textAlign="left" fontWeight={"bold"}>
                          Details
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel pb={4}>
                      <Flex justify={"space-between"}>
                        <Text>Contract Address</Text>
                        <Text>{getstandardizedOwnerAddress(contractAddress || "")}</Text>
                      </Flex>
                      <Flex justify={"space-between"}>
                        <Text>Token ID</Text>
                        <Text>{id}</Text>
                      </Flex>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
              <Box>
                <Heading
                  textStyle={"h3"}
                  fontWeight={"bold"}
                  borderBottom={"1px"}
                  borderBottomStyle={"solid"}
                  borderBottomColor={"gray.400"}
                  pb={5}
                >
                  {nftMetadata?.name}
                </Heading>
                <Text
                  textStyle={"h3"}
                  fontWeight={"bold"}
                  borderBottom={"1px"}
                  borderBottomStyle={"solid"}
                  borderBottomColor={"gray.400"}
                  py={3}
                >
                  Configure Advanced Settings
                </Text>
                {/* <form onSubmit={onSubmit}> */}
                <HStack mt={[5, 5, 10]} spacing={[5, 5, 5, 20]}>
                  <Switch
                    size="lg"
                    isChecked={!isTransferable}
                    onChange={() => {
                      onOpenNFTConfirmationModal();
                      setActionType(EditNFTActionModalType.NON_TRANSFERABLE);
                    }}
                  />
                  <VStack align={"flex-start"} spacing={0}>
                    <Text textStyle={"h3"}>Non-transferable</Text>
                    <Text textStyle={"paragraphMedium"}>
                      if toggled on, NFT cannot be transfered to another wallet or sold on marketplaces
                    </Text>
                  </VStack>
                </HStack>
                <HStack mt={[5, 5, 10]} spacing={[5, 5, 5, 20]}>
                  <Switch
                    size="lg"
                    isChecked={isAllowListRequired}
                    onChange={() => {
                      onOpenNFTConfirmationModal();
                      setActionType(EditNFTActionModalType.ALLOW_LIST);
                    }}
                  />
                  <VStack align={"flex-start"} spacing={0}>
                    <Text textStyle={"h3"}>Allow List</Text>
                    <Text textStyle={"paragraphMedium"}>
                      if toggled on, users cannot mint unless they are on the Allow List, Configure allow list in the
                      NFT dashboard
                    </Text>
                  </VStack>
                </HStack>
                <HStack mt={[5, 5, 10]} spacing={[5, 5, 5, 7]}>
                  <Text textStyle={"h3"}>Max Supply</Text>
                  <Flex align={"flex-start"}>
                    <Button
                      ml={3}
                      px={10}
                      onClick={() => {
                        onOpenNFTConfirmationModal();
                        setActionType(EditNFTActionModalType.MAX_SUPPLY);
                      }}
                    >
                      Set Max Supply
                    </Button>
                  </Flex>
                </HStack>

                {DEV_ENV && (
                  <>
                    <HStack mt={[5, 5, 10]} spacing={[5, 5, 5, 20]}>
                      <Switch size="lg" />
                      <VStack align={"flex-start"} spacing={0}>
                        <Text textStyle={"h3"}>Hide NFT</Text>
                        <Text textStyle={"paragraphMedium"}>
                          if toggled on, NFT will display pre-reveal media. If no media is uploaded. NFT will not
                          display
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack spacing={[5, 5, 5, 20]} mt={3}>
                      <Box w={50}></Box>
                      <Flex maxW={500} flexDirection={["column", "row"]}>
                        <Box w="full">
                          <input
                            type="file"
                            onChange={(e) => {
                              setHideNFTImage(e.target.files?.[0]);
                            }}
                            accept={"image/*"}
                            name={"hideNFTImage"}
                            ref={hideImageInputRef}
                            style={{ display: "none" }}
                          />
                          <Flex
                            height={200}
                            width={250}
                            bg={useColorModeValue("black.100", "black.200")}
                            justifyContent={"center"}
                            alignItems={"center"}
                            cursor={"pointer"}
                            rounded={10}
                            boxShadow={"md"}
                          >
                            <Flex direction={"column"} px={5} textAlign={"center"}>
                              <Text textStyle={"h3"} mb={2}>
                                Upload Your Media
                              </Text>
                              <Text textStyle={"paragraphMedium"}>PNG, JPG, GIF, or JPEG files are allowed</Text>
                              {!hideNFTImage && (
                                <IconButton
                                  icon={<FiUpload />}
                                  variant={"ghost"}
                                  aria-label="profile-image"
                                  size={"lg"}
                                  onClick={() => hideImageInputRef?.current?.click()}
                                />
                              )}
                            </Flex>
                          </Flex>
                        </Box>
                        <Box maxH="200">
                          {hideNFTImage && (
                            <Image
                              src={URL.createObjectURL(hideNFTImage)}
                              onClick={() => hideImageInputRef?.current?.click()}
                              alt="Image Preview"
                              objectFit={"contain"}
                              width={imageSize}
                            />
                          )}
                        </Box>
                      </Flex>
                    </HStack>

                    <HStack mt={[5, 5, 10]} spacing={[5, 5, 5, 20]}>
                      <Switch size="lg" />
                      <VStack align={"flex-start"} spacing={0}>
                        <Text textStyle={"h3"}>Replace NFT Image</Text>
                        <Text textStyle={"paragraphMedium"}>
                          Upload new image to replace existing image attached to NFT. This action is irreversible{" "}
                        </Text>
                      </VStack>
                    </HStack>
                    <HStack spacing={[5, 5, 5, 20]} mt={3}>
                      <Box w={50}></Box>
                      <Flex maxW={500} flexDirection={["column", "row"]}>
                        <Box w="full">
                          <input
                            type="file"
                            onChange={(e) => {
                              setReplaceNFTImage(e.target.files?.[0]);
                            }}
                            accept={"image/*"}
                            name={"replaceNFTImage"}
                            ref={replaceImageInputRef}
                            style={{ display: "none" }}
                          />
                          <Flex
                            height={200}
                            width={250}
                            bg={useColorModeValue("black.100", "black.200")}
                            justifyContent={"center"}
                            alignItems={"center"}
                            cursor={"pointer"}
                            rounded={10}
                            boxShadow={"md"}
                          >
                            <Flex direction={"column"} px={5} textAlign={"center"}>
                              <Text textStyle={"h3"} mb={2}>
                                Upload Your Media
                              </Text>
                              <Text textStyle={"paragraphMedium"}>PNG, JPG, GIF, or JPEG files are allowed</Text>
                              {!replaceNFTImage && (
                                <IconButton
                                  icon={<FiUpload />}
                                  variant={"ghost"}
                                  aria-label="profile-image"
                                  size={"lg"}
                                  onClick={() => replaceImageInputRef?.current?.click()}
                                />
                              )}
                            </Flex>
                          </Flex>
                        </Box>
                        <Box maxH="200">
                          {replaceNFTImage && (
                            <Image
                              src={URL.createObjectURL(replaceNFTImage)}
                              onClick={() => replaceImageInputRef?.current?.click()}
                              alt="Image Preview"
                              objectFit={"contain"}
                              height={imageSize}
                              width={imageSize}
                              mt={[1, 1, 1, -20]}
                            />
                          )}
                        </Box>
                      </Flex>
                    </HStack>
                  </>
                )}
              </Box>
            </HStack>
          </>
        </Box>
      )}
      {contract && (
        <EditNFTActionModal
          isOpen={isOpenNFTConfirmationModal}
          onClose={onCloseNFTConfirmationModal}
          contractAddress={contractAddress || ""}
          tokenId={id || ""}
          modalActionType={actionType}
          nonTransferableValue={!isTransferable}
          isAllowListRequired={!isAllowListRequired}
          setTransferable={setTransferable}
          toggleAllowList={toggleAllowList}
          setTokenMaxSupply={setTokenMaxSupplyEditNFT}
          maxSupply={maxSupplyEditNFT}
        />
      )}
    </MasterBox>
  );
};
