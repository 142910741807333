import { gql } from "@apollo/client";

export const FETCH_USER_BY_WALLET_ADDRESS = gql`
  query ($walletAddress: String) {
    user(walletAddress: $walletAddress) {
      _id
      organization {
        _id
        name
        title
      }
      walletAddress
      username
      email
      avatarUri
      bio
      bannerUri
      twitterUri
      instagramUri
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $id: ID!
    $username: String
    $email: String
    $avatarUri: String
    $bio: String
    $bannerUri: String
    $twitterUri: String
    $instagramUri: String
    $role: UserRoleEnum
    $status: String
  ) {
    updateUser(
      id: $id
      username: $username
      email: $email
      avatarUri: $avatarUri
      bio: $bio
      bannerUri: $bannerUri
      twitterUri: $twitterUri
      instagramUri: $instagramUri
      role: $role
      status: $status
    ) {
      username
      email
      avatarUri
      bio
      bannerUri
      twitterUri
      instagramUri
      status
    }
  }
`;

export const UPDATE_USER_ORGANIZATION_MUTATION = gql`
  mutation updateUser($id: ID!, $username: String, $organizationId: String) {
    updateUser(id: $id, username: $username, organizationId: $organizationId) {
      _id
      username
      organization {
        _id
        name
      }
    }
  }
`;

export const UPDATE_USER_ROLE_MUTATION = gql`
  mutation updateUser($id: ID!, $role: UserRoleEnum, $organizationId: String) {
    updateUser(id: $id, role: $role, organizationId: $organizationId) {
      _id
      role
      organization {
        _id
      }
    }
  }
`;

export const FETCH_USERS_BY_WALLET_ADDRESS = gql`
  query ($walletAddressList: [String]) {
    users(walletAddressList: $walletAddressList) {
      _id
      username
      email
      walletAddress
      avatarUri
    }
  }
`;
