import { useQuery } from "@apollo/client";
import { Box, Code, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { ConnectWalletContext, ConnectWalletContextType } from "../../context/connectWalletContext";
import { FETCH_USER_BY_WALLET_ADDRESS } from "../../queries/profile";
import Loader from "../common/Loader";
import MasterBox from "./../Layout/MasterBox";
import ProfileEditForm from "./Forms/EditForm";

function EditProfile() {
  const { address: walletAddress } = useContext(ConnectWalletContext) as ConnectWalletContextType;
  const { data, loading, error } = useQuery(FETCH_USER_BY_WALLET_ADDRESS, {
    variables: {
      walletAddress,
    },
    fetchPolicy: "no-cache",
  });

  return (
    <>
      <MasterBox minH={"70vh"}>
        <Box mt={[5, 10]} mb={[2, 5]}>
          <Text textStyle={"h1"} fontWeight={"normal"}>
            Profile Edit
          </Text>
        </Box>
        {loading ? (
          <Loader />
        ) : error ? (
          <Text textStyle={"paragraphMedium"} color={"red.400"}>
            Something went wrong {JSON.stringify(error)}
          </Text>
        ) : (
          <>
            {data && data.user ? (
              <ProfileEditForm user={data?.user || {}} />
            ) : (
              <Text textStyle={"paragraphMedium"}>
                No user found with that walletAddress <Code color="red">{walletAddress}</Code>. Please contact to admin.
                Thanks
              </Text>
            )}
          </>
        )}
      </MasterBox>
    </>
  );
}

export default EditProfile;
