import { Box, Button, Center, Image, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { NFT } from "../../Interfaces/NFT";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import OwnerAvatarGroup from "../common/OwnerAvatarGroup";
import useFetchNFTOwners from "../../hooks/FetchNFTOwners";
import { hexToDecimal } from "../../util/helper";

function ProfileCard(props: { nft: NFT }) {
  const {
    metadata: { id, name, image, description },
    contractAddress = "",
  } = props.nft;
  const navigate = useNavigate();
  const fetchingOwnerRef: any = useRef(null);
  const { owners, fetchOwners } = useFetchNFTOwners();

  useEffect(() => {
    if (!(fetchingOwnerRef.current as boolean)) {
      fetchOwners(contractAddress, id);
      fetchingOwnerRef.current = "ownersLoaded";
    }
  }, [id, contractAddress]);

  const routeChange = () => {
    const path = `/detail/${contractAddress}/${hexToDecimal(id?.toString()?.split("x")[1] || "")}`;
    navigate(path);
  };

  if (!image || !name) return <></>;

  return (
    <>
      <Center onClick={routeChange} cursor={"pointer"}>
        <Box
          role={"group"}
          p={[2, 2, 3, 3, 5]}
          maxW={"330px"}
          w={"full"}
          bg={useColorModeValue("transparent", "gray.800")}
          // boxShadow={'2xl'}
          border={"1px"}
          borderColor={useColorModeValue("gray.200", "gray.500")}
          borderStyle={"solid"}
          rounded={"lg"}
          _hover={{ boxShadow: useColorModeValue("xl", "2xl") }}
        >
          <Box rounded={"lg"} height={["130px", "230px", "170px", "230px", "230px", "230px"]}>
            <Image
              rounded={"lg"}
              height={[150, 250, 200, 250, 250, 250]}
              width={[180, 280, 230, 280, 280, 280]}
              objectFit={"cover"}
              src={image || ""}
              alt={"NTF_IMAGE"}
              boxShadow={"xl"}
            />
          </Box>
          <Stack pt={[8, 10]}>
            <Text textStyle={"h3"} noOfLines={1}>
              {name}
            </Text>
            <Text
              fontFamily={"body"}
              color={useColorModeValue("black.300", "white")}
              fontSize={[12, 15]}
              noOfLines={2}
              minH={10}
            >
              {description}
            </Text>
            <OwnerAvatarGroup owners={owners || []} />
            <Button variant={"filled"} onClick={routeChange}>
              Discover
            </Button>
          </Stack>
        </Box>
      </Center>
    </>
  );
}

export default ProfileCard;
