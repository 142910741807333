import { useLazyQuery } from "@apollo/client";
import axios from "axios";
import { useEffect, useState } from "react";
import { FETCH_USERS_BY_WALLET_ADDRESS } from "../queries/profile";
import { appConfig } from "../util/Constants";

function useFetchOwnerNFTCollection() {
  const [getUsers, { loading: usersLoading, data: usersData }] = useLazyQuery(FETCH_USERS_BY_WALLET_ADDRESS);
  const [nftOwnerMap, setNftOwnerMap] = useState<Map<string, Map<string, string[]>> | undefined>();
  const [ownerEmailMap, setOwnerEmailMap] = useState<Map<string, string>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const users = usersData?.users;
    if (users && Array.isArray(users)) {
      const emailMap = users.reduce(function (map, user: any) {
        if (user.walletAddress && user.email) map.set(user.walletAddress.toUpperCase(), user.email);
        return map;
      }, new Map<string, string>());
      setOwnerEmailMap(emailMap);
      setLoading(false);
    }
  }, [usersData]);

  const fetch = async (addresses: string[]) => {
    const baseURL = `${appConfig.ALCHEMY_POLYGON_URL}/getOwnersForCollection`;
    let ownerAddressSet = new Set<string>();
    const nftOwnerList = await Promise.all(
      addresses.map(async (address) => {
        const config = {
          method: "get",
          url: `${baseURL}?contractAddress=${address}&withTokenBalances=true`,
          headers: {},
        };

        return axios(config)
          .then((response) => {
            const ownerAddresses = response.data.ownerAddresses;
            // remap nft tokenIds and user wallet addresses
            const tokenUserAddressMap = ownerAddresses.reduce((map: any, ownerAddress: any) => {
              const oa = ownerAddress.ownerAddress;
              ownerAddressSet.add(oa);
              if (!oa) return map;
              const tokens = ownerAddress.tokenBalances || [];
              tokens.forEach((token: any) => {
                if (token.tokenId) {
                  let existingAddressList = map.get(token.tokenId) || [];
                  map.set(token.tokenId, [...existingAddressList, oa]);
                }
              });
              return map;
            }, new Map<string, string[]>());

            return {
              nftContractAddress: address,
              tokenUserMap: tokenUserAddressMap,
            };
          })
          .catch((error) => {
            console.error("error", error);
            return {
              nftContractAddress: address,
              tokenUserMap: undefined,
            };
          });
      })
    );

    const map = nftOwnerList.reduce(function (map, obj: any) {
      if (obj.nftContractAddress && obj.tokenUserMap) {
        map.set(obj.nftContractAddress, obj.tokenUserMap);
      }
      return map;
    }, new Map<string, Map<string, string[]>>());

    // Fetch owner emails
    getUsers({
      variables: {
        walletAddressList: [...ownerAddressSet],
      },
    });
    setNftOwnerMap(map);
  };

  const fetchOwnerNFTCollection = (addresses: string[]) => {
    if (!loading) {
      setLoading(true);
      const filteredAddresses = [...new Set(addresses)];
      fetch(filteredAddresses);
    }
  };

  return {
    nftOwnerMap,
    ownerEmailMap,
    loading: loading || usersLoading,
    error,
    fetchOwnerNFTCollection,
  };
}

export default useFetchOwnerNFTCollection;
