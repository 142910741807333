import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";

function EligibilityRulesModal({ isOpen, onClose }: { isOpen: boolean; onClose: any }) {
  const bgColor = useColorModeValue("secondary.900", "gray.900");
  const [value, setValue] = useState("0,1,2");
  const handleChange = (event: any) => setValue(event.target.value);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent borderRadius={"2xl"} py={5}>
        <Text textStyle={"h2"} px={6} pt={5}>
          Eligibility Rules
        </Text>
        <ModalCloseButton />
        <ModalBody>
          <Text textStyle={"paragraph"} color={useColorModeValue("black.300", "white")}>
            List of Tokens Required for claiming
          </Text>
          <Text mb="8px" mt={5} textStyle={"labelMedium"}>
            Token (comma delimited)
          </Text>

          <Input
            type={"text"}
            variant="filled"
            value={value}
            onChange={handleChange}
            placeholder="Comma Sperated Value"
          />
        </ModalBody>
        <ModalFooter justifyContent={"center"}>
          <Button>Save</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default EligibilityRulesModal;
