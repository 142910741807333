import {
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

export default function SingleFieldFomModal(props: {
  isOpen: boolean;
  onClose: any;
  title: string;
  description: string;
  label: string;
  ctaAction: any;
  ctaTitle: string;
  placeholder: string;
  value: any;
  setValue: any;
  loading: boolean;
  isSecond: boolean;
  secondValue: any;
  setSecondValue: any;
  secondLabel: any;
  secondPlaceHolder: any;
}) {
  const {
    isOpen,
    onClose,
    title,
    description,
    label,
    ctaAction,
    ctaTitle,
    placeholder,
    value,
    setValue,
    loading,
    secondLabel,
    secondPlaceHolder,
    secondValue,
    setSecondValue,
    isSecond,
  } = props;

  const handleChange = (event: any) => setValue(event.target.value);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"md"} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent borderRadius={"2xl"} py={5}>
        <Text textStyle={"h2"} px={6} pt={5}>
          {title}
        </Text>
        <ModalCloseButton />
        <ModalBody>
          <Text textStyle={"paragraph"} color={useColorModeValue("black.300", "white")}>
            {description}
          </Text>
          <Text mb="8px" mt={5} textStyle={"labelMedium"}>
            {label}
          </Text>
          <Input type={"text"} variant="filled" value={value} onChange={handleChange} placeholder={placeholder} />
          <Flex justify={"center"} mt={5}>
            <Button onClick={ctaAction} isLoading={loading} loadingText={"Loading"}>
              {ctaTitle}
            </Button>
          </Flex>
          {isSecond && (
            <>
              <Text mb="8px" mt={5} textStyle={"labelMedium"}>
                {secondLabel}
              </Text>
              <Input
                type={"text"}
                variant="filled"
                value={secondValue}
                onChange={(event: any) => setSecondValue(event.target.value)}
                placeholder={secondPlaceHolder}
              />
            </>
          )}
        </ModalBody>
        <ModalFooter flexDirection={"column"}></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
