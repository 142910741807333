import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FALLBACK_URL_IMAGE } from "../../../util/Constants";
import { ActionType } from "../../Dashboard"; //Save for later

import {
  ConnectWalletContext,
  ConnectWalletContextType,
  LoginWithTypeEnum,
  UserType,
} from "../../../context/connectWalletContext";

import { useContext } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_ORGANIZATION_MUTATION } from "../../../queries/profile";

export interface orgsTableRow {
  _id: string;
  name: string;
  logoUri: string;
  description: string;
  users: string[];
}
interface orgsProps {
  pData: orgsTableRow[];
}
//save for later
interface SettingsModalProps {
  isOpen: boolean;
  onClose: any;
}

const Columns = [
  {
    label: "Organization",
    colSpan: 2,
  },
  {
    label: "User Count",
    colSpan: 1,
  },
  {
    label: "Status",
    colSpan: 2,
  },
  {
    label: "",
    colSpan: 1,
  },
];

export default function OrganizationsTable(props: orgsProps): any {
  const { pData } = props;
  const { user, updateUser } = useContext(ConnectWalletContext) as ConnectWalletContextType;
  const [updateUser2] = useMutation(UPDATE_USER_ORGANIZATION_MUTATION);
  const rowBorderColor = useColorModeValue("gray.200", "gray.500");
  const rowValueColor = useColorModeValue("black.300", "white");

  const { isOpen: isOpenEligibilityRules, onClose: onCloseEligibilityRules } = useDisclosure();

  const setNewActiveOrg = (orgData: string) => {
    if (user !== null && user.loginWith === LoginWithTypeEnum.MAGIC_LINK) {
      updateUser2({
        variables: {
          id: user?._id || "",
          organizationId: orgData,
        },
      });
      user.organization = { _id: `${orgData}` };
      if (user) {
        let tempUser: UserType = {
          _id: user._id,
          role: user.role,
          organization: {
            _id: orgData,
          },
          loginWith: LoginWithTypeEnum.MAGIC_LINK,
        };

        localStorage.setItem("user", JSON.stringify(tempUser));
        updateUser(tempUser);
        // window.setTimeout(function () { location.reload() }, 300)
      }
    }
  };

  return (
    <Box overflow={"auto"}>
      <Grid
        templateRows={"repeat(1, 1fr)"}
        templateColumns={"repeat(10, 1fr)"}
        bg={useColorModeValue("black.100", "black.400")}
        py={4}
        rounded={"lg"}
        minW={"1050px"}
        overflow={"hidden"}
        alignItems={"center"}
        textAlign={{ base: "center", md: "left" }}
      >
        <GridItem></GridItem>
        {Columns.map((item, index) => (
          <GridItem key={item.label + index} colSpan={item.colSpan}>
            <Text textStyle={"paragraph"} color={rowValueColor}>
              {item.label}
            </Text>
          </GridItem>
        ))}
        <GridItem></GridItem>
      </Grid>

      {pData.map((item: any) => (
        <Grid
          key={item._id.slice(0, 8)}
          templateRows={"repeat(1, 1fr)"}
          templateColumns={"repeat(10, 1fr)"}
          rounded={"lg"}
          border={"1px"}
          borderColor={rowBorderColor}
          borderStyle={"solid"}
          py={4}
          mt={3}
          overflow={"hidden"}
          alignItems={"center"}
          textAlign={{ base: "center", md: "left" }}
          minW={"1050px"}
        >
          <GridItem>
            <Flex
              justifyContent={"center"}
              onClick={() => {
                null;
              }}
            >
              <Image
                rounded="sm"
                width="90px"
                height="90px"
                fit="cover"
                src={item?.logoUri || undefined}
                fallbackSrc={FALLBACK_URL_IMAGE}
                alt={item.name}
                _hover={
                  {
                    // width: "100px",
                    // height: "100px",
                    // save for later?
                  }
                }
                cursor={"pointer"}
              />
            </Flex>
          </GridItem>

          <GridItem colSpan={2}>
            <Text textStyle={"paragraph"} color={rowValueColor}>
              {item.name}
            </Text>
          </GridItem>

          <GridItem colSpan={1}>
            <Text textStyle={"paragraph"} color={rowValueColor}>
              {item.users?.length}
            </Text>
          </GridItem>

          {!!(item._id === user?.organization._id) ? (
            <GridItem colSpan={2}>
              <Button
                size={"sm"}
                onClick={() => {
                  null;
                }}
              >
                Active
              </Button>
            </GridItem>
          ) : (
            <GridItem colSpan={2}>
              {/* Active/Inactive Button */}
              <Button
                backgroundColor={"gray"}
                size={"sm"}
                onClick={() => {
                  setNewActiveOrg(item._id);
                }}
              >
                Set Active
              </Button>
            </GridItem>
          )}
          <GridItem colSpan={1}>
            {/* Settings Button */}
            <HStack justifyContent={{ base: "center", md: "flex-start" }}>
              {/* Settings */}
              <Button size={"sm"} backgroundColor={"grey"} onClick={() => {}}>
                Settings
              </Button>
            </HStack>
          </GridItem>
        </Grid>
      ))}
    </Box>
  );
}
