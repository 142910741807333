import {
  Button,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { BsInstagram, BsThreeDots } from "react-icons/bs";
import { FiTwitter } from "react-icons/fi";
import { BiEditAlt, BiLink } from "react-icons/bi";
import { MdSend } from "react-icons/md";
import ValutAddress from "./../common/ValutAddress";
import { Link } from "react-router-dom";
import { openInNewTab } from "../../util/helper";
import { Fragment, useContext } from "react";
import { ConnectWalletContext, ConnectWalletContextType } from "../../context/connectWalletContext";
import { SITE_URL } from "../../util/Constants";

interface SocialSectionProps {
  instagramURL: string | undefined;
  twitterURL: string | undefined;
  addresses?: string[];
  user?: any;
  username?: string | undefined;
  isMyProfile?: boolean;
}

const CopyLink = ({ addresses }: { addresses: string[] | undefined }) => {
  const address = addresses && addresses.length > 0 ? addresses[0] : "";
  const sharedProfilePageURL = `${SITE_URL}/profile/${address || ""}`;
  const { hasCopied, onCopy } = useClipboard(sharedProfilePageURL);
  return (
    <Tooltip label={hasCopied ? "Copied Successfully" : "Shareable Profile Link"} fontSize="md">
      <Button variant={"unstyled"} rounded={"full"} justifyContent={"center"} size={"lg"} onClick={onCopy}>
        <BiLink size={20} style={{ width: "100%" }} />
      </Button>
    </Tooltip>
  );
};

const DesktopMenu = (props: SocialSectionProps) => {
  const { instagramURL, twitterURL, user, addresses, isMyProfile } = props;
  return (
    <Stack direction={"row"} display={{ base: "none", md: "flex" }} align="center">
      <Tooltip label="Exit" fontSize="md">
        <Link
          to={`/profile/exit`}
        >
          <MdSend size={20} style={{ width: "100%" }} />
        </Link>
      </Tooltip>
      <Tooltip label="Instagram" fontSize="md">
        <Button
          variant={"unstyled"}
          rounded={"full"}
          justifyContent={"center"}
          size={"lg"}
          onClick={() => {
            if (instagramURL) openInNewTab(instagramURL);
          }}
          disabled={!instagramURL}
        >
          <BsInstagram size={20} style={{ width: "100%" }} />
        </Button>
      </Tooltip>
      <Tooltip label="Twitter" fontSize="md">
        <Button
          variant={"unstyled"}
          rounded={"full"}
          justifyContent={"center"}
          size={"lg"}
          onClick={() => {
            if (twitterURL) openInNewTab(twitterURL);
          }}
          disabled={!twitterURL}
        >
          <FiTwitter size={20} style={{ width: "100%" }} />
        </Button>
      </Tooltip>

      <CopyLink addresses={addresses} />

      {user && isMyProfile && (
        <Link to={`/profile/edit`}>
          <Button leftIcon={<BiEditAlt />} variant={"filled"} size="sm">
            Edit Profile
          </Button>
        </Link>
      )}
    </Stack>
  );
};

const MobileMenu = (props: SocialSectionProps) => {
  const { instagramURL, twitterURL, addresses, user, isMyProfile } = props;

  return (
    <Stack direction={"row"} display={{ base: "flex", md: "none" }}>
      <CopyLink addresses={addresses} />
      <Menu>
        <MenuButton as={Button} variant={"unstyled"} rounded={"full"} size={"lg"}>
          <BsThreeDots size={20} style={{ width: "100%" }} />
        </MenuButton>
        <MenuList zIndex={3}>
          {addresses?.length && (
            <>
              <MenuItem>
                <Text textTransform={"uppercase"} fontSize={12} color={"gray.400"}>
                  Addressess
                </Text>
              </MenuItem>
              <Divider />
              {addresses?.map((item) => (
                <Fragment key={`social_menu_mobile_${item}`}>
                  <MenuItem>
                    <ValutAddress walletAddress={item} />
                  </MenuItem>
                  <Divider />
                </Fragment>
              ))}
            </>
          )}
          <MenuItem>
            <Text textTransform={"uppercase"} fontSize={12} color={"gray.400"}>
              Links
            </Text>
          </MenuItem>
          <Divider />
          <MenuItem
            onClick={() => {
              if (twitterURL) openInNewTab(twitterURL);
            }}
            isDisabled={!twitterURL}
            icon={<FiTwitter size={15} style={{ width: "100%" }} />}
          >
            Twitter
          </MenuItem>
          <Divider />
          <MenuItem
            icon={<BsInstagram size={15} style={{ width: "100%" }} />}
            onClick={() => {
              if (instagramURL) openInNewTab(instagramURL);
            }}
            isDisabled={!instagramURL}
          >
            Instagram
          </MenuItem>
          {user && isMyProfile && (
            <>
              <Divider />
              <MenuItem>
                <Text textTransform={"uppercase"} fontSize={12} color={"gray.400"}>
                  Profile
                </Text>
              </MenuItem>
              <Divider />
              <Link to="/profile/edit">
                <MenuItem icon={<BiEditAlt />}>Edit Profile</MenuItem>
              </Link>
              <Link to="/profile/exit">
                  <MenuItem icon={<MdSend /> }>Exit</MenuItem>
              </Link>
            </>
          )}
        </MenuList>
      </Menu>
    </Stack>
  );
};

function SocialSection(props: SocialSectionProps) {
  const { instagramURL, twitterURL, addresses, username } = props;
  const { user, address: walletAddress } = useContext(ConnectWalletContext) as ConnectWalletContextType;

  const isMyProfile = addresses?.includes(walletAddress?.toLowerCase() || "");

  return (
    <>
      <DesktopMenu
        instagramURL={instagramURL}
        twitterURL={twitterURL}
        user={user}
        username={username}
        addresses={addresses}
        isMyProfile={isMyProfile}
      />
      <MobileMenu
        instagramURL={instagramURL}
        twitterURL={twitterURL}
        addresses={addresses}
        user={user}
        username={username}
        isMyProfile={isMyProfile}
      />
    </>
  );
}

export default SocialSection;
