import { useEffect, useContext, useCallback, useState, useRef } from "react";
import { Avatar, Box, Flex, Text, useColorModeValue, HStack, VStack, useToast } from "@chakra-ui/react";
import SocialSection from "./Social";
import AddressMenu from "./Menu/AddressMenu";
import ProfileMenu from "./Menu/ProfileMenu";
import MasterBox from "../Layout/MasterBox";
import { defaultAvatarURL } from "../../util/helper";
import useFetchUserNFT from "../../hooks/FetctUserNFT";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { FETCH_USER_BY_WALLET_ADDRESS, UPDATE_USER_MUTATION } from "../../queries/profile";
import Loader from "../common/Loader";
import { appConfig } from "../../util/Constants";
import { FETCH_ORGANIZATION_CONTRACT_ADDRESS } from "../../queries/organization";
import { useParams } from "react-router-dom";
import { ConnectWalletContext, ConnectWalletContextType } from "../../context/connectWalletContext";
import ConnectYourWallet from "../common/ConnectWallet/ConnectYourWallet";
import NotFound from "../common/NotFound";

export default function SocialProfileWithImage() {
  const { address: contextWalletAddress } = useContext(ConnectWalletContext) as ConnectWalletContextType;
  const { nfts, totalCount, loading, error, fetchUserNFTs } = useFetchUserNFT();
  const { walletAddress: paramsWalletAddress } = useParams();
  const [profilePictureURL, setProfilePictureURL] = useState(() => defaultAvatarURL());
  const [orgSettingsBasedOnContracts, setOrgSettingsBasedOnContracts] = useState<{
    [key: string]: {
      [key: string]: string;
    };
  }>({});
  const toast = useToast();

  const containerBackground = useColorModeValue("white", "gray.800");
  const boxBackground = useColorModeValue("gray.100", "gray.600");

  const walletAddress = paramsWalletAddress || contextWalletAddress || null;

  // Fetching User Data for profile page
  const {
    data,
    loading: userLoading,
    error: userError,
  } = useQuery(FETCH_USER_BY_WALLET_ADDRESS, {
    variables: {
      walletAddress,
    },
  });

  // Fetching Contract Addresses for Organizations
  const [
    getOrganizationContract,
    { loading: organizationLoading, error: organizationError, data: orgContractAddresses },
  ] = useLazyQuery(FETCH_ORGANIZATION_CONTRACT_ADDRESS);

  // For Updating profile picture
  const [updateUser, { data: updatedUserData, error: updatedUserError }] = useMutation(UPDATE_USER_MUTATION);

  useEffect(() => {
    if (data) {
      const organizationsArray = [];
      if (data?.user?.organization?._id) {
        organizationsArray.push(data.user.organization._id);
      }
      if (data?.user?.avatarUri) {
        setProfilePictureURL(data?.user?.avatarUri);
      }
      getOrganizationContract({
        variables: {
          ids: [...new Set(organizationsArray)],
        },
      });
    }
  }, [data]);

  useEffect(() => {
    if (orgContractAddresses && walletAddress) {
      let orgContarctAddressesArray: string[] = [];
      let orgContractMapForLinkedin: any = {};
      orgContractAddresses?.organizations.forEach((element: any) => {
        element?.collections.forEach((subElement: any) => {
          if (!subElement.isDefaultCollection || !subElement.isVisible) {
          } else {
            orgContractMapForLinkedin[`${subElement.contractAddress.toLowerCase()}`] = {
              linkedinOrgId: element.linkedinOrgId,
              isLinkedinCredential: subElement.isLinkedinCredential,
            };
            orgContarctAddressesArray.push(subElement.contractAddress);
          }
        });
      });
      setOrgSettingsBasedOnContracts(orgContractMapForLinkedin);
      fetchUserNFTs(walletAddress || "", orgContarctAddressesArray);
    }
  }, [orgContractAddresses, walletAddress]);

  // Error handler for updating profile picture
  useEffect(() => {
    if (updatedUserError) {
      toast({
        title: "Some things went wrong while updating your profile picture. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [updatedUserError]);

  // After successfully updating showing success message
  useEffect(() => {
    if (updatedUserData) {
      toast({
        title: "Profile Picture Updated Successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [updatedUserData]);

  // For Updating the Profile
  const updateUserProfilePicture = (url: string) => {
    updateUser({
      variables: {
        id: data?.user?._id || "",
        avatarUri: url,
      },
    });
    setProfilePictureURL(url);
  };

  const isLinkedinSharingAvailable = (contractAddress: string) => {
    if (orgSettingsBasedOnContracts && orgSettingsBasedOnContracts[`${contractAddress}`]) {
      return orgSettingsBasedOnContracts[`${contractAddress}`];
    } else {
      return {
        linkedinOrgId: "",
        isLinkedinCredential: false,
      };
    }
  };

  // If walletAddress is missing from url param and ConnectWalletContext
  if (!walletAddress) {
    return <ConnectYourWallet />;
  }

  if (userLoading) {
    return (
      <MasterBox px={[0, 20]} minH={"70vh"}>
        <Loader />
      </MasterBox>
    );
  }

  if (!userLoading && !data.user) {
    return (
      <MasterBox px={[0, 20]} minH={"70vh"}>
        <Text fontSize="md" p={20}>
          No profile found! Please make sure you have correct wallet address. Thanks
        </Text>
      </MasterBox>
    );
  }

  return (
    <>
      <Flex
        w={"full"}
        bg={containerBackground}
        backgroundImage={data?.user?.bannerUri || "/img/profilebg.png"}
        // overflow={"hidden"}
        backgroundSize={"cover"}
      >
        <Box bg={boxBackground} pb={["25%", "15%"]} />
        <Box pb={["25%", "15%"]} />
      </Flex>

      <MasterBox px={[0, 2, 5, 20]}>
        <Flex justify={"flex-start"} direction={["column", "column", "column", "row"]} px={[2, 0]}>
          <Avatar
            width={[100, 120, 120, 140, 180, 200]}
            height={[100, 120, 120, 140, 180, 200]}
            src={profilePictureURL}
            css={{ border: "5px solid white" }}
            mt={[-10, -16, -16, -16, -20, -28]}
            mr={4}
            style={{
              background: "white",
            }}
          />
          <Flex justifyContent={"space-between"} alignItems={"center"} w={"full"}>
            <VStack alignItems={"flex-start"} spacing={1}>
              <Text textStyle={"h2"}>{data?.user?.username}</Text>
              <Text textStyle={"label"} color={"black.200"}>
                {data?.user?.bio}
              </Text>
              <HStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <AddressMenu addresses={[walletAddress || ""]} />
                </Box>
                {/* <Text color={"gray.500"}>Joined February 2022</Text> */}
              </HStack>
            </VStack>
            <SocialSection
              instagramURL={data?.user?.instagramUri}
              twitterURL={data?.user?.twitterUri}
              addresses={[walletAddress.toLowerCase() || ""]}
              username={data?.user?.username || ""}
            />
          </Flex>
        </Flex>
        <Box minH={400}>
          {userLoading || loading || organizationLoading || !orgSettingsBasedOnContracts ? (
            <Loader />
          ) : (
            <>
              {nfts && nfts.length > 0 && totalCount > 0 ? (
                <ProfileMenu
                  nfts={nfts}
                  totalCount={totalCount}
                  updateUserProfilePicture={updateUserProfilePicture}
                  isUserOwnProfile={data?.user?.walletAddress?.toLowerCase() === contextWalletAddress?.toLowerCase()}
                  isLinkedinSharingAvailable={isLinkedinSharingAvailable}
                />
              ) : paramsWalletAddress && contextWalletAddress && contextWalletAddress === paramsWalletAddress ? (
                <NotFound
                  message="Welcome to your minted profile - the destination to view the NFTs in your blockchain wallet. Looks like you don't have any NFTs yet, but stay tuned for airdrops or explore your organizations collections."
                  buttonLabel="Explore the collection"
                  destination="/collection"
                />
              ) : (
                <NotFound
                  message="No NFTs collected. Explore the Collections."
                  buttonLabel="Go to Explore"
                  destination="/collection"
                />
              )}
            </>
          )}
        </Box>
      </MasterBox>
    </>
  );
}
