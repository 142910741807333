import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { ConnectWalletContext, ConnectWalletContextType } from "../../../context/connectWalletContext";

function ProtectedRoute(props: { redirectPath: string; children: any; isCheckAdminOrOperator?: boolean, isCheckOperator?:Boolean }) {
  const { redirectPath, children, isCheckAdminOrOperator, isCheckOperator } = props;
  const { address, user } = useContext(ConnectWalletContext) as ConnectWalletContextType;

  if (!address) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isCheckAdminOrOperator && !["OPERATOR", "ADMIN"].includes(user?.role || "")) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isCheckOperator && !["OPERATOR"].includes(user?.role || "")) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;

}

export default ProtectedRoute;