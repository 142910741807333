import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import useAxios from "axios-hooks";
import { useState } from "react";
import { appConfig } from "../../../util/Constants";
import Loader from "../../common/Loader";
const imageSize = ["250px", "250px", "250px", "350px", "350px"];
interface GenerateArtModalProps {
  isOpen: boolean;
  onClose: any;
}

const GenerateArtModal = ({ isOpen, onClose }: GenerateArtModalProps) => {
  const [quote, setquote] = useState("");

  // For generating art
  const [{ data: generateArtData, loading: generateArtLoading, error: generateArtError }, generateArt] = useAxios(
    {
      baseURL: appConfig.REST_API_URL,
      url: "/createImage",
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const onClickGenerateArt = () => {
    generateArt({
      params: {
        quote,
      },
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} size={"2xl"}>
        <ModalOverlay />
        <ModalContent>
          <Box py={8}></Box>
          <ModalCloseButton />
          <ModalBody mt={-2}>
            <Text textStyle={"h2"} pb={2}>
              {"Generate Art using DALL-E"}
            </Text>
            <Text textStyle={"paragraphMedium"} pb={2}>
              Download DALL-E images and upload as NFT media
            </Text>
            <Input
              placeholder="Write qoute here. It will generate art based on that"
              size="lg"
              onChange={(event) => {
                setquote(event.target.value);
              }}
            />

            <Flex
              py={5}
              mb={5}
              overflow={"auto"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "2px",
                  height: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "2px",
                  height: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "lightgrey",
                  borderRadius: "24px",
                },
              }}
            >
              {generateArtError && (
                <Alert status="error">
                  <AlertIcon />
                  {generateArtError?.response?.data?.message || "Something went wrong please try again"}
                </Alert>
              )}
              {generateArtLoading && <Loader loadingText="generating art" />}
              {!generateArtLoading &&
                generateArtData?.urls?.imageUrls?.map((item: any) => (
                  <Image
                    key={item}
                    src={item}
                    // onClick={() => NFTImageInputRef?.current?.click()}
                    alt="Image Preview"
                    objectFit={"contain"}
                    height={imageSize}
                    width={imageSize}
                    mr={4}
                    cursor={"pointer"}
                  />
                ))}
            </Flex>

            <Flex justify={"center"} pb={5}>
              <Button disabled={quote.length < 5 || generateArtLoading} onClick={onClickGenerateArt}>
                Generate Art
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GenerateArtModal;
