export enum IFILE_UPLOAD_TYPE {
  NFT_IMAGE = "nftImage",
  NFT_METADATA = "nftMetadata",
}

export interface IFormValues {
  contract: string;
  title: string;
  description: string;
  properties: { trait: string; traitValue: string }[];
  tokenId: string;
  media: File | undefined;
}

export interface IMetaDataJSON {
  name: string;
  description: string;
  attributes: { trait_type: string; value: string }[];
  tokenId: string;
  selectedContractName: string;
}

export interface IMintNFTData {
  tokenId: string;
  metaDataURI: string;
}
