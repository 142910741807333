import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Select,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { DASHBOARD_MODAL_DATA, FALLBACK_URL_IMAGE } from "../../../util/Constants";
import { UserActionType } from "../../Dashboard";
import { NFTTableRowType } from "../Table/NFTsTable";
import { SmallCloseIcon } from "@chakra-ui/icons";
import useAdminFunctions from "../../../hooks/Dashboard/AdminFunctions";
import { getCleanErrorMessage } from "../../../util/helper";

interface DashboardUserActionModalType {
  isOpen: any;
  onClose: any;
  onOpenSuccessModal: any;
  actionType: UserActionType | null;
  nfts: NFTTableRowType[];
  orgCollectionsNames: any;
  walletAddress: string;
}

function DashboardUserActionModal(props: DashboardUserActionModalType) {
  const { isOpen, onClose, actionType, onOpenSuccessModal, nfts, orgCollectionsNames, walletAddress } = props;
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedContractAddress, setSelectedContractAddress] = useState(
    () => Object.keys(orgCollectionsNames)[0] || ""
  );
  const [filterOptions, setFilterOptions] = useState<NFTTableRowType[]>(() => nfts);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const rowValueColor = useColorModeValue("black.300", "white");
  const boxShadow = useColorModeValue("xl", "2xl");

  // Fetching Allow list for user
  const { contract, airdropManyToSingle } = useAdminFunctions({
    contractAddress: selectedContractAddress || Object.keys(orgCollectionsNames)[0],
  });

  useEffect(() => {
    setError("");
    setSelectedOptions([]);
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm.length > 0 && selectedContractAddress) {
        const filterData = nfts.filter(
          (item: any) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            item.nftContractAddress.toLowerCase() === selectedContractAddress.toLowerCase()
        );
        setFilterOptions(filterData);
      } else if (nfts.length > 0 && selectedContractAddress) {
        const newFilterData = nfts.filter(
          (item) => item.nftContractAddress.toLowerCase() === selectedContractAddress.toLowerCase()
        );
        setFilterOptions(newFilterData);
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [searchTerm, selectedContractAddress]);

  const onSelectAll = () => {
    const allNFTs = filterOptions.map((item: NFTTableRowType) => item.tokenId);
    setSelectedOptions(allNFTs);
  };

  const onClickSave = async () => {
    try {
      setError("");
      setLoading(true);
      if (actionType === UserActionType.AIRDROP) {
        await airdropManyToSingle(selectedOptions, walletAddress);
      }
      onClose();
      onOpenSuccessModal();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const cleanErrorMessage = getCleanErrorMessage(error?.reason || error?.message);
      setError(cleanErrorMessage);
      console.log(`onClickSave:${actionType}:error`, error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"} scrollBehavior={"inside"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        {actionType && (
          <Flex justify={"center"} direction={"column"} align={"center"} mt={10}>
            <Text textStyle={"h3"}>{DASHBOARD_MODAL_DATA[actionType].modalTitle}</Text>
            <Text textStyle={"paragraphMedium"} pb={5}>
              {DASHBOARD_MODAL_DATA[actionType].modalDesc}
            </Text>
          </Flex>
        )}
        <Box px={8}>
          <HStack>
            {orgCollectionsNames && (
              <Select
                value={selectedContractAddress}
                variant="main"
                onChange={(event) => {
                  setSearchTerm("");
                  setSelectedContractAddress(event.target.value);
                  setSelectedOptions([]);
                }}
              >
                {Object.keys(orgCollectionsNames).map((item: string) => (
                  <option value={item}>{orgCollectionsNames[item]}</option>
                ))}
              </Select>
            )}
            <InputGroup size="md">
              <Input
                value={searchTerm}
                placeholder="Search by NFT name"
                size="md"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              {searchTerm.length > 0 && (
                <InputRightElement width="4.5rem">
                  <IconButton
                    aria-label="clear user search"
                    h="1.75rem"
                    size="sm"
                    icon={<SmallCloseIcon />}
                    variant={"unstyled"}
                    onClick={() => setSearchTerm("")}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </HStack>
          <HStack pt={2} pb={1}>
            <Button variant="link" onClick={onSelectAll}>
              Select All
            </Button>
            {selectedOptions.length > 0 && (
              <Button
                variant="link"
                onClick={() => {
                  setSelectedOptions([]);
                  if (error) {
                    setError("");
                  }
                }}
              >
                Clear
              </Button>
            )}
          </HStack>
          {selectedOptions.length > 0 && (
            <Text pb={2} textStyle={"label"} color={"gray.500"}>
              You have selected {selectedOptions.length} {selectedOptions.length > 1 ? "NFTS" : "NFT"}
            </Text>
          )}
        </Box>
        <ModalBody>
          <FormControl p={4} px={0} pt={0} isInvalid={error ? true : false}>
            <FormErrorMessage pb={2} pt={0} mt={0}>
              {error && error}
            </FormErrorMessage>
            <CheckboxGroup
              onChange={(data) => {
                setSelectedOptions(data);
                if (error) {
                  setError("");
                }
              }}
              value={selectedOptions}
            >
              <VStack align={"flex-start"} justify={"flex-start"}>
                {filterOptions.length > 0 &&
                  filterOptions.map((item, index) => (
                    <Checkbox
                      value={`${item.tokenId}`}
                      key={`${item.tokenId}_${item.nftContractAddress}_user_air_drop_modal_${index}`}
                      _hover={{ boxShadow: boxShadow }}
                      style={{ padding: "10px", width: "100%" }}
                    >
                      <HStack maxH={"200px"}>
                        <Image
                          rounded="xl"
                          width="70px"
                          height="70px"
                          fit="cover"
                          src={item.image}
                          fallbackSrc={FALLBACK_URL_IMAGE}
                          alt={item.name}
                        />
                        <Text textStyle={"paragraph"} color={rowValueColor}>
                          {item.name}
                        </Text>
                      </HStack>
                    </Checkbox>
                  ))}
              </VStack>
            </CheckboxGroup>
          </FormControl>
        </ModalBody>
        {actionType && contract && (
          <ModalFooter justifyContent={"center"}>
            <Button
              colorScheme="blue"
              loadingText={"Loading"}
              mr={3}
              onClick={onClickSave}
              isLoading={loading}
              disabled={loading ? true : false || selectedOptions.length === 0}
            >
              {DASHBOARD_MODAL_DATA[actionType].modalBtnTitle}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

export default DashboardUserActionModal;
