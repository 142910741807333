import { gql } from "@apollo/client";

export const FETCH_INIVITES_BY_ORGANIZATION_ID = gql`
  query ($organizationId: String) {
    invites(orgId: $organizationId) {
      _id
      emailAddress
      status
      inviteDate
    }
  }
`;

export const CREATE_INVITES_MUTATION = gql`
  mutation createInvites($organizationId: String, $emailAddressList: [String]) {
    createInvites(organizationId: $organizationId, emailAddressList: $emailAddressList) {
      emailAddress
    }
  }
`;
