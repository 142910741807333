import { Fragment, useEffect, useState } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import SingleFieldFomModal from "../Modal/SingleFieldFomModal";
import SuccessModal from "../Modal/SuccessModal";
import useConnectWalletStrategy from "./ConnectWalletStrategy";
import NetworkMismatch from "../NetworkMismatch";
import { useChainId, useNetworkMismatch } from "@thirdweb-dev/react";
import LogRocket from "logrocket";
import { DEV_ENV } from "../../../util/Constants";
import { useLazyQuery } from "@apollo/client";
import { FETCH_ORGANIZATION_BY_ACCESS_CODE } from "../../../queries/organization";

export enum LoginTypeEnum {
  METAMASK = "metamask",
  MAGIC_LINK = "magic_link",
}

const WALLETS = [
  {
    title: "Metamask (coming soon)",
    value: LoginTypeEnum.METAMASK,
    image: "/img/wallet/metamask.png",
    isDisable: !DEV_ENV,
  },
  {
    title: "Email",
    value: LoginTypeEnum.MAGIC_LINK,
    image: "/img/wallet/magicLinkLogo.png",
    isDisable: false,
  },
];

interface ConnectWalletModalProps {
  isOpen: boolean;
  setIsLogin: any;
  onClose: any;
  setLoading: any;
  loading: boolean;
  accessCode?: string;
}

function ConnectWalletModal(props: ConnectWalletModalProps) {
  const { isOpen, setIsLogin, onClose, setLoading, loading } = props;
  const bgColor = useColorModeValue("secondary.900", "gray.900");
  const toast = useToast();
  const { connectWallet } = useConnectWalletStrategy();
  const [email, setEmail] = useState("");
  const [accessCode, setAccessCode] = useState<string>("");
  const { isOpen: isOpenLoginForm, onOpen: onOpenLoginForm, onClose: onCloseLoginForm } = useDisclosure();
  const { isOpen: isOpenSuccessLogin, onOpen: onOpenSuccessLogin, onClose: onCloseSuccessLogin } = useDisclosure();
  const {
    isOpen: isNetworkMismatchOpen,
    onOpen: onOpenNetworkMismatchOpen,
    onClose: onCloseNetworkMismatchOpen,
  } = useDisclosure();
  const networkMismatch = useNetworkMismatch();
  const activeChainId = useChainId();

  // Fetching Organization By Access
  const [getOrgByAccessCode, { loading: orgLoading, error: orgError, data: orgData }] = useLazyQuery(
    FETCH_ORGANIZATION_BY_ACCESS_CODE
  );

  // Get Join Code from URL
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamAccessCode = queryParams.get("code") || "";
    setAccessCode(queryParamAccessCode);
  }, []);

  const onClickItem = async (type: string) => {
    try {
      setLoading(true);
      if (type === LoginTypeEnum.MAGIC_LINK) {
        onClose();
        onOpenLoginForm();
        setLoading(false);
      } else {
        const data = await connectWallet(LoginTypeEnum.METAMASK);
        if (data.success) {
          setIsLogin(true);
          onClose();
          setLoading(false);
          // Check for network mismatch
          if (activeChainId && networkMismatch) {
            onOpenNetworkMismatchOpen();
          }
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: "Some things went wrong while connect with wallet. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  //Magic Link login - we will need to add the magic "provider" as a provider variable in wallet context (same for Metamask)
  //To do: authenticate user based on their ability to sign into email-based wallet.
  const magicLogin = async () => {
    try {
      setLoading(true);

      let checkPass = accessCode.length === 0;
      if (accessCode.length > 0) {
        const orgTempData = await getOrgByAccessCode({
          variables: {
            accessCode: accessCode,
          },
        });
        if (orgTempData.data.organization) {
          checkPass = true;
        } else {
          checkPass = false;
          setLoading(false);
          toast({
            title: "Please enter the correct join code. This join code is incorrect",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top",
          });
          return "";
        }
      }

      if (checkPass) {
        await connectWallet(LoginTypeEnum.MAGIC_LINK, email, accessCode);
        onCloseLoginForm();
        setEmail("");
        setAccessCode("");
        onOpenSuccessLogin();
        setIsLogin(true);
        setLoading(false);
        LogRocket.identify(email);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast({
        title: JSON.stringify(error) || "Something went wrong while connect with magic. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent borderRadius={"2xl"} py={5}>
          <Text textStyle={"h2"} px={6} pt={5}>
            Connect Wallet
          </Text>
          <ModalCloseButton />
          <ModalBody>
            {WALLETS.map((item) => (
              <Link
                onClick={() => {
                  if (!item.isDisable) {
                    onClickItem(item.value);
                  }
                }}
                role={"group"}
                display={"block"}
                key={item.value}
                p={4}
                rounded={"md"}
                _hover={item.isDisable ? {} : { bg: bgColor }}
                opacity={item.isDisable ? 0.3 : 1}
              >
                <Stack direction={"row"} align={"center"}>
                  <Box>
                    <Image boxSize={[14]} objectFit="contain" src={item.image} alt={item.value} />
                  </Box>
                  <Box>
                    <Text
                      transition={"all .3s ease"}
                      _groupHover={{ color: "primary.800" }}
                      textStyle={"h3"}
                      fontWeight={700}
                      pl={2}
                    >
                      {item.title}
                    </Text>
                  </Box>
                  <Flex
                    transition={"all .3s ease"}
                    transform={"translateX(-10px)"}
                    opacity={0}
                    _groupHover={{
                      opacity: "100%",
                      transform: "translateX(0)",
                    }}
                    justify={"flex-end"}
                    align={"center"}
                    flex={1}
                  >
                    <Icon color={"primary.800"} w={5} h={5} as={ChevronRightIcon} />
                  </Flex>
                </Stack>
              </Link>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>

      <SingleFieldFomModal
        isOpen={isOpenLoginForm}
        onClose={onCloseLoginForm}
        title={"Login with email"}
        description={"Sign-in with your organization credentials"}
        label={"Email address"}
        ctaAction={magicLogin}
        ctaTitle={"Continue with email"}
        placeholder={"Enter your email address"}
        value={email}
        setValue={setEmail}
        isSecond
        secondValue={accessCode}
        setSecondValue={setAccessCode}
        secondLabel={"Have a join code?"}
        secondPlaceHolder={"Enter your optional join code"}
        loading={loading}
      />

      <SuccessModal
        isOpen={isOpenSuccessLogin}
        onClose={onCloseSuccessLogin}
        ctaAction={() => {
          onCloseSuccessLogin();
        }}
        title={"Verified!"}
        description={"You have successfully verified your account"}
        subDescription={""}
        ctaTitle={"Continue"}
      />

      <NetworkMismatch isOpen={isNetworkMismatchOpen} onClose={onCloseNetworkMismatchOpen} />
    </Fragment>
  );
}

export default ConnectWalletModal;
