import { useContext, useState } from "react";
import { useQuery } from "@apollo/client";
import { BsSend } from "react-icons/bs";
import {
  SimpleGrid,
  Text,
  Box,
  Input,
  Stack,
  HStack,
  Container,
  Spinner,
  Icon,
  Avatar,
  useDisclosure,
  SlideFade,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image
} from "@chakra-ui/react";
import MasterBox from "../Layout/MasterBox";
import CollectionCard from "./CollectionCard";
import Loader from "../common/Loader";
import { FETCH_ORGANIZATION_COLLECTION_BY_ID } from "./../../queries/collection";
import { ConnectWalletContext, ConnectWalletContextType } from "../../context/connectWalletContext";
import { appConfig, DEFAULT_INTRO_MESSAGES, DEFAULT_AI_MESSAGES } from "../../util/Constants";
import NotFound from "../common/NotFound";
import useFetchAIResponse from "../../hooks/FetchAIResponse";

function Collection() {
  const { user } = useContext(ConnectWalletContext) as ConnectWalletContextType;
  const { data, loading, error } = useQuery(FETCH_ORGANIZATION_COLLECTION_BY_ID, {
    variables: {
      id: user?.organization?._id || appConfig.DEFAULT_ORGANIZATION_ID,
    },
  });

  function CollectionHeader() {
    return (
      <Box mt={[5, 10]} mb={[2, 5]}>
        <Text textStyle={"h1"} fontWeight={"normal"}>
          {data?.organization?.title || DEFAULT_INTRO_MESSAGES.exploreTitle}
        </Text>
        <Text textStyle={"paragraph"} color={"black.200"}>
          {data?.organization?.description || DEFAULT_INTRO_MESSAGES.exploreSubtitle}
        </Text>
      </Box>
    );
  }

  function CollectionAI() {
    const [aiQuery, setAiQuery] = useState("");
    const { error, loading, fetchedAiResponse, fetchAIResponse } = useFetchAIResponse();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const getAIResponse = async () => {
      onClose();
      await fetchAIResponse(aiQuery);
      onOpen();
    };

    return (
      <Stack spacing={6} p={4} mb={8} backgroundColor={"#f4f6fb"} borderRadius={"6px"}>
        <HStack spacing={6}>
          {loading ? (
            <HStack p={2}>
              <Spinner color="grey" mr={2} />
              <Text fontSize={"14px"} color="grey" fontWeight={"normal"}>
                {"Loading"}
              </Text>
            </HStack>
          ) : (
            <>
              {(fetchedAiResponse || error) && (
                <Container maxW={"1300px"} padding={0}>
                 <SlideFade in={isOpen} offsetY={"80px"}>
                  <Container
                    background={"white"}
                    p={4}
                    borderRadius={"8px"}
                    boxShadow={"rgba(0, 0, 0, 0.16) 0px 1px 4px"}
                    maxW={"1300px"}
                  >
                    {error ? (
                      <Text fontSize={"14px"} fontStyle={"italic"} color={"#a9aab9"}>
                        {DEFAULT_AI_MESSAGES.aiErrorResponse}
                      </Text>
                    ) : (
                      <Text fontSize={"14px"} fontStyle={"italic"} color={"#a9aab9"} maxW={"1300px"}>
                        {fetchedAiResponse}
                      </Text>
                    )}
                  </Container>
                 </SlideFade>
                </Container>
              )}
            </>
          )}
          {(fetchedAiResponse || error) && (
            <SlideFade in={isOpen} offsetY={"100px"}>
              <Menu>
                <MenuButton className={"prompt-menu-button"}>
                  <Icon className={"prompt-menu-icon"} width={"24px"} height={"24px"} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color={"#79809e"}>
                  <path 
                  fill="currentColor" 
                  d="M15.5,12 L17,12 L17,3.5 C17,2.67157288 16.3284271,2 15.5,2 L6.5,2 C5.67157288,2 5,2.67157288 5,3.5 L5,12 L6.5,12 L6.5,11.6323808 C6.5,10.7542236 6.96074708,9.94045656 7.71376061,9.48864844 L8.68372662,8.90666884 C8.2565549,8.38832622 8,7.72412391 8,7 C8,5.34314575 9.34314575,4 11,4 C12.6568542,4 14,5.34314575 14,7 C14,7.72412391 13.7434451,8.38832622 13.3162734,8.90666884 L14.2862394,9.48864844 C15.0392529,9.94045656 15.5,10.7542236 15.5,11.6323808 L15.5,12 L15.5,12 Z M14.5,12 L14.5,11.6323808 C14.5,11.1054865 14.2235517,10.6172262 13.7717436,10.3461414 L12.5131641,9.59099367 C12.0689421,9.85098383 11.5518734,10 11,10 C10.4481266,10 9.93105788,9.85098383 9.48683587,9.59099367 L8.22825637,10.3461414 C7.77644825,10.6172262 7.5,11.1054865 7.5,11.6323808 L7.5,12 L14.5,12 L14.5,12 Z M17,13 L5,13 L5,18.5 C5,19.3284271 5.67157288,20 6.5,20 L15.5,20 C16.3284271,20 17,19.3284271 17,18.5 L17,13 L17,13 Z M17.958119,3.04188098 C19.1200402,3.25706275 20,4.27576856 20,5.5 L20,20.5 C20,21.8807119 18.8807119,23 17.5,23 L8.5,23 C7.27576856,23 6.25706275,22.1200402 6.04188098,20.958119 C4.8799598,20.7429373 4,19.7242314 4,18.5 L4,3.5 C4,2.11928813 5.11928813,1 6.5,1 L15.5,1 C16.7242314,1 17.7429373,1.8799598 17.958119,3.04188098 L17.958119,3.04188098 Z M7.08535285,21 C7.29127106,21.5825962 7.84689059,22 8.5,22 L17.5,22 C18.3284271,22 19,21.3284271 19,20.5 L19,5.5 C19,4.84689059 18.5825962,4.29127106 18,4.08535285 L18,18.5 C18,19.8807119 16.8807119,21 15.5,21 L7.08535285,21 L7.08535285,21 Z M11,9 C12.1045695,9 13,8.1045695 13,7 C13,5.8954305 12.1045695,5 11,5 C9.8954305,5 9,5.8954305 9,7 C9,8.1045695 9.8954305,9 11,9 Z M7.5,16 C7.22385763,16 7,15.7761424 7,15.5 C7,15.2238576 7.22385763,15 7.5,15 L14.5,15 C14.7761424,15 15,15.2238576 15,15.5 C15,15.7761424 14.7761424,16 14.5,16 L7.5,16 Z M7.5,18 C7.22385763,18 7,17.7761424 7,17.5 C7,17.2238576 7.22385763,17 7.5,17 L12.5,17 C12.7761424,17 13,17.2238576 13,17.5 C13,17.7761424 12.7761424,18 12.5,18 L7.5,18 Z"/>
                  </Icon>
                </MenuButton>
                <MenuList>
                  {/* Update hardcoded values as backend improves, dummy data for now */}
                  <MenuItem>Sassy</MenuItem>
                  <MenuItem>Serious</MenuItem>
                </MenuList>
              </Menu>
            </SlideFade>
          )}
        </HStack>
        <HStack spacing={6}>
          <Avatar
            name="Blockchain GPT Bot"
            src="/img/owl_logo.png"
            background={"white"}
            boxShadow={"rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px"}
          />
          <Input
            minHeight={"50px"}
            borderColor={"#e4e5ea"}
            borderRadius={"8px"}
            focusBorderColor={"rgba(68,52,190)"}
            backgroundColor={"white"}
            fontStyle={"italic"}
            placeholder={DEFAULT_AI_MESSAGES.aiExampleMessage1}
            onChange={(event) => setAiQuery(event.target.value)}
          />
          <Icon
            as={BsSend}
            fontSize={"20px"}
            color={"#79809e"}
            onClick={() => {
              getAIResponse();
            }}
            _hover={{ color: "rgba(62,72,218)" }}
          />
        </HStack>
      </Stack>
    );
  }

  return (
    <>
      <MasterBox>
        {loading ? (
          <Box height={"70vh"}>
            <Loader />
          </Box>
        ) : (
          <>
            {!error ? (
              <>
                {data && data.organization?.collections && data.organization?.collections.length > 0 ? (
                  <>
                    {/* <CollectionHeader /> */}
                    <MasterBox py={10}>
                      <CollectionAI />
                      <SimpleGrid columns={[1, 1, 2, 2, 3]} spacing={8}>
                        {data.organization.collections.map((collection: { contractAddress: string }) => (
                          <CollectionCard
                            key={"collection_card" + collection.contractAddress}
                            collection={collection}
                          />
                        ))}
                      </SimpleGrid>
                    </MasterBox>
                  </>
                ) : (
                  <>
                    <CollectionHeader />
                    <NotFound message="No NFT collections available. Contact your org admin or the minted support team." />
                  </>
                )}
              </>
            ) : (
              <MasterBox height={"70vh"}>
                <Text py={10}>{error.message}</Text>
              </MasterBox>
            )}
          </>
        )}
      </MasterBox>
    </>
  );
}

export default Collection;
