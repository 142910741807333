import { Avatar, HStack, Icon, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdVerified } from "react-icons/md";
import { FALLBACK_URL_IMAGE } from "../../util/Constants";

function LabelValue(props: {
  label: string;
  value: string;
  imageURL: string;
  isVerified: boolean;
  backgroundColor?: string;
}) {
  const { label, value, imageURL, isVerified, backgroundColor } = props;
  const [image, setImage] = useState(imageURL ? imageURL : `/img/avatars/${Math.floor(Math.random() * 5) + 1}.svg`);

  useEffect(() => {
    if (imageURL) {
      setImage(imageURL);
    }
  }, [imageURL]);

  return (
    <HStack maxW={250}>
      <Avatar name="Avtar Image" src={image} backgroundColor={backgroundColor || "transparent"} color={"transparent"} />
      <VStack spacing={"-1"} alignItems={"start"}>
        <Text textStyle={"labelMedium"} color="black.200" fontWeight={"700"}>
          {label}
        </Text>
        <HStack spacing={1} alignItems={"center"}>
          <Text textStyle={"paragraphMedium"} fontWeight={"700"} maxWidth={"full"} noOfLines={1}>
            {value}
          </Text>
          {isVerified && <Icon as={MdVerified} color="blue.500" />}
        </HStack>
      </VStack>
    </HStack>
  );
}

export default LabelValue;
