import { CopyIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useColorModeValue,
  Spacer,
  useClipboard,
  Box,
  InputGroup,
  Input,
  InputRightElement,
  Tooltip,
  Icon,
  Flex,
  Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import QRCode from "qrcode";

export default function QRCodeModal(props: { isOpen: boolean; onClose: any; accessCode?: string }) {
  const { isOpen, onClose, accessCode } = props;
  const url = window.location.href + `?code=${accessCode || ""}`;
  const { hasCopied, onCopy } = useClipboard(url);

  const [qr, setQr] = useState("");
  useEffect(() => {
    QRCode.toDataURL(
      url,
      {
        width: 300,
        margin: 2,
        color: {},
      },
      (err: any, url: any) => {
        if (err) return console.error(err);
        setQr(url);
      }
    );
  }, [url]);

  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} isCentered size={"lg"} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent borderRadius={"2xl"} py={5}>
        <ModalCloseButton />
        <ModalBody>
          <Text textStyle={"h3"} color={useColorModeValue("black.300", "white")}>
            Share NFT with QR code!
          </Text>
          <Spacer h={4} />
          <Box>
            <InputGroup>
              <Input type="text" value={url} variant={"filled"} disabled />
              <InputRightElement onClick={() => onCopy()} cursor={"pointer"}>
                <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                  <Icon as={CopyIcon} />
                </Tooltip>
              </InputRightElement>
            </InputGroup>
          </Box>
          <Spacer h={2} />
          {qr && (
            <>
              <Flex justify={"center"} mt={5}>
                <img src={qr} />
              </Flex>
              <Flex justify={"center"} mt={5}>
                <Button onClick={() => saveAs(qr, "QRCode.png")}>Download</Button>
              </Flex>
            </>
          )}
        </ModalBody>
        <ModalFooter flexDirection={"column"}></ModalFooter>
      </ModalContent>
    </Modal>
  );
}
