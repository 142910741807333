import { useLazyQuery, useMutation } from "@apollo/client";
import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Tooltip,
  useClipboard,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState, useMemo } from "react";
import { FaUserCog } from "react-icons/fa";
import useFetchUserNFT from "../../../hooks/FetctUserNFT";
import { ICollectionNFT } from "../../../Interfaces/collection";
import { UserStatusType } from "../../../Interfaces/users";
import { FETCH_ORGANIZATION_CONTRACT_ADDRESS } from "../../../queries/organization";
import { UPDATE_USER_MUTATION } from "../../../queries/profile";
import { DASHBOARD_MODAL_DATA, FALLBACK_URL_IMAGE } from "../../../util/Constants";
import { getCleanErrorMessage, getUniqueListBy } from "../../../util/helper";
import { UserActionType } from "../../Dashboard";
import Label from "../Form/Label";
import Loader from "../Loader";

interface UserOwnedNFTsModalProps {
  isOpen: any;
  onClose: any;
  onOpenSuccessModal: any;
  actionType: UserActionType | null;
  walletAddress: string;
  organizationId: string;
  userId: string;
  refetchOrgUsers: any;
  user: any;
}

const labelWidth = "25%";
const fieldWidth = "75%";

function UserOwnedNFTsModal(props: UserOwnedNFTsModalProps) {
  const {
    isOpen,
    onClose,
    actionType,
    onOpenSuccessModal,
    walletAddress,
    organizationId,
    userId,
    refetchOrgUsers,
    user,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [apiError, setAPIError] = useState("");
  const [apiLoading, setAPILoading] = useState(false);
  const rowValueColor = useColorModeValue("black.300", "white");
  const boxShadow = useColorModeValue("xl", "2xl");
  const { hasCopied, onCopy } = useClipboard(walletAddress || "");

  // Fetching Contract Addresses for Organizations
  const [getOrganizationContract, { loading: organizationLoading, data: orgContractAddresses }] = useLazyQuery(
    FETCH_ORGANIZATION_CONTRACT_ADDRESS
  );

  // For Updating profile picture
  const [updateUser, { data: updatedUserData, error: updatedUserError }] = useMutation(UPDATE_USER_MUTATION);

  const { nfts, loading, fetchUserNFTs } = useFetchUserNFT();

  useEffect(() => {
    setAPIError("");
    setSelectedOptions([]);
  }, []);

  useEffect(() => {
    if (organizationId) {
      const organizationsArray = [organizationId];
      getOrganizationContract({
        variables: {
          ids: [...new Set(organizationsArray)],
        },
      });
    }
  }, [organizationId]);

  useEffect(() => {
    if (orgContractAddresses && walletAddress) {
      let orgContarctAddressesArray: string[] = [];
      orgContractAddresses?.organizations.forEach((element: any) => {
        element?.collections.forEach((subElement: any) => {
          if (!subElement.isDefaultCollection || !subElement.isVisible) {
          } else {
            orgContarctAddressesArray.push(subElement.contractAddress);
          }
        });
      });
      fetchUserNFTs(walletAddress || "", orgContarctAddressesArray);
    }
  }, [orgContractAddresses, walletAddress]);

  const onClickSave = async (updateStatus: boolean) => {
    try {
      setAPIError("");
      setAPILoading(true);
      if (updateStatus) {
        await updateUser({
          variables: {
            id: userId || "",
            status: user.status === UserStatusType.ACTIVE ? UserStatusType.INACTIVE : UserStatusType.ACTIVE,
          },
        });
      } else {
        await updateUser({
          variables: {
            id: userId || "",
            avatarUri: selectedOptions[0],
          },
        });
      }
      if (refetchOrgUsers) {
        refetchOrgUsers();
      }
      onClose();
      onOpenSuccessModal();
      setSelectedOptions([]);
      setAPILoading(false);
    } catch (error: any) {
      setAPILoading(false);
      const cleanErrorMessage = getCleanErrorMessage(error?.reason || error?.message);
      setAPIError(cleanErrorMessage);
      console.log(`onClickSave:${actionType}:error`, error);
    }
  };

  const filterNFTs = useMemo(() => {
    return nfts && nfts.length > 0 ? getUniqueListBy(nfts, "contract.address", "id.tokenId") : [];
  }, [nfts]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"4xl"} scrollBehavior={"inside"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        {actionType && (
          <Flex mt={10} justifyContent="center" gap={5}>
            <Flex justify="flex-end">
              {actionType === UserActionType.SET_PROFILE_PICTURE && <Icon as={FaUserCog} w={12} h={12} />}
            </Flex>
            <Flex direction={"column"} align={"center"} w={{ fieldWidth }}>
              <Text textStyle={"h3"}>{DASHBOARD_MODAL_DATA[actionType].modalTitle}</Text>
              <Text textStyle={"paragraphMedium"} pb={5} textAlign="center" maxW={"350px"}>
                {DASHBOARD_MODAL_DATA[actionType].modalDesc}
              </Text>
            </Flex>
          </Flex>
        )}
        <ModalBody pb={5}>
          <Flex gap={5} align={"flex-start"} justifyContent={"flex-start"} direction={["column", "row"]}>
            <VStack w={["100%", "100%", "65%"]}>
              <Flex w={"full"} gap={5} pb={5}>
                <Box w={labelWidth} textAlign={"right"}>
                  <Label>Username</Label>
                </Box>
                <InputGroup w={fieldWidth}>
                  <Input type="text" placeholder={user?.username || ""} variant={"filled"} disabled />
                  <InputRightElement onClick={onCopy} cursor={"pointer"}>
                    <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                      <Icon as={CopyIcon} />
                    </Tooltip>
                  </InputRightElement>
                </InputGroup>
              </Flex>

              <Flex w={"full"} gap={5} pb={5}>
                <Box w={labelWidth} textAlign={"right"}>
                  <Label>Email Address</Label>
                </Box>
                <InputGroup w={fieldWidth}>
                  <Input type="text" placeholder={user?.email || ""} variant={"filled"} disabled />
                  <InputRightElement onClick={onCopy} cursor={"pointer"}>
                    <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                      <Icon as={CopyIcon} />
                    </Tooltip>
                  </InputRightElement>
                </InputGroup>
              </Flex>

              <Flex w={"full"} gap={5} pb={5}>
                <Box w={labelWidth} textAlign={"right"}>
                  <Label>Wallet Address</Label>
                </Box>
                <InputGroup w={fieldWidth}>
                  <Input type="text" placeholder={walletAddress || ""} variant={"filled"} disabled />
                  <InputRightElement onClick={onCopy} cursor={"pointer"}>
                    <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                      <Icon as={CopyIcon} />
                    </Tooltip>
                  </InputRightElement>
                </InputGroup>
              </Flex>

              {/* <Flex w={"full"} gap={5}>
                <Box w={labelWidth} textAlign={"right"}>
                  <Label>Joined</Label>
                </Box>
                <Box w={fieldWidth}>
                  <Text textStyle={"paragraph"} color={"gray.400"}>
                    June 5, 2022
                  </Text>
                </Box>
              </Flex> */}

              <Flex w={"full"} gap={5} pb={5}>
                <Box w={labelWidth} textAlign={"right"}>
                  <Label>Role</Label>
                </Box>
                <Box w={fieldWidth}>
                  <Text textStyle={"paragraph"} color={"gray.400"} textTransform={"capitalize"}>
                    {user?.role?.toLowerCase() || "-"}
                  </Text>
                </Box>
              </Flex>

              <Flex w={"full"} gap={5} pb={5}>
                <Box w={labelWidth} textAlign={"right"}>
                  <Label>Status</Label>
                </Box>
                <HStack w={fieldWidth}>
                  <Text textStyle={"paragraph"} color={"gray.400"}>
                    {user?.status || "-"}
                  </Text>
                  <Button
                    size={"sm"}
                    onClick={() => onClickSave(true)}
                    isLoading={apiLoading}
                    disabled={apiLoading ? true : false}
                  >
                    {user?.status && user.status === UserStatusType.ACTIVE ? "Deactivate" : "Activate"}
                  </Button>
                </HStack>
              </Flex>
            </VStack>

            <Box>
              {loading || organizationLoading ? (
                <Loader loadingText="Loading User Owned NFTs..." />
              ) : (
                <>
                  {filterNFTs && filterNFTs.length === 0 ? (
                    <Box>
                      <Text textAlign={"center"} textStyle={"paragraph"} pb={10}>
                        This user not owned any NFTs
                      </Text>
                    </Box>
                  ) : (
                    <>
                      <FormControl
                        p={4}
                        px={0}
                        pt={0}
                        isInvalid={apiError ? true : false}
                        maxH={"450px"}
                        overflowY={"auto"}
                      >
                        <FormErrorMessage pb={2} pt={0} mt={0}>
                          {apiError && apiError}
                        </FormErrorMessage>
                        <CheckboxGroup
                          onChange={(data) => {
                            if (data.length === 1) {
                              setSelectedOptions([data[0]]);
                            } else if (data.length === 2) {
                              setSelectedOptions([data[1]]);
                            }
                          }}
                          value={selectedOptions}
                        >
                          <VStack align={"flex-start"} justify={"flex-start"}>
                            {filterNFTs &&
                              filterNFTs.length > 0 &&
                              filterNFTs.map((item: ICollectionNFT, index) => {
                                const {
                                  id: { tokenId },
                                  contract: { address },
                                  metadata: { image, name },
                                } = item;

                                if (!image || !name) return <></>;

                                return (
                                  <Checkbox
                                    value={image}
                                    key={tokenId + address + index}
                                    _hover={{ boxShadow: boxShadow }}
                                    style={{ padding: "10px", width: "100%" }}
                                  >
                                    <HStack maxH={"200px"}>
                                      <Image
                                        rounded="xl"
                                        width="70px"
                                        height="70px"
                                        fit="cover"
                                        src={image}
                                        fallbackSrc={FALLBACK_URL_IMAGE}
                                        alt={image}
                                      />
                                      <Text textStyle={"paragraph"} color={rowValueColor}>
                                        {name}
                                      </Text>
                                    </HStack>
                                  </Checkbox>
                                );
                              })}
                          </VStack>
                        </CheckboxGroup>
                      </FormControl>

                      {actionType && filterNFTs && filterNFTs.length > 0 && (
                        <Button
                          colorScheme="blue"
                          loadingText={"Loading"}
                          mr={3}
                          onClick={() => onClickSave(false)}
                          isLoading={apiLoading}
                          disabled={apiLoading ? true : false || selectedOptions.length === 0}
                        >
                          {DASHBOARD_MODAL_DATA[actionType].modalBtnTitle}
                        </Button>
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default UserOwnedNFTsModal;
