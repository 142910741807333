import { useEffect, useRef, useState } from "react";
import { Box, SimpleGrid, Stack, HStack, Flex, Container, Button, useDisclosure, Text } from "@chakra-ui/react";
import MasterBox from "./../Layout/MasterBox";
import ViewNFTSection from "./ViewNFTSection";
import AttributeCard from "./AttributeCard";
import OwnerCard from "./OwnerCard";
import { useParams } from "react-router-dom";
import { FETCH_COLLECTION_BY_CONTRACT_ADDRESS } from "../../queries/collection";
import { useLazyQuery, useQuery } from "@apollo/client";
import PaperModal from "./PaperModal";
import useFetchNFTOwners from "../../hooks/FetchNFTOwners";
import useFetchNFTMetaData from "../../hooks/FetchNTFMetaData";
import Loader from "../common/Loader";
import { FETCH_USERS_BY_WALLET_ADDRESS } from "../../queries/profile";

function Detail() {
  const cardRef: any = useRef(null);
  const [activeTab, setActiveTab] = useState("attribute");
  const [ownerMetaDataObj, setOwnerMetaDataObj] = useState<{ [key: string]: any }>({});
  const { isOpen: isOpenPaperModal, onOpen: onOpenPaperModal, onClose: onClosePaperModal } = useDisclosure();

  let { id, contractAddress } = useParams();
  const { data, loading, error } = useQuery(FETCH_COLLECTION_BY_CONTRACT_ADDRESS, {
    variables: {
      contractAddress,
    },
  });

  // Fetching Contract Addresses for Organizations
  const [getUsers, { loading: usersLoading, error: usersError, data: usersData }] =
    useLazyQuery(FETCH_USERS_BY_WALLET_ADDRESS);

  const { owners, loading: ownerLoading, fetchOwners } = useFetchNFTOwners();
  const { metaData: nftMetadata, loading: metaDataLoading, fetchMetaData } = useFetchNFTMetaData();

  useEffect(() => {
    if (!(cardRef.current as boolean)) {
      fetchOwners(contractAddress || "", id || "");
      fetchMetaData(contractAddress || "", id || "");
      cardRef.current = "metadataLoaded";
    }
  }, [id]);

  useEffect(() => {
    if (owners?.length) {
      getUsers({
        variables: {
          walletAddressList: owners,
        },
      });
    }
  }, [owners]);

  useEffect(() => {
    if (usersData && usersData?.users) {
      let ownerMetaDataObj: { [key: string]: any } = {};
      usersData?.users.forEach((element: any) => {
        if (element.walletAddress) {
          ownerMetaDataObj[`${element.walletAddress.toLowerCase()}`] = element;
        }
      });
      setOwnerMetaDataObj(ownerMetaDataObj);
    }
  }, [usersData]);

  return (
    <>
      <MasterBox>
        {/* NFT View Section Start */}
        {metaDataLoading || loading ? (
          <Box minH={"70vh"}>
            <Loader />
          </Box>
        ) : (
          <>
            <Box py={10}>
              {contractAddress && nftMetadata && (
                <ViewNFTSection
                  meta={nftMetadata}
                  contractAddress={contractAddress || ""}
                  id={id || ""}
                  collectionDataLoading={loading}
                  claimType={data?.collection?.claimType || ""}
                  contractType={data?.collection?.contractType || ""}
                  ownerName={data?.collection?.owner?.name || ""}
                  orgLogoURI={data?.collection?.owner?.logoUri || ""}
                  title={data?.collection?.title || ""}
                  collectionImageUrl={data?.collection?.profileImageUrl || ""}
                  openPaperModal={onOpenPaperModal}
                  accessCode={data?.collection?.owner?.accessCode || ""}
                />
              )}
            </Box>
            {/* Tabs View Section Start */}
            <Stack justifyContent={"center"} alignItems={"center"}>
              <Box>
                <HStack justifyContent={"center"}>
                  <Button
                    variant={activeTab === "attribute" ? "filled" : "ghost"}
                    onClick={() => {
                      setActiveTab("attribute");
                    }}
                  >
                    Attributes
                  </Button>
                  <Button
                    variant={activeTab === "owner" ? "filled" : "ghost"}
                    onClick={() => {
                      setActiveTab("owner");
                    }}
                  >
                    Owners
                  </Button>
                </HStack>
              </Box>
              {activeTab === "attribute" && (
                <Container>
                  <SimpleGrid columns={{ base: 2, md: 3 }} spacing={5} py={5}>
                    {nftMetadata?.attributes?.map((item: any) => (
                      <AttributeCard key={"attribute_card" + item.trait_type} item={item} />
                    ))}
                  </SimpleGrid>
                  {!nftMetadata?.attributes?.length && <Text fontSize="md">No attributes!</Text>}
                </Container>
              )}
              {activeTab === "owner" && (
                <Container>
                  <SimpleGrid columns={{ base: 2, md: 3 }} spacing={5} py={5}>
                    {ownerLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {owners && owners.length > 0 && ownerMetaDataObj && (
                          <>
                            {owners.map((owner: any) => (
                              <OwnerCard
                                key={"owner_card" + owner}
                                owner={owner}
                                ownerMetaData={ownerMetaDataObj[`${owner.toLowerCase()}`] || {}}
                              />
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </SimpleGrid>

                  {!loading && owners && owners?.length === 0 && (
                    <Flex width={"full"} justifyContent={"center"}>
                      <Text fontSize="md">No owners!</Text>
                    </Flex>
                  )}
                </Container>
              )}
              <Flex justifyContent={"center"} pb={18}>
                {/* <Button variant={"ghost"} color={"black.200"}>
            See 15 more
          </Button> */}
              </Flex>
            </Stack>
          </>
        )}
      </MasterBox>
      <PaperModal onClose={onClosePaperModal} isOpen={isOpenPaperModal} />
    </>
  );
}

export default Detail;
