import { useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { ICollectionNFTsResponse } from "../../Interfaces/collection";
import { appConfig } from "../../util/Constants";

const baseURL = `${appConfig.ALCHEMY_POLYGON_URL}/getNFTsForCollection?withMetadata=true&limit=10`;

interface useCollectionNFTsProps {
  contractAddress: string;
}

const fetchCollectionNFTs = async (args: any) => {
  const { pageParam = 0 } = args;
  let newURL = `${baseURL}&contractAddress=${args.queryKey[1].contractAddress}`;
  if (pageParam) {
    newURL = `${newURL}&startToken=${pageParam}`;
  }
  const res = await fetch(newURL);
  return res.json();
};

export const useCollectionNFTs = (props: useCollectionNFTsProps) => {
  const { contractAddress } = props;
  const { data, error, fetchNextPage, status, hasNextPage } = useInfiniteQuery({
    queryKey: ["collectionNFTs", { contractAddress }],
    queryFn: fetchCollectionNFTs,
    getNextPageParam: (lastPage: ICollectionNFTsResponse) => {
      if (lastPage.nextToken) return lastPage.nextToken;
      return false;
    },
    staleTime: 300000, // Will refresh data after 5min if user focus on screen
  });

  const collectionNFTs = useMemo(
    () =>
      data?.pages.reduce((prev, page) => {
        return {
          nextToken: "asd",
          nfts: [...prev.nfts, ...page.nfts],
        };
      }),
    [data]
  );

  return {
    error,
    fetchNextPage,
    status,
    hasNextPage,
    collectionNFTs,
    data,
  };
};
