import { gql } from "@apollo/client";

export const INITIATE_USER_AUTH_FLOW = gql`
  mutation initiateUserAuthFlow($walletAddress: String!) {
    initiateUserAuthFlow(walletAddress: $walletAddress) {
      walletAddress
      nonce
    }
  }
`;

export const LOGIN_USER = gql`
  mutation loginUser($walletAddress: String!, $signature: String!) {
    loginUser(walletAddress: $walletAddress, signature: $signature) {
      authentication
      jwt
      user {
        _id
        role
        organization {
          _id
        }
      }
    }
  }
`;

export const LOGIN_USER_MAGIC = gql`
  mutation loginUserMagic(
      $didToken: String!
      $accessCode: String!) {
    loginUserMagic(
      didToken: $didToken
      accessCode: $accessCode) {
      authentication
      jwt
      user {
        _id
        role
        organization {
          _id
        }
      }
    }
  }
`;
