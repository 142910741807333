import { useEffect, useState } from "react";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { BiMailSend } from "react-icons/bi";
import { FiCheck } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { DEV_ENV, ErrorMessage, FALLBACK_URL_IMAGE } from "../../../util/Constants";
import { getstandardizedOwnerAddress } from "../../../util/helper";
import { UserActionType } from "../../Dashboard";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_USER_MUTATION } from "../../../queries/profile";
import CommonModal from "../Modal";
import SuccessModal from "../Modal/SuccessModal";
import AddUsersModal from "../Modal/AddUsersModal";
import { CREATE_INVITES_MUTATION, FETCH_INIVITES_BY_ORGANIZATION_ID } from "../../../queries/invite";
import * as API from "../../../util/api";
import NotFound from "../NotFound";
import { CsvDownloadButton } from "../../Dashboard/CsvDownloadButton";
import { FaUserCog } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdSend } from "react-icons/md";

export interface UserTableRowType {
  _id: string;
  avatarUri: string;
  username: string;
  walletAddress: string;
  email: string;
  role: string;
  userStatus: USER_STATUS;
  status: USER_STATUS;
}

interface UsersTableProps {
  onOpen: any;
  onOpenMessageModal: any;
  setActionType: any;
  data: UserTableRowType[];
  accessCode: string;
  organizationId: string;
}

const Columns = [
  {
    label: "User",
    colSpan: 3,
  },
  {
    label: "Wallet",
    colSpan: 2,
  },
  {
    label: "Role",
    colSpan: 1,
  },
  {
    label: "Status",
    colSpan: 1,
  },
  {
    label: "",
    colSpan: 2,
  },
];

export enum USER_ROLE_TYPE {
  ADMIN = "ADMIN",
  USER = "USER",
  OPERATOR = "OPERATOR",
}

enum USER_STATUS {
  ACTIVE = "Active",
  INVITED = "Invited",
  DEACTIVATED = "Inactive",
}

interface UserRoleColumnProps {
  role: string;
  userId: string;
}

const UserRoleColumn = (props: UserRoleColumnProps) => {
  const { role } = props;
  const [userRole, setUserRole] = useState(() => role);
  const [tempRole, setTempRole] = useState(() => role);
  const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER_MUTATION);
  const {
    isOpen: isOpenSuccessModal,
    onOpen: onOpenSuccessModal,
    onClose: onCloseSuccessAirDropToken,
  } = useDisclosure();
  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={<ChevronDownIcon />}
          variant={"ghost"}
          size="sm"
          textTransform={"capitalize"}
        >
          {userRole?.toLowerCase() || ""}
        </MenuButton>
        <MenuList zIndex={3}>
          <MenuOptionGroup
            defaultValue={userRole}
            type="radio"
            onChange={(value: any) => {
              setTempRole(value);
              onOpenSuccessModal();
            }}
          >
            <MenuItemOption value={USER_ROLE_TYPE.ADMIN}>Admin</MenuItemOption>
            <MenuItemOption value={USER_ROLE_TYPE.USER}>User</MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      <CommonModal
        isOpen={isOpenSuccessModal}
        onClose={onCloseSuccessAirDropToken}
        ctaAction={() => {
          console.log("I'm Sure");
        }}
        title={"Confirmation"}
        message={"Are You sure to change this role. Because it will change role on blockchain as well"}
        ctaTitle={"Continue"}
      />
    </>
  );
};

function UsersTable(props: UsersTableProps) {
  const { onOpen, setActionType, data, onOpenMessageModal, accessCode, organizationId } = props;
  const [searchTerm, setSearchTerm] = useState("");
  const [statusSelection, setStatusSelection] = useState<USER_STATUS | undefined>(USER_STATUS.ACTIVE);
  const [filterOptions, setFilterOptions] = useState<UserTableRowType[]>(() => data);
  const [rowData, setRowData] = useState<UserTableRowType[]>(() => data);

  const [isOpenAddUserModal, setOpenAddUserModal] = useState<boolean>(false);
  const [isOpenAddUserSuccessModal, setOpenAddUserSuccessModal] = useState<boolean>(false);
  const [newUserEmail, setNewUserEmail] = useState<string>("");

  const rowBorderColor = useColorModeValue("gray.200", "gray.500");
  const rowValueColor = useColorModeValue("black.300", "white");
  const navigate = useNavigate();
  const toast = useToast();

  const {
    fetchMore: fetchInvite,
    data: inviteData,
    loading: inviteDataLoading,
    error: inviteDataError,
  } = useQuery(FETCH_INIVITES_BY_ORGANIZATION_ID, {
    variables: { organizationId: organizationId },
  });
  const [createInvites, { loading: isAddUserLoading, error: addUserError }] = useMutation(CREATE_INVITES_MUTATION);

  const handleAddUser = () => {
    // Add user to DB
    const emailList = newUserEmail.split(",");
    createInvites({
      variables: {
        organizationId: organizationId || "",
        emailAddressList: emailList,
      },
    })
      .then(() => {
        setNewUserEmail("");
        setOpenAddUserModal(false);
        setOpenAddUserSuccessModal(true);
        fetchInvite({
          variables: { organizationId: organizationId },
        });
        API.sendEmailInvitets({
          emails: emailList,
          code: accessCode,
        } as API.SendEmailInviteProps);
      })
      .catch((e) => {
        console.error("error", e);
      });
  };

  useEffect(() => {
    if (!isAddUserLoading && addUserError) {
      const errorMsg = addUserError.message?.toLowerCase().includes("duplicate key")
        ? ErrorMessage.duplicateInvite
        : ErrorMessage.genericInviteErrorMsg;
      toast({
        title: errorMsg,
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [addUserError]);

  // Merge users and invites
  useEffect(() => {
    // TODO: for invites, might need to further filter the invite status
    const allData = data
      .map((d) => {
        return { ...d, userStatus: d.status };
      })
      .concat(
        inviteData?.invites
          ?.map((d: any) => {
            return { ...d, email: d.emailAddress, userStatus: USER_STATUS.INVITED };
          })
          .sort((a: any, b: any) => (a.status?.toLowerCase() === "pending" ? -1 : 1)) || []
      );
    setRowData(allData);
  }, [data, inviteData]);

  // Search and Filter
  useEffect(() => {
    const getData = setTimeout(() => {
      const statusFilteredData = statusSelection ? rowData.filter((r) => r.userStatus === statusSelection) : rowData;
      if (searchTerm.length > 0) {
        const lowerCaseST = searchTerm.toLowerCase();
        const filterData = statusFilteredData.filter((item: UserTableRowType) =>
          item.email.toLowerCase().includes(lowerCaseST)
        );
        setFilterOptions(filterData);
      } else {
        setFilterOptions(statusFilteredData);
      }
    }, 300);
    return () => clearTimeout(getData);
  }, [searchTerm, statusSelection, rowData]);

  return (
    <>
      <Box mb={5}>
        <Flex flexWrap={"wrap"}>
          <Box flex={"2"} display="flex" mt={2} mr={4}>
            <InputGroup size="md" minW={300} maxW={["100%", "100%", "100%", "100%"]}>
              <Input
                value={searchTerm}
                placeholder="Search user by email"
                size="md"
                onChange={(event) => setSearchTerm(event.target.value)}
              />
              {searchTerm.length > 0 && (
                <InputRightElement width="4.5rem">
                  <IconButton
                    aria-label="clear user search"
                    h="1.75rem"
                    size="sm"
                    icon={<CloseIcon />}
                    variant={"unstyled"}
                    onClick={() => setSearchTerm("")}
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>
          <Box flex={"1"} display="flex" mt={2}>
            <Menu>
              <MenuButton
                as={Button}
                textStyle={"paragraph"}
                variant={"outline"}
                borderColor={rowValueColor}
                rightIcon={<ChevronDownIcon />}
              >
                {statusSelection || "Select Status"}
              </MenuButton>
              <MenuList>
                <MenuOptionGroup onChange={(v) => setStatusSelection(v as USER_STATUS)} type={"radio"}>
                  {[USER_STATUS.ACTIVE, USER_STATUS.DEACTIVATED, USER_STATUS.INVITED].map((option, index) => {
                    return (
                      <MenuItemOption key={`filter-option-${option}`} value={option} icon={<></>}>
                        <Flex>
                          <Text flex={"2"}>{option}</Text>
                          {statusSelection === option && (
                            <Box flex={"1"} display="flex" justifyContent={"right"}>
                              <FiCheck />
                            </Box>
                          )}
                        </Flex>
                      </MenuItemOption>
                    );
                  })}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
            <Button variant={"link"} ml={"4"} onClick={() => setStatusSelection(undefined)}>
              Clear
            </Button>
          </Box>
          <Box flex={"3"} display="flex" justifyContent={"right"} mt={2}>
            <Button onClick={() => setOpenAddUserModal(true)}>Invite Member</Button>
            <CsvDownloadButton
              fileName={"Users"}
              data={filterOptions.map((item: any) => {
                return {
                  Email: item.email || "",
                  User: item.username || "",
                  Wallet: item.walletAddress || "",
                  Role: item?.role?.toLowerCase() || "",
                  Status: item.userStatus || "",
                  Invite_Status: item.status || "",
                };
              })}
            />
          </Box>
        </Flex>
      </Box>
      <Box overflow={"auto"}>
        <Grid
          templateRows={"repeat(1, 1fr)"}
          templateColumns={"repeat(10, 1fr)"}
          bg={useColorModeValue("black.100", "black.400")}
          py={4}
          rounded={"lg"}
          minW={"1050px"}
          overflow={"hidden"}
          alignItems={"center"}
          textAlign={{ base: "center", md: "left" }}
        >
          <GridItem></GridItem>
          {Columns.map((item, index) => (
            <GridItem key={item.label + index} colSpan={item.colSpan}>
              <Text textStyle={"paragraph"} color={rowValueColor}>
                {item.label}
              </Text>
            </GridItem>
          ))}
          <GridItem></GridItem>
        </Grid>
        {filterOptions.map((item: any) => (
          <Grid
            templateRows={"repeat(1, 1fr)"}
            templateColumns={"repeat(10, 1fr)"}
            rounded={"lg"}
            border={"1px"}
            borderColor={rowBorderColor}
            borderStyle={"solid"}
            py={4}
            mt={3}
            overflow={"hidden"}
            alignItems={"center"}
            textAlign={{ base: "center", md: "left" }}
            key={item._id}
            minW={"1050px"}
          >
            <GridItem>
              <Flex justifyContent={"center"}>
                <Link to={`/profile/${item.walletAddress}`}>
                  <Image
                    rounded="xl"
                    width="90px"
                    height="90px"
                    fit="cover"
                    src={item.avatarUri || `/img/avatars/${Math.floor(Math.random() * 5) + 1}.svg`}
                    fallbackSrc={FALLBACK_URL_IMAGE}
                    alt={item.username}
                    _hover={{
                      width: "100px",
                      height: "100px",
                    }}
                    cursor={"pointer"}
                  />
                </Link>
              </Flex>
            </GridItem>
            <GridItem colSpan={3}>
              <VStack alignItems={"flex-start"} spacing={0}>
                <Text textStyle={"paragraph"} color={rowValueColor}>
                  {item.email || ""}
                </Text>
                <Text textStyle={"paragraph"} color={"gray.400"}>
                  {item.username || ""}
                </Text>
              </VStack>
            </GridItem>
            <GridItem colSpan={2}>
              {item.walletAddress && (
                <Text textStyle={"paragraph"} color={rowValueColor}>
                  {getstandardizedOwnerAddress(item.walletAddress)}
                </Text>
              )}
            </GridItem>

            <GridItem colSpan={1}>
              <Text textStyle={"paragraph"} color={rowValueColor} noOfLines={1} textTransform={"capitalize"}>
                {item?.role?.toLowerCase() || ""}
              </Text>
            </GridItem>

            {/* <GridItem colSpan={1}>
            <Text textStyle={"paragraph"} color={rowValueColor} noOfLines={1}>
              3 days ago
            </Text>
          </GridItem> */}

            <GridItem colSpan={1}>
              <Text textStyle={"paragraph"}>{item.userStatus}</Text>
              {item.userStatus === USER_STATUS.INVITED && item.status && (
                <Text textStyle={"paragraph"} color={"gray.400"}>
                  {item.status}
                </Text>
              )}
            </GridItem>

            <GridItem colSpan={2}>
              <HStack justifyContent={{ base: "center", md: "flex-start" }}>
                <Button
                  size={"sm"}
                  onClick={() => {
                    onOpen();
                    setActionType(UserActionType.AIRDROP, item);
                  }}
                  disabled={!item.walletAddress}
                >
                  Airdrop Token
                </Button>
                <Tooltip label="Send Email to User">
                  <IconButton
                    variant="ghost"
                    fontSize="20px"
                    icon={<BiMailSend />}
                    aria-label={""}
                    onClick={() => {
                      if (DEV_ENV) {
                        onOpenMessageModal();
                        setActionType(UserActionType.SENT_MESSAGE, item);
                      }
                    }}
                    disabled={!item.walletAddress}
                  />
                </Tooltip>
                <Tooltip label="Modify user settings">
                  <IconButton
                    variant="ghost"
                    fontSize="20px"
                    icon={<FaUserCog />}
                    aria-label={""}
                    onClick={() => {
                      setActionType(UserActionType.SET_PROFILE_PICTURE, item);
                    }}
                    disabled={!item.walletAddress}
                  />
                </Tooltip>
                <Tooltip label="Export NFTs">
                  <Link to={`/profile/exit`}>
                    <MdSend/>
                  </Link>
                </Tooltip>
              </HStack>
            </GridItem>
          </Grid>
        ))}
        {data.length === 0 && <NotFound message={"No users created"} />}
      </Box>

      <AddUsersModal
        isOpen={isOpenAddUserModal}
        onClose={() => {
          setNewUserEmail("");
          setOpenAddUserModal(false);
        }}
        value={newUserEmail}
        setValue={setNewUserEmail}
        ctaAction={handleAddUser}
        loading={isAddUserLoading}
        accessCode={accessCode}
      />

      <SuccessModal
        isOpen={isOpenAddUserSuccessModal}
        onClose={() => {
          setOpenAddUserSuccessModal(false);
        }}
        ctaAction={() => {
          setOpenAddUserSuccessModal(false);
        }}
        title={"Invite Sent!"}
        description={"You have successfully invited users to your digital collectable workspace!"}
        subDescription={""}
        ctaTitle={"Close"}
      />
    </>
  );
}

export default UsersTable;
