import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { DASHBOARD_MODAL_DATA, FALLBACK_URL_IMAGE } from "../../../util/Constants";
import { useQuery } from "@apollo/client";
import { FETCH_ORGANIZATION_BY_ID } from "../../../queries/dashboard";
//import { useContract, useContractData } from "@thirdweb-dev/react";
import { getCleanErrorMessage, getstandardizedOwnerAddress } from "../../../util/helper";
import useAdminFunctions from "../../../hooks/Dashboard/AdminFunctions";
import Loader from "../Loader";
import { BsCardChecklist } from "react-icons/bs";
import { BiRocket } from "react-icons/bi";
import { GiCommercialAirplane } from "react-icons/gi";
import { CloseIcon, SmallCloseIcon } from "@chakra-ui/icons";
import UsersTable, { USER_ROLE_TYPE } from "../Table/UsersTable";
import { BiBox } from "react-icons/bi";
import useFetchNFTOwners from "../../../hooks/FetchNFTOwners";
import { UserStatusType } from "../../../Interfaces/users";

enum ActionType {
  ALLOW_LIST = "allow_list",
  AIRDROP = "airdrop",
  ELIFIBILITY = "eligibility",
  RECLAIM_TOKEN = "reclaim_token",
}

interface DashboardNFTActionModalType {
  isOpen: any;
  onClose: any;
  onOpenSuccessModal: any;
  actionType: ActionType | null;
  tokenId: string;
  amount: number;
  contractAddress: string;
  organizationId: string;
}

function DashboardNFTActionModal(props: DashboardNFTActionModalType) {
  const { isOpen, onClose, actionType, onOpenSuccessModal, tokenId, amount, contractAddress, organizationId } = props;
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const [options, setOptions] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // const [isChecked, setIsChecked] = useState<any>("");
  const [filterOptions, setFilterOptions] = useState<any[]>([]);
  const [error, setError] = useState("");

  // Fetching Organization Users
  const { data: oraganizationUsers, refetch } = useQuery(FETCH_ORGANIZATION_BY_ID, {
    variables: { id: organizationId },
  });

  // Fetching Allow list for user
  const { contract, getAllowList, allowListUsers, createAllowList, airdropSingleToMany, reclaimToken } =
    useAdminFunctions({
      contractAddress,
    });

  // Fetching NFT Owners
  const { owners, fetchOwners } = useFetchNFTOwners();

  const rowValueColor = useColorModeValue("black.300", "white");
  const boxShadow = useColorModeValue("xl", "2xl");

  useEffect(() => {
    if (tokenId && actionType && contract) {
      getAllowList(tokenId);
      if (actionType === ActionType.RECLAIM_TOKEN) {
        fetchOwners(contractAddress, tokenId);
      }
      setSelectedOptions([]);
      setError("");
      refetch();
    }
  }, [tokenId, actionType, contract]);

  useEffect(() => {
    if (oraganizationUsers && allowListUsers) {
      let allowListAndOwnersUsers = [...allowListUsers];
      if (Array.isArray(owners) && actionType === ActionType.RECLAIM_TOKEN) {
        allowListAndOwnersUsers = [...owners];
      }
      const userWalletAddressWithLowerCase = allowListAndOwnersUsers.map((item: string) => item.toLowerCase());
      let defaultSelectedUsers: any = [];

      let allUsers: any[] = [];
      if (oraganizationUsers?.organization?.users?.length > 0) {
        allUsers = [...oraganizationUsers.organization.users];
        if (actionType === ActionType.RECLAIM_TOKEN) {
          allUsers = allUsers.filter((item: any) =>
            userWalletAddressWithLowerCase.includes(item.walletAddress.toLowerCase())
          );
        }
      }

      const tempData = allUsers
        .filter(
          (item: any) =>
            (item.role &&
              item.role.toUpperCase() !== USER_ROLE_TYPE.OPERATOR &&
              item.status === UserStatusType.ACTIVE) ||
            (actionType === ActionType.RECLAIM_TOKEN &&
              userWalletAddressWithLowerCase.includes(item.walletAddress.toLowerCase()))
        )
        .map((item: any) => {
          if (
            actionType === ActionType.ALLOW_LIST &&
            userWalletAddressWithLowerCase.includes(item.walletAddress.toLowerCase())
          ) {
            defaultSelectedUsers.push(item.walletAddress);
          }
          return {
            _id: item.id,
            value: item.walletAddress,
            label: getstandardizedOwnerAddress(item.walletAddress),
            labelInfo: ` - ${item.email || ""}`,
            username: item.username || "",
            email: item.email || "",
            image: item.avatarUri || `/img/avatars/${Math.floor(Math.random() * 5) + 1}.svg`,
            alreadyInAllowList:
              actionType === ActionType.ALLOW_LIST
                ? userWalletAddressWithLowerCase.includes(item.walletAddress.toLowerCase())
                : false,
          };
        });
      setSelectedOptions(defaultSelectedUsers);
      setOptions(tempData);
      setFilterOptions(tempData);
    }
  }, [oraganizationUsers, allowListUsers, owners]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (searchTerm.length > 0) {
        const lowerCaseST = searchTerm.toLowerCase();
        const filterData = options.filter(
          (item: any) =>
            item.username.toLowerCase().includes(lowerCaseST) || item.email.toLowerCase().includes(lowerCaseST)
        );
        setFilterOptions(filterData);
      } else if (options.length > 0) {
        setFilterOptions(options);
      }
    }, 1000);
    return () => clearTimeout(getData);
  }, [searchTerm]);

  const onClickSave = async () => {
    try {
      setError("");
      setLoading(true);
      let result = null;
      if (actionType === ActionType.ALLOW_LIST) {
        result = await createAllowList(tokenId, selectedOptions);
      } else if (actionType === ActionType.AIRDROP) {
        result = await airdropSingleToMany(tokenId, selectedOptions);
      } else if (actionType === ActionType.RECLAIM_TOKEN) {
        result = await reclaimToken(selectedOptions[0], tokenId, 1, true);
      }
      onClose();
      onOpenSuccessModal();
      refetch();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const cleanErrorMessage = getCleanErrorMessage(error?.reason || error?.message);
      setError(cleanErrorMessage);
      // console.log(`onClickSave:${actionType}:error`, error);
    }
  };

  const onSelectAll = () => {
    const allUsers = oraganizationUsers?.organization?.users.map((item: any) => item.walletAddress);
    setSelectedOptions(allUsers);
  };

  return (
    <Modal isOpen={isOpen} onClose={!loading && onClose} size={"xl"} scrollBehavior={"inside"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        {actionType && (
          <Flex mt={10}>
            <Flex w={"20%"} justify="flex-end">
              {actionType === ActionType.ALLOW_LIST && <Icon as={BsCardChecklist} w={12} h={12} />}
              {actionType === ActionType.AIRDROP && <Icon as={GiCommercialAirplane} w={12} h={12} />}
              {actionType === ActionType.RECLAIM_TOKEN && <Icon as={BiBox} w={14} h={14} />}
            </Flex>
            <Flex direction={"column"} align={"center"} w={"70%"}>
              <Text textStyle={"h3"}>{DASHBOARD_MODAL_DATA[actionType].modalTitle}</Text>
              <Text textStyle={"paragraphMedium"} pb={5} textAlign="center" maxW={"350px"}>
                {DASHBOARD_MODAL_DATA[actionType].modalDesc}
              </Text>
            </Flex>
          </Flex>
        )}
        <Box px={8}>
          <InputGroup size="md">
            <Input
              value={searchTerm}
              placeholder="Search users by email or username"
              size="md"
              onChange={(event) => setSearchTerm(event.target.value)}
            />
            {searchTerm.length > 0 && (
              <InputRightElement width="4.5rem">
                <IconButton
                  aria-label="clear user search"
                  h="1.75rem"
                  size="sm"
                  icon={<CloseIcon />}
                  variant={"unstyled"}
                  onClick={() => setSearchTerm("")}
                />
              </InputRightElement>
            )}
          </InputGroup>
          {actionType !== ActionType.RECLAIM_TOKEN && (
            <>
              <HStack pt={2} pb={1}>
                <Button variant="link" onClick={onSelectAll}>
                  Select All
                </Button>
                {selectedOptions.length > 0 && (
                  <Button variant="link" onClick={() => setSelectedOptions([])}>
                    Clear
                  </Button>
                )}
              </HStack>
              {selectedOptions.length > 0 && (
                <Text pb={2} textStyle={"label"} color={"gray.500"}>
                  You have selected {selectedOptions.length} {selectedOptions.length > 1 ? "users" : "user"}
                </Text>
              )}
            </>
          )}
        </Box>

        {actionType && (
          <>
            <ModalBody>
              {contract ? (
                <>
                  <FormControl p={4} px={0} pt={0} isInvalid={error ? true : false}>
                    <FormErrorMessage pb={2} pt={0} mt={0}>
                      {error && error}
                    </FormErrorMessage>
                    <CheckboxGroup
                      onChange={(data) => {
                        console.log({ data });
                        if (actionType === ActionType.RECLAIM_TOKEN) {
                          if (data.length === 1) {
                            setSelectedOptions([data[0]]);
                          } else if (data.length === 2) {
                            setSelectedOptions([data[1]]);
                          }
                        } else {
                          setSelectedOptions(data);
                        }
                      }}
                      value={selectedOptions}
                    >
                      <VStack align={"flex-start"} justify={"flex-start"}>
                        {filterOptions.length > 0 &&
                          filterOptions.map((item: any) => (
                            <Checkbox
                              value={item.value}
                              key={item.value}
                              _hover={{ boxShadow: boxShadow }}
                              style={{ padding: "10px", width: "100%" }}
                            >
                              <HStack maxH={"200px"}>
                                <Image
                                  rounded="xl"
                                  width="70px"
                                  height="70px"
                                  fit="cover"
                                  src={item.image}
                                  fallbackSrc={FALLBACK_URL_IMAGE}
                                  alt={item.labelInfo}
                                />
                                <Text textStyle={"paragraph"} color={rowValueColor}>
                                  {item.label + item.labelInfo}
                                </Text>
                              </HStack>
                            </Checkbox>
                          ))}
                      </VStack>
                    </CheckboxGroup>
                  </FormControl>
                </>
              ) : (
                <Loader />
              )}
            </ModalBody>
            {contract && (
              <ModalFooter justifyContent={"center"}>
                <Button
                  colorScheme="blue"
                  isLoading={loading}
                  loadingText={"Loading"}
                  mr={3}
                  onClick={onClickSave}
                  disabled={error || loading ? true : false}
                >
                  {DASHBOARD_MODAL_DATA[actionType].modalBtnTitle}
                </Button>
              </ModalFooter>
            )}
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
export default DashboardNFTActionModal;
