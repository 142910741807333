import { Box, HStack, IconButton, Input, Link, SimpleGrid, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import MasterBox from "./MasterBox";
import Logo from "./../common/logo";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { FOOTER_LINKS } from "../../util/Constants";
import { useNavigate } from "react-router-dom";

const ListHeader = (props: any) => {
  return (
    <Text textStyle={"h3"} fontSize={14} mb={6} color={useColorModeValue("white", "white")} {...props}>
      {props.children}
    </Text>
  );
};

const ListItem = (props: any) => {
  const { children } = props;
  return (
    <Text
      textStyle={"paragraphMedium"}
      fontWeight={"normal"}
      cursor={"pointer"}
      pb={2}
      color={useColorModeValue("black.700", "gray.600")}
    >
      {children}
    </Text>
  );
};

const EmailInput = ({}) => {
  return (
    <Stack direction={"row"} spacing={0}>
      <Input
        placeholder={"Get minted updates"}
        color={"white"}
        bg={useColorModeValue("black.500", "transparent")}
        border={0}
        _focus={{
          bg: "whiteAlpha.300",
          color: "white",
        }}
      />
      <IconButton color={"white"} variant={"unstyled"} aria-label="Subscribe" icon={<ArrowForwardIcon />} />
    </Stack>
  );
};

export default function Footer() {
  const navigate = useNavigate();

  return (
    <Box bg={useColorModeValue("black", "gray.900")} color={useColorModeValue("gray.700", "gray.200")}>
      <MasterBox py={10}>
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
          <Stack display={{ base: "none", md: "flex" }}>
            <Box onClick={() => navigate("/")} cursor={"pointer"}>
              <Logo textColor={useColorModeValue("white", "white")} logoColor={"white"} />
            </Box>
          </Stack>

          <HStack
            display={{ base: "none", md: "flex" }}
            w={"xs"}
            justifyContent={"initial"}
            alignItems={"flex-start"}
            spacing={10}
          >
            {Object.keys(FOOTER_LINKS).map((item, index) => {
              return (
                <Stack align={"baseline"} key={item + index} justifyContent={"flex-start"}>
                  <ListHeader>{item}</ListHeader>
                  {Object.keys(FOOTER_LINKS[item]).map((subItem) => {
                    return (
                      <ListItem key={subItem + index}>
                        <Link href={FOOTER_LINKS[item][subItem]} isExternal>
                          {subItem}
                        </Link>
                      </ListItem>
                    );
                  })}
                </Stack>
              );
            })}
          </HStack>
        </Stack>

        <Text textAlign={"center"} pt={2}>
          Copyright © 2022 mintED. All rights reserved.
        </Text>
      </MasterBox>
    </Box>
  );
}
