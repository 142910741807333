import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Flex,
  Box,
  Textarea,
  FormControl,
  FormErrorMessage,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { DeleteIcon } from "@chakra-ui/icons";
import Label from "../Form/Label";
import { useEffect } from "react";
import * as API from "../../../util/api";

interface SendEmailToUserModalType {
  isOpen: any;
  onClose: any;
  onOpenSuccessModal: any;
  email: string;
}

type FormValues = {
  to: string;
  subject: string;
  message: string;
};

function SendEmailToUserModal(props: SendEmailToUserModalType) {
  const { isOpen, onClose, email, onOpenSuccessModal } = props;
  const defaultSubject = "Congrats! You just received an airdrop!";
  const toast = useToast();

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      to: email,
      subject: defaultSubject,
    },
  });

  useEffect(() => {
    if (email) {
      setValue("to", email);
      setValue("subject", defaultSubject);
    }
  }, [email]);

  const onSubmit = handleSubmit((data) => {
    API.sendAirdropsEmail({
      emails: [data.to],
      subject: data.subject,
      message: data.message,
    } as API.SendAirdropsEmailProp)
      .then((res) => {
        onClose();
        onOpenSuccessModal();
      })
      .catch((error) => {
        toast({
          title: "Some things went wrong while sending the message. Please try again",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      })
      .finally(() => {
        reset();
      });
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        reset();
      }}
      size={"xl"}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <form onSubmit={onSubmit}>
          <ModalBody pt={12}>
            <Flex mb="10px" justify="space-between" align={"center"}>
              <Box w="14%">
                <Label>To:</Label>
              </Box>
              <Box w="85%">
                <FormControl isInvalid={errors.to ? true : false}>
                  <Input
                    type="text"
                    autoComplete="off"
                    color="gray"
                    {...register("to", {
                      required: "This field is required",
                    })}
                    variant={"unstyled"}
                    disabled
                  />
                  <FormErrorMessage>{errors.to && errors.to.message}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>
            <Flex mb="10px" justify="space-between" align={"center"}>
              <Box w="14%">
                <Label>Subject:</Label>
              </Box>
              <Box w="85%">
                <FormControl isInvalid={errors.subject ? true : false}>
                  <Input
                    type="text"
                    {...register("subject", {
                      required: "This field is required",
                    })}
                    placeholder="Subject"
                    variant={"unstyled"}
                  />
                  <FormErrorMessage>{errors.subject && errors.subject.message}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Box pt={5} mt={5} borderTop={"1px solid"} borderTopColor={"gray.200"}>
              <FormControl isInvalid={errors.message ? true : false}>
                <Textarea
                  {...register("message", {
                    required: "This field is required",
                    minLength: { value: 5, message: "Minimum length of message should be 5" },
                  })}
                  size="lg"
                  minH={300}
                  variant={"outline"}
                  placeholder="Write your message here.."
                />
                <FormErrorMessage>{errors.message && errors.message.message}</FormErrorMessage>
              </FormControl>
            </Box>
          </ModalBody>

          <ModalFooter display="block" mt="4%">
            <Flex justify="space-between">
              <Box></Box>
              <Button colorScheme="blue" type="submit">
                Send
              </Button>
              <Button variant="ghost" onClick={() => resetField("message")}>
                <DeleteIcon />
              </Button>
            </Flex>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
export default SendEmailToUserModal;
