import { Stack, Text, Card, Image, Box, useColorModeValue, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure, Link, OrderedList, ListItem } from "@chakra-ui/react";
import { DEFAULT_EXPORT_NFT_MESSAGES, DEFAULT_INTRO_MESSAGES } from "../../util/Constants";
import MasterBox from "../Layout/MasterBox";

interface ExportCardProps {
    title: string;
    subtitle?: string;
    imgPath: string;
    imgPathDark: string;
    content: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
}

interface ExportNftModalProps {
    isOpen: boolean,
    onClose: () => void;
}

function ExportNftsHeader() {
    return (
        <Stack mt={[5, 10]} mb={[2, 5]} alignItems={"center"}>
          <Text textStyle={"h1"} fontWeight={"normal"}>
            {DEFAULT_EXPORT_NFT_MESSAGES.title}
          </Text>
          <Text textStyle={"paragraph"} color={"black.200"} maxW={"630px"} textAlign={"center"}>
            {DEFAULT_EXPORT_NFT_MESSAGES.subtitle}
          </Text>
        </Stack>
    );
}

function ExportNftsCard(props: ExportCardProps) {

    const backgroundBox = useColorModeValue("white", "gray.800");
    const borderColorBox = useColorModeValue("gray.200", "gray.500");
    const boxShadow = useColorModeValue("xl", "2xl");
    const iconPath = useColorModeValue(props.imgPath, props.imgPathDark);

    return (
    <Stack py={8} px={20} maxW={"600px"} bg={backgroundBox} alignItems={"center"} spacing={props.subtitle ? 6 : 12}
    onClick={props.onClick}
    opacity={props.disabled === true ? 0.3 : 1}
    _hover={props.disabled === true ? {} : { 
      boxShadow: boxShadow,
    }}
    rounded={"lg"}
    border={"1px"}
    borderColor={borderColorBox}
    borderStyle={"solid"}
    overflow={"hidden"}>
        <Box>
            <Text textStyle={"h3"} fontWeight={"bold"} textAlign={"center"}>
                {props.title}
            </Text>
            {props.subtitle &&
            <Text textStyle={"paragraph"} color={"black.200"} textAlign={"center"}>
                {props.subtitle}
            </Text>}
        </Box>
            <Image src={iconPath} boxSize={"200px"} objectFit={"contain"}/>
            <Text textStyle={"paragraph"} color={"black.200"} textAlign={"center"} pt={props.subtitle ? 6 : 0}>
                {props.content}
            </Text>
        </Stack>
    );
}

function ExportNftsModal(props: ExportNftModalProps) {
    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Wallet</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
                    <Text textStyle={"paragraph"}>
                        1. Follow the instructions to export your wallet:
                    </Text>
                    <Link href="https://gotminted.notion.site/Exporting-NFTs-magic-wallets-253ad880ccb744738a5fea81a0022072" isExternal>
                        <Text textDecor={"underline"} _hover={{color: "primary.800"}}>
                            https://gotminted.notion.site/Exporting-NFTs-magic-wallets-253ad880ccb744738a5fea81a0022072
                        </Text>
                    </Link>
                    <br/>
                    <Text textStyle={"paragraph"}>
                        2. Reveal your private key with Magic:
                    </Text>
                    <Link href="https://reveal.magic.link/minted" isExternal>
                        <Text textDecor={"underline"} _hover={{color: "primary.800"}}>
                            https://reveal.magic.link/minted
                        </Text>
                    </Link>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='primary.800' mr={3} onClick={props.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
}

function ExportNfts() {
    const {onOpen, isOpen, onClose} = useDisclosure();
    return (
        <MasterBox>
            <ExportNftsHeader />
            <Stack py={10} direction={["column", "column", "column", "row"]} justifyContent={"center"} spacing={16}>
                <ExportNftsCard title={DEFAULT_EXPORT_NFT_MESSAGES.card1Title} subtitle={DEFAULT_EXPORT_NFT_MESSAGES.card1Subtitle} imgPath="/img/wallet-pay.svg" imgPathDark="/img/wallet-pay-dark.svg" content={DEFAULT_EXPORT_NFT_MESSAGES.card1Content} disabled={true}/>
                <ExportNftsCard title={DEFAULT_EXPORT_NFT_MESSAGES.card2Title} subtitle={DEFAULT_EXPORT_NFT_MESSAGES.card2Subtitle} imgPath="/img/export.svg" imgPathDark="/img/export-dark.svg" content={DEFAULT_EXPORT_NFT_MESSAGES.card2Content} onClick={onOpen}/>
            </Stack>
            <ExportNftsModal isOpen={isOpen} onClose={onClose}/>
        </MasterBox>
    )
}

export default ExportNfts;