import { gql } from "@apollo/client";

export const FETCH_ORGANIZATION_BY_ID = gql`
  query ($id: ID!) {
    organization(id: $id) {
      _id
      name
      users {
        _id
        walletAddress
        username
        email
        role
        avatarUri
        status
      }
    }
  }
`;
