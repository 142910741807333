import axios from "axios";
let data = "";
//pull from .env
const auth = "Bearer 6ce3f7c4-7a5f-4c59-b410-72f4f4143ead";
//this value needs to be passed in from the calling application. claimButtonUrl value from collection data
const url =
  "https://paper.xyz/api/v1/checkout/9c83d5aa-e3d8-46cf-b4d8-4a0fdad22b29/one-time-link";
const tokenId = 0;

const config = {
  method: "post",
  url: `${url}`,
  headers: {
    Authorization: `${auth}`,
  },
  data: data,
};

const getPaperLink = () => {
  // axios(config)
  //   .then(function (response) {
  //     console.log("response", response);
  //     return response;
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //     return error;
  //   });


    //pull from .env
const auth = "Bearer 6ce3f7c4-7a5f-4c59-b410-72f4f4143ead";
//this value needs to be passed in from the calling application. claimButtonUrl value from collection data
const url = "https://paper.xyz/api/v1/checkout/9c83d5aa-e3d8-46cf-b4d8-4a0fdad22b29/one-time-link";

  
  fetch(url, {
    method: 'POST',
    mode: 'no-cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: auth
    }
  })
    .then(response => response.json())
    .then(response => {
      console.log(response)
      return response
    })
    .catch(err => {
      console.error(err)
      return err;
    });


};

 
export { getPaperLink };
