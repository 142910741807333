import { Icon } from "@chakra-ui/icon";
import { VStack, Box, Text, Button, Input, Flex, useDisclosure } from "@chakra-ui/react";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { AiOutlineWarning } from "react-icons/ai";
import { useEffect, useState } from "react";
import { getCleanErrorMessage } from "../../../util/helper";
import SuccessModal from "../../common/Modal/SuccessModal";

export enum EditNFTActionModalType {
  ALLOW_LIST = "allow_list",
  NON_TRANSFERABLE = "non_transferable",
  MAX_SUPPLY = "max_supply",
}

interface EditNFTActionModalProps {
  modalActionType: EditNFTActionModalType | undefined;
  contractAddress: string;
  tokenId: string;
  isOpen: any;
  onClose: any;
  nonTransferableValue: boolean;
  isAllowListRequired: boolean;
  setTransferable: any;
  toggleAllowList: any;
  setTokenMaxSupply: any;
  maxSupply: number;
}

const modalContent: {
  [key in EditNFTActionModalType]: {
    title: string;
    buttonTitle: string;
  };
} = {
  [EditNFTActionModalType.ALLOW_LIST]: {
    title: "Toggle Allowlist",
    buttonTitle: "Confirm",
  },
  [EditNFTActionModalType.MAX_SUPPLY]: {
    title: "Set Max Supply",
    buttonTitle: "Confirm",
  },
  [EditNFTActionModalType.NON_TRANSFERABLE]: {
    title: "Set Non-Transferable",
    buttonTitle: "Confirm",
  },
};

const EditNFTActionModal = (props: EditNFTActionModalProps) => {
  const {
    isOpen,
    onClose,
    modalActionType,
    tokenId,
    setTokenMaxSupply,
    setTransferable,
    toggleAllowList,
    nonTransferableValue,
    isAllowListRequired,
    maxSupply,
  } = props;

  const [maxSupplyValue, setMaxSupplyValue] = useState<string>(`0`);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { isOpen: isOpenSuccessModal, onOpen: onOpenSuccessModal, onClose: onCloseSuccessModal } = useDisclosure();

  useEffect(() => {
    if (maxSupply > 0) {
      setMaxSupplyValue(maxSupply + "");
    }
  }, [maxSupply]);

  const onClickSave = async () => {
    try {
      setError("");
      setLoading(true);
      let result = null;
      if (modalActionType === EditNFTActionModalType.NON_TRANSFERABLE) {
        result = await setTransferable(tokenId, nonTransferableValue);
      } else if (modalActionType === EditNFTActionModalType.ALLOW_LIST) {
        result = await toggleAllowList(tokenId, isAllowListRequired);
      } else if (modalActionType === EditNFTActionModalType.MAX_SUPPLY) {
        result = await setTokenMaxSupply(tokenId, +maxSupplyValue);
      }
      onClose();
      onOpenSuccessModal();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const cleanErrorMessage = getCleanErrorMessage(error?.reason || error?.message);
      setError(cleanErrorMessage);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton onClick={() => setError("")} />
          <ModalBody>
            {modalActionType && (
              <VStack my={10} spacing={2}>
                <Box>
                  {modalActionType === EditNFTActionModalType.ALLOW_LIST && (
                    <Icon as={AiOutlineWarning} w={12} h={12} />
                  )}
                  {modalActionType === EditNFTActionModalType.MAX_SUPPLY && (
                    <Icon as={AiOutlineWarning} w={12} h={12} />
                  )}
                  {modalActionType === EditNFTActionModalType.NON_TRANSFERABLE && (
                    <Icon as={AiOutlineWarning} w={12} h={12} />
                  )}
                </Box>
                <Box>
                  <Text textStyle={"h3"}> {modalContent[modalActionType].title}</Text>
                </Box>
                <Flex>
                  {modalActionType === EditNFTActionModalType.MAX_SUPPLY && (
                    <Input
                      type="number"
                      placeholder="Max # of NFTs"
                      size="md"
                      value={maxSupplyValue}
                      onChange={(event) => setMaxSupplyValue(event.target.value)}
                    />
                  )}
                </Flex>

                {error && (
                  <Text color={"red.400"} size="sm">
                    {error}
                  </Text>
                )}

                {modalActionType && (
                  <Button colorScheme="blue" mr={3} onClick={onClickSave} isLoading={loading}>
                    {modalContent[modalActionType].buttonTitle}
                  </Button>
                )}
              </VStack>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      <SuccessModal
        isOpen={isOpenSuccessModal}
        onClose={onCloseSuccessModal}
        ctaAction={() => {
          onCloseSuccessModal();
        }}
        title={"Success"}
        description={""}
        ctaTitle={"Dismiss"}
        subDescription={""}
      />
    </>
  );
};

export default EditNFTActionModal;
