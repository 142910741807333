import { useQuery } from "@apollo/client";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Icon,
  IconProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ConnectWalletContext, ConnectWalletContextType } from "../../../context/connectWalletContext";
import { FETCH_ORGANIZATION_CONTRACT_ADDRESS } from "../../../queries/organization";
import { appConfig, DEFAULT_INTRO_MESSAGES, HOME_PAGE_YT_URL } from "../../../util/Constants";
import Loader from "../../common/Loader";

export default function HeroSection() {
  const navigate = useNavigate();
  const { user } = useContext(ConnectWalletContext) as ConnectWalletContextType;
  const { data, loading, error } = useQuery(FETCH_ORGANIZATION_CONTRACT_ADDRESS, {
    variables: {
      ids: [user?.organization?._id || appConfig.DEFAULT_ORGANIZATION_ID],
    },
  });
  const organization = data?.organizations && data.organizations.length > 0 ? data.organizations[0] : null;
  return (
    <Container maxW={"7xl"}>
      {loading ? (
        <Box height={"70vh"}>
          <Loader />
        </Box>
      ) : (
        <Stack
          align={"center"}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading as="h2" size={["lg", "xl"]}>
              {organization?.introText || DEFAULT_INTRO_MESSAGES.welcomeMessage}
            </Heading>
            <Text textStyle={"h2"}>{organization?.introTitle || DEFAULT_INTRO_MESSAGES.subtitle}</Text>
            <Stack spacing={{ base: 4, sm: 6 }} direction={{ base: "column", sm: "row" }}>
              <Button
                rounded={"full"}
                size={"lg"}
                fontWeight={"normal"}
                px={6}
                rightIcon={<ArrowForwardIcon />}
                onClick={() => navigate("/collection")}
              >
                Explore
              </Button>
            </Stack>
          </Stack>
          <Flex flex={1} justify={"center"} align={"center"} position={"relative"} w={"full"}>
            <Blob
              w={"150%"}
              h={"150%"}
              position={"absolute"}
              top={"-20%"}
              left={0}
              zIndex={-1}
              color={useColorModeValue("secondary.900", "primary.900")}
            />
            <Box
              position={"relative"}
              height={"300px"}
              rounded={"2xl"}
              boxShadow={"2xl"}
              width={"full"}
              overflow={"hidden"}
            >
              <iframe
                src={organization?.videoUri || HOME_PAGE_YT_URL}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
                width={"100%"}
                height={"100%"}
              />
            </Box>
          </Flex>
        </Stack>
      )}
    </Container>
  );
}

export const Blob = (props: IconProps) => {
  return (
    <Icon width={"100%"} viewBox="0 0 578 440" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};
