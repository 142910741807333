import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ConnectWalletContext, ConnectWalletContextType } from "../../../context/connectWalletContext";
import { getCleanErrorMessage } from "../../../util/helper";
import Loader from "../../common/Loader";
import CommonModal from "../../common/Modal";
import ProgressStepper from "../ProgressStepper";

interface MintingOnBlockChainModalProps {
  isOpen: boolean;
  onClose: any;
  contractAddress: string;
  tokenId: string;
  metaDataURI: string;
  creatNextTokenOnBlockChain: any;
  mintOnBlockChain: any;
  burnNFTOnBlockChain: any;
  resetForm: any;
}

const stepperTitle: string[] = ["Uploading Data", "Minting", "Finishing Up"];

const MintingOnBlockChainModal = ({
  isOpen,
  onClose,
  contractAddress,
  creatNextTokenOnBlockChain,
  mintOnBlockChain,
  burnNFTOnBlockChain,
  resetForm,
}: MintingOnBlockChainModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { isOpen: isOpenCommonModal, onOpen: onOpenCommonModal, onClose: onCloseCommonModal } = useDisclosure();
  const [createNFTState, setCreateNFTState] = useState<any[]>([]);

  const navigate = useNavigate();

  const onClickMintNFT = async () => {
    try {
      setLoading(true);
      await creatNextTokenOnBlockChain();
      setCreateNFTState([
        {
          setUri: { isDone: true },
        },
      ]);
      await mintOnBlockChain();
      setCreateNFTState((previousCreateNFTState: any) => [
        ...previousCreateNFTState,
        [
          {
            mint: { isDone: true },
          },
        ],
      ]);
      await burnNFTOnBlockChain();
      setCreateNFTState((previousCreateNFTState: any) => [
        ...previousCreateNFTState,
        [
          {
            burn: { isDone: true },
          },
        ],
      ]);
      onClose();
      onOpenCommonModal();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const cleanErrorMessage = getCleanErrorMessage(error?.reason || error?.message);
      setError(cleanErrorMessage);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          resetForm?.();
          onClose();
        }}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <Box py={8}></Box>
          <ModalCloseButton />
          <ModalBody mt={-2} textAlign={"center"}>
            <Flex align={"center"} justifyContent={"center"}>
              <Stack bg={error ? "red.100" : "secondary.900"} padding={5} borderRadius={"100px"} mb={6}>
                <Icon as={AiOutlineExclamationCircle} w={"45px"} h={"45px"} color={error ? "red.400" : "primary.800"} />
              </Stack>
            </Flex>
            <Text textStyle={"h2"} px={6} pb={2}>
              {"Minting On Blockchain"}
            </Text>

            {loading && (
              <>
                <Text textStyle={"paragraph"}>
                  Please wait for the minting of NFT on blockchain. Don't close the browser. Thanks
                </Text>
                <ProgressStepper
                  loading={loading}
                  errorMessage={error}
                  title={stepperTitle[createNFTState.length] || ""}
                  activeIndex={createNFTState.length}
                />
              </>
            )}
            <Button onClick={onClickMintNFT} mt={5} isLoading={loading}>
              Mint NFT
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <CommonModal
        isOpen={isOpenCommonModal}
        onClose={() => {
          resetForm?.();
          onCloseCommonModal();
        }}
        ctaAction={() => {
          // TODO: After merge of Edit NFT. Will replace with correct URL
          navigate(`/collection/${contractAddress}`);
        }}
        title={"Success"}
        message={"NFT has beeen created"}
        ctaTitle={"Advance Settings"}
        showTitleBelowIcon
        secondCta
        secondCtaTitle={"View Dashboard"}
        secondCtaAction={() => {
          navigate(`/collection/${contractAddress}`);
        }}
      />
    </>
  );
};

export default MintingOnBlockChainModal;
