import { useMutation } from "@apollo/client";
import { CopyIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  StackDirection,
  Switch,
  SystemProps,
  Text,
  Textarea,
  Tooltip,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { UPDATE_COLLECTION_MUTATION } from "../../../queries/collection";
import { URL_REGEX } from "../../../util/Constants";
import { ConTableRowType } from "../Table/ContractsTable";

const layoutStyles = {
  labelWidth: ["full", "full", "180px"],
  containerWidth: ["xs", "sm", "lg", "md", "lg"],
  containerHeight: 16,
  containerDirection: ["column", "column", "row", "row"],
};

const Label = ({ children }: any) => <Text textStyle={"paragraph"}>{children}</Text>;

type FormValues = {
  title: string;
  description: string;
  isVisible: boolean;
  isLinkedinCredential: boolean;
  profileImageUrl: string;
  bannerUrl: string;
  contractAddress: string;
  contractType: string;
};

export default function ContractSettingModal(props: { isOpen: boolean; onClose: any; contractInfo: ConTableRowType }) {
  const { isOpen, onClose, contractInfo } = props;
  const { hasCopied, setValue: setCopyValue, onCopy } = useClipboard("");
  const [updateCollection, { data: updatedContractData, loading, error: contractUpdateError }] =
    useMutation(UPDATE_COLLECTION_MUTATION);
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    defaultValues: contractInfo,
  });

  // To ensure modal shows the selected contract
  // and remove unsaved data after closing
  useEffect(() => {
    reset(contractInfo);
  }, [contractInfo, isOpen]);

  const onSubmit = handleSubmit((data) => {
    updateCollection({
      variables: {
        id: contractInfo._id || "",
        ...data,
      },
    });
  });

  useEffect(() => {
    if (updatedContractData && !loading) {
      toast({
        title: "Contract Updated Successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      onClose();
    }
  }, [updatedContractData]);

  useEffect(() => {
    if (contractUpdateError && !loading) {
      toast({
        title: "Some things went wrong while updating contract. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [contractUpdateError]);

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onClose} blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent borderRadius={"2xl"} py={5}>
        <Text textStyle={"h1"} px={6} pt={5}>
          Edit Contract
        </Text>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <Stack alignItems={["center", "flex-start"]} direction={layoutStyles.containerDirection as StackDirection}>
              <Flex w={layoutStyles.containerWidth}>
                <Box>
                  {/* Title */}
                  <Flex
                    py={4}
                    w={layoutStyles.containerWidth}
                    direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                  >
                    <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                      <Label>Title</Label>
                    </Box>
                    <Box w="full">
                      <FormControl isInvalid={errors.title ? true : false}>
                        <Input
                          type="text"
                          placeholder="Title of NFT collection"
                          {...register("title", {
                            required: "This is required",
                          })}
                        />
                        <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Flex>

                  {/* Description */}
                  <Flex
                    py={4}
                    w={layoutStyles.containerWidth}
                    direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                  >
                    <Box w={layoutStyles.labelWidth}>
                      <Label>Description</Label>
                    </Box>
                    <Box w="full">
                      <FormControl isInvalid={errors.description ? true : false}>
                        <Textarea
                          placeholder="Anything you want to say!"
                          variant={"outline"}
                          {...register("description")}
                        />
                        <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Flex>
                </Box>
              </Flex>

              {/* Right */}
              <Flex w={layoutStyles.containerWidth} pl={[0, 0, 0, 10]}>
                <Box>
                  {/* Contract Image */}
                  <Flex
                    py={4}
                    w={layoutStyles.containerWidth}
                    direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                  >
                    <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                      <Label>Contract Image</Label>
                    </Box>
                    <Box w="full">
                      <FormControl isInvalid={errors.profileImageUrl ? true : false}>
                        <Input
                          type="profileImageUrl"
                          placeholder="Profile URL (500 x 500px)"
                          {...register("profileImageUrl", {
                            required: "This is required",
                            pattern: { value: URL_REGEX, message: "Please enter a valid URL" },
                          })}
                        />
                        <FormErrorMessage>{errors.profileImageUrl && errors.profileImageUrl.message}</FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Flex>

                  {/* Contract Banner */}
                  <Flex
                    py={4}
                    w={layoutStyles.containerWidth}
                    direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                  >
                    <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                      <Label>Contract Banner</Label>
                    </Box>
                    <Box w="full">
                      <FormControl isInvalid={errors.bannerUrl ? true : false}>
                        <Input
                          type="bannerUrl"
                          placeholder="Banner URL (1400 x 220px)"
                          {...register("bannerUrl", {
                            pattern: { value: URL_REGEX, message: "Please enter a valid URL" },
                          })}
                        />
                        <FormErrorMessage>{errors.bannerUrl && errors.bannerUrl.message}</FormErrorMessage>
                      </FormControl>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Stack>
            <Stack alignItems={["center", "flex-start"]} direction={layoutStyles.containerDirection as StackDirection}>
              <Flex w={layoutStyles.containerWidth}>
                <Box>
                  {/* Visibility */}
                  <Flex
                    py={4}
                    w={layoutStyles.containerWidth}
                    h={layoutStyles.containerHeight}
                    direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                  >
                    <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                      <Label>Visibility</Label>
                    </Box>
                    <Box w="full" display="flex" alignItems="center">
                      <FormControl>
                        <Flex alignItems="center">
                          <Switch id="isVisible" style={{ marginRight: "5px" }} {...register("isVisible", {})} />
                          <Text fontSize="xs"> Determines if NFT displays in the Explore Page</Text>
                        </Flex>
                      </FormControl>
                    </Box>
                  </Flex>

                  {/* Credential */}
                  <Flex
                    py={4}
                    w={layoutStyles.containerWidth}
                    h={layoutStyles.containerHeight}
                    direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                  >
                    <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                      <Label>Credential</Label>
                    </Box>
                    <Box w="full" display="flex" alignItems="center">
                      <FormControl>
                        <Flex alignItems="center">
                          <Switch
                            id="isLinkedinCredential"
                            style={{ marginRight: "5px" }}
                            {...register("isLinkedinCredential", {})}
                          />
                          <Text fontSize="xs"> Determines if Users can 'Add to LinkedIn' on Profile Page</Text>
                        </Flex>
                      </FormControl>
                    </Box>
                  </Flex>
                </Box>
              </Flex>

              <Flex w={layoutStyles.containerWidth} pl={[0, 0, 0, 10]}>
                <Box>
                  {/* Contract Address */}
                  <Flex
                    py={4}
                    w={layoutStyles.containerWidth}
                    h={layoutStyles.containerHeight}
                    direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                  >
                    <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                      <Label>Contract Address</Label>
                    </Box>
                    <Box w="full" display="flex" alignItems="center">
                      <InputGroup>
                        <Input
                          type="text"
                          placeholder={contractInfo.contractAddress || ""}
                          variant={"filled"}
                          disabled
                        />
                        <InputRightElement
                          onClick={() => {
                            setCopyValue(contractInfo.contractAddress || "");
                            onCopy();
                          }}
                          cursor={"pointer"}
                        >
                          <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                            <Icon as={CopyIcon} />
                          </Tooltip>
                        </InputRightElement>
                      </InputGroup>
                    </Box>
                  </Flex>

                  {/* Contract Type */}
                  <Flex
                    py={4}
                    w={layoutStyles.containerWidth}
                    h={layoutStyles.containerHeight}
                    direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
                  >
                    <Box w={layoutStyles.labelWidth} display="flex" alignItems="center">
                      <Label>Contract Type</Label>
                    </Box>
                    <Box w="full" display="flex" alignItems="center">
                      <InputGroup>
                        <Input type="text" placeholder={contractInfo.contractType || ""} variant={"filled"} disabled />
                        <InputRightElement
                          onClick={() => {
                            setCopyValue(contractInfo.contractType || "");
                            onCopy();
                          }}
                          cursor={"pointer"}
                        >
                          <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                            <Icon as={CopyIcon} />
                          </Tooltip>
                        </InputRightElement>
                      </InputGroup>
                    </Box>
                  </Flex>
                </Box>
              </Flex>
            </Stack>
          </Box>
        </ModalBody>
        <ModalFooter justifyContent={"left"}>
          <HStack py={10} spacing={10}>
            <Button rounded={"lg"} onClick={() => onClose()} disabled={loading}>
              Cancel
            </Button>
            <Button
              rounded={"lg"}
              disabled={loading}
              isLoading={loading}
              loadingText={"Updating Contract"}
              onClick={() => onSubmit()}
            >
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
