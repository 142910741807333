import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Icon,
  Stack,
  Button,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { HiCheck } from "react-icons/hi";

interface CommonModalProps {
  isOpen: boolean;
  onClose: any;
  title: string;
  message: string;
  ctaTitle?: string | null;
  ctaAction?: any;
  showTitleBelowIcon?: boolean;
  secondCta?: boolean;
  secondCtaTitle?: string;
  secondCtaAction?: React.MouseEventHandler<HTMLButtonElement>;
}

export enum CommonModalType {
  ERROR = "Error",
  SUCCESS = "Success",
  CONFIRM = "Confirmation",
}

function CommonModal(props: CommonModalProps) {
  const {
    isOpen,
    onClose,
    title,
    message,
    ctaTitle,
    ctaAction,
    showTitleBelowIcon,
    secondCta,
    secondCtaTitle,
    secondCtaAction,
  } = props;
  const textColor = useColorModeValue("black.300", "white");

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          {showTitleBelowIcon ? <Box py={8}></Box> : <ModalHeader>{title}</ModalHeader>}
          <ModalCloseButton />
          <ModalBody mt={-2} textAlign={showTitleBelowIcon ? "center" : "left"}>
            {title === CommonModalType.ERROR && (
              <>
                <Flex align={"center"} justifyContent={"center"}>
                  <Stack bg="red.100" padding={5} borderRadius={"100px"} mb={6}>
                    <Icon as={AiOutlineExclamationCircle} w={"45px"} h={"45px"} color="red.400" />
                  </Stack>
                </Flex>
              </>
            )}
            {title === CommonModalType.SUCCESS && (
              <>
                <Flex align={"center"} justifyContent={"center"}>
                  <Stack bg="secondary.900" padding={5} borderRadius={"100px"} mb={6}>
                    <Icon as={HiCheck} w={"45px"} h={"45px"} color={"primary.800"} />
                  </Stack>
                </Flex>
              </>
            )}
            {title === CommonModalType.CONFIRM && (
              <>
                <Flex align={"center"} justifyContent={"center"}>
                  <Stack bg="secondary.900" padding={5} borderRadius={"100px"} mb={6}>
                    <Icon as={AiOutlineExclamationCircle} w={"45px"} h={"45px"} color={"primary.800"} />
                  </Stack>
                </Flex>
              </>
            )}
            {showTitleBelowIcon && (
              <Text textStyle={"h2"} px={6} pb={2}>
                {title}
              </Text>
            )}
            <Text textStyle={"paragraph"} color={textColor}>
              {message}
            </Text>
            <Flex justifyContent={"center"} alignItems={"center"} my={5}>
              <Button onClick={ctaAction || onClose} mr={5}>
                {ctaTitle || "Got it"}
              </Button>
              {secondCta && (
                <Button onClick={secondCtaAction || onClose} variant={"filled"}>
                  {secondCtaTitle || "Got it"}
                </Button>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CommonModal;
