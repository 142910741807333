import { gql } from "@apollo/client";

export const COLLECTIONS_QUERY = gql`
  {
    collections {
      _id
      contractAddress
      description
      bannerUrl
      title
      isDefaultCollection
      isVisible
      contractType
      owner {
        name
      }
    }
  }
`;

export const FETCH_COLLECTION_BY_CONTRACT_ADDRESS = gql`
  query ($contractAddress: String) {
    collection(contractAddress: $contractAddress) {
      _id
      contractAddress
      title
      description
      bannerUrl
      isVisible
      profileImageUrl
      isDefaultCollection
      isCustomApp
      callToActionButtonText
      callToActionButtonUrl
      claimType
      contractType
      claimButtonUrl
      claimConfig
      owner {
        _id
        name
        domain
        description
        logoUri
        accessCode
      }
    }
  }
`;

export const FETCH_ORGANIZATION_COLLECTION_BY_ID = gql`
  query ($id: ID!) {
    organization(id: $id) {
      _id
      name
      description
      title
      collections {
        _id
        contractAddress
        description
        bannerUrl
        title
        isDefaultCollection
        isVisible
        isLinkedinCredential
        contractType
        versionNumber
        profileImageUrl
        owner {
          name
        }
      }
    }
  }
`;

export const UPDATE_ORGANIZATION_MUTATION = gql`
  mutation updateOrganization(
    $id: ID!
    $name: String
    $description: String
    $title: String
    $introText: String
    $introTitle: String
    $videoUri: String
    $logoUri: String
  ) {
    updateOrganization(
      id: $id
      name: $name
      description: $description
      title: $title
      introText: $introText
      introTitle: $introTitle
      videoUri: $videoUri
      logoUri: $logoUri
    ) {
      _id
      name
      description
      title
      introText
      introTitle
      videoUri
      logoUri
    }
  }
`;

export const UPDATE_COLLECTION_MUTATION = gql`
  mutation updateCollection(
    $id: ID!
    $contractAddress: String
    $description: String
    $bannerUrl: String
    $title: String
    $isDefaultCollection: Boolean
    $isVisible: Boolean
    $contractType: String
    $profileImageUrl: String
    $isLinkedinCredential: Boolean
  ) {
    updateCollection(
      id: $id
      contractAddress: $contractAddress
      description: $description
      bannerUrl: $bannerUrl
      title: $title
      isDefaultCollection: $isDefaultCollection
      isVisible: $isVisible
      contractType: $contractType
      profileImageUrl: $profileImageUrl
      isLinkedinCredential: $isLinkedinCredential
    ) {
      _id
      contractAddress
      description
      bannerUrl
      title
      isDefaultCollection
      isVisible
      contractType
      profileImageUrl
      isLinkedinCredential
    }
  }
`;
