import { useContext, useEffect, useState } from "react";
import { Buffer } from "buffer";
import { useMutation } from "@apollo/client";
import { INITIATE_USER_AUTH_FLOW, LOGIN_USER, LOGIN_USER_MAGIC } from "../../../queries/auth";
import { useToast } from "@chakra-ui/react";
import {
  ConnectWalletContext,
  ConnectWalletContextType,
  LoginWithTypeEnum,
} from "../../../context/connectWalletContext";
import { useNavigate } from "react-router-dom";
import { magic } from "../../../services/Magic";
import { ethers } from "ethers";
import { LoginTypeEnum } from "./ConnectWalletModal";
import { useSDK } from "@thirdweb-dev/react";
import LogRocket from "logrocket";

// Login Handle With MetaMask
const useConnectWithMetaMask = () => {
  const [address, setAddress] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const sdk = useSDK();

  const { updateAddress, updateUser } = useContext(ConnectWalletContext) as ConnectWalletContextType;

  const ethereum = window.ethereum;

  const [
    initiateUserAuthFlow,
    { data: initiateUserAuthFlowData, loading: initiateUserAuthFlowLoading, error: initiateUserAuthFlowError },
  ] = useMutation(INITIATE_USER_AUTH_FLOW);

  const [loginUser, { data: loginUserData, loading: loginUserLoading, error: loginUserError }] =
    useMutation(LOGIN_USER);

  useEffect(() => {
    if (initiateUserAuthFlowData && initiateUserAuthFlowData?.initiateUserAuthFlow?.nonce) {
      handleSign();
    }
  }, [initiateUserAuthFlowData]);

  useEffect(() => {
    if (loginUserData && loginUserData?.loginUser?.jwt && loginUserData?.loginUser?.user) {
      localStorage.setItem("jwt", loginUserData.loginUser.jwt);
      localStorage.setItem("walletAddress", address);
      const tempUser = {
        _id: loginUserData.loginUser.user?._id || "",
        role: loginUserData.loginUser.user?.role || "",
        organization: {
          _id: loginUserData.loginUser.user?.organization?._id || null,
        },
        loginWith: LoginWithTypeEnum.METAMASK,
      };
      localStorage.setItem("user", JSON.stringify(tempUser));
      updateAddress(address);
      updateUser(tempUser);
    }
  }, [loginUserData]);

  useEffect(() => {
    if (initiateUserAuthFlowError || loginUserError) {
      toast({
        title: "Some things went wrong while connect with wallet. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [initiateUserAuthFlowError, loginUserError]);

  async function handleGetaddress() {
    try {
      // A Web3Provider wraps a standard Web3 provider, which is
      // what MetaMask injects as window.ethereum into each page
      const provider = new ethers.providers.Web3Provider(window.ethereum as any);

      // MetaMask requires requesting permission to connect users accounts
      const getAddressResponse = await provider.send("eth_requestAccounts", []);

      // The MetaMask plugin also allows signing transactions to
      // send ether and pay to change state within the blockchain.
      // For this, you need the account signer...
      const signer = provider.getSigner();

      // const getAddressResponse = await ethereum?.request({
      //   method: "eth_requestAccounts",
      // });
      if (getAddressResponse.length > 0) {
        await initiateUserAuthFlow({
          variables: {
            walletAddress: getAddressResponse[0],
          },
        });
        setAddress(getAddressResponse[0]);
        sdk?.updateSignerOrProvider(signer);
      } else {
        throw new Error("Wallet Address Not Found");
      }
    } catch (error) {
      console.log("handleGetaddress:error:", error);
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          walletType: "MetaMask Login",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "connectWalletStrategy:handleGetaddress",
        },
      });
      throw Promise.reject(error);
    }
  }

  async function handleSign() {
    try {
      const nonce = initiateUserAuthFlowData.initiateUserAuthFlow.nonce;
      const msg = `0x${Buffer.from(`${nonce}`, "utf8").toString("hex")}`;
      const sign = await ethereum?.request({
        method: "personal_sign",
        params: [msg, address],
      });
      await loginUser({
        variables: {
          walletAddress: address,
          signature: sign,
        },
      });
    } catch (error) {
      throw Promise.reject(error);
    }
  }

  const connectToMetaMask = async () => {
    try {
      await handleGetaddress();
    } catch (error) {
      console.log("handleGetaddress:error:", error);
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          walletType: "MetaMask Login",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "connectWalletStrategy:connectToMetaMask",
        },
      });
      throw Promise.reject(error);
    }
  };
  return {
    connectToMetaMask,
  };
};

// Login Handle With Magic Link
const useConnectWthMagicLink = () => {
  const [address, setAddress] = useState("");
  const toast = useToast();
  const navigate = useNavigate();

  const [loginUserMagic, { data: loginUserData, error: loginUserMagicError }] = useMutation(LOGIN_USER_MAGIC);
  const { updateAddress, updateUser } = useContext(ConnectWalletContext) as ConnectWalletContextType;
  const sdk = useSDK();

  useEffect(() => {
    if (loginUserData && loginUserData?.loginUserMagic?.jwt && loginUserData?.loginUserMagic?.user) {
      localStorage.setItem("jwt", loginUserData.loginUserMagic.jwt);
      localStorage.setItem("walletAddress", address);
      const tempUser = {
        _id: loginUserData.loginUserMagic.user?._id || "",
        role: loginUserData.loginUserMagic.user?.role || "",
        organization: {
          _id: loginUserData.loginUserMagic.user?.organization?._id || null,
        },
        loginWith: LoginWithTypeEnum.MAGIC_LINK,
      };
      localStorage.setItem("user", JSON.stringify(tempUser));
      updateAddress(address);
      updateUser(tempUser);
    }
  }, [loginUserData]);

  useEffect(() => {
    if (loginUserMagicError) {
      toast({
        title: "Something went wrong while connect app with magic. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [loginUserMagicError]);

  const getMagicLinkToken = async (email: string, accessCode: string) => {
    try {
      let didToken = await magic.auth.loginWithMagicLink({ email });
      if (magic.user && didToken) {
        const provider = new ethers.providers.Web3Provider(magic.rpcProvider as any);
        const signer = provider.getSigner();
        const userAddress = await signer.getAddress();
        setAddress(userAddress);
        sdk?.updateSignerOrProvider(signer);
        return await loginUserMagic({
          variables: {
            didToken: didToken,
            accessCode: accessCode,
          },
        });
      } else {
        return Promise.reject(
          new Error("Magic not return a user. Please check you email address and try again. Thanks")
        );
      }
    } catch (error: any) {
      console.log("getMagicLinkToken:error", error);
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          walletType: "Magic Login",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "connectWalletStrategy:getMagicLinkToken",
        },
      });
      return Promise.reject(error);
    }
  };

  const connectWithMagic = async (email: string, accessCode: string) => {
    try {
      return await getMagicLinkToken(email, accessCode);
    } catch (error) {
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          walletType: "Magic Login",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "connectWalletStrategy:connectWithMagic",
        },
      });
      throw Promise.reject(error);
    }
  };

  return { connectWithMagic };
};

function useConnectWalletStrategy() {
  const { connectToMetaMask } = useConnectWithMetaMask();
  const { connectWithMagic } = useConnectWthMagicLink();
  const connectWallet = async (connectType: LoginTypeEnum, email?: string, accessCode?: string) => {
    try {
      let response = undefined;
      if (connectType === LoginTypeEnum.METAMASK) {
        response = await connectToMetaMask();
      } else if (connectType === LoginTypeEnum.MAGIC_LINK) {
        response = await connectWithMagic(email || "", accessCode || "");
      }
      return Promise.resolve({
        success: true,
        data: response as any,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };
  return { connectWallet };
}

export default useConnectWalletStrategy;
