import { Grid, Tab, TabList, TabPanel, Tabs, TabPanels } from "@chakra-ui/react";
import GeneralSettingSubsection from "./GeneralSettingSubsection";
import PlanNBillingSettingSubsection from "./PlanNBillingSettingSubsection";

function SettingSection(props: any) {
  return (
    <Grid>
      <Tabs>
        <TabList>
          <Tab>General</Tab>
          <Tab>Plan & Billing</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <GeneralSettingSubsection data={props.data} />
          </TabPanel>
          <TabPanel>
            <PlanNBillingSettingSubsection data={props.data} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Grid>
  );
}

export default SettingSection;
