import { useState } from "react";
import { appConfig } from "../util/Constants";
import axios from "axios";
import { makeQueryStringForArray } from "../util/helper";

function useFetchUserNFT() {
  const [nfts, setNfts] = useState<[] | null>(null);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUserNFTs = (owner: string, contractAddress: string[]) => {
    setLoading(true);
    if (nfts && nfts?.length > 0) {
      setNfts([]);
    }
    const baseURL = `${appConfig.ALCHEMY_POLYGON_URL}/getNFTs/`;
    const config = {
      method: "get",
      url: `${baseURL}?${makeQueryStringForArray(contractAddress || [], "contractAddresses")}&owner=${owner}`,
      headers: {},
    };

    axios(config)
      .then((response) => {
        setNfts(response.data?.ownedNfts);
        setTotalCount(response.data?.totalCount);
        setLoading(false);
        setError(null);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  return { nfts, totalCount, loading, error, fetchUserNFTs };
}

export default useFetchUserNFT;
