import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  useColorModeValue,
  Switch,
  useDisclosure,
  Tooltip,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { useQuery } from "@apollo/client";
import { AiOutlineSetting } from "react-icons/ai";
import { TiArrowSortedDown } from "react-icons/ti";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_MODAL_DATA, DEV_ENV, FALLBACK_URL_IMAGE, supportEmail } from "../../../util/Constants";
import { ContractActionType } from "../../Dashboard";
import { ContractAction } from "../../Dashboard";
import { FETCH_ORGANIZATION_COLLECTION_BY_ID } from "../../../queries/collection";
import useAdminFunctions from "../../../hooks/Dashboard/AdminFunctions";
import ConfirmContractActionModal from "../Modal/ConfirmContractActionModal";
import { getCleanErrorMessage } from "../../../util/helper";
import SuccessModal from "../Modal/SuccessModal";
import Loader from "../Loader";
import ContractSettingModal from "../Modal/ContractSettingModal";
import { cloneDeep } from "@apollo/client/utilities";
import NotFound from "../NotFound";
import { CsvDownloadButton } from "../../Dashboard/CsvDownloadButton";

export interface ConTableRowType {
  _id: string;
  bannerUrl: string;
  contractAddress: string;
  contractType: string;
  description: string;
  isDefaultCollection: boolean;
  isRevealed: boolean;
  isVisible: boolean;
  title: string;
  versionNumber: number;
  profileImageUrl: string;
  isLinkedinCredential: boolean;
}

interface ContractTableProps {
  organizationId: any;
}

type SortBy = "COLLECTION" | "STATUS" | "REVEALED";

const Columns = [
  {
    label: "",
    colSpan: 2,
  },
  {
    label: "Collection Name",
    sort: "COLLECTION" as SortBy,
    colSpan: 3,
  },

  {
    label: "Status",
    sort: "STATUS" as SortBy,
    colSpan: 1,
  },
  {
    label: "Revealed",
    sort: "REVEALED" as SortBy,
    colSpan: 1,
  },
  {
    label: "", // For setting icon
    colSpan: 1,
  },
];

interface RevealedRowProps {
  contractAddress: string;
  versionNumber: number;
  updateRevealInData: (contractAddress: string, isRevealed: boolean) => void;
}

const RevealedRow = (props: RevealedRowProps) => {
  const { contractAddress, versionNumber, updateRevealInData } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { contract, isRevealed, getIsRevealed, setIsRevealedOnContract } = useAdminFunctions({
    contractAddress: contractAddress,
  });
  const {
    isOpen: isOpenConfirmActionModal,
    onOpen: onOpenConfirmActionModal,
    onClose: onCloseConfirmActionModal,
  } = useDisclosure();

  const { isOpen: isOpenSuccessModal, onOpen: onOpenSuccessModal, onClose: onCloseSuccessModal } = useDisclosure();

  useEffect(() => {
    if (contractAddress && contract) {
      getIsRevealed();
    }
  }, [contractAddress, contract]);

  useMemo(() => {
    updateRevealInData(contractAddress, isRevealed);
  }, [isRevealed]);

  const onClickConfirm = async () => {
    try {
      setError("");
      setLoading(true);
      await setIsRevealedOnContract();
      onCloseConfirmActionModal();
      onOpenSuccessModal();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const cleanErrorMessage = getCleanErrorMessage(error?.reason || error?.message);
      setError(cleanErrorMessage);
      // console.log(`onClickSave:${actionType}:error`, error);
    }
  };

  return (
    <>
      <Switch
        justifySelf={"center"}
        isChecked={isRevealed}
        onChange={() => {
          if (versionNumber >= 1.1) {
            onOpenConfirmActionModal();
          }
        }}
      />

      <ConfirmContractActionModal
        actionType={ContractActionType.REVEALED}
        actionOption={isRevealed ? ContractAction.DISABLE : ContractAction.ENABLE}
        isOpen={isOpenConfirmActionModal}
        onClose={onCloseConfirmActionModal}
        loading={loading}
        errorMessage={error}
        onClickConfirm={onClickConfirm}
      />

      <SuccessModal
        isOpen={isOpenSuccessModal}
        onClose={onCloseSuccessModal}
        ctaAction={() => {
          onCloseSuccessModal();
        }}
        title={DASHBOARD_MODAL_DATA[`${!isRevealed ? "hide_NFTS" : "reveal_NFTS"}`].modalSuccessHeader}
        description={DASHBOARD_MODAL_DATA[`${!isRevealed ? "hide_NFTS" : "reveal_NFTS"}`].modalSuccessDesc}
        ctaTitle={DASHBOARD_MODAL_DATA[`${!isRevealed ? "hide_NFTS" : "reveal_NFTS"}`].modalSuccessBtnTitle}
        subDescription={""}
      />
    </>
  );
};

export default function ContractsTable(props: ContractTableProps) {
  const { organizationId } = props;

  const navigate = useNavigate();
  const rowBorderColor = useColorModeValue("gray.200", "gray.500");
  const rowValueColor = useColorModeValue("black.300", "white");

  const { data, loading } = useQuery(FETCH_ORGANIZATION_COLLECTION_BY_ID, {
    variables: {
      id: organizationId || "",
    },
  });
  const [filterOptions, setFilterOptions] = useState<ConTableRowType[]>([]);
  const [contractOnSetting, setContractOnSetting] = useState<ConTableRowType>();
  const [isSettingModalOpen, setSettingModalOpen] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy>("STATUS");
  const [isHoverEmail, setIsHoverEmail] = useState<boolean>(false);

  // Store reveal value back to filterOptions so that the field is sortable
  const updateRevealInData = (contractAddress: string, isRevealed: boolean) => {
    const updatedFilterOptions = cloneDeep(filterOptions);
    const index = updatedFilterOptions.findIndex((option) => option.contractAddress === contractAddress);
    if (index) {
      updatedFilterOptions[index].isRevealed = isRevealed;
      setFilterOptions(updatedFilterOptions);
    }
  };

  useEffect(() => {
    //added a filter to remove collections where isVisible is false
    let orgContractAddressArray: any[] = [];
    if (data?.organization?.collections) {
      orgContractAddressArray = data?.organization.collections.map((collection: any) => {
        return { ...collection, isRevealed: false };
      });
    }
    setFilterOptions(orgContractAddressArray);
  }, [data]);

  if (loading) {
    return <Loader />;
  }

  function NoContractMsg() {
    return (
      <>
        No Contracts created. Contact the minted support team at{" "}
        <a
          href={`mailto:${supportEmail}?subject=Request to setup my org NFT Contracts!`}
          target="_blank"
          style={{ color: isHoverEmail ? "#252FC1" : "#3e48da", textDecoration: isHoverEmail ? "none" : "underline" }}
          onMouseEnter={() => {
            setIsHoverEmail(true);
          }}
          onMouseLeave={() => {
            setIsHoverEmail(false);
          }}
        >
          {supportEmail}
        </a>{" "}
        to get setup. This usually takes 24 hours and once your contracts are deployed, you can mint and distribute your
        NFTs.
      </>
    );
  }

  return (
    <>
      <Box overflow={"auto"}>
        <Box display="flex" justifyContent={"right"}>
          {DEV_ENV && <Button onClick={() => navigate("/createContract")}>+ Create Contract</Button>}
          <CsvDownloadButton
            fileName={"Contracts"}
            data={filterOptions.map((item: any) => {
              return {
                Title: item.title || "",
                Statue: item.isVisible ? "Active" : "Archived",
                Revealed: !!item.isRevealed ? "True" : "False",
              };
            })}
          />
        </Box>
        <Grid
          templateRows={"repeat(1, 1fr)"}
          templateColumns={"repeat(10, 1fr)"}
          bg={useColorModeValue("black.100", "black.400")}
          py={4}
          rounded={"lg"}
          minW={"1050px"}
          overflow={"hidden"}
          alignItems={"center"}
          textAlign={{ base: "center", md: "left" }}
        >
          {Columns.map((item, index) => (
            <GridItem key={item.label + index} colSpan={item.colSpan}>
              <Flex>
                <Box display="flex" alignItems="center">
                  <Text textStyle={"paragraph"} color={rowValueColor}>
                    {item.label}
                  </Text>
                  {item.sort && (
                    <Tooltip label={`Sort by ${item.label}`}>
                      <IconButton
                        variant="ghost"
                        fontSize="20px"
                        h={"max-content"}
                        icon={<TiArrowSortedDown />}
                        aria-label={""}
                        onClick={() => setSortBy(item.sort)}
                      />
                    </Tooltip>
                  )}
                </Box>
              </Flex>
            </GridItem>
          ))}
        </Grid>
        {filterOptions?.length > 0 &&
          filterOptions
            .sort((a, b) => {
              if (sortBy === "COLLECTION") return b.title > a.title ? -1 : 0;
              else if (sortBy === "REVEALED") return Number(b.isRevealed) - Number(a.isRevealed);
              else return Number(b.isVisible) - Number(a.isVisible);
            })
            .map((item, index) => (
              <Grid
                templateRows={"repeat(1, 1fr)"}
                templateColumns={"repeat(10, 1fr)"}
                rounded={"lg"}
                border={"1px"}
                borderColor={rowBorderColor}
                borderStyle={"solid"}
                py={4}
                mt={3}
                overflow={"hidden"}
                alignItems={"center"}
                textAlign={{ base: "center", md: "left" }}
                minW={"1050px"}
                key={"contract_table" + item.contractAddress + "_" + index}
              >
                <GridItem colSpan={2}>
                  <Flex
                    justifyContent={"center"}
                    onClick={() => {
                      navigate(`/collection/${item.contractAddress}`);
                    }}
                  >
                    <Image
                      rounded="sm"
                      width="230px"
                      height="90px"
                      fit="cover"
                      // ml={'5px'}
                      src={item.bannerUrl}
                      fallbackSrc={FALLBACK_URL_IMAGE}
                      alt={item.title + "banner Url"}
                      cursor={"pointer"}
                    />
                  </Flex>
                </GridItem>

                <GridItem colSpan={3}>
                  <Text textStyle={"paragraph"} color={rowValueColor} textAlign="left" paddingLeft="5px">
                    {item.title}
                  </Text>
                </GridItem>

                <GridItem colSpan={1}>
                  {item.isVisible ? (
                    <Text textStyle={"paragraph"} color={rowValueColor} noOfLines={1}>
                      Active
                    </Text>
                  ) : (
                    <Text textStyle={"paragraph"} fontStyle="italic" color={rowValueColor} noOfLines={1}>
                      Archived
                    </Text>
                  )}
                </GridItem>

                <GridItem colSpan={1}>
                  <RevealedRow
                    contractAddress={item.contractAddress}
                    versionNumber={item?.versionNumber || 0}
                    updateRevealInData={updateRevealInData}
                  />
                </GridItem>

                <GridItem colSpan={1}>
                  <Tooltip label="Contract Setting">
                    <IconButton
                      variant="ghost"
                      fontSize="22px"
                      icon={<AiOutlineSetting />}
                      aria-label={""}
                      onClick={() => {
                        setContractOnSetting(item);
                        setSettingModalOpen(true);
                      }}
                    />
                  </Tooltip>
                </GridItem>
              </Grid>
            ))}
        {!loading && !(data?.organization?.collections > 0) && <NotFound message={<NoContractMsg />} />}
      </Box>
      <ContractSettingModal
        isOpen={isSettingModalOpen}
        onClose={() => setSettingModalOpen(false)}
        contractInfo={contractOnSetting || ({} as ConTableRowType)}
      />
    </>
  );
}
