import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { useQuery } from "@apollo/client";
import { CopyIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
  useClipboard,
  useColorModeValue,
  VStack,
  Checkbox,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@chakra-ui/react";
import { AiFillFacebook } from "react-icons/ai";
import { BsFilter, BsShareFill } from "react-icons/bs";
import { FiTwitter } from "react-icons/fi";
import { MdVerified } from "react-icons/md";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link as RouterLink, useParams } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { useNFTsFiltered } from "../../hooks/Dashboard/useNFTsFiltered";
import { useCollectionNFTs } from "../../hooks/Dashboard/useCollectionNFTs";
import { FETCH_COLLECTION_BY_CONTRACT_ADDRESS } from "../../queries/collection";
import { IPFS_IMAGE_BASE_URL, SITE_URL } from "../../util/Constants";
import HelmetMetaData from "../common/HelmetMetaData";
import Loader from "../common/Loader";
import MasterBox from "../Layout/MasterBox";
import ProfileCard from "./ProfileCard";
import { getUniqueListBy } from "../../util/helper";

function CollectionProfile() {
  const backgroundBanner = useColorModeValue("gray.100", "gray.800");
  const backgroundBannerBox = useColorModeValue("gray.100", "gray.600");
  let { contractAddress } = useParams();
  const { data, loading, error } = useQuery(FETCH_COLLECTION_BY_CONTRACT_ADDRESS, {
    variables: {
      contractAddress,
    },
  });

  const getFiltersAndOpen = async () => {
    await getFilters();
    onOpen();
  };

  const applyAndClose = async () => {
    fetchingNFTsRef.current = null;
    getFilteredNfts(checkedAttributes);
    onClose();
  };

  const [checkedAttributes, setCheckedAttributes] = useState<any[]>([]);
  const [checked, setChecked] = useState<any[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fetchingNFTsRef: any = useRef(null);
  const btnRef = useRef(null);

  const {
    collectionNFTs,
    error: nftError,
    fetchNextPage,
    hasNextPage,
    status,
  } = useCollectionNFTs({ contractAddress: contractAddress || "" });

  const { filteredNfts, nftFilters, getFilters, getFilteredNfts, filtersLoading } = useNFTsFiltered([
    contractAddress || "",
  ]);

  const { hasCopied, onCopy } = useClipboard(`${SITE_URL}/collection/${contractAddress}`);

  let checkIndex = 0;

  if (loading) {
    return (
      <MasterBox height={"70vh"}>
        <Loader />
      </MasterBox>
    );
  }
  return (
    <Fragment>
      {data && data.collection && (
        <>
          <HelmetMetaData
            title={data?.collection?.title || ""}
            description={data?.collection?.description || ""}
            image={data.collection?.bannerUrl || ""}
            quote={data?.collection?.title || ""}
          />
          <Flex w={"full"} bg={backgroundBanner} backgroundImage={data.collection?.bannerUrl} backgroundSize={"cover"}>
            <Box bg={backgroundBannerBox} pb={["25%", "15%"]} />
          </Flex>
          <MasterBox id="master_box" px={[0, 2, 5, 20]}>
            <Flex id="flex_id" justify={"flex-start"} direction={["column", "column", "column", "row"]} px={[2, 0]}>
              <Avatar
                id="avatar_id"
                // size={["md", "3xl"]}
                width={[100, 120, 120, 140, 180, 200]}
                height={[100, 120, 120, 140, 180, 200]}
                src={data.collection?.profileImageUrl || ""}
                // alt={"User"}
                css={{
                  border: "5px solid white",
                }}
                mt={[-10, -16, -16, -16, -20, -28]}
                mr={4}
              />
              <VStack id="vstack" spacing={0} w={"100%"}>
                <Flex justifyContent={"space-between"} alignItems={"center"} w={"full"}>
                  <VStack alignItems={"flex-start"} spacing={0}>
                    <Text textStyle={"h1"}>{data.collection.title}</Text>
                    <HStack spacing={1}>
                      <Text textStyle={"label"} color={"black.200"} p={0} m={0}>
                        Created by
                      </Text>
                      <Text textStyle={"label"} fontSize={14} p={0} mr={1}>
                        {data.collection?.owner?.name}
                      </Text>
                      {data.collection?.owner?.name && <Icon as={MdVerified} color="blue.500" />}
                    </HStack>
                  </VStack>
                  <Menu>
                    <MenuButton as={Button} variant={"unstyled"} rounded={"full"} size={"lg"}>
                      <BsShareFill size={20} style={{ width: "100%" }} />
                    </MenuButton>
                    <MenuList zIndex={2}>
                      <MenuItem as={Box}>
                        <TwitterShareButton
                          url={SITE_URL}
                          title={data?.collection?.title || ""}
                          hashtags={["minted"]}
                          style={{
                            width: "100%",
                          }}
                        >
                          <HStack width={"100%"}>
                            <FiTwitter />
                            <Text ml={2}>Twitter</Text>
                          </HStack>
                        </TwitterShareButton>
                      </MenuItem>

                      <MenuItem as={Box}>
                        <FacebookShareButton
                          url={SITE_URL}
                          quote={data?.collection?.title || ""}
                          hashtag="#minted"
                          style={{
                            width: "100%",
                          }}
                        >
                          <HStack width={"100%"}>
                            <AiFillFacebook />
                            <Text ml={2}>Facebook</Text>
                          </HStack>
                        </FacebookShareButton>
                      </MenuItem>
                      <MenuItem icon={<CopyIcon style={{ fontSize: 14 }} />} onClick={onCopy}>
                        {hasCopied ? "Copied Successfully" : "Copy Link"}
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
                <Stack
                  spacing={[10, 20, 15, 32]}
                  pt={2}
                  w={"100%"}
                  justifyContent={["center", "space-between"]}
                  direction={["column", "row"]}
                >
                  <Text textStyle={"label"} color={"black.200"}>
                    {data.collection?.description}
                  </Text>
                  {data.collection?.isCustomApp && (
                    <RouterLink to={`/quiz`}>
                      <Button size={"md"} px={16}>
                        {data.collection?.callToActionButtonText}
                      </Button>
                    </RouterLink>
                  )}
                </Stack>
              </VStack>
            </Flex>
            <HStack alignItems={"flex-start"} width={"100%"} pt={2}>
              <Button
                ref={btnRef}
                onClick={getFiltersAndOpen}
                rounded={"full"}
                variant={"outline"}
                leftIcon={<BsFilter size={30} width={"100%"} />}
                iconSpacing={2}
              >
                Filters
              </Button>
              <Modal onClose={onClose} finalFocusRef={btnRef} isOpen={isOpen} scrollBehavior={"inside"}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Filters</ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    {filtersLoading ? (
                      <Box height={"70vh"}>
                        <Loader loadingText="Loading NFT filters" />
                      </Box>
                    ) : (
                      [...nftFilters.keys()].map((key: any) => {
                        return (
                          <div key={key}>
                            <Text key={`${key}_text`}>
                              <strong>{key}</strong>
                            </Text>
                            <ul key={`${key}_list`} style={{ columnCount: 2 }}>
                              {Array.from(nftFilters?.get(key) || new Set<string>()).map((attr: string) => {
                                if (attr !== "") {
                                  let index = checkIndex;
                                  let checklist = [...checked];
                                  checkIndex++;
                                  return (
                                    <li key={`${key}.${attr}`}>
                                      {/* persist attributes per checkbox */}
                                      <Checkbox
                                        id={`${index}`}
                                        mr={2}
                                        isChecked={checked[index]}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setCheckedAttributes(
                                              checkedAttributes.concat({ trait_type: key, value: attr })
                                            );
                                          } else {
                                            setCheckedAttributes(
                                              checkedAttributes.filter((activeAttr) => {
                                                return activeAttr.trait_type !== key || activeAttr.value !== attr;
                                              })
                                            );
                                          }
                                          checklist[index] = e.target.checked;
                                          setChecked(checklist);
                                        }}
                                      >
                                        {attr}
                                      </Checkbox>
                                    </li>
                                  );
                                }
                                return null;
                              })}
                            </ul>
                          </div>
                        );
                      })
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button onClick={applyAndClose}>Apply</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </HStack>
            {status === "loading" && (
              <Box height={"70vh"}>
                <Loader loadingText="Loading NFTs" />
              </Box>
            )}

            {!["loading", "error"].includes(status) && checkedAttributes.length === 0 && !isOpen ? (
              <InfiniteScroll
                dataLength={collectionNFTs ? collectionNFTs.nfts.length : 0}
                next={() => fetchNextPage()}
                hasMore={!!hasNextPage}
                loader={<Loader loadingText="Loading NFTs" />}
              >
                <SimpleGrid columns={[2, 2, 3, 3, 4, 4]} spacing={[3, 5]} my={4}>
                  {collectionNFTs &&
                    getUniqueListBy(collectionNFTs.nfts, "id.tokenId").map((item: any, index: any) => {
                      const image = item?.metadata?.image
                        ? item.metadata.image.replace("ipfs://", IPFS_IMAGE_BASE_URL)
                        : "";
                      const nftCopy = {
                        ...item,
                        metadata: {
                          ...item.metadata,
                          id: item.id.tokenId,
                          image: image,
                        },
                        contractAddress: item.contract?.address,
                      };
                      return (
                        <Fragment key={`${item.metadata.id}_collection_profile_${index}`}>
                          <ProfileCard nft={nftCopy} />
                        </Fragment>
                      );
                    })}
                </SimpleGrid>
              </InfiniteScroll>
            ) : filteredNfts?.length === 0 && !isOpen ? (
              <Box height={"70vh"} pt={2}>
                <Text>No matching NFTs found</Text>
              </Box>
            ) : (
              <SimpleGrid columns={[2, 2, 3, 3, 4, 4]} spacing={[3, 5]} my={4}>
                {filteredNfts &&
                  getUniqueListBy(filteredNfts, "id.tokenId").map((item: any, index: any) => {
                    const image = item?.metadata?.image
                      ? item.metadata.image.replace("ipfs://", IPFS_IMAGE_BASE_URL)
                      : "";
                    const nftCopy = {
                      ...item,
                      metadata: {
                        ...item.metadata,
                        id: item.id.tokenId,
                        image: image,
                      },
                      contractAddress: item.contract?.address,
                    };
                    return (
                      <Fragment key={`${item.metadata.id}_collection_profile_${index}`}>
                        <ProfileCard nft={nftCopy} />
                      </Fragment>
                    );
                  })}
              </SimpleGrid>
            )}
          </MasterBox>
        </>
      )}
      <Flex py={10}></Flex>
    </Fragment>
  );
}

export default CollectionProfile;
