import { Box, Text, Button, RadioGroup, Stack, Radio, Spacer } from "@chakra-ui/react";
import React from "react";
import { USAGE_TYPE } from "../JoinOrganization";

function Usage(props: { usage: USAGE_TYPE | undefined; setUsage: any; nextStep: any }) {
  const { usage, setUsage, nextStep } = props;
  const radioBtnColor = "black";
  return (
    <Box>
      <Text textStyle={"h1"}>How are you planning to use minted?</Text>
      <Spacer h={8} />
      <RadioGroup onChange={(selection) => setUsage(selection as USAGE_TYPE)} value={usage}>
        <Stack spacing={8}>
          <Radio color={radioBtnColor} value={USAGE_TYPE.WORK}>
            For Work
          </Radio>
          <Radio color={radioBtnColor} value={USAGE_TYPE.EDUCATION}>
            For Education
          </Radio>
          <Radio color={radioBtnColor} value={USAGE_TYPE.PERSONAL}>
            For Personal Project
          </Radio>
        </Stack>
      </RadioGroup>
      <Spacer h={10} />
      <Button onClick={() => nextStep()} disabled={!usage}>
        Continue
      </Button>
    </Box>
  );
}

export default Usage;
