import axios from "axios";
import { useState } from "react";
import { appConfig } from "../../util/Constants";
import retry from 'async-retry';

const baseURL = `${appConfig.ALCHEMY_POLYGON_URL}/getNFTsForCollection?withMetadata=true&limit=10`;

const fetchNFTs = async (contractAddresses: string[]) => {
  const result = await retry(
    async () => {
      let nfts: any[] = [];
      const baseURL = `${appConfig.ALCHEMY_POLYGON_URL}/getNFTsForCollection`;
      for (let contractAddress of contractAddresses) {
        let nextToken = undefined;
        let callNum = 0;
        while (nextToken || callNum === 0) {
          const config = {
            method: "get",
            url: nextToken
              ? `${baseURL}?contractAddress=${contractAddress}&withMetadata=true&startToken=${nextToken}`
              : `${baseURL}?contractAddress=${contractAddress}&withMetadata=true`,
            headers: {},
          };
          const res: any = await axios(config);
          if (res.data?.nfts && Array.isArray(res.data?.nfts)) nfts.push(...res.data.nfts);
          nextToken = res.data?.nextToken;
          callNum++;
        }
      }
      return nfts;
    },
    {
      retries: 5,
    }
  );

  return result;
};

export const useNFTsFiltered = (contractAddresses: string[]) => {
  const [nfts, setNfts] = useState<any[] | null>(null);
  const [filteredNfts, setFilteredNfts] = useState<any[] | null>(null);
  const [nftFilters, setNftFilters] = useState<Map<string, Set<string>>>(new Map<string, Set<string>>());
  const [filtersLoading, setFiltersLoading] = useState<boolean>(false);

  const getFilters = async () => {
    const filters = new Map<string, Set<string>>();
    setFiltersLoading(true);
    fetchNFTs(contractAddresses)
      .then((data) => {
        if (data) {
          setNfts(data);
          data.forEach((item: any) => {
            if (item.metadata?.attributes) {
              item.metadata?.attributes.forEach((attr: any) => {
                if (attr.trait_type) {
                  let trait_type: string = attr.trait_type;
                  let value: string = attr.value;
                  trait_type = trait_type.charAt(0).toUpperCase() + trait_type.slice(1);
                  value = value.charAt(0).toUpperCase() + value.slice(1);
                  if (filters.get(attr.trait_type)) {
                    filters.get(trait_type)?.add(value);
                  } else {
                    filters.set(trait_type, new Set<any>([value]));
                  }
                }
              });
            }
          });
          setNftFilters(filters);
        }
      })
      .finally(() => {
        setFiltersLoading(false);
      });
  };

  const getFilteredNfts = async (activeAttributes: any[]) => {
    let filteredNftsFromAttr = [];
    if (nfts) {
      filteredNftsFromAttr = nfts?.filter((item: any) => {
        let hits = 0;
        activeAttributes?.forEach((checkedAttr: any) => {
          return item.metadata?.attributes.forEach((attr: any) => {
            if (
              attr.trait_type.toLowerCase() === checkedAttr.trait_type.toLowerCase() &&
              attr.value.toLowerCase() === checkedAttr.value.toLowerCase()
            ) {
              hits++;
            }
          });
        });
        return activeAttributes?.length === hits;
      });
    }
    setFilteredNfts(filteredNftsFromAttr);
  };

  return { getFilters, filteredNfts, getFilteredNfts, nftFilters, filtersLoading };
};
