import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  SystemProps,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
  useToast,
  useClipboard,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DEFAULT_INTRO_MESSAGES, URL_REGEX, JOIN_CODE_URL_PREFIX } from "../../../util/Constants";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { UPDATE_ORGANIZATION_MUTATION } from "../../../queries/collection";
import { CopyIcon } from "@chakra-ui/icons";

type FormValues = {
  orgName: string;
  welcomeMessage: string;
  subtitle: string;
  videoEmbed: string;
  logoUrl: string;
  exploreTitle: string;
  exploreSubtitle: string;
  accessCode: string;
};

const errorMessage = (message: string) => {
  return (
    <Box>
      <Text textStyle={"paragraphMedium"} color={"red.400"}>
        {message}
      </Text>
    </Box>
  );
};

function GeneralSettingSubsection(props: any) {
  const { data: orgData } = props;
  const [udpateOrgnaization, { data, loading, error }] = useMutation(UPDATE_ORGANIZATION_MUTATION);
  const joinCodeUrl = orgData.accessCode ? JOIN_CODE_URL_PREFIX + orgData.accessCode : "";
  const { hasCopied, onCopy } = useClipboard(joinCodeUrl);
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      orgName: orgData.name,
      welcomeMessage: orgData.introText,
      subtitle: orgData.introTitle,
      videoEmbed: orgData.videoUri,
      logoUrl: orgData.logoUri,
      exploreTitle: orgData.title,
      exploreSubtitle: orgData.description,
      accessCode: orgData.accessCode,
    },
  });

  const onSubmit = handleSubmit((d) => {
    udpateOrgnaization({
      variables: {
        id: orgData._id || "",
        name: d.orgName,
        introText: d.welcomeMessage,
        introTitle: d.subtitle,
        videoUri: d.videoEmbed,
        logoUri: d.logoUrl,
        title: d.exploreTitle,
        description: d.exploreSubtitle,
        accessCode: d.accessCode,
      },
    });
  });

  // Update sucessfully message
  useEffect(() => {
    if (data && !loading) {
      toast({
        title: "Organization Settings Updated Successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [data]);

  // Update faild message
  useEffect(() => {
    if (error && !loading) {
      toast({
        title: "Some things went wrong while updating organization settings. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [error]);

  // Styling
  const borderColor = useColorModeValue("rgba(0, 0, 0, 0.1)", "gray");
  const Label = ({ children }: any) => <Text textStyle={"paragraph"}>{children}</Text>;
  const DialogDiv = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid;
    border-radius: 15px;
    padding: 20px 20px;
  `;
  const layoutStyles = {
    labelWidth: ["full", "full", "160px"],
    labelpy: 5,
    labelpx: 5,
    inputBoxWidth: ["full", "full", "500px"],
    containerWidth: ["xs", "sm", "lg", "md", "5xl"],
    containerDirection: ["column", "column", "column", "row", "row"],
    blockMargin: 10,
  };

  const labelStyles = {
    w: layoutStyles.labelWidth,
    pt: 1,
  };

  const inputLabelFlexStyles = {
    py: layoutStyles.labelpy,
    w: "full",
    direction: layoutStyles.containerDirection as SystemProps["flexDirection"],
  };

  const resetBtnStyles = {
    variant: "link",
    ml: 4,
    mt: 1,
    mb: 1,
    h: "fit-content",
    fontSize: "sm",
    alignSelf: "flex-end",
  };
  return (
    <form onSubmit={onSubmit}>
      <DialogDiv style={{ borderColor: borderColor }}>
        <Box borderWidth="1px" borderRadius="lg">
          <Box>
            <Text textStyle={"h2"} fontWeight={"normal"}>
              Home page configuration
            </Text>
            <Flex {...inputLabelFlexStyles}>
              <Box {...labelStyles}>
                <Label>Org Name</Label>
              </Box>
              <Box w={layoutStyles.inputBoxWidth}>
                <FormControl isInvalid={errors.orgName ? true : false}>
                  <Input
                    type="text"
                    placeholder="The name of your side"
                    {...register("orgName", {
                      required: "This is required",
                    })}
                  />
                  <FormErrorMessage>{errors.orgName && errors.orgName.message}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Flex {...inputLabelFlexStyles}>
              <Box {...labelStyles}>
                <Label>Welcome Message</Label>
              </Box>
              <Box w={layoutStyles.inputBoxWidth}>
                <FormControl isInvalid={errors.welcomeMessage ? true : false}>
                  <Input
                    type="text"
                    placeholder={DEFAULT_INTRO_MESSAGES.welcomeMessage}
                    {...register("welcomeMessage")}
                  />
                  <FormErrorMessage>{errors.welcomeMessage && errors.welcomeMessage.message}</FormErrorMessage>
                </FormControl>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    {...resetBtnStyles}
                    onClick={() => setValue("welcomeMessage", DEFAULT_INTRO_MESSAGES.welcomeMessage)}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </Flex>

            <Flex {...inputLabelFlexStyles}>
              <Box {...labelStyles}>
                <Label>Subtitle</Label>
              </Box>
              <Box w={layoutStyles.inputBoxWidth}>
                <FormControl isInvalid={errors.subtitle ? true : false}>
                  <Textarea
                    placeholder={DEFAULT_INTRO_MESSAGES.subtitle}
                    variant={"outline"}
                    {...register("subtitle")}
                  />
                  <FormErrorMessage>{errors.subtitle && errors.subtitle.message}</FormErrorMessage>
                </FormControl>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button {...resetBtnStyles} onClick={() => setValue("subtitle", DEFAULT_INTRO_MESSAGES.subtitle)}>
                    Reset
                  </Button>
                </Box>
              </Box>
            </Flex>

            <Flex {...inputLabelFlexStyles}>
              <Box {...labelStyles}>
                <Label>Video Embed</Label>
              </Box>
              <Box w={layoutStyles.inputBoxWidth}>
                <FormControl isInvalid={errors.videoEmbed ? true : false}>
                  <Input
                    type="text"
                    placeholder="http://"
                    {...register("videoEmbed", {
                      pattern: { value: URL_REGEX, message: "Please enter a valid URL" },
                    })}
                  />
                  <FormErrorMessage>{errors.videoEmbed && errors.videoEmbed.message}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Flex {...inputLabelFlexStyles}>
              <Box {...labelStyles}>
                <Label>Logo URL</Label>
              </Box>
              <Box w={layoutStyles.inputBoxWidth}>
                <FormControl isInvalid={errors.logoUrl ? true : false}>
                  <Input
                    type="text"
                    placeholder="http://"
                    {...register("logoUrl", {
                      pattern: { value: URL_REGEX, message: "Please enter a valid URL" },
                    })}
                  />
                  <FormErrorMessage>{errors.logoUrl && errors.logoUrl.message}</FormErrorMessage>
                  <Text fontSize="xs" fontWeight={"thin"} color={useColorModeValue("gray", "white")}>
                    This image will appear at the top of each Collection page & NFT Claim page. Recommend square aspect
                    ratio, 200x200 to 1080x1080px.
                  </Text>
                </FormControl>
              </Box>
            </Flex>
          </Box>
          <Box marginBlockStart={layoutStyles.blockMargin}>
            <Text textStyle={"h2"} fontWeight={"normal"}>
              Explore page configuration
            </Text>
            <Flex {...inputLabelFlexStyles}>
              <Box {...labelStyles}>
                <Label>Title</Label>
              </Box>
              <Box w={layoutStyles.inputBoxWidth}>
                <FormControl isInvalid={errors.exploreTitle ? true : false}>
                  <Input type="text" placeholder={DEFAULT_INTRO_MESSAGES.exploreTitle} {...register("exploreTitle")} />
                  <FormErrorMessage>{errors.exploreTitle && errors.exploreTitle.message}</FormErrorMessage>
                </FormControl>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    {...resetBtnStyles}
                    onClick={() => setValue("exploreTitle", DEFAULT_INTRO_MESSAGES.exploreTitle)}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </Flex>

            <Flex {...inputLabelFlexStyles}>
              <Box {...labelStyles}>
                <Label>Subtitle</Label>
              </Box>
              <Box w={layoutStyles.inputBoxWidth}>
                <FormControl isInvalid={errors.exploreSubtitle ? true : false}>
                  <Input
                    type="text"
                    placeholder={DEFAULT_INTRO_MESSAGES.exploreSubtitle}
                    {...register("exploreSubtitle")}
                  />
                  <FormErrorMessage>{errors.exploreSubtitle && errors.exploreSubtitle.message}</FormErrorMessage>
                </FormControl>
                <Box display={"flex"} justifyContent={"flex-end"}>
                  <Button
                    {...resetBtnStyles}
                    onClick={() => setValue("exploreSubtitle", DEFAULT_INTRO_MESSAGES.exploreSubtitle)}
                  >
                    Reset
                  </Button>
                </Box>
              </Box>
            </Flex>
          </Box>

          {joinCodeUrl && (
            <Box marginBlockStart={layoutStyles.blockMargin}>
              <Text textStyle={"h2"} fontWeight={"normal"}>
                Login Details
              </Text>
              <Flex {...inputLabelFlexStyles}>
                <Box {...labelStyles}>
                  <Label>Join Code</Label>
                </Box>
                <Box w={layoutStyles.inputBoxWidth}>
                  <FormControl>
                    <InputGroup>
                      <Input type="text" variant={"filled"} value={joinCodeUrl} disabled />
                      <InputRightElement onClick={() => onCopy()} cursor={"pointer"}>
                        <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                          <Icon as={CopyIcon} />
                        </Tooltip>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                </Box>
              </Flex>
            </Box>
          )}

          <Button rounded={"lg"} mt={4} type="submit" isLoading={loading} loadingText={"Updating Data"}>
            Save Changes
          </Button>
        </Box>
      </DialogDiv>
    </form>
  );
}

export default GeneralSettingSubsection;
