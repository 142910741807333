import { Box, Flex, Grid, GridItem, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { FALLBACK_URL_IMAGE } from "../../../util/Constants";

const Columns = [
  {
    label: "#Token ID",
    colSpan: 2,
  },
  {
    label: "# Time Minted",
    colSpan: 2,
  },
  {
    label: "# Owners vs Allow List",
    colSpan: 2,
  },
  {
    label: "Claimed",
    colSpan: 2,
  },
];

function DashboardNFTAnalyticsTable() {
  const rowValueColor = useColorModeValue("black.300", "white");
  const rowBorderColor = useColorModeValue("gray.200", "gray.500");

  return (
    <Box overflow={"auto"}>
      <Grid
        templateRows={"repeat(1, 1fr)"}
        templateColumns={"repeat(10, 1fr)"}
        bg={useColorModeValue("black.100", "black.400")}
        py={4}
        rounded={"lg"}
        minW={"1050px"}
        overflow={"hidden"}
        alignItems={"center"}
        textAlign={{ base: "center", md: "left" }}
      >
        <GridItem></GridItem>
        {Columns.map((item, index) => (
          <GridItem key={item.label + index} colSpan={item.colSpan}>
            <Text textStyle={"paragraph"} color={rowValueColor}>
              {item.label}
            </Text>
          </GridItem>
        ))}
      </Grid>
      <Grid
        templateRows={"repeat(1, 1fr)"}
        templateColumns={"repeat(10, 1fr)"}
        rounded={"lg"}
        border={"1px"}
        borderColor={rowBorderColor}
        borderStyle={"solid"}
        py={4}
        mt={3}
        overflow={"hidden"}
        alignItems={"center"}
        textAlign={{ base: "center", md: "left" }}
        minW={"1050px"}
      >
        <GridItem>
          <Flex justifyContent={"center"} onClick={() => {}}>
            <Image
              rounded="xl"
              width="90px"
              height="90px"
              fit="cover"
              src={
                "https://static01.nyt.com/images/2021/03/12/arts/11nft-auction-cryptopunks-print/11nft-auction-cryptopunks-print-mobileMasterAt3x.jpg"
              }
              fallbackSrc={FALLBACK_URL_IMAGE}
              alt={"NFT"}
              _hover={{
                width: "100px",
                height: "100px",
              }}
              cursor={"pointer"}
            />
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Text textStyle={"paragraph"} color={rowValueColor}>
            {"#2957"}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text textStyle={"paragraph"} color={rowValueColor}>
            {89}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text textStyle={"paragraph"} color={rowValueColor}>
            {"8/85"}
          </Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Text textStyle={"paragraph"} color={rowValueColor}>
            {"130"}
          </Text>
        </GridItem>
      </Grid>
    </Box>
  );
}

export default DashboardNFTAnalyticsTable;
