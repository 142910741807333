import { useEffect, useRef } from "react";
import { NFT } from "../../Interfaces/NFT";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  useColorModeValue,
  Text,
  Stack,
  Image,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  useClipboard,
} from "@chakra-ui/react";
import { MdVerified } from "react-icons/md";
import { FALLBACK_URL_IMAGE, LINKEDIN_BASE_URL, SITE_URL } from "../../util/Constants";
import OwnerAvatarGroup from "../common/OwnerAvatarGroup";
import useFetchNFTOwners from "../../hooks/FetchNFTOwners";
import { BsThreeDots } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { AiOutlineFileSearch } from "react-icons/ai";
import { BiLink } from "react-icons/bi";
import { hexToDecimal, openInNewTab } from "../../util/helper";

interface CardProps {
  nft: NFT;
  isProfileNFTCard: boolean;
  isUserOwnNFT?: boolean;
  updateUserProfilePicture?: any;
  isLinkedinSharingAvailable?: any;
}

function Card(props: CardProps) {
  const navigate = useNavigate();
  const {
    metadata: { id, name, image, description, attributes },
    contractAddress = "",
  } = props.nft;

  const { isProfileNFTCard, isUserOwnNFT, updateUserProfilePicture, isLinkedinSharingAvailable } = props;
  const path = `/detail/${contractAddress}/${hexToDecimal(id?.toString()?.split("x")[1] || "")}`;

  const { owners, fetchOwners } = useFetchNFTOwners();
  const { hasCopied, onCopy } = useClipboard(`${SITE_URL}${path}`);

  const routeChange = () => {
    navigate(path);
  };

  const fetchingOwnerRef: any = useRef(null);

  useEffect(() => {
    if (!(fetchingOwnerRef.current as boolean)) {
      fetchOwners(contractAddress, id);
      fetchingOwnerRef.current = "ownersLoaded";
    }
  }, [id]);

  const isLinkedin: any = isLinkedinSharingAvailable(contractAddress.toLowerCase());
  const issuingOrgAttr: any = props.nft.metadata.attributes?.find((attr: any) => attr.trait_type === "Issuer LinkedIn ID");
  const issuingOrgId: string = issuingOrgAttr?.value;

  if (!name && !image && !description) return null;

  // LinkedIn
  const linkedinCertUrl = "https://wolverine.gotminted.app" + path;
  const shareToLinkedIn = `${LINKEDIN_BASE_URL}profile/add?startTask=CERTIFICATION_NAME&name=${encodeURIComponent(
    name || ""
  )}&organizationId=${encodeURIComponent(issuingOrgId || isLinkedin?.linkedinOrgId || "")}&certUrl=${encodeURIComponent(
    linkedinCertUrl
  )}`;

  return (
    <>
      <Center cursor={"pointer"} onClick={isUserOwnNFT ? () => {} : routeChange}>
        <Box
          role={"group"}
          p={[2, 2, 3, 3, 5]}
          maxW={"330px"}
          w={["full"]}
          bg={useColorModeValue("transparent", "gray.800")}
          border={"1px"}
          borderColor={useColorModeValue("gray.200", "gray.500")}
          borderStyle={"solid"}
          rounded={"lg"}
          _hover={{ boxShadow: useColorModeValue("xl", "2xl") }}
        >
          <Box rounded={"lg"} height={["110px", "230px", "170px", "230px", "230px", "230px"]} onClick={routeChange}>
            <Image
              rounded={"lg"}
              height={[130, 250, 200, 250, 250, 250]}
              width={[160, 280, 230, 280, 280, 280]}
              objectFit={"cover"}
              src={image || ""}
              alt={"NTF_IMAGE"}
              boxShadow={"xl"}
              fallbackSrc={FALLBACK_URL_IMAGE}
            />
          </Box>
          <Stack pt={[8, 10]}>
            <Text textStyle={"h3"} noOfLines={1}>
              {name}
            </Text>
            {isUserOwnNFT ? (
              <Flex>
                <Menu placement="top-end">
                  <MenuButton
                    as={IconButton}
                    aria-label="Options"
                    icon={<Icon as={BsThreeDots} w={"25px"} h={"25px"} style={{ marginTop: "5px" }} />}
                    variant={"unstyled"}
                  />
                  <MenuList>
                    <MenuItem
                      icon={<Icon as={CgProfile} w={5} h={5} style={{ marginTop: "5px" }} />}
                      onClick={() => {
                        updateUserProfilePicture(image || "");
                      }}
                    >
                      Make Profile Pic
                    </MenuItem>
                    {isLinkedin?.isLinkedinCredential && (
                      <MenuItem
                        icon={<Icon as={AiOutlineFileSearch} w={5} h={5} style={{ marginTop: "5px" }} />}
                        onClick={() => {
                          openInNewTab(shareToLinkedIn);
                        }}
                      >
                        Add To LinkedIn
                      </MenuItem>
                    )}
                    <MenuItem icon={<Icon as={BiLink} w={5} h={5} style={{ marginTop: "5px" }} />} onClick={onCopy}>
                      {hasCopied ? "Copied" : "Copy Link"}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            ) : (
              <>
                <Text
                  fontFamily={"body"}
                  color={useColorModeValue("black.300", "white")}
                  fontSize={[12, 15]}
                  noOfLines={2}
                  minH={[10, 45]}
                >
                  {description}
                </Text>
                <OwnerAvatarGroup owners={owners || []} />
                {isProfileNFTCard ? (
                  <Button variant={"filled"} onClick={routeChange}>
                    Discover
                  </Button>
                ) : (
                  <Stack direction={["column", "row"]} align={"center"} justifyContent={"space-between"}>
                    <Flex direction={"column"} justifyContent={"center"}>
                      <Text textStyle={"label"} color={"black.200"} p={0} m={0}>
                        Created by
                      </Text>
                      <Flex align={"center"}>
                        <Text textStyle={"label"} fontSize={14} p={0} mr={1} maxWidth={125} noOfLines={1}>
                          {contractAddress}
                        </Text>
                        <Icon as={MdVerified} color="blue.500" />
                      </Flex>
                    </Flex>
                    <Stack>
                      {/* <NFTClaimButton token={id} variant="small" contractAddress={contractAddress} /> */}
                    </Stack>
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </Box>
      </Center>
    </>
  );
}

export default Card;
