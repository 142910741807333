import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
  useColorMode,
  MenuList,
  MenuButton,
  Menu,
  MenuItem,
  HStack,
  Avatar,
} from "@chakra-ui/react";
import * as CI from "@chakra-ui/icons";
import { FaMoon, FaSun } from "react-icons/fa";
import Logo from "./../common/logo";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { HiOutlineLogout } from "react-icons/hi";
import { FiHelpCircle } from "react-icons/fi";
import { RiLayoutGridLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
// import { useWeb3Auth } from './../../services/web3auth';
import { useDisconnect } from "@thirdweb-dev/react";
import { getstandardizedOwnerAddress, openInNewTab } from "../../util/helper";
import { NOTION_GENERAL_PAGE_LINK } from "../../util/Constants";
import ConnectWalletModal from "../common/ConnectWallet/ConnectWalletModal";
import { ConnectWalletContext, ConnectWalletContextType } from "../../context/connectWalletContext";
import { useBetween } from "use-between";

const NAV_ITEMS = [
  {
    label: "Home",
    href: "/",
    value: "home",
  },
  {
    label: "Explore",
    href: "/collection",
    value: "explore",
  },
  // {
  //   label: "Explore",
  //   href: "#",
  //   value: "explore",
  //   children: [
  //     // {
  //     //   label: "All NFTs",
  //     //   subLabel: "All NFTs of different users",
  //     //   href: "/explore",
  //     // },
  //     {
  //       label: "Collections",
  //       subLabel: "Here is collection of NFTs.",
  //       href: "/collection",
  //     },
  //   ],
  // },
];

let MOBILE_PROFILE_MENU = {
  label: "Profile",
  href: "#",
  value: "profile",
  children: [
    {
      label: "My Profile",
      subLabel: "",
      href: "/profile",
    },
    {
      label: "Help & Support",
      subLabel: "",
      href: NOTION_GENERAL_PAGE_LINK,
      isExternal: true,
    },
  ],
};

const getActiveIndex = (path: any) => {
  if (path.includes("explore") || path.includes("collection")) return 1;
  else if (path.includes("home")) return 0;
  else if (path === "/") return 0;
  else return -1;
};

export const useLoginState = () => {
  const [isLogin, setIsLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  return {
    isLogin,
    setIsLogin,
    loading,
    setLoading,
  };
};

export default function Header() {
  const { isLogin, setIsLogin, loading, setLoading } = useBetween(useLoginState);
  const { isOpen, onToggle } = useDisclosure();
  const { toggleColorMode: toggleMode } = useColorMode();
  const { isOpen: isOpenConnectWallet, onOpen: onOpenConnectWallet, onClose: onCloseConnectWallet } = useDisclosure();
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const text = useColorModeValue("dark", "light");

  // Handle Active Menu Logic
  const location = useLocation();
  const currentActiveNav = getActiveIndex(location.pathname);

  const showCreateNFT = true;
  const textColor = useColorModeValue("black.300", "white");

  const {
    address: walletAddress,
    disconnectWallet,
    user: connectedUserData,
  } = useContext(ConnectWalletContext) as ConnectWalletContextType;

  const disconnect = useDisconnect();
  const isAdminOrOperator = ["OPERATOR", "ADMIN"].includes(connectedUserData?.role || "");
  const isOperator = ["OPERATOR"].includes(connectedUserData?.role || "");

  const [profileImage, setProfileImage] = useState(`/img/avatars/${Math.floor(Math.random() * 5) + 1}.svg`);
  const navigate = useNavigate();

  const onLogin = () => {
    onOpenConnectWallet();
    setIsLogin(true);
  };

  const onLogout = () => {
    disconnect();
    disconnectWallet();
  };

  const getMintedBtn = (
    <Button fontSize={[12, 16]} fontWeight={"700"} variant="gray" onClick={() => navigate("/joinMinted/1")}>
      Get minted for FREE
    </Button>
  );

  return (
    <>
      <Box pt={2}>
        <Flex
          color={useColorModeValue("gray.600", "white")}
          minH={"61px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={2}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
          justifyContent={["flex-start", "space-between"]}
        >
          <Flex flex={{ md: "auto" }} ml={{ base: -2 }} display={{ base: "flex", md: "none" }}>
            <IconButton
              onClick={onToggle}
              icon={isOpen ? <CI.CloseIcon w={3} h={3} /> : <CI.HamburgerIcon w={5} h={5} />}
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
            {!walletAddress && getMintedBtn}
          </Flex>
          <Flex justify={{ base: "center", md: "start" }}>
            <Box onClick={() => navigate("/")} cursor={"pointer"}>
              <Logo />
            </Box>
            <Flex display={{ base: "none", md: "flex" }} ml={4} alignItems={"center"}>
              <DesktopNav currentActiveNav={currentActiveNav} />
            </Flex>
          </Flex>

          {/* <Box w={["xs", "sm", "md"]} display={{ base: "none", md: "flex" }}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                  variant="filled"
                  placeholder="Search for digital collections & more"
                />
              </InputGroup>
            </Box>
          */}

          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-start"}
            display={{ base: "none", md: "flex" }}
            alignItems={"center"}
            direction={"row"}
            spacing={2}
          >
            {isLogin && !showCreateNFT && (
              <Button
                fontWeight={"600"}
                onClick={() => {
                  // router.push("/nft/create");
                }}
              >
                Create NFT
              </Button>
            )}
            <IconButton
              size="md"
              fontSize="lg"
              aria-label={`Switch to ${text} mode`}
              variant="ghost"
              color="current"
              ml={{ base: "0", md: "3" }}
              onClick={toggleMode}
              icon={<SwitchIcon />}
            />
            {walletAddress ? (
              <Menu>
                <MenuButton
                  style={{ border: "none", padding: "7px" }}
                  as={IconButton}
                  aria-label="Options"
                  icon={
                    <HStack>
                      <Avatar name="Profile Picture" src={profileImage} size={"sm"} background={"transparent"} />
                      <Text textStyle={"paragraphMedium"}>{getstandardizedOwnerAddress(walletAddress)}</Text>
                      <CI.ChevronDownIcon />
                    </HStack>
                  }
                  variant="outline"
                />
                <MenuList alignItems={"center"} fontWeight={"bold"} fontSize={"lg"} color={textColor}>
                  <MenuItem
                    icon={<CgProfile />}
                    onClick={() => {
                      navigate("/profile/" + walletAddress);
                    }}
                  >
                    My Profile
                  </MenuItem>
                  {isAdminOrOperator && (
                    <>
                      <MenuItem
                        icon={<RiLayoutGridLine />}
                        onClick={() => {
                          navigate("/dashboard");
                        }}
                      >
                        Dashboard
                      </MenuItem>
                    </>
                  )}
                  {isOperator && (
                    <>
                      <MenuItem
                        icon={<CI.LockIcon />}
                        onClick={() => {
                          navigate("/access-hub");
                        }}
                      >
                        Access Hub
                      </MenuItem>
                    </>
                  )}
                  <MenuItem icon={<FiHelpCircle />} onClick={() => openInNewTab(NOTION_GENERAL_PAGE_LINK)}>
                    Help & Support
                  </MenuItem>
                  <MenuItem
                    icon={<HiOutlineLogout />}
                    color={"red.500"}
                    onClick={() => {
                      onLogout();
                    }}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <>
                {getMintedBtn}
                <Button
                  fontSize={[12, 16]}
                  fontWeight={"700"}
                  onClick={onLogin}
                  isLoading={loading}
                  loadingText={"loading.."}
                >
                  Connect wallet
                </Button>
              </>
            )}
          </Stack>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <MobileNav
            onLogin={onLogin}
            onLogout={onLogout}
            walletAddress={walletAddress}
            profileImage={profileImage}
            isAdminOrOperator={isAdminOrOperator}
            isOperator={isOperator}
          />
        </Collapse>
      </Box>
      <ConnectWalletModal
        isOpen={isOpenConnectWallet}
        onClose={onCloseConnectWallet}
        setIsLogin={setIsLogin}
        setLoading={setLoading}
        loading={loading}
      />
    </>
  );
}

const DesktopNav = (props: any) => {
  const { currentActiveNav } = props;
  const linkColor = useColorModeValue("gray.700", "gray.200");
  const linkHoverColor = useColorModeValue("gray.500", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");
  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem, index) => (
        <Box key={navItem.label} justifyContent={"center"}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Text
                borderBottom={currentActiveNav === index ? 2 : 0}
                borderBottomColor={"primary.900"}
                borderStyle={"solid"}
                p={2}
                pb={5}
                mb={-3}
                fontSize={"sm"}
                fontWeight={500}
                color={currentActiveNav === index ? "primary.900" : linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
                _focus={{}}
              >
                <RouterLink to={navItem.href}>{navItem.label}</RouterLink>
              </Text>
            </PopoverTrigger>

            {/* {navItem?.children && (
              <PopoverContent border={0} boxShadow={"xl"} bg={popoverContentBgColor} p={4} rounded={"xl"} minW={"sm"}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )} */}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = (props: any) => {
  const { label, href, subLabel } = props;
  return (
    <Link
      role={"group"}
      display={"block"}
      p={2}
      as={RouterLink}
      to={href}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("purple.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text transition={"all .3s ease"} _groupHover={{ color: "primary.800" }} fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"primary.800"} w={5} h={5} as={CI.ChevronRightIcon} />
        </Flex>
      </Stack>
    </Link>
  );
};

const MobileNav = (props: any) => {
  const { toggleColorMode: toggleMode } = useColorMode();
  const mode = useColorModeValue("light", "dark");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);
  const { onLogin, onLogout, walletAddress, profileImage, isAdminOrOperator, isOperator } = props;

  if (isOperator && MOBILE_PROFILE_MENU["children"].filter((item) => item.label === "Access Hub").length === 0) {
    MOBILE_PROFILE_MENU["children"].splice(1, 0, {
      label: "Access Hub",
      subLabel: "",
      href: "/access-hub",
    });
  }

  if (isAdminOrOperator && MOBILE_PROFILE_MENU["children"].filter((item) => item.label === "Dashboard").length === 0) {
    MOBILE_PROFILE_MENU["children"].splice(1, 0, {
      label: "Dashboard",
      subLabel: "",
      href: "/dashboard",
    });
  }

  const menu = walletAddress ? [...NAV_ITEMS, MOBILE_PROFILE_MENU] : NAV_ITEMS;

  return (
    <Stack bg={useColorModeValue("white", "gray.800")} p={4} display={{ md: "none" }}>
      {menu.map((navItem) => (
        <MobileNavItem
          key={navItem.label}
          {...navItem}
          walletAddress={walletAddress}
          onLogin={onLogin}
          onLogout={onLogout}
          profileImage={profileImage}
        />
      ))}
      {!walletAddress && (
        <Flex direction={"row"} justifyContent={"space-between"}>
          <Button fontSize={[12, 16]} fontWeight={"700"} onClick={onLogin}>
            Connect wallet
          </Button>
        </Flex>
      )}
      <Flex direction={"row"} justifyContent={"space-between"}>
        <HStack>
          {/* <Icon as={FaMoon} /> */}
          <Text textStyle={"label"} fontSize={16} textTransform={"capitalize"}>
            {mode} Mode
          </Text>
        </HStack>
        <IconButton
          size="md"
          fontSize="lg"
          aria-label={`Switch to ${mode} mode`}
          variant="ghost"
          color="current"
          mr={{ base: "-2", md: "3" }}
          onClick={toggleMode}
          icon={<SwitchIcon />}
        />
      </Flex>
    </Stack>
  );
};

const MobileNavItem = (props: any) => {
  const { label, children, href, walletAddress, onLogin, onLogout, profileImage } = props;
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
        onClick={() => {
          navigate(href);
        }}
      >
        {label === "Profile" && walletAddress && (
          <>
            <Stack direction={"row"} align={"center"}>
              <Avatar name="Profile Picture" src={profileImage} size={"sm"} background={"transparent"} />
              <Text textStyle={"paragraph"}>{getstandardizedOwnerAddress(walletAddress)}</Text>
            </Stack>
            {children && (
              <Icon
                as={CI.ChevronDownIcon}
                transition={"all .25s ease-in-out"}
                transform={isOpen ? "rotate(180deg)" : ""}
                w={6}
                h={6}
              />
            )}
          </>
        )}
        {!["Profile"].includes(label) && (
          <>
            <Text fontWeight={600} color={useColorModeValue("gray.600", "gray.200")}>
              {label}
            </Text>
            {children && (
              <Icon
                as={CI.ChevronDownIcon}
                transition={"all .25s ease-in-out"}
                transform={isOpen ? "rotate(180deg)" : ""}
                w={6}
                h={6}
              />
            )}
          </>
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={0}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child: any) => (
              <Text
                key={child.label}
                py={2}
                onClick={() => {
                  if (child.isExternal) {
                    openInNewTab(child.href);
                  } else {
                    child.href === "/profile" ? navigate(`${child.href}/${walletAddress}`) : navigate(child.href);
                  }
                }}
              >
                {child.label}
              </Text>
            ))}
          {label === "Profile" && (
            <Text onClick={onLogout} color={"red.500"} py={2}>
              Logout
            </Text>
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
};
