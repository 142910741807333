import { useContract, useEditionDrop, useSDK } from "@thirdweb-dev/react";
import { ethers } from "ethers";
import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { magic } from "../../../services/Magic";
interface ClaimStrategyProps {
  contractType: string;
  contractAddress: string;
}

export enum ContractTypeEnum {
  GOTMINTED_1155_SOULBOUND = "GOTMINTED1155SOULBOUND",
  THIRDWEB_EDITIONFROP = "thirdweb-editiondrop",
  THIRDWEB_NFT_COLLECTION = "THIRDWEBNFTCOLLECTION",
  GOTMINTED_ERC1155 = "GOTMINTEDERC1155",
}

function getSuccessMessage(contractType: string) {
  switch (contractType) {
    case "ERC721":
      return "NFT minted successfully!";
    default:
      return "Your NFT has been minted and added to your collection!";
  }
}

function useClaimStrategy(props: ClaimStrategyProps) {
  const { contractAddress, contractType } = props;
  const [stateContractAddress, setStateContractAddress] = useState(contractAddress);
  const sdk = useSDK();

  // - Thirdweb-EditionDrop
  let editionDropContract: any = null;
  if (contractType === ContractTypeEnum.THIRDWEB_EDITIONFROP) {
    editionDropContract = useEditionDrop(stateContractAddress);
  }
  // - GOTMINTED1155SOULBOUND
  let customContract: any = null;
  if (contractType === ContractTypeEnum.GOTMINTED_1155_SOULBOUND) {
    const { contract } = useContract(stateContractAddress);
    customContract = contract;
  }

  useEffect(() => {
    if ((customContract || editionDropContract) && contractAddress) {
      const signer = sdk?.getSigner();
      if (signer === undefined) {
        const provider = new ethers.providers.Web3Provider(magic.rpcProvider as any);
        const signer = provider.getSigner();
        sdk?.updateSignerOrProvider(signer);
        setStateContractAddress(contractAddress);
      } else if (signer && contractAddress !== customContract?.getAddress()) {
        const provider = new ethers.providers.Web3Provider(magic.rpcProvider as any);
        const signer = provider.getSigner();
        sdk?.updateSignerOrProvider(signer);
        setStateContractAddress(contractAddress);
      }
    }
  }, [customContract, editionDropContract, contractAddress]);

  // Function for Claim NFTs
  // Claim With MetaMask
  const claimNFT = async (token: any, qty: number) => {
    try {
      let response = null;
      if (contractType === ContractTypeEnum.THIRDWEB_EDITIONFROP) {
        response = await editionDropContract?.claim(token, qty);
      } else if (contractType === ContractTypeEnum.GOTMINTED_1155_SOULBOUND) {
        if (customContract?.call) {
          console.log("mintNFT", { customContract, contractAddress, token, qty });
          response = await customContract?.call("mint", token, qty, []);
        } else {
          return Promise.reject(
            new Error("While initialization contract Something went wrong. Please reconnect the wallet again")
          );
        }
      } else {
        return Promise.reject(new Error("ClaimType is not defined."));
      }
      return { response, successMessage: getSuccessMessage(contractType) };
    } catch (error: any) {
      if (typeof error === "object") {
        console.log(Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          claimNFT: "Claim NFT",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "claimStrategy:claimNFT",
        },
      });
      return Promise.reject(error);
    }
  };

  const contract = editionDropContract || customContract;

  return { claimNFT, contract };
}

export default useClaimStrategy;
