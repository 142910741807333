import { useContext, useState } from "react";
import { Button, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import CommonModal, { CommonModalType } from "../Modal";
import useClaimStrategy from "./ClaimStrategy";
import NetworkMismatch from "../NetworkMismatch";
import { useChainId, useNetworkMismatch } from "@thirdweb-dev/react";
import {
  ConnectWalletContext,
  ConnectWalletContextType,
  LoginWithTypeEnum,
} from "../../../context/connectWalletContext";
import { getCleanErrorMessage } from "../../../util/helper";
import { useNavigate } from "react-router-dom";
import ConnectWalletModal from "../ConnectWallet/ConnectWalletModal";
import { useBetween } from "use-between";
import { useLoginState } from "../../Layout/Header";

interface ClaimButtonProps {
  token: any;
  contractAddress: string;
  variant: string;
  size: string | string[];
  rounded?: string;
  contractType: string;
}

function NFTClaimButton(props: ClaimButtonProps) {
  const { token, contractAddress, size, variant, rounded, contractType } = props;
  const { user, address } = useContext(ConnectWalletContext) as ConnectWalletContextType;
  const { isLogin, setIsLogin, loading, setLoading } = useBetween(useLoginState);
  const { isOpen: isOpenConnectWallet, onOpen: onOpenConnectWallet, onClose: onCloseConnectWallet } = useDisclosure();
  const [claiming, setClaiming] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isNetworkMismatchOpen,
    onOpen: onOpenNetworkMismatchOpen,
    onClose: onCloseNetworkMismatchOpen,
  } = useDisclosure();
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const { claimNFT, contract } = useClaimStrategy({ contractAddress: contractAddress, contractType: contractType });
  const networkMismatch = useNetworkMismatch();
  const activeChainId = useChainId();
  const navigate = useNavigate();

  const onClaimNFT = async () => {
    const qty = 1;

    // Check for network mismatch
    if (
      activeChainId &&
      networkMismatch &&
      user?.loginWith === LoginWithTypeEnum.METAMASK
    ) {
      onOpenNetworkMismatchOpen();
      return "";
    }
    // Claiming Logic Begin Here
    if (!claiming) {
      setClaiming(true);
      try {
        const response = await claimNFT(token, qty);
        setModalTitle(CommonModalType.SUCCESS);
        setModalMessage(response.successMessage);
        onOpen();
        setClaiming(false);
      } catch (error: any) {
        const { message, reason } = error;
        setModalTitle(CommonModalType.ERROR);
        if (!reason && JSON.stringify(message).includes("Contract transaction failed")) {
          setModalMessage("Contract transaction failed. please try again");
        } else {
          const cleanErrorMessage = getCleanErrorMessage(reason || message);
          setModalMessage(cleanErrorMessage);
        }
        onOpen();
        console.log("Failed to claim. Error: ", error);
        setClaiming(false);
      }
    }
  };

  return (
    <>
      <Button
        size={size}
        variant={variant}
        rounded={rounded}
        onClick={() => {
          if (user) {
            onClaimNFT();
          } else {
            onOpenConnectWallet();
          }
        }}
        disabled={!contract || claiming}
        isLoading={!contract || claiming}
        loadingText={!contract ? "Loading Contract" : "Claiming"}
        _loading={{
          color: useColorModeValue("white", "gray.400"),
        }}
        w={"500px"}
        h={"56px"}
      >
        {user ? "Claim NFT" : "Connect Wallet"}
      </Button>
      <CommonModal
        isOpen={isOpen}
        onClose={onClose}
        title={modalTitle}
        message={modalMessage}
        ctaAction={modalTitle === CommonModalType.SUCCESS ? () => navigate(`/profile/${address}`) : null}
        ctaTitle={modalTitle === CommonModalType.SUCCESS ? "View your profile" : null}
        showTitleBelowIcon={modalTitle === CommonModalType.SUCCESS}
      />
      <ConnectWalletModal
        isOpen={isOpenConnectWallet}
        onClose={onCloseConnectWallet}
        setIsLogin={setIsLogin}
        setLoading={setLoading}
        loading={loading}
      />
      <NetworkMismatch isOpen={isNetworkMismatchOpen} onClose={onCloseNetworkMismatchOpen} />
    </>
  );
}

export default NFTClaimButton;
