import { ThirdwebProvider, metamaskWallet, walletConnect, magicLink } from "@thirdweb-dev/react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
// import { Polygon } from "@thirdweb-dev/chains";

import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";

import Header from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import Home from "./components/Home/Home";
import Explore from "./components/Explore";
import Collection from "./components/Collection/Collection";
import Profile from "./components/Profile/Profile";
import ProfileEdit from "./components/Profile/Edit";
import NotFound from "./components/common/NotFound";
import NFTDetail from "./components/Detail/Detail";
//import { WEB3AUTH_NETWORK_TYPE } from "./config/web3AuthNetwork";
//import { CHAIN_CONFIG_TYPE } from "./config/chainConfig";
//import { Web3AuthProvider } from "./services/web3auth";
import theme from "./theme/index";
import Fonts from "./theme/fonts";
import CollectionProfile from "./components/Collection/CollectionProfile";
import Quiz from "./components/Quiz";
// import { SERVICE_URLS } from "./config/serviceURLs";
import ScrollToTop from "./components/common/ScrollToTop";
import { appConfig, DEV_ENV } from "./util/Constants";
import ConnectWalletProvider from "./context/connectWalletContext";
import ProtectedRoute from "./components/common/ProtectedRoute";

import Dashboard from "./components/Dashboard";
import AccessHub from "./components/AccessHub";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import JoinOrganization from "./components/JoinOrganization/JoinOrganization";
import JoinMinted from "./components/JoinMinted";
import CreateNFT from "./components/minter/CreateNFT";
import { useEffect, useState } from "react";
import { EditNFT } from "./components/minter/EditNFT";
import { CreateContract } from "./components/minter/CreateContract";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ExportNfts from "./components/ExportNfts/ExportNfts";
import { ethers } from "ethers";

//const graphqlUrl = process.env
const queryClient = new QueryClient();

const client = new ApolloClient({
  uri: `${appConfig.GRAPHQL_API_URL}`, //SERVICE_URLS.graphql,
  cache: new InMemoryCache(),
});

function App() {
  LogRocket.init(appConfig.LOGROCKET_ID);
  setupLogRocketReact(LogRocket);
  const [hideHeader, setHideHeader] = useState<boolean>(false);

  let location = useLocation();
  useEffect(() => {
    setHideHeader(location.pathname.toLowerCase().includes("join"));
  }, [location]);

  //const web3AuthNetwork : WEB3AUTH_NETWORK_TYPE = "testnet";
  //const chain : CHAIN_CONFIG_TYPE = "mumbai";
  //extract this to appconfig
  // const desiredChain = appConfig.DESIRED_CHAIN;
  return (
    <ApolloProvider client={client}>
      <ThirdwebProvider
        activeChain="polygon"
        clientId="df47c0330f4668e29fe2af709c50a196"
        sdkOptions={{
          gasless: {
            openzeppelin: {
              relayerUrl: appConfig.RELAYER_URL,
            },
          },
          readonlySettings: {
            rpcUrl: appConfig.ALCHEMY_POLYGON_URL, // force read calls to go through your own RPC url
            chainId: appConfig.DESIRED_CHAIN, // reduce RPC calls by specifying your chain ID
          }
        }}
        // activeChain={{
        //   ...Polygon,
        //   chainId: appConfig.DESIRED_CHAIN,
        //   rpc: [appConfig.ALCHEMY_POLYGON_URL],
        // }}
        supportedWallets={[metamaskWallet(), walletConnect(), magicLink({apiKey: appConfig.REACT_APP_MAGIC_KEY, type: "connect"})]}
      >
        {/* <Web3AuthProvider chain={chain} web3AuthNetwork={web3AuthNetwork}> */}
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={theme}>
            <ConnectWalletProvider>
              <Fonts />
              {!hideHeader && <Header />}
              <ScrollToTop />
              <Routes>
                <Route path="/" element={<Home />} />
                {/* <Route path="/explore" element={<Explore />} /> */}
                <Route path="/quiz" element={<Quiz />} />
                <Route path="/collection/:contractAddress" element={<CollectionProfile />} />
                <Route path="/collection" element={<Collection />} />
                <Route path="/detail/:contractAddress/:id" element={<NFTDetail />} />
                <Route path="/profile/:walletAddress" element={<Profile />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/joinMinted/:step" element={<JoinOrganization />} />
                <Route path="/join" element={<JoinMinted />} />
                <Route
                  path="/profile/edit"
                  element={
                    <ProtectedRoute redirectPath="/">
                      <ProfileEdit />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/profile/exit"
                  element={
                    <ProtectedRoute redirectPath="/">
                      <ExportNfts />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard/:tab"
                  element={
                    <ProtectedRoute redirectPath="/" isCheckAdminOrOperator>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute redirectPath="/" isCheckAdminOrOperator>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/createNFT"
                  element={
                    <ProtectedRoute redirectPath="/" isCheckAdminOrOperator>
                      <CreateNFT />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/createContract"
                  element={
                    <ProtectedRoute redirectPath="/" isCheckAdminOrOperator>
                      <CreateContract />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/editNFT/:contractAddress/:id"
                  element={
                    <ProtectedRoute redirectPath="/" isCheckAdminOrOperator>
                      <EditNFT />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/access-hub"
                  element={
                    <ProtectedRoute redirectPath="/" isCheckOperator>
                      <AccessHub />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="*"
                  element={
                    <NotFound
                      message="Page not found! Click on button below to explore the collections"
                      buttonLabel="Explore the collection"
                      destination="/collection"
                    />
                  }
                />
              </Routes>
              <Footer />
            </ConnectWalletProvider>
          </ChakraProvider>
        </QueryClientProvider>
        {/* </Web3AuthProvider>   */}
      </ThirdwebProvider>
    </ApolloProvider>
  );
}

export default App;
