import { gql } from "@apollo/client";

export const CREATE_ORGANIZATION_MUTATION = gql`
  mutation createOrganization($name: String!, $orgType: String, $domain: String!) {
    createOrganization(name: $name, orgType: $orgType, domain: $domain) {
      _id
      name
      orgType
      accessCode
    }
  }
`;

export const FETCH_ORGANIZATION_CONTRACT_ADDRESS = gql`
  query ($ids: [ID]!) {
    organizations(ids: $ids) {
      _id
      name
      description
      title
      introText
      introTitle
      logoUri
      videoUri
      linkedinOrgId
      accessCode
      collections {
        _id
        isDefaultCollection
        contractAddress
        isVisible
        title
        isLinkedinCredential
        versionNumber
      }
      users {
        _id
        walletAddress
        username
        email
        role
        avatarUri
        status
      }
    }
  }
`;

export const FETCH_ORGANIZATIONS_ACCESS_HUB = gql`
  query {
    organizations {
      _id
      name
      logoUri
      users {
        _id
        username
      }
    }
  }
`;

export const FETCH_ORGANIZATION_BY_ACCESS_CODE = gql`
  query ($accessCode: String) {
    organization(accessCode: $accessCode) {
      _id
      name
      accessCode
    }
  }
`;

export const UPDATE_ORGANIZATION_SETTINGS_MUTATION = gql`
  mutation updateOrganization($id: _id!) {
    updateOrganization(id: $id) {
      _id
      name
      introText
      introTitle
      logoUri
      videoUri
    }
  }
`;
