import { HStack, Button, SimpleGrid, Text } from "@chakra-ui/react";
import { useState, Fragment, useMemo, useCallback } from "react";
import Card from "./../../Explore/Card";
import { IPFS_IMAGE_BASE_URL } from "../../../util/Constants";
import { getUniqueListBy } from "../../../util/helper";

interface ProfileMenuProps {
  nfts: any;
  totalCount: number;
  updateUserProfilePicture?: any;
  isUserOwnProfile?: boolean;
  isLinkedinSharingAvailable?: any;
}

function ProfileMenu(props: ProfileMenuProps) {
  const { nfts, totalCount, updateUserProfilePicture, isUserOwnProfile, isLinkedinSharingAvailable } = props;

  const filterNFTs = nfts.length > 0 ? getUniqueListBy(nfts, "contract.address", "id.tokenId") : [];

  return (
    <>
      <HStack pt={5}>
        <Button variant={"filled"}>
          {`Collected `}
          <Text textStyle={"paragraphMedium"} fontWeight={"bold"}>
            &nbsp;<b>{totalCount}</b>
          </Text>
        </Button>
      </HStack>

      <SimpleGrid columns={[2, 2, 3, 3, 4, 4]} spacing={[3, 3, 3, 3, 3, 5]} my={4}>
        {filterNFTs.length > 0 &&
          filterNFTs.map((nft: any, index: number) => {
            const image = nft?.metadata?.image?.replace("ipfs://", IPFS_IMAGE_BASE_URL) || "";
            const nftCopy = {
              ...nft,
              metadata: {
                ...nft.metadata,
                id: nft.id.tokenId,
                image: image,
              },
              contractAddress: nft.contract?.address,
            };
            return (
              <Fragment key={`${nft.id.tokenId}_NFT_${index}_${nft.contract?.address}`}>
                <Card
                  nft={nftCopy}
                  key={`${nft.id.tokenId}`}
                  isProfileNFTCard
                  isUserOwnNFT={isUserOwnProfile}
                  updateUserProfilePicture={updateUserProfilePicture}
                  isLinkedinSharingAvailable={isLinkedinSharingAvailable}
                />
              </Fragment>
            );
          })}
      </SimpleGrid>
    </>
  );
}

export default ProfileMenu;
