import { IconButton, Tooltip } from "@chakra-ui/react";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import { HiDownload } from "react-icons/hi";

interface CsvDownloadButtonProps {
  data: object[];
  fileName: string;
}

export function CsvDownloadButton(props: CsvDownloadButtonProps) {
  const csvLinkRef = useRef<any>(null);
  return (
    <>
      <Tooltip label="Download CSV File">
        <IconButton
          variant="ghost"
          fontSize="20px"
          icon={<HiDownload />}
          aria-label={""}
          onClick={() => csvLinkRef.current?.link.click()}
          ml={4}
          mr={4}
        />
      </Tooltip>
      <CSVLink data={props.data} filename={`${props.fileName}.csv`} target="_blank" ref={csvLinkRef} />
    </>
  );
}
