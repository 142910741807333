//import { useChainId } from "@thirdweb-dev/react";
import { EthNetworkConfiguration } from "magic-sdk";
interface paperFlowLink {
  [key: number]: string;
}

interface FooterLink {
  [key: string]: {
    [key: string]: string;
  };
}
interface allConfig {
  DESIRED_CHAIN: number;
  RELAYER_URL: string;
  GRAPHQL_API_URL: string;
  REST_API_URL: string;
  BACKEND_API_URL: string;
  ALCHEMY_POLYGON_URL: string;
  PAPER_CHECKOUT_CONTRACT_ADDRESS: string[];
  QUIZ_CONTRACT_ADDRESS: string;
  PAPER_FLOW_URLs: {
    [key: string]: paperFlowLink;
  };
  REACT_APP_MAGIC_KEY: string;
  MAGIC_NETWORK_OPTIONS: EthNetworkConfiguration;
  DEFAULT_ORGANIZATION_ID: string;
  LOGROCKET_ID: string;
  OPENSEA_BASE_URL: string;
  AI_URL: string;
}

const dev = {
  //DESIRED_CHAIN: ChainId.Mumbai,
  DESIRED_CHAIN: 80001,
  RELAYER_URL: process.env.REACT_APP_RELAYER_URL_DEV || "",
  BACKEND_API_URL: process.env.REACT_APP_BACKEND_API_URL_DEV || "",
  GRAPHQL_API_URL: process.env.REACT_APP_GRAPHQL_API_URL_DEV || "",
  REST_API_URL: process.env.REACT_APP_REST_API_URL_DEV || "",
  ALCHEMY_POLYGON_URL: process.env.REACT_APP_ALCHEMY_POLYGON_URL_DEV || "",
  REACT_APP_MAGIC_KEY: process.env.REACT_APP_MAGIC_KEY_DEV || "",
  AI_URL: process.env.REACT_APP_AI_URL_DEV || "",
  MAGIC_NETWORK_OPTIONS: {
    rpcUrl: "https://rpc-mumbai.maticvigil.com/",
    chainId: 80001,
  },
  QUIZ_CONTRACT_ADDRESS: "0x4056ba43b7161d8df9dad67ac614088a46e202e4",
  PAPER_CHECKOUT_CONTRACT_ADDRESS: [
    "0x4056ba43b7161d8df9dad67ac614088a46e202e4",
    "0xc54ed13f26a902ce6646a91e924c8173e21f8348",
    "0x0a38ad850ef6029bf345d69186a0160c848998cf",
  ],
  PAPER_FLOW_URLs: {
    "0x4056ba43b7161d8df9dad67ac614088a46e202e4": {
      1: "https://paper.xyz/checkout/bc2a254c-3806-4e09-ba8b-7ef796a2b76e",
      2: "https://paper.xyz/checkout/45926e4c-7c74-4ea4-bf3f-9b7833a3ef72",
      3: "https://paper.xyz/checkout/04cf7590-afeb-48a3-a7c9-6c756ce4b527",
      4: "https://paper.xyz/checkout/f2de39f5-45de-48da-a86e-0234c8b8a611",
      5: "https://paper.xyz/checkout/c97d4a6c-5b45-4a8d-9813-93950798cb61",
    },
    "0xc54ed13f26a902ce6646a91e924c8173e21f8348": {
      1: "https://paper.xyz/checkout/81a841d9-4649-4fac-acbb-9f38fd3789a6",
      2: "https://paper.xyz/checkout/ab21133b-d518-4b98-ae30-a5e068fbe35d",
      3: "https://paper.xyz/checkout/c27d815c-723d-4b2d-b632-492fd49f10d2",
      4: "https://paper.xyz/checkout/95aedd7f-40db-4212-b468-b01d0979b7f9",
      5: "https://paper.xyz/checkout/9baeca53-9a87-4b3a-85bd-21e73634db51",
      6: "https://paper.xyz/checkout/54dcd9ac-ef54-44c4-84d6-cebdf09a0aca",
      7: "https://paper.xyz/checkout/a4b99642-7385-43a7-a380-eea0d2680055",
    },
    "0x0a38ad850ef6029bf345d69186a0160c848998cf": {
      1: "https://paper.xyz/checkout/8b69033e-0ee5-4afa-b724-8417d8da9f43",
      2: "https://paper.xyz/checkout/4734cc63-5d84-449b-a81e-5208ba6d4563",
      3: "https://paper.xyz/checkout/16eebd8d-eacf-40fe-a3b1-4a83a239f777",
    },
  },
  DEFAULT_ORGANIZATION_ID: "62acdff96e10a5a7d1f1a527",
  LOGROCKET_ID: "dtovsi/minted-staging",
  OPENSEA_BASE_URL: "https://testnets.opensea.io/assets/mumbai/",
};

const prod = {
  DESIRED_CHAIN: 137,
  RELAYER_URL: process.env.REACT_APP_RELAYER_URL_PROD || "",
  BACKEND_API_URL: process.env.REACT_APP_BACKEND_API_URL_PROD || "",
  GRAPHQL_API_URL: process.env.REACT_APP_GRAPHQL_API_URL_PROD || "",
  REST_API_URL: process.env.REACT_APP_REST_API_URL_PROD || "",
  ALCHEMY_POLYGON_URL: process.env.REACT_APP_ALCHEMY_POLYGON_URL_PROD || "",
  REACT_APP_MAGIC_KEY: process.env.REACT_APP_MAGIC_KEY_PROD || "",
  AI_URL: process.env.REACT_APP_AI_URL_PROD || "",
  MAGIC_NETWORK_OPTIONS: {
    rpcUrl: "https://polygon-rpc.com/",
    chainId: 137,
  },
  QUIZ_CONTRACT_ADDRESS: "0x12A4915914c9648EcB1480994585A7d6C881283a",
  PAPER_CHECKOUT_CONTRACT_ADDRESS: [
    "0x7322f2Fc0Fab2F5CeEa83bcD29AfeF665BCa97C9",
    "0xD557CD01247F20F5Db3831244D6412A98392C3D9",
    "0xdD069e03554d05DAc9320F425798b912F6F3eB48",
  ],
  PAPER_FLOW_URLs: {
    "0x7322f2Fc0Fab2F5CeEa83bcD29AfeF665BCa97C9": {
      1: "https://paper.xyz/checkout/5fa8dc08-8179-483a-b2aa-a34ca9280093",
      2: "https://paper.xyz/checkout/f380d812-838c-4462-bc24-4f268a35e504",
      3: "https://paper.xyz/checkout/52215758-93ff-4cb0-afff-52ea03c2bdcd",
      4: "https://paper.xyz/checkout/83d1d99c-ad2f-43ad-ba1a-b821b57066e0",
      5: "https://paper.xyz/checkout/48f39a00-92ca-46c2-b811-ac7c875512d3",
    },
    "0xD557CD01247F20F5Db3831244D6412A98392C3D9": {
      1: "https://paper.xyz/checkout/2d3c7413-d1ad-467d-be28-291f7978caef",
      2: "https://paper.xyz/checkout/332988c0-9421-4164-96cf-7fdfbe842556",
      3: "https://paper.xyz/checkout/d2d619aa-e4b8-4b47-b22e-47ada37ba48a",
      4: "https://paper.xyz/checkout/e41f1236-4384-41c6-91a7-3d36aba75725",
      5: "https://paper.xyz/checkout/d9037ff4-e8cd-4a90-9e6b-7dd928cc63b0",
      6: "https://paper.xyz/checkout/142de963-575a-40c4-9fbd-b9a84a2f7ab1",
      7: "https://paper.xyz/checkout/9b74b8e5-d0f3-4b1a-b8d5-cf1b5a6a7dd1",
    },
    "0xdD069e03554d05DAc9320F425798b912F6F3eB48": {
      1: "https://paper.xyz/checkout/d45b66f0-8957-424e-8c7a-dbb058f79f6c",
      2: "https://paper.xyz/checkout/9fef089a-b530-4887-aaad-7e4bfb201d6c",
      3: "https://paper.xyz/checkout/a53fdbe0-8461-4a38-86f4-ec04fadbf2a7",
    },
  },
  DEFAULT_ORGANIZATION_ID: "62acdff96e10a5a7d1f1a527",
  LOGROCKET_ID: "dtovsi/minted-prod",
  OPENSEA_BASE_URL: "https://opensea.io/assets/matic/",
};

export const appConfig: allConfig = process.env.REACT_APP_STAGE === "production" ? prod : dev;
export const DEV_ENV = process.env.REACT_APP_STAGE !== "production";

// Common Exports
// Domain Name
export const SITE_URL = window.location.hostname || "";
export const LINKEDIN_BASE_URL = "https://www.linkedin.com/";
export const FALLBACK_URL_IMAGE = "/img/background/solid.png";
export const HOME_PAGE_YT_URL = "https://www.youtube.com/embed/p1DKsaVgKsQ?wmode=opaque&enablejsapi=1";
export const FOOTER_LINKS: FooterLink = {
  About: {
    Company: "https://gotminted.notion.site/Company-10db78865b2b46d6b53c2a2dd6726699",
    Careers: "https://gotminted.notion.site/Careers-7048ef277a3a4cec85bc47047082e373",
    "Press Kit": "https://gotminted.notion.site/Press-Kit-22d82d93a90146efb021b047e37e8fd2",
  },
  Resources: {
    FAQs: "https://gotminted.notion.site/FAQs-0b42325fae3e42cabb65487d91c08601",
  },
};
export const DEFAULT_BACKGROUND_COLOR = "#808080";
export const IPFS_IMAGE_BASE_URL = "https://gateway.ipfscdn.io/ipfs/";
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const URL_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const NOTION_GENERAL_PAGE_LINK =
  "https://www.notion.so/gotminted/Minted-Technologies-e7a613bbab8c422ab2aab1c50cde8c01";

export const JOIN_CODE_URL_PREFIX = "http://www.gotminted.app/join?code=";

export const DASHBOARD_MODAL_DATA = {
  allow_list: {
    modalTitle: "Allow List",
    modalDesc: "Select users eligible to claim NFT",
    modalBtnTitle: "Save",
    modalSuccessHeader: "Allow List Saved",
    modalSuccessDesc: "You have successfully airdropped user.",
    modalSuccessBtnTitle: "View Dashboard",
  },
  eligibility: {
    modalTitle: "Eligibility Rules",
    modalDesc: "Select the Tokens that are prerequistie",
    modalBtnTitle: "Save",
    modalSuccessHeader: "Eligibility Sent",
    modalSuccessDesc: "Eligibility Rules Sent",
    modalSuccessBtnTitle: "View Dashboard",
  },
  airdrop: {
    modalTitle: "Airdrop Token",
    modalDesc: "Select the users to airdrop token to",
    modalBtnTitle: "Send",
    modalSuccessHeader: "Sent!",
    modalSuccessDesc: "You have successfully airdropped user.",
    modalSuccessBtnTitle: "View Dashboard",
  },
  reclaim_token: {
    modalTitle: "Burn NFT",
    modalDesc:
      "As an admin you can burn a token on another user's wallet, which will remove their ownership of the NFT",
    modalBtnTitle: "Burn",
    modalSuccessHeader: "Sent!",
    modalSuccessDesc: "You have successfully burnt user.",
    modalSuccessBtnTitle: "View Dashboard",
  },
  user_airdrop: {
    modalTitle: "Select Token(s) to Airdrop",
    modalDesc: "Select Token to airdrop user",
    modalBtnTitle: "Airdrop",
    modalSuccessHeader: "Sent!",
    modalSuccessDesc: "You have successfully airdropped user.",
    modalSuccessBtnTitle: "View Dashboard",
  },
  user_sent_message: {
    modalTitle: "Select Token(s) to Airdrop",
    modalDesc: "Select Token to airdrop user",
    modalBtnTitle: "Send",
    modalSuccessHeader: "Sent!",
    modalSuccessDesc: "You have successfully messaged user.",
    modalSuccessBtnTitle: "View Dashboard",
  },
  user_profile_picture: {
    modalTitle: "Update User Profile",
    modalDesc: "Below are lis of NFTs owned by this user",
    modalBtnTitle: "Set as Profile Picture",
    modalSuccessHeader: "User Settings Modified",
    modalSuccessDesc: "You have successfully changed the user settings.",
    modalSuccessBtnTitle: "View Dashboard",
  },
  hide_NFTS: {
    modalSuccessHeader: "Success",
    modalSuccessDesc: "You have successfully hide all NFTs images to users.",
    modalSuccessBtnTitle: "View Dashboard",
  },
  reveal_NFTS: {
    modalSuccessHeader: "Success",
    modalSuccessDesc: "You have successfully reveal all NFTs images to users.",
    modalSuccessBtnTitle: "View Dashboard",
  },
};

export const ErrorMessage = {
  duplicateEmail: "Your email has already registered with minted. Please contact the minted team for support",
  genericLoginErrorMsg: "Something went wrong while logging in to minted. Please try again",
  duplicateDomain: "Your domain is already registered with minted. Please contact the minted support team.",
  genericJoinMintedErrorMsg:
    "Your organization is already signed up with minted. Please contact the minted support team.",
  duplicateInvite: "A user in your invite list has already been invited.",
  genericInviteErrorMsg: "Some things went wrong while sending invites. Please try again.",
};

export const DEFAULT_INTRO_MESSAGES = {
  welcomeMessage:
    "Minted is the destination to commemorate experiences with digital collectibles to grow, engage, and reward communities",
  subtitle: "Explore our collections",
  exploreTitle: "Welcome to the NFTverse",
  exploreSubtitle: "A place to commemorate your career achievements, affiliations, and experiences",
};


export const DEFAULT_EXPORT_NFT_MESSAGES = {
  title: "Take your NFTs with you",
  subtitle: "minted NFTs, although non-transferable, can be moved to a seperate wallet or you can export your current wallet, "
  + "which enables you to take your NFTs with you outside the minted platform.",
  card1Title: "Move to New Wallet",
  card1Subtitle: "(Coming Soon)",
  card1Content: "Moving your NFTs will transfer them to another wallet and you will no longer be able to view your NFTs on the gotminted platform.",
  card2Title: "Export Wallet",
  card2Subtitle: "(Advanced)",
  card2Content: "Exporting your wallet involves revealing your private key and then storing that key in another wallet application "
  + "such as metamask"
}

export const DEFAULT_AI_MESSAGES = {
  aiTitle: "Ask about your NFTs",
  aiPlaceholder: "Powered by our blockchainGPT AI assistant",
  aiResponse:
    "There are Avatars of different types, such as Characters, Animals, and Humanoids. They can also have different Classes, such as Lion, Walrus, Sith Lord, Llama, etc., and different Features, such as Flames, Dark Cloak, Snowy Mountain, Demon Llama, and Controller.",
  aiErrorResponse: "I'm sorry, I don't know",
  aiExampleMessage1: "Example Prompt: How many people have an NFT that's a type of cat, and what types are they",
  aiExampleMessage2: "How many NFTs are super heros?",
};


import { AiOutlineUser } from "react-icons/ai";
import { FaUsers, FaBreadSlice } from "react-icons/fa";

import { GiAchievement, GiSkills } from "react-icons/gi";
import { BsCalendar3EventFill } from "react-icons/bs";

export const createContractData = [
  {
    id: 1,
    icon: AiOutlineUser,
    title: "Avatars",
    description: "Handcrafted avatars roaming your Nftverse. Designed to be 1 on 1 collectibles",
  },
  {
    id: 2,
    icon: FaUsers,
    title: "Memberships",
    description: "NFTs that represent your affiliations - from clubs, teams, guilds, and tribes.",
  },
  {
    id: 3,
    icon: GiAchievement,
    title: "Awards & Achievements",
    description:
      "NFTs that are earned and awarded. Stop wasting paper, create and distribute the evolution of achievements.",
  },
  {
    id: 4,
    icon: BsCalendar3EventFill,
    title: "Events",
    description: "Proof of Attendance Tokens designed to be claimed for specific events",
  },
  {
    id: 5,
    icon: GiSkills,
    title: "Skills",
    description: "Skills badges earned through training, milestones reached. Pre-configured to be added to LinkedIn",
  },
  {
    id: 6,
    icon: FaBreadSlice,
    title: "Token of Appreciation (TOAST)",
    description: "The digital version of a thank you!",
  },
];
export const supportEmail = "jbtech@gotminted.xyz";
