import React from "react";
import { Avatar, AvatarGroup, Stack, Text } from "@chakra-ui/react";
import { defaultAvatarURL } from "../../util/helper";

function OwnerAvatarGroup(props: any) {
  const { owners } = props;
  return (
    <Stack direction={["column", "row"]} align={"center"} pb={[0, 5]} minH={50}>
      {owners.length > 0 && (
        <>
          <AvatarGroup size="sm" max={4} colorScheme={"black"}>
            {owners.slice(0, 4).map((_: any, index: number) => {
              return (
                <Avatar
                  name={"owner"}
                  src={defaultAvatarURL()}
                  size={"sm"}
                  showBorder
                  backgroundColor={"gray.200"}
                  objectFit={"fill"}
                  key={"owner" + index}
                  borderWidth={"3px"}
                  style={{
                    borderWidth: "3px",
                    borderColor: "gray.500",
                    borderStyle: "solid",
                  }}
                />
              );
            })}
          </AvatarGroup>
          <Text textStyle={"label"} color={"black.200"}>
            {`${owners.length > 4 ? "+" : ""} ${owners.length > 4 ? owners.length - 4 : owners.length} people claimed `}
          </Text>
        </>
      )}
    </Stack>
  );
}

export default OwnerAvatarGroup;
