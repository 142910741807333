import { useMutation } from "@apollo/client";
import { CopyIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  StackDirection,
  SystemProps,
  Text,
  Textarea,
  Tooltip,
  useClipboard,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { BsTwitter } from "react-icons/bs";
import { FaInstagramSquare } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ConnectWalletContext, ConnectWalletContextType } from "../../../context/connectWalletContext";

import { UPDATE_USER_MUTATION } from "../../../queries/profile";
import { EMAIL_REGEX, URL_REGEX } from "../../../util/Constants";

const layoutStyles = {
  labelWidth: ["full", "full", "150px"],
  containerWidth: ["xs", "sm", "lg", "md", "2xl"],
  containerDirection: ["column", "column", "column", "row", "row"],
};

type FormValues = {
  username: string;
  email: string;
  bio: string;
  avatarUri: string;
  bannerUri: string;
  twitterUri: string;
  instagramUri: string;
};

const Label = ({ children }: any) => <Text textStyle={"paragraph"}>{children}</Text>;

function ProfileEditForm(props: any) {
  const { user } = props;
  const profileImageInputRef = useRef<HTMLInputElement>(null);
  const coverPhotoInputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const toast = useToast();

  const [updateUser, { data, loading, error }] = useMutation(UPDATE_USER_MUTATION);
  const { address: walletAddress } = useContext(ConnectWalletContext) as ConnectWalletContextType;
  const { hasCopied, onCopy } = useClipboard(walletAddress || "");

  const [profileImage, setProfileImage] = useState(0 as any);
  const [profileBanner, setProfileBanner] = useState(0 as any);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: props.user,
  });

  const onSubmit = handleSubmit((data) => {
    updateUser({
      variables: {
        id: user._id || "",
        ...data,
      },
    });
  });

  useEffect(() => {
    if (data && !loading) {
      toast({
        title: "Profile Updated Successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      navigate("/profile/" + walletAddress);
    }
  }, [data]);

  useEffect(() => {
    if (error && !loading) {
      toast({
        title: "Some things went wrong while updating profile. Please try again",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [error]);

  return (
    <form onSubmit={onSubmit}>
      <Stack alignItems={["center", "flex-start"]} direction={layoutStyles.containerDirection as StackDirection}>
        <Flex w={layoutStyles.containerWidth}>
          <Box>
            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth}>
                <Label>Username</Label>
              </Box>
              <Box w="full">
                <FormControl isInvalid={errors.username ? true : false}>
                  <Input
                    type="text"
                    placeholder="Enter a Username"
                    {...register("username", {
                      required: "This is required",
                      minLength: { value: 4, message: "Minimum length should be 4" },
                    })}
                  />
                  <FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth}>
                <Label>Bio</Label>
              </Box>
              <Box w="full">
                <FormControl isInvalid={errors.bio ? true : false}>
                  <Textarea
                    placeholder="Anything you want to say!"
                    variant={"outline"}
                    // {...register("bio", {
                    //   required: "This is required",
                    // })}
                    {...register("bio")}
                  />
                  <FormErrorMessage>{errors.bio && errors.bio.message}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth}>
                <Label>Email Address</Label>
              </Box>
              <Box w="full">
                <FormControl isInvalid={errors.email ? true : false}>
                  <InputGroup>
                    <Input
                      type="email"
                      placeholder="Enter a Email"
                      // {...register("email", {
                      //   required: "This is required",
                      //   pattern: { value: EMAIL_REGEX, message: "Please enter a valid email address" },
                      // })}
                      {...register("email")}
                      disabled
                      variant={"filled"}
                    />
                    <InputRightElement onClick={onCopy} cursor={"pointer"}>
                      <Tooltip label="This is the email associated with your blockchain wallet and cannot be modified.">
                        <Icon as={InfoIcon} />
                      </Tooltip>
                    </InputRightElement>
                  </InputGroup>

                  <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                </FormControl>
              </Box>
            </Flex>

            {/* <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth}>
                <Label>Links</Label>
              </Box>
              <Box w="full">
                <Input type="text" placeholder="yoursite.io" />
              </Box>
            </Flex> */}

            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth}>
                <Label>Wallet Address</Label>
              </Box>
              <Box w="full">
                <InputGroup>
                  <Input type="text" placeholder={walletAddress || ""} variant={"filled"} disabled />
                  <InputRightElement onClick={onCopy} cursor={"pointer"}>
                    <Tooltip label={hasCopied ? "Copied" : "Copy"}>
                      <Icon as={CopyIcon} />
                    </Tooltip>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </Flex>

            <VStack spacing={0} alignItems={"left"} py={4}>
              <Text textStyle={"paragraph"}>Social Connections</Text>
              <Text textStyle={"labelMedium"} color={"black.200"}>
                Help collectors verify your account by connecting Social Media
              </Text>
            </VStack>

            <VStack alignItems={"left"} spacing={5} py={4}>
              <HStack spacing={5}>
                <Icon as={BsTwitter} w={7} h={7} color={"black.200"} />
                <FormControl isInvalid={errors.twitterUri ? true : false}>
                  <Input
                    type="twitterUri"
                    placeholder="Twitter Profile URL"
                    {...register("twitterUri", {
                      pattern: { value: URL_REGEX, message: "Please enter a valid URL" },
                    })}
                  />
                  <FormErrorMessage>{errors.twitterUri && errors.twitterUri.message}</FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack spacing={5}>
                <Icon as={FaInstagramSquare} w={7} h={7} color={"black.200"} />
                <FormControl isInvalid={errors.instagramUri ? true : false}>
                  <Input
                    type="instagramUri"
                    placeholder="Instagram Profile URL"
                    {...register("instagramUri", {
                      pattern: { value: URL_REGEX, message: "Please enter a valid URL" },
                    })}
                  />
                  <FormErrorMessage>{errors.instagramUri && errors.instagramUri.message}</FormErrorMessage>
                </FormControl>
              </HStack>
            </VStack>
          </Box>
        </Flex>
        <Flex w={layoutStyles.containerWidth} pl={[0, 0, 0, 10]}>
          <Box>
            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth}>
                <Label>Profile Image</Label>
              </Box>
              <Box w="full">
                <FormControl isInvalid={errors.avatarUri ? true : false}>
                  <Input
                    type="avatarUri"
                    placeholder="Avatar Image URL"
                    {...register("avatarUri")}
                    // {...register("avatarUri", {
                    //   required: "This is required",
                    //   pattern: { value: URL_REGEX, message: "Please enter a valid URL" },
                    // })}
                  />
                  <FormErrorMessage>{errors.avatarUri && errors.avatarUri.message}</FormErrorMessage>
                </FormControl>
              </Box>

              {/* <Box w="full">
                <input
                  type="file"
                  onChange={(e) => {
                    setProfileImage(e.target.files?.[0]);
                  }}
                  accept={"image/*"}
                  name={"profile_image"}
                  ref={profileImageInputRef}
                  style={{ display: "none" }}
                />
                <Flex
                  height={"150px"}
                  width={"150px"}
                  bg={useColorModeValue("black.100", "black.200")}
                  justifyContent={"center"}
                  alignItems={"center"}
                  rounded={80}
                  cursor={"pointer"}
                >
                  {profileImage ? (
                    <Image
                      src={URL.createObjectURL(profileImage)}
                      onClick={() => profileImageInputRef?.current?.click()}
                      alt="Image Preview"
                      objectFit={"cover"}
                      height={"150px"}
                      width={"150px"}
                      rounded={80}
                    />
                  ) : (
                    <IconButton
                      icon={<FiUpload />}
                      variant={"ghost"}
                      aria-label="profile-image"
                      size={"lg"}
                      onClick={() => profileImageInputRef?.current?.click()}
                    />
                  )}
                </Flex>
              </Box> */}
            </Flex>
            <Flex
              py={4}
              w={layoutStyles.containerWidth}
              direction={layoutStyles.containerDirection as SystemProps["flexDirection"]}
            >
              <Box w={layoutStyles.labelWidth}>
                <Label>Profile Banner</Label>
              </Box>
              <Box w="full">
                <FormControl isInvalid={errors.bannerUri ? true : false}>
                  <Input
                    type="bannerUri"
                    placeholder="Banner Image URL"
                    {...register("bannerUri", {
                      pattern: { value: URL_REGEX, message: "Please enter a valid URL" },
                    })}
                  />
                  <FormErrorMessage>{errors.bannerUri && errors.bannerUri.message}</FormErrorMessage>
                </FormControl>
              </Box>

              {/* <Box w="full">
                <input
                  type="file"
                  onChange={(e) => {
                    setProfileBanner(e.target.files?.[0]);
                  }}
                  accept={"image/*"}
                  name={"profile_image"}
                  ref={coverPhotoInputRef}
                  style={{ display: "none" }}
                />
                <Flex
                  height={"150px"}
                  width={"150px"}
                  bg={useColorModeValue("black.100", "black.200")}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {profileBanner ? (
                    <Image
                      src={URL.createObjectURL(profileBanner)}
                      onClick={() => coverPhotoInputRef?.current?.click()}
                      alt="Image Preview"
                      objectFit={"cover"}
                      height={"150px"}
                      width={"150px"}
                    />
                  ) : (
                    <IconButton
                      icon={<FiUpload />}
                      aria-label="cover"
                      variant={"ghost"}
                      size={"lg"}
                      onClick={() => {
                        coverPhotoInputRef.current?.click();
                      }}
                    />
                  )}
                </Flex>
              </Box> */}
            </Flex>
          </Box>
        </Flex>
      </Stack>
      <HStack py={10} spacing={10}>
        <Button
          rounded={"lg"}
          onClick={() => {
            navigate("/profile");
          }}
          disabled={loading}
        >
          Cancel
        </Button>
        <Button rounded={"lg"} type="submit" isLoading={loading} loadingText={"Updating Data"}>
          Save
        </Button>
      </HStack>
    </form>
  );
}

export default ProfileEditForm;
