import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import AvatarLabelValue from "./../common/AvatarLabelValue";
import { CgMenuLeftAlt } from "react-icons/cg";
import { getPaperFlowLink, hexToDecimal, openInNewTab } from "../../util/helper";
import { appConfig, DEFAULT_BACKGROUND_COLOR, FALLBACK_URL_IMAGE, IPFS_IMAGE_BASE_URL } from "../../util/Constants";
import NFTClaimButton from "../common/NFTClaimButton";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiExternalLink } from "react-icons/fi";
import { AiOutlineQrcode } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useState } from "react";
import QRCodeModal from "../common/Modal/QRCodeModal";

function ViewNFTSection(props: {
  createNft?: boolean;
  ctaClick?: any;
  meta: any;
  contractAddress: string;
  id: string;
  collectionDataLoading: boolean;
  claimType: string;
  openPaperModal: any;
  contractType: string;
  ownerName: string;
  title?: string;
  collectionImageUrl?: string;
  orgLogoURI?: string;
  accessCode?: string;
}) {
  const {
    meta: { name, description, image },
    contractAddress,
    id,
    claimType,
    contractType,
    ownerName,
    title,
    collectionImageUrl,
    createNft,
    orgLogoURI,
    accessCode,
  } = props;
  const [isQRCodeModalOpen, setQRCodeModalOpen] = useState<boolean>(false);
  return (
    <>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={0}>
        <Stack justify={"center"} align={"center"}>
          <Box boxSize={["xs", "sm", "xs", "sm", "lg"]} style={{ display: "flex", alignItems: "center" }}>
            <Image
              src={image.replace("ipfs://", IPFS_IMAGE_BASE_URL)}
              alt={name}
              borderRadius={20}
              w={"100%"}
              fallbackSrc={FALLBACK_URL_IMAGE}
              maxH={"100%"}
            />
          </Box>
        </Stack>
        <Stack>
          <Text textStyle={"h1"}>{name}</Text>
          <Flex pb={4} justifyContent={"space-between"} alignItems={"center"}>
            <HStack spacing={5} alignItems={"center"}>
              <Link to={`/collection`}>
                <AvatarLabelValue
                  label={"Created By"}
                  value={ownerName}
                  isVerified={ownerName ? true : false}
                  imageURL={orgLogoURI || ""}
                  key={"owner_avatar"}
                  backgroundColor={"gray.100"}
                />
              </Link>
              <Link to={`/collection/${contractAddress}`}>
                <AvatarLabelValue
                  key={"collection_avatar"}
                  label={"Collection"}
                  value={title || ""}
                  isVerified={title ? true : false}
                  imageURL={collectionImageUrl || FALLBACK_URL_IMAGE}
                  backgroundColor={DEFAULT_BACKGROUND_COLOR}
                />
              </Link>
            </HStack>
            {!createNft && (
              <Menu>
                <MenuButton as={Button} variant={"unstyled"} rounded={"full"} size={"lg"}>
                  <BsThreeDotsVertical size={20} style={{ width: "100%" }} />
                </MenuButton>
                <MenuList zIndex={2}>
                  <MenuItem
                    icon={<FiExternalLink />}
                    onClick={() => {
                      openInNewTab(`${appConfig.OPENSEA_BASE_URL}${contractAddress}/${id}`);
                    }}
                  >
                    View on Opensea
                  </MenuItem>
                  <MenuItem icon={<AiOutlineQrcode />} onClick={() => setQRCodeModalOpen(true)}>
                    Generate QR Code
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
          <Box>
            <HStack alignItems={"center"} justifyContent={"flex-start"} pb={5}>
              <Icon as={CgMenuLeftAlt} color={"primary.900"} />
              <Text textStyle={"labelMedium"} fontSize={18} fontWeight={"700"}>
                Description
              </Text>
            </HStack>
            <Text height={[60, 40, 52]} overflow={"auto"} pr={2} whiteSpace={"pre-wrap"}>
              {description}
            </Text>
          </Box>
          <Box py={5}></Box>
          <Flex alignItems={"center"} justifyContent={"center"}>
            {claimType === "paper" ? (
              <Button
                onClick={() => {
                  const link = getPaperFlowLink(contractAddress, parseInt(id) + 1);
                  if (link) {
                    openInNewTab(link);
                  }
                }}
                w={"500px"}
                h={"56px"}
              >
                Claim NFT
              </Button>
            ) : (
              <NFTClaimButton
                token={id}
                variant="solid"
                size={"md"}
                contractAddress={contractAddress}
                contractType={contractType}
              />
            )}
          </Flex>
        </Stack>
      </SimpleGrid>
      <QRCodeModal isOpen={isQRCodeModalOpen} onClose={() => setQRCodeModalOpen(false)} accessCode={accessCode} />
    </>
  );
}

export default ViewNFTSection;
