import * as React from "react";

export enum LoginWithTypeEnum {
  MAGIC_LINK = "magic_link",
  METAMASK = "metamask",
}

export interface UserType {
  _id: string;
  role: string;
  organization: {
    _id: string | null;
  };
  loginWith: LoginWithTypeEnum;
}

export interface ConnectWalletContextType {
  address: string | null;
  updateAddress: (address: string | null) => void;
  disconnectWallet: () => void;
  updateUser: (user: UserType | null) => void;
  user: UserType | null;
  // We need to pass the provider into context (metamask vs magic provider) to use within app
  // provider: any;
  // updateProvider: (provider: any) => void;
}

export const ConnectWalletContext = React.createContext<ConnectWalletContextType | null>(null);

const ConnectWalletProvider = (props: { children: any }) => {
  const { children } = props;
  const [address, setAddress] = React.useState<string | null>(localStorage.getItem("walletAddress") || null);
  const [user, setUser] = React.useState<UserType | null>(JSON.parse(`${localStorage.getItem("user")}`) || null);
  // const [provider, setProvider] = React.useState<any>(localStorage.getItem("provider") || null);

  const updateAddress = (walletAddress: string | null) => {
    setAddress(walletAddress);
  };

  const updateUser = (user: UserType | null) => {
    setUser(user);
  };

  // const updateProvider = (provider: any) => {
  //   setProvider(provider);
  // };

  const disconnectWallet = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("walletAddress");
    localStorage.removeItem("user");
    setAddress(null);
    setUser(null);
  };

  return (
    <ConnectWalletContext.Provider value={{ address, updateAddress, disconnectWallet, user, updateUser }}>
      {children}
    </ConnectWalletContext.Provider>
  );
};

export default ConnectWalletProvider;
