import React from "react";
import styled from "styled-components";
import { SatoshiBoldJaguar20px } from "../../styles/styledMixins";
import { useNavigate } from "react-router-dom";
import { Button } from "@chakra-ui/react";

const Container = styled.div`
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const BoldText = styled.div`
  ${SatoshiBoldJaguar20px}
  max-width: 800px;
  text-align: center;
  color: var(--x79809e);
`;

type Props = {
  message: any;
  buttonLabel?: string;
  destination?: string;
};

function NotFound(props: Props) {
  const { message, buttonLabel, destination } = props;
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <BoldText className={`group-68 `}>{message}</BoldText>
        {buttonLabel && destination && (
          <Button
            rounded={"full"}
            size={"sm"}
            fontWeight={"normal"}
            px={6}
            onClick={() => navigate(destination)}
            mt={5}
          >
            {buttonLabel}
          </Button>
        )}
      </Container>
    </>
  );
}

export default NotFound;
