import { extendTheme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

// Custom Button
const Button = {
  baseStyle: {},
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "sm",
      px: 2, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: "md",
      px: 5, // <-- these values are tokens from the design system
      py: 3, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    gray:{
      bg: 'secondary.900',
      _hover: {
        bg: "gray.300",
      },
      color: 'black',
    },
    unstyled: {
      _hover: {
        boxShadow: " rgb(4 17 29 / 25%) 0px 0px 8px 0px",
      },
    },
    solid: {
      bg: "primary.900",
      color: "white",
      _hover: {
        bg: "primary.800",
      },
      _active: {
        bg: "primary.800",
      },
      _focus: {
        ring: 0,
      },
    },
    filled: {
      bg: "secondary.900",
      color: "primary.900",
      fontFamily: "paragraph",
      fontSize: 14,
    },
    link: ({ colorMode }) => ({
      color: colorMode === "dark" ? "white" : "primary.900",
    }),
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "solid",
  },
};

// Custom Tab
const Tabs = {
  variants: {
    line: {
      tab: {
        _selected: ({ colorMode }) => ({
          borderColor: colorMode === "dark" ? "primary.900" : "primary.800",
          color: colorMode === "dark" ? "primary.900" : "primary.800",
        }),
      },
    },
  },
};

// Custom Switch
const Switch = {
  variants: {
    basic: {
      _active: {
        bg: "primary.900",
      },
    },
  },
  defaultProps: {
    variant: "basic",
  },
};

// Fonts
const fonts = {
  heading: `'Satoshi-Black', sans-serif`,
  body: `'Satoshi-Medium', sans-serif`,
  paragraph: `'Satoshi-Bold', sans-serif`,
};

// Custom textStyles
const textStyles = {
  h1: {
    // you can also use responsive styles
    fontSize: ["28px", "32px", "48px"],
    fontWeight: "bold",
    fontFamily: "Satoshi-Black",
  },
  h2: {
    fontSize: ["20px", "24px"],
    fontWeight: "bold",
    fontFamily: "Satoshi-Black",
  },
  h3: {
    fontSize: ["16px", "20px"],
    fontWeight: "bold",
    fontFamily: "Satoshi-Medium",
  },
  paragraph: {
    fontSize: ["12px", "16px"],
    fontWeight: "bold",
    fontFamily: "Satoshi-Bold",
  },
  paragraphMedium: {
    fontSize: ["12px", "16px"],
    fontFamily: "Satoshi-Medium",
  },
  label: {
    fontSize: ["12px", "12px"],
    fontWeight: "bold",
    fontFamily: "Satoshi-Bold",
  },
  labelMedium: {
    fontSize: ["12px", "14px"],
    fontFamily: "Satoshi-Medium",
  },
  hyperlink:{
    color:"primary.900",
    textDecoration: "underline",
    _hover: {
      color: "primary.800",
      textDecoration: "none"
    },
  }
};

// Colors
const colors = {
  primary: {
    // Added my own
    800: "#3e48da",
    900: "#252FC1",
  },
  secondary: {
    900: "#EAEAF9",
  },
  black: {
    100: "#F2F3F6",
    200: "#79809E",
    300: "#3F4358",
    400: "#060713",
    500: "#11121E",

    // Added by me
    700: "#66666d",
    800: "#262B2F",
  },
};

// Default Config
const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  colors,
  textStyles,
  fonts,
  components: {
    Button,
    Tabs,
    Switch,
    Input: {
      baseStyle: {
        field: {
          borderRadius: "10px",
          border: "1px solid rgb(229, 232, 235)",
        },
      },
      variants: {
        outline: (props) => ({
          field: {
            _focus: {
              zIndex: 1,
              borderColor: "primary.900",
              borderWidth: "1.5px",
              boxShadow: "sm",
            },
          },
        }),
        standard: (props) => ({
          field: {
            border: "none",
            borderRadius: "0px",
            borderBottom: "1px solid rgb(229, 232, 235)",
            _focus: {
              zIndex: 1,
              borderColor: "primary.900",
              borderWidth: "1.5px",
              boxShadow: "sm",
            },
          },
        }),
        unstyled: (props) => ({
          field: {
            outline: "none",
            border: "none",
            borderRadius: "none",
          },
        }),
        filled: (props) => ({
          field: {
            _focus: {
              zIndex: 1,
              borderColor: "primary.900",
              borderWidth: "1.5px",
              boxShadow: "sm",
            },
          },
        }),
      },
    },
    Textarea: {
      baseStyle: {
        borderRadius: "10px",
        border: "1px solid rgb(229, 232, 235)",
      },
      variants: {
        outline: (props) => ({
          _focus: {
            zIndex: 1,
            borderColor: "primary.900",
            borderWidth: "1.5px",
            boxShadow: "sm",
          },
        }),
        filled: (props) => ({
          _focus: {
            zIndex: 1,
            borderColor: "primary.900",
            borderWidth: "1.5px",
            boxShadow: "sm",
          },
        }),
      },
    },
    Checkbox: {
      baseStyle: () => ({
        container: {
          borderColor: "rgb(229, 232, 235)",
        },
        control: {
          _checked: ({ colorMode }) => ({
            bg: "primary.900",
            borderColor: "primary.900",
            color: "white",
            _hover: {
              bg: "primary.800",
            },
          }),
          _hover: {
            borderColor: "primary.800",
          },
        },
      }),
    },
    Select: {
      baseStyle: {
        field: {
          borderRadius: "10px",
          border: "1px solid rgb(229, 232, 235)",
          _focus: {
            zIndex: 1,
            borderColor: "primary.900",
            borderWidth: "1.5px",
          },
        },
      },
      variants: {
        main: {
          field: {
            background: "transparent",
            borderColor: "inherit",
            _focus: {
              borderColor: "primary.900",
              borderWidth: "1.5px",
              background: "transparent",
            },
          },
        },
      },
    },
  },
});

export default theme;
