import { Avatar, HStack, Icon, Text, Tooltip, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { MdVerified } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { getstandardizedOwnerAddress } from "../../util/helper";

function OwnerCard(props: { owner: string; ownerMetaData: any }) {
  const { owner, ownerMetaData } = props;
  const navigate = useNavigate();

  return (
    <Tooltip label={ownerMetaData.username || ownerMetaData.email || ""} placement="right" hasArrow>
      <VStack
        border={2}
        borderRadius={10}
        borderStyle={"solid"}
        borderColor={"black.100"}
        py={5}
        px={4}
        onClick={() => {
          navigate("/profile/" + owner);
        }}
        cursor={"pointer"}
        _hover={{
          boxShadow: " rgb(4 17 29 / 25%) 0px 0px 8px 0px",
        }}
      >
        <Avatar
          src={ownerMetaData.avatarUri || `/img/avatars/${Math.floor(Math.random() * 5) + 1}.svg`}
          backgroundColor={ownerMetaData.avatarUri ? "" : "transparent"}
        />
        <VStack spacing={1} alignItems={"center"}>
          <Text textStyle={"labelMedium"} fontWeight={"700"} maxWidth={125} noOfLines={1}>
            {getstandardizedOwnerAddress(owner)}
          </Text>
          {/* <Icon as={MdVerified} color="blue.500" /> */}
        </VStack>
      </VStack>
    </Tooltip>
  );
}

export default OwnerCard;
