import { Box, Text, useColorModeValue } from "@chakra-ui/react";

interface StatsCardProps {
  title: string;
  number: string;
}

export const StatsCard = (props: StatsCardProps) => {
  const { title, number } = props;
  const backgroundBox = useColorModeValue("white", "gray.800");
  const borderColorBox = useColorModeValue("gray.200", "gray.500");
  return (
    <Box
      bg={backgroundBox}
      p={4}
      rounded={"lg"}
      border={"1px"}
      borderColor={borderColorBox}
      borderStyle={"solid"}
      overflow={"hidden"}
    >
      <Text color="black.200" textStyle={"paragraphMedium"}>
        {title}
      </Text>
      <Text textStyle={"h1"}>{number}</Text>
    </Box>
  );
};
