import React, { Fragment } from "react";
import { Divider, Flex, HStack, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from "@chakra-ui/react";
import { ChevronDownIcon, CopyIcon } from "@chakra-ui/icons";
import { SiEthereum } from "react-icons/si";
import ValutAddress from "../../common/ValutAddress";
import { getstandardizedOwnerAddress } from "../../../util/helper";

interface AddressMenuProps {
  addresses: string[];
}

function AddressMenu(props: AddressMenuProps) {
  const { addresses } = props;
  if (addresses.length === 0) return <></>;
  return (
    <Menu>
      <MenuButton>
        <Flex direction={"row"} justifyContent={"space-between"} alignItems={"center"} w={"full"}>
          <HStack>
            <SiEthereum />
            <Text>{getstandardizedOwnerAddress(addresses[0])}</Text>
          </HStack>
          <HStack pl={1}>
            <ChevronDownIcon />
          </HStack>
        </Flex>
      </MenuButton>
      <MenuList zIndex={2}>
        {addresses.map((item, index) => (
          <Fragment key={`AddressMenu_${item}_${index}`}>
            <MenuItem>
              <ValutAddress walletAddress={item} />
            </MenuItem>
            {index !== addresses.length - 1 && <Divider />}
          </Fragment>
        ))}
      </MenuList>
    </Menu>
  );
}
export default AddressMenu;