import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
//import { useContract, useContractData } from "@thirdweb-dev/react";
import * as FI from "react-icons/fi";

interface propsType {
  isOpen: boolean;
  onClose: any;
  actionType: any;
  actionOption: any;
  loading: boolean;
  errorMessage: string;
  onClickConfirm: any;
}

//Text Data for reveal/hiding the images
const modalTextContent: any = {
  revealed: {
    disable: {
      header: "Hide NFTs?",
      body: "This will hide all NFT images to users",
    },
    enable: {
      header: "Reveal NFTs?",
      body: "This will expose all NFT images to users",
    },
  },
  status: {
    enable: {
      header: "Unpause Contract",
      body: "Unpausing the contract will allow users to claim, get airdropped, and burn",
    },
    disable: {
      header: "Pause Contract",
      body: "Pausing the contracty will prevent minting, airdropping, and burning",
    },
  },
};

export default function ConfirmContractActionModal(props: propsType) {
  const { isOpen, onClose, actionType, actionOption, loading, errorMessage, onClickConfirm } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={"xl"} scrollBehavior={"inside"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <Flex mt={16} justify={"center"} align={"center"} mb={6}>
          <Box style={{ position: "absolute" }} left={64}>
            <Icon as={FI.FiAlertTriangle} w={16} h={16} fontWeight={"light"} />
          </Box>
        </Flex>

        <ModalBody>
          <Flex justify={"center"} direction={"column"} align={"center"} marginTop={"10"}>
            <Text textStyle={"h3"}> {modalTextContent[actionType][actionOption].header} </Text>
            <Text textStyle={"paragraphMedium"} px={16} pb={5}>
              {modalTextContent[actionType][actionOption].body}
            </Text>
          </Flex>
          {errorMessage && (
            <Text textStyle={"paragraphMedium"} color={"red.400"} textAlign={"center"}>
              Something went wrong
            </Text>
          )}
        </ModalBody>

        <ModalFooter justifyContent={"center"}>
          <Button
            colorScheme="blue"
            isLoading={loading}
            loadingText={"Loading"}
            mr={3}
            mb={2}
            onClick={onClickConfirm}
            disabled={loading}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
