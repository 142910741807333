import { useState, useEffect } from "react";
import { useContract, useSDK } from "@thirdweb-dev/react";
import LogRocket from "logrocket";
import { BigNumber, ethers } from "ethers";
import { magic } from "../../services/Magic";
import { hexToDecimal } from "../../util/helper";

interface AdminFunctionsProps {
  contractAddress: string;
}

function useAdminFunctions(props: AdminFunctionsProps) {
  const { contractAddress } = props;
  const sdk = useSDK();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [stateContractAddress, setStateContractAddress] = useState(contractAddress);
  const [allowListUsers, setAllowListUsers] = useState<string[]>([]);
  const [maxSupply, setMaxSupply] = useState<BigNumber>();
  const [isRevealed, setIsRevealed] = useState<boolean>(false);

  const [isTransferable, setIsTransferable] = useState<boolean>(() => false);
  const [isAllowListRequired, setIsAllowListRequired] = useState<boolean>(() => false);
  const [maxSupplyEditNFT, setMaxSupplyEditNFT] = useState<number>(() => 0);
  const { contract } = useContract(stateContractAddress);

  useEffect(() => {
    if (contract && contractAddress) {
      const signer = sdk?.getSigner();
      if (signer === undefined) {
        const provider = new ethers.providers.Web3Provider(magic.rpcProvider as any);
        const signer = provider.getSigner();
        sdk?.updateSignerOrProvider(signer);
        setStateContractAddress(contractAddress);
      } else if (signer && contractAddress !== contract?.getAddress()) {
        const provider = new ethers.providers.Web3Provider(magic.rpcProvider as any);
        const signer = provider.getSigner();
        sdk?.updateSignerOrProvider(signer);
        setStateContractAddress(contractAddress);
      }
    }
  }, [contract, contractAddress]);

  const getAllowList = async (tokenId: string) => {
    try {
      let result = null;
      result = await contract?.call("getAllowList", [tokenId]);
      setAllowListUsers(result);
      console.log("getAllowList", { contract, result });
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("getAllowList", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "getAllowList",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:getAllowList",
        },
      });
      return Promise.reject(error);
    }
  };

  const createAllowList = async (tokenId: string, users: string[]) => {
    try {
      let result = null;
      console.log("createAllowList", { contract, tokenId, users });
      result = await contract?.call("createAllowList", [tokenId, users]);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("createAllowList", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "createAllowList",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:createAllowList",
        },
      });
      return Promise.reject(error);
    }
  };

  const getTokenMaxSupply = async (tokenId: string) => {
    setIsLoading(true);
    try {
      let result = null;
      let decimalTokenId = parseInt(tokenId, 16);
      result = await contract?.call("getTokenMaxSupply", [decimalTokenId]);
      setMaxSupply(result);
      setIsLoading(false);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.error("getTokenMaxSupply", error);
      }
      LogRocket.captureException(error as Error, {
        tags: {
          adminFunction: "getTokenMaxSupply",
        },
        extra: {
          pageName: "admninFunctions:getTokenMaxSupply",
        },
      });
      setIsLoading(false);
      return Promise.reject(error);
    }
  };

  const airdropSingleToMany = async (tokenId: string, users: string[]) => {
    try {
      let result = null;
      console.log("airdropSingleToMany", { contract, tokenId, users });
      console.log("ClientId: " + sdk?.getPublisher().options?.clientId);
      result = await contract?.call("airdropSingleToMany", [users, tokenId, 1, []]);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("airdropSingleToMany", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "airdropSingleToMany",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:airdropSingleToMany",
        },
      });
      return Promise.reject(error);
    }
  };

  const airdropManyToSingle = async (ids_: string[], user: string) => {
    try {
      let result = null;
      const amount = ids_.map((item) => {
        return 1;
      });
      console.log("airdropManyToSingle", { contract, user, ids_, amount, data: [] });
      result = await contract?.call("airdropManyToSingle", [user, ids_, amount, []]);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("airdropManyToSingle", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "airdropManyToSingle",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:airdropManyToSingle",
        },
      });
      return Promise.reject(error);
    }
  };

  const reclaimToken = async (from: string, to: string, amount: number, toBurn: boolean) => {
    try {
      let result = null;
      console.log("reclaimToken", { contract, from, to, amount, toBurn });
      result = await contract?.call("reclaimToken", [from, to, amount, toBurn]);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "reclaimToken",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:reclaimToken",
        },
      });
      return Promise.reject(error);
    }
  };

  const getIsRevealed = async () => {
    try {
      let result = null;
      result = await contract?.call("isRevealed");
      if (result) {
        setIsRevealed(result);
      }
    } catch (error: any) {
      if (typeof error === "object") {
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "isRevealed",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:isRevealed",
        },
      });
    }
  };

  const setIsRevealedOnContract = async (): Promise<boolean> => {
    // TODO placeholder logic
    try {
      const result = await contract?.call("toggleReveal", [!isRevealed]);
      setIsRevealed(!isRevealed);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("setIsRevealed", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "setIsRevealed",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:setIsRevealed",
        },
      });
      return Promise.reject(error);
    }
  };

  const setUri = async (tokenId: string, metaDataURI: string) => {
    try {
      const result = await contract?.call("setUri", [tokenId, metaDataURI]);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("setUri", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "setUri",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:setUri",
        },
      });
      return Promise.reject(error);
    }
  };

  const setTokenMaxSupply = async (tokenId: string, maxSupply: string) => {
    try {
      const result = await contract?.call("setTokenMaxSupply", [tokenId, maxSupply]);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("setTokenMaxSupply", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "setTokenMaxSupply",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:setTokenMaxSupply",
        },
      });
      return Promise.reject(error);
    }
  };

  const toggleAllowList = async (tokenId: string, flag: boolean) => {
    try {
      const result = await contract?.call("toggleAllowList", [tokenId, flag]);
      setIsAllowListRequired(flag);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("toggleAllowList", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "toggleAllowList",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:toggleAllowList",
        },
      });
      return Promise.reject(error);
    }
  };

  const burnNFT = async (walletAddress: string, tokenId: string, value: number) => {
    try {
      console.log("burn", { walletAddress, tokenId, value });
      const result = await contract?.call("burn", [walletAddress, tokenId, value]);
      console.log("burn", { result });
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("burnNFT", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "burnNFT",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:burnNFT",
        },
      });
      return Promise.reject(error);
    }
  };

  const mintNFT = async (tokenId: string, qty: string) => {
    try {
      console.log("mintNFT", { tokenId, qty, data: [] });
      const result = await contract?.call("mint", [tokenId, qty, []]);
      console.log("mint", { result });
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("mint", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "mint",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:mint",
        },
      });
      return Promise.reject(error);
    }
  };

  const getIsAllowListRequired = async (tokenId: string) => {
    try {
      let result = null;
      result = await contract?.call("isAllowListRequired", [tokenId]);
      console.log("getIsAllowListRequired", { result });
      if (result) {
        setIsAllowListRequired(result);
      }
    } catch (error: any) {
      if (typeof error === "object") {
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "isAllowListRequired",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:isAllowListRequired",
        },
      });
    }
  };

  const setTokenMaxSupplyEditNFT = async (tokenId: string, maxSupplyValue: number) => {
    try {
      console.log("setTokenMaxSupply", { tokenId, maxSupply });
      const result = await contract?.call("setTokenMaxSupply", [tokenId, maxSupplyValue]);
      setMaxSupplyEditNFT(maxSupplyValue);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("setTokenMaxSupply", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "setTokenMaxSupply",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:setTokenMaxSupply",
        },
      });
      return Promise.reject(error);
    }
  };

  const getIsTransferable = async (tokenId: string) => {
    try {
      let result = null;
      result = await contract?.call("isTransferable", [tokenId]);
      console.log("getIsTransferable", { result });
      if (result) {
        setIsTransferable(result);
      }
    } catch (error: any) {
      if (typeof error === "object") {
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "isTransferable",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:isTransferable",
        },
      });
    }
  };

  const setTransferable = async (tokenId: string, flag: boolean) => {
    try {
      console.log("setTransferable", { tokenId, flag });
      const result = await contract?.call("setTransferable", [tokenId, flag]);
      setIsTransferable(flag);
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("setTransferable", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "setTransferable",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:setTransferable",
        },
      });
      return Promise.reject(error);
    }
  };

  const getTokenMaxSupplyEditNFT = async (tokenId: string) => {
    try {
      let result = null;
      result = await contract?.call("getTokenMaxSupply", [tokenId]);
      if (result && result?._hex) {
        const supplyValue = hexToDecimal(result._hex);
        console.log("getTokenMaxSupply", { result, supplyValue });
        setMaxSupplyEditNFT(supplyValue);
      }
    } catch (error: any) {
      if (typeof error === "object") {
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "getTokenMaxSupply",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:getTokenMaxSupply",
        },
      });
    }
  };

  const getNextTokenId = async () => {
    try {
      let result = null;
      result = await contract?.call("getNextTokenId");
      return result?._hex || "";
    } catch (error: any) {
      if (typeof error === "object") {
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "getNextTokenId",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:getNextTokenId",
        },
      });
    }
  };

  const mintNFTV3 = async (
    _tokenUri: string,
    _maxSupply: number,
    _isTransferable: boolean,
    _isAllowListRequired: boolean,
    _isRevealed: boolean
  ) => {
    try {
      let result = null;
      console.log("createNextToken", {
        _tokenUri,
        _maxSupply,
        _isTransferable,
        _isAllowListRequired,
        _isRevealed,
      });
      result = await contract?.call(
        "createNextToken",
       [_tokenUri,
        _maxSupply,
        _isTransferable,
        _isAllowListRequired,
        _isRevealed]
      );
      console.log("createNextToken", { result });
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "createNextToken",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:createNextToken",
        },
      });
    }
  };

  const airdropSingleToSingle = async (to: string, id: string) => {
    try {
      let result = null;
      console.log("airdropSingleToSingle", { to, id });
      result = await contract?.call("airdropSingleToSingle", [to, id, 1, []]);
      console.log("airdropSingleToSingle", { result });
      return result;
    } catch (error: any) {
      if (typeof error === "object") {
        console.log("airdropSingleToSingle", Object.keys(error));
        Object.keys(error).map((item: any) => {
          console.log({ item: error[item] });
        });
      }
      LogRocket.captureException(error as Error, {
        tags: {
          // additional data to be grouped as "tags"
          adminFunction: "airdropSingleToSingle",
        },
        extra: {
          // additional arbitrary data associated with the event
          pageName: "admninFunctions:airdropSingleToSingle",
        },
      });
      return Promise.reject(error);
    }
  };

  return {
    contract,
    allowListUsers,
    reclaimToken,
    isRevealed,
    getAllowList,
    createAllowList,
    airdropSingleToMany,
    airdropManyToSingle,
    getIsRevealed,
    setIsRevealedOnContract,
    setUri,
    setTokenMaxSupply,
    burnNFT,
    mintNFT,
    maxSupply,
    getTokenMaxSupply,
    isLoading,
    // Edit NFT Page Calls
    setTokenMaxSupplyEditNFT,
    getTokenMaxSupplyEditNFT,
    maxSupplyEditNFT,
    setTransferable,
    getIsTransferable,
    isTransferable,
    toggleAllowList,
    getIsAllowListRequired,
    isAllowListRequired,
    // V3: Contract Functions
    getNextTokenId,
    mintNFTV3,
    airdropSingleToSingle,
  };
}

export default useAdminFunctions;
