import { useQuery } from "@apollo/client";
import { Box, Text } from "@chakra-ui/react";
import { useContext, useMemo } from "react";
import { ConnectWalletContext, ConnectWalletContextType } from "../../context/connectWalletContext";
import { FETCH_ORGANIZATION_CONTRACT_ADDRESS } from "../../queries/organization";
import Loader from "../common/Loader";
import MasterBox from "../Layout/MasterBox";
import CreateNFTForm from "./Form/CreateNFTForm";

function CreateNFT() {
  const { user } = useContext(ConnectWalletContext) as ConnectWalletContextType;
  // Fetching Organization Contract Address
  const {
    data: orgUsersData,
    loading: orgUsersDataLoading,
    error: orgUsersDataError,
  } = useQuery(FETCH_ORGANIZATION_CONTRACT_ADDRESS, {
    variables: { ids: [user?.organization?._id || ""] },
  });

  // All Collections
  const collections = useMemo(() => {
    if (Array.isArray(orgUsersData?.organizations?.[0]?.collections)) {
      return orgUsersData?.organizations?.[0]?.collections?.filter(
        (collection: any) => collection.isVisible && collection.versionNumber === 3
      );
    }
    return [];
  }, [orgUsersData]);

  return (
    <MasterBox minH={"65vh"}>
      <Box mt={[5, 10]} mb={[2, 5]}>
        <Text textStyle={"h1"} fontWeight={"normal"}>
          Create NFT
        </Text>
      </Box>
      {orgUsersDataLoading && <Loader />}
      {orgUsersDataError && (
        <Text textStyle={"paragraphMedium"} color={"red.400"}>
          Something went wrong while fetching your organization. please try again or contact with admin. Thanks
          {JSON.stringify(orgUsersDataError)}
        </Text>
      )}
      {!orgUsersDataLoading && !orgUsersDataError && (
        <>
          {orgUsersData && orgUsersData?.organizations?.[0]?.collections?.length === 0 ? (
            <Text textStyle={"paragraphMedium"} color={"red.400"}>
              Your organization have zero collections. please contact with admin. Thanks
            </Text>
          ) : (
            <CreateNFTForm collections={collections} organizationName={orgUsersData?.organizations?.[0]?.name || ""} />
          )}
        </>
      )}
    </MasterBox>
  );
}

export default CreateNFT;
