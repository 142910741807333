import CommonModal from "../Modal";

interface NetworkMismatchProps {
  isOpen: boolean;
  onClose: any;
}

function NetworkMismatch({ isOpen, onClose }: NetworkMismatchProps) {
  return (
    <CommonModal
      isOpen={isOpen}
      onClose={onClose}
      title={"Warning"}
      message={"The connected wallet is not on the correct network. Please change that!"}
    />
  );
}

export default NetworkMismatch;
