import { HStack, Spinner, Text } from "@chakra-ui/react";

function Loader({ loadingText }: { loadingText?: string }) {
  return (
    <HStack p={10}>
      <Spinner color="grey" mr={2} />
      <Text textStyle={"h3"} color="grey" fontWeight={"normal"}>
        {loadingText || "Loading"}
      </Text>
    </HStack>
  );
}

export default Loader;
